import React from 'react'


import { Container, Image } from './index.styled';

export default function FullScreenImage(props) {
  return (
    <Container onClick={() => props.isOpen(false)}>
      <Image src={props.url} />
    </Container>
  )
}
