import styled from 'styled-components';

const DEFAULT_WIDTH = 'auto';
const DEFAULT_HEIGHT = '12rem';

const DEFAULT_DARK_MODE = '#303030';
const DEFAULT_LIGHT_MODE = '#FFFFFF';

const DEFAULT_TEXT_LIGHT = '#444242';
const DEFAULT_TEXT_DARK = '#FFFFFF';

const DEFAULT_BORDER_LIGHT = '#828282';
const DEFAULT_BORDER_DARK = '#464646';

const DEFAULT_PLACEHOLDER_DARK = '#BBBBBB';
const DEFAULT_PLACEHOLDER_LIGHT = '#828282';

const DEFAULT_ACTIVE_DARK = '#787878';
const DEFAULT_ACTIVE_LIGHT = '';

const Field = styled.div`
    position: relative;
    width: ${props => props.width ? props.width : DEFAULT_WIDTH};
    height: ${props => props.height ? props.height : DEFAULT_HEIGHT};

    label {
      position: absolute; 
      top: .6rem;
      left: .9rem;

      pointer-events: none;

      color: ${props => props.mode === 'light' ? DEFAULT_PLACEHOLDER_LIGHT : DEFAULT_PLACEHOLDER_DARK};

      font-weight: 600;
      font-size: 16px;

      transition: .25s ease-in-out;
    }

    textarea {
      display: flex;
      flex-direction: row;
      align-items: center;

      resize: none;

      width: 100%;
      height: 100%;
      padding: 1.5rem .8rem 1rem .8rem;
      outline: none;

      color: ${props => props.mode === 'light' ? DEFAULT_TEXT_LIGHT : DEFAULT_TEXT_DARK};
      
      background: ${props => props.mode === 'light' ? DEFAULT_LIGHT_MODE : DEFAULT_DARK_MODE};
      font-size: 16px;
      font-weight: 600;
      border: 1px solid ${props => props.mode === 'light' ? DEFAULT_BORDER_LIGHT : DEFAULT_BORDER_DARK};
      border-radius: 8px;
    } 
    
    textarea:focus ~ label, textarea:valid ~ label{
      font-size: 12px;
      top: .5rem;
      color: ${props => props.mode === 'light' ? DEFAULT_ACTIVE_LIGHT : DEFAULT_ACTIVE_DARK};
    }
`;

export default Field;