import Field from './index.styled.js';




function Select(props) {
  return (
    <Field mode={props.mode} width={props.width} height={props.height}>
      <select {...props} placeholder='' />
      <label>{props.placeholder}</label>
      <Expand />
    </Field>
  )
}

export default Select;






const Expand = () => (
  <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L8 8L14 2" stroke="#E1E1E1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)