import React from 'react';


import { Container } from './index.styled';




export function RotinaAluno({rotina, setRotina, setEditado, carregando}) {
	return (
		<Container>
			<div>
				<h3>Segunda</h3>
				{rotina?.seg?.map((item, index) => (
					<textarea
            // disabled={carregando}
						key={index}
						value={item}
						onChange={e => {
							rotina.seg[index] = e.target.value;
							setRotina({ ...rotina });
              setEditado(true);
						}}
					/>
				))}
			</div>

			<div>
				<h3>Terça</h3>
				{rotina?.ter?.map((item, index) => (
					<textarea
            // disabled={carregando}
						key={index}
						value={item}
						onChange={e => {
							rotina.ter[index] = e.target.value;
							setRotina({ ...rotina });
              setEditado(true);
						}}
					/>
				))}
			</div>

			<div>
				<h3>Quarta</h3>
				{rotina?.qua?.map((item, index) => (
					<textarea
            // disabled={carregando}
						key={index}
						value={item}
						onChange={e => {
							rotina.qua[index] = e.target.value;
							setRotina({ ...rotina });
              setEditado(true);
						}}
					/>
				))}
			</div>

			<div>
				<h3>Quinta</h3>
				{rotina?.qui?.map((item, index) => (
					<textarea
            // disabled={carregando}
						key={index}
						value={item}
						onChange={e => {
							rotina.qui[index] = e.target.value;
							setRotina({ ...rotina });
              setEditado(true);
						}}
					/>
				))}
			</div>

			<div>
				<h3>Sexta</h3>
				{rotina?.sex?.map((item, index) => (
					<textarea
            // disabled={carregando}
						key={index}
						value={item}
						onChange={e => {
							rotina.sex[index] = e.target.value;
							setRotina({ ...rotina });
              setEditado(true);
						}}
					/>
				))}
			</div>

			<div>
				<h3>Sábado</h3>
				{rotina?.sab?.map((item, index) => (
					<textarea
            // disabled={carregando}
						key={index}
						value={item}
						onChange={e => {
							rotina.sab[index] = e.target.value;
							setRotina({ ...rotina });
              setEditado(true);
						}}
					/>
				))}
			</div>

			<div>
				<h3>Domingo</h3>
				{rotina?.dom?.map((item, index) => (
					<textarea
            // disabled={carregando}
						key={index}
						value={item}
						onChange={e => {
							rotina.dom[index] = e.target.value;
							setRotina({ ...rotina });
              setEditado(true);
						}}
					/>
				))}
			</div>
		</Container>
	);
}
