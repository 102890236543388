import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: start;
  align-items: flex-start;
  
  background-color: #2C2A2A;
  color: #fff;
  user-select: none;
  
  border: 1px solid #000;
  width: 237px;
  min-width: 237px;
  height: 272px;

  padding: 18px 15px;
  transition: .3s ease-in-out;


  &:hover {
    transform: scale(1.1);
  }

  > div {
    margin-top: 25px;
    margin-bottom: auto;
    width: 90%;
  }

  
  h3 {
    font-size:10px;
    color:#fff;
    font-family: 'Poppins-bold';
  }

  h1 {
    font-size: 20px;
    color:#fff;
  }


  

  p {
    font-family: Poppins-Regular;
    font-size: 12px;
    width: 200px;
    height: 110px;

    padding-right: 20px;

    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  p:hover {
    overflow: auto;
    -webkit-line-clamp: unset;
  }

            
  p::-webkit-scrollbar {
    width: 6px;
    border-radius: 999px;
  }
  
  p::-webkit-scrollbar-thumb {
    background: #414141; 
    border-radius: 10px;
  }
  
  p::-webkit-scrollbar-thumb:hover {
    background: #555555; 
  }
  
  

`



const BotaoSolido = styled.button`
	font-family: ${props => props.fontFamily ?? 'Poppins-Bold'};
	font-size: ${props => props.fontSize ?? '16px'};
	color: ${props => props.color ?? '#fff'};
	background: ${props => props.background ?? '#3A73B9'};
	border-radius: ${props => props.borderRadius ?? '60px'};
	padding: ${props => props.padding ?? '5px 22px'};
	margin: ${props => props.margin ?? 'none'};
	outline: none;
	border: none;
	user-select: none;
	cursor: pointer;
`;

export { BotaoSolido };
