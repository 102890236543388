import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './index.scss';


import TextoContainer from '../../../comps/TextoContainer';
import Botao from '../../../../../components/styled/botoes/redondo/index.styled';





export default function Finalizado({ dados }) {
  const [textToDisplay, setTextToDisplay] = useState();

  const {cla, espiral} = useParams();
  const navigate = useNavigate();


  function montarTextoLeitura() {
    if(dados.viewsPainelDireita && dados.viewsPainelEsquerda)
      setTextToDisplay(`Você viu o painel direito ${dados.viewsPainelDireita} vez(es) e o painel esquerdo ${dados.viewsPainelEsquerda} vez(es)`);
    
    else if(dados.viewsPainelDireita && !dados.viewsPainelEsquerda)
      setTextToDisplay(`Você viu o painel direito ${dados.viewsPainelDireita} vez(es)`);
    
    else if(!dados.viewsPainelDireita && dados.viewsPainelEsquerda)
      setTextToDisplay(`Você viu o painel esquerdo ${dados.viewsPainelEsquerda} vez(es)`);
    else
      setTextToDisplay(`É muito importante ler os painéis para o maior proveito do treino!`);
  }




  useEffect(()=>{
    montarTextoLeitura();
    
    //eslint-disable-next-line
  },[]);



  return (
    <main className='tela-finalizado'>
      <div>
        {dados.tipo === 'quiz' && 
          <TextoContainer
            titulo='Treino Finalizado!'
            descricao={dados.passou ? `Parabéns você passou! Você acertou ${dados.acertos} de ${dados.perguntas}` 
                                    : `Ops, veja a correção, você acertou ${dados.acertos} de ${dados.perguntas}`}
                                    
            largura={'300px'}
          />
        }

        {dados.tipo === 'leitura' && 
          
          <TextoContainer
            titulo='Treino Finalizado!'
            descricao={textToDisplay}
            largura={'350px'}
          />
        }
        
        <Botao 
          onClick={() => navigate(`/treinoteorico/cla/${cla}/espiral/${espiral}/treinos`)} 
          largura='120px' 
          altura='40px'
          padding='10px 15px'
        > 
          Voltar 
        </Botao>
      </div>
    </main>
  )
}
