import React, { useEffect, useState } from 'react';
import doIt from '../../../../servicos/doIt';
import './index.scss';


import CardCla from '../../../../components/cardCla';
import { Carrossel } from '../../../../components/carrossel';
import { TituloAdmin1, TituloAdmin2 } from '../../../../components/styled/textos/titulosAdmin';
import PaginaAdmin from '../../../../components/paginas/paginaAdmin';


import * as api from '../../../../api/admin/contaAPI';
import { menu } from '../menu';





export default function Index() {
  const [clas, setClas] = useState();



  useEffect(() => {
    async function listagem() {
      doIt(async () => {
        const r = await api.listarClas();
        setClas(r ?? []);
      })
    }

    listagem();
    //eslint-disable-next-line
  }, []);



  return (
    <PaginaAdmin
      className='conteudo_cla_admin'
      carregando={clas === undefined}
      menu={menu}
    >

      <div className='conteudo_cla_admin-sub'>
        <TituloAdmin1> Clãs </TituloAdmin1>

        <section className='clas'>
          {clas?.length === 0 ?
            <TituloAdmin2> Não há cursos cadastrados no momento. </TituloAdmin2>
            :
            <Carrossel>
              {clas?.map(({ info, estilo, id }, index) => (
                <CardCla
                  titulo={info.nome}
                  descricao={info.descricao}
                  background={estilo.fundo}
                  icone={estilo.icone}
                  rota='/admin/conteudo/cla/:cla/espiral'
                  parametros={[id]}
                  key={index}
                />
              ))}
            </Carrossel>
          }
        </section>
      </div>

    </PaginaAdmin>

  );
}
