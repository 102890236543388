import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    padding: 0.8em;
    
    width: 280px;
    height: 166px;
    background: #2C2A2A;
    box-shadow: 0px 0px 6px #535353;
    
    transition: .3s;

    &:hover {
        transform: ${props => props.classe === 'bloqueado' ? 'unset' : 'scale(1.1)'};
    }

   .espiral-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .espiral-icones {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .espiral-infos {
            margin-top: 10px;
            font-size: 12px;

            p {
              text-overflow: ellipsis;
              width: 220px;
              overflow: hidden;
              height: 70px;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              padding-right: 20px;
            }

            p:hover {
              overflow: auto;
              -webkit-line-clamp: unset;
            }

                      
            p::-webkit-scrollbar {
              width: 6px;
              border-radius: 999px;
            }
            
            p::-webkit-scrollbar-thumb {
              background: #414141; 
              border-radius: 10px;
            }
            
            p::-webkit-scrollbar-thumb:hover {
              background: #555555; 
            }
        }
    }


    .espiral-acesso {
        align-self: flex-end;
        
        button {
            background-color:transparent;
            border: none;
            font-size: 12px;
            font-weight: 700;                
        }

    }


`



const Button = styled.button`
  background-color:transparent;
  border: none;
 
  font-size: 12px;
  font-weight: 700;
  color: ${props => props.bloqueado ? '#484848' : '#ffffff'};
  cursor:${props => props.bloqueado ? 'not-allowed' : 'pointer'}; ;
  
  
  transition: .3s;
  &:hover {
    color: ${props => props.bloqueado ? 'inherit' : '#cdcdcd'};
  }
`;



const H1 = styled.h1`
  color: ${props => props.bloqueado ? '#484848' : '#BDC741'};
  font-size: 24px;
  font-weight: 600;
`;



export { Button, H1 };