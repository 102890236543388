import 'rc-slider/assets/index.css';

import { AumentarIcone, MudoIcone, PauseIcone, PlayIcone, TempoIcone, VolumeIcone } from './icones';
import { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import { Container, Painel } from "./index.styled";
import DmkSlider from "./slider";
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';








export default function DmkPlayer({ url, titulo, descricao, podeAvancar, onFimVideo, className }) {
  const [velocidade, setVelocidade] = useState(1);
  
  const [tocando, setTocando] = useState(false);
  const [seeking, setSeeking] = useState(false);

  const [volume, setVolume] = useState(1);
  const [mudo, setMudo] = useState(false);
  
  const [duracao, setDuracao] = useState(0);
  const [tempo, setTempo] = useState(0);

  const player = useRef();


  function tempoRestante() {
    if (duracao === 0) return '00:00';
    const restante = duracao - tempo;
    const min = restante / 60;
    const seg = parseInt((min - parseInt(min)) * 60);    
    return `${parseInt(min).toString().padStart(2, '0')}:${seg.toString().padStart(2, '0')}`;
  }

  const alterarVelocidade = () => {
    if (velocidade !== 2) {
      setVelocidade(velocidade + .25);
    } else {
      setVelocidade(1);
    }
  }



  const mutar = () => setMudo(!mudo)
  const tocar = () => setTocando(!tocando)
  const fullScreen = () => screenfull.request(findDOMNode(player.current))

  
  
  const atualizarProgresso = ({ playedSeconds }) => {
    if (seeking === false) {
      setTempo(playedSeconds)
    }
  }
  

  const atualizarTempo = (valor) => {
    const irPara = valor + 2 >= duracao ? duracao : valor;
    player.current.seekTo(irPara, 'seconds')
    setTempo(irPara);
  }


  const videoPronto = () => {
    const qualidade = player.current.getInternalPlayer().getAvailableQualityLevels()[0];
    player.current.getInternalPlayer().setPlaybackQuality(qualidade);
  }


  const fimVideo = () => {
    setTocando(false);
    setTempo(duracao);
    onFimVideo();
  }




  return (
    <Container className={className}>

      <ReactPlayer
        className='dmk-react-player'
        playbackRate={velocidade}
        playing={tocando}
        volume={volume}
        muted={mudo}
        ref={player}
        url={url}
        

        onReady={videoPronto}
        onPlay={_ => setTocando(true)}
        onPause={_ => setTocando(false)}
        onEnded={fimVideo}
        onProgress={atualizarProgresso}
        onDuration={setDuracao}
      />


      <Painel>
        
        <DmkSlider
          className='slider-tempo'
          min={0}
          max={duracao}
          value={tempo}
          onChange={novoTempo => podeAvancar || novoTempo < tempo ? atualizarTempo(novoTempo) : null}
          onMouseDown={() => setSeeking(true)}
          onMouseUp={() => setTimeout(_=> setSeeking(false), 1000)}
        />
        


        <section>

          <div className='titulo'>
            {tocando ? <PauseIcone onClick={tocar} /> : <PlayIcone onClick={tocar} />}
            <div title={titulo}> {titulo} </div>
          </div>

          
          <div className='opcoes'>
            {<AumentarIcone onClick={fullScreen} />}

            <div>
              {mudo ? <MudoIcone onClick={mutar} /> : <VolumeIcone onClick={mutar} />}
              <DmkSlider
                className='slider-volume'
                value={mudo ? 0 : volume * 100}
                onChange={v => setVolume(v / 100)}
                onMouseDown={() => setSeeking(true)}
                onMouseUp={() => setSeeking(false)}
              />
            </div>

            <div className='velocidade' onClick={alterarVelocidade}>
              {velocidade.toLocaleString(undefined,{ minimumFractionDigits: 1 })}x
            </div>

            <div>
              <TempoIcone className="tempo-icone"/>
              <div className='tempo'> {tempoRestante()} </div>
            </div>
          </div>

        </section>


        <section className='descricao'>
          <div>
            <span>Descrição</span>
          </div>
          <div>
            <ReactMarkdown 
              disallowedElements={['img', 'iframe', 'video', 'table', 'tr', 'th','thead', 'tbody', 'audio', 'style', 'head', 'script']}
              remarkPlugins={[remarkBreaks]}>
              {descricao}
            </ReactMarkdown>
          </div>
        </section>
      </Painel>

    </Container>
  )
}

