import styled from 'styled-components';


const Container = styled.div`
    background: linear-gradient(180deg, #181718 0%, #272331 100%);
    padding: 5em 0em 0em 0em;

    .estrela {
        display: flex;
        justify-content: flex-end;
        animation: fadeInRight; 
        animation-duration: 1.3s;
        width: 90%;
    }

    .agrupamento-conteudo-trilhas {
        display: flex;
        flex-direction: column;
        align-items: center;

        .titulo-trilhas {
            font-family: Mukta-bold;
            font-size: 2.5em;
            color: #EFAF14;
        }

        .descricao-trilhas {
            font-family: Mukta-light;
            font-size: 1em;
            text-align: center;
            width: 65%;

            margin-bottom: 4em;
        }

        .agrupamento-trilhas {
            display: flex;
            justify-content: center;
            display: flex;
            flex-flow: row wrap;
            gap: 30px 50px;
            min-height: 400px;
            width: 85vw;
        }
    }
    
    .barrinha {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        animation: fadeInRight; 
        animation-duration: 1.3s;
    }

    @media (min-width: 1920px) {
        .agrupamento-conteudo-trilhas {
            .descricao-trilhas {
                width: 55%;
            }

            .agrupamento-trilhas {
                width: 60vw;
            }
        }
    }

    @media (max-width: 1440px) {
        .agrupamento-conteudo-trilhas {
            .agrupamento-trilhas {
                width: 79vw;
            }
        }
    }

    @media (max-width: 1366px) {
        .agrupamento-conteudo-trilhas {
            .agrupamento-trilhas {
                width: 83vw;
            }
        }
    }

    @media (max-width: 1280px) {
        .agrupamento-conteudo-trilhas {
            .agrupamento-trilhas {
                width: 89vw;
            }
        }
    }

    @media (max-width: 1024px) {
        padding: 0em;

        .estrela {
            display: none;
        }

        .agrupamento-conteudo-trilhas {
            .titulo-trilhas {
                font-size: 1.8em;
                width: 9em;
                line-height: 1.2em;
                text-align: center;
            }

            .descricao-trilhas {
                margin: 2em 0em 0em 0em;
            }

            .agrupamento-trilhas {
                margin-bottom: 2em;
            }
        }
    }
`

export { Container }