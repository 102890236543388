import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';


import { HeaderStyled } from './index.styled.js';
import BotaoStyled from '../styled/botoes/redondo/index.styled.js';



export default function MenuTreinoTeorico() {
  const { cla, espiral } = useParams();
  const navigate = useNavigate();
  
  return (
    <HeaderStyled>
      <div>
          <div>
            <img src="/assets/images/logos/dmklogousuarioamarelo.png" alt="" />
            <span>Devmonk</span>
          </div>
          <BotaoStyled onClick={() => navigate(`/treinoteorico/cla/${cla}/espiral/${espiral}/treinos`)} background='#373737' color='#B7BB0A' padding='7px 15px'> Voltar </BotaoStyled>
      </div>
    </HeaderStyled>
  )
}
