import styled from "styled-components";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #D0BE00;

    font-weight: 500;
    font-size: 22px;
    border-radius: 100%;    
    background-color: #3F4650;
    height: 45px;
    width: 45px;

    @media (max-width: ${p => p.mediaMaxWidth ?? '1024px'}) {
      display: none;
    }
`

export { Container }