import * as React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import storage from 'local-storage';
import { API_ADDRESS } from '../../../../api/apiConfig.js';
import * as api from '../../../../api/admin/contaAPI.js';
import doIt from '../../../../servicos/doIt.js';





import SApp, {
  Informacoes,
  CabecalhoAdmin,
  Box,
  GerenciarAluno,
  ListagemAlunos,
  Overflow,
  AvatarAluno,
  Faixa
} from './index.styled.js';
import InputRedondo from '../../../../components/styled/inputs/redondo/index.styled.js';
import InputComLabel from '../../../../components/styled/inputs/comLabel/index.js';
import Checkbox from '../../../../components/styled/inputs/checkbox/index.js';
import CheckboxComPadding from '../../../../components/styled/inputs/checkboxComPadding/index.js';
import Button from '../../../../components/styled/botoes/arredondado/index.styled.js';
import TabelaGerenciarAlunos from '../../../../components/tabelas/gerenciarAlunos/index.js';
import LoadingFullscreen from '../../../../components/loading/fullscreen/index.js';
import { Menu as MenuAdmin } from '../../../../components/menu/index.js';
import { menu } from '../menu.js';





function App() {
  const [nome, setNome] = React.useState('');
  const [imagem, setImagem] = React.useState('');

  const [pesquisaAluno, setPesquisaAluno] = React.useState('');

  const [novaImagem, setNovaImagem] = React.useState();

  const [clas, setClas] = React.useState([]);
  const [alunos, setAlunos] = React.useState([
    {
      id: '',
      info: {
        nome: '',
        email: '',
        celular: '',
        sexo: '',
        nascimento: '',
        imagem: ''
      },
      clas: []
    }
  ]);

  const [aluno, setAluno] = React.useState({
    id: '',
    info: {
      nome: '-',
      email: '-',
      celular: '-',
      sexo: '-',
      nascimento: '-',
      imagem: ''
    }
  });
  const [alunoAtivo, setAlunoAtivo] = React.useState(false);
  const [alunoClas, setAlunoClas] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [loadingTudo, setLoadingTudo] = React.useState(true);

  const [updated, setUpdated] = React.useState(false);




  const navigate = useNavigate();




  async function obterTudo() {
    await doIt(async () => {
      const administrador = await api.obterInformacoesAdministradorLogado();
      const listagemClas = await api.listarClas();
      const listagemAlunos = await api.listarAlunos();

      setNome(administrador.info.nome);
      setImagem(administrador.info.imagem);
      setAlunos(listagemAlunos);

      setClas(listagemClas);

      setLoadingTudo(false);
    }, {
      onError: function () {
        setLoadingTudo(false)
      }
    });
  }



  async function atualizarAvatarPerfil() {
    await doIt(async () => {
      setLoading(true);

      await api.editarAvatar(novaImagem);
      toast('Imagem atualizada com sucesso.');

      setLoading(false);

      await obterTudo();
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }




  function redefinirCampos() {
    setAluno({
      id: '',
      info: {
        nome: '',
        email: '',
        celular: '',
        sexo: '',
        nascimento: '',
        imagem: ''
      }
    });
    setAlunoAtivo(false);
    setAlunoClas([]);
    setUpdated(!updated);
  }




  async function handlePesquisarAlunos() {
    await doIt(async () => {
      const listagemAlunos = await api.pesquisarAlunos(pesquisaAluno);
      setAlunos(listagemAlunos);
    });
  }




  async function handleSalvarAlteracoesAluno() {
    await doIt(async () => {
      setLoading(true);
      
      await api.alterarAtivoAluno(aluno.id, alunoAtivo);
      await api.atualizarListaClasAluno(aluno.id, alunoClas);
      toast(`Alterações do aluno selecionado foram feitas com sucesso!`);
      redefinirCampos();
      await obterTudo();

      setLoading(false);
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }




  function handleSelecionarCla(claId) {
    const array = alunoClas;
    const itemPos = array.indexOf(claId);

    setAlunoClas([]);

    if (itemPos < 0)
      array.push(claId);
    else
      array.splice(itemPos, 1);
    
    setAlunoClas([...array]);
  }




  async function handleSelecionarAluno(aluno) {
    redefinirCampos();
    setAluno(aluno);
    setAlunoAtivo(aluno.ativo);
    setAlunoClas([]);
    setAlunoClas([...aluno.clas]);
    setUpdated(!updated);
  }



  function exibirNasc(nasc) {
    const partes = nasc?.split('-');
    if (!partes || nasc === '-' || partes.length <= 1) return nasc;
    return `${partes[2]}/${partes[1]}/${partes[0]}`
  }



  async function logado() {
    if (!storage('dmk-user')) {
       navigate('/admin/conta/login');
       toast('Usuário administrador não está conectado, faça o login.');
    }
  }




  React.useEffect(() => {
    logado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 




  React.useEffect(() => {
    obterTudo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  React.useEffect(() => {
    handlePesquisarAlunos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pesquisaAluno])




  React.useEffect(() => {
    if (novaImagem && typeof(novaImagem) !== 'string') {
      async function atualizar() {
        await atualizarAvatarPerfil();
        setNovaImagem();
        await obterTudo();
      }
      atualizar();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novaImagem])




  return (
    <SApp>
      <MenuAdmin
        className='menu'
        config={menu}
        tipo='flutuante'
        acesso='admin'
      />
      <LoadingFullscreen
        loading={loading}
      />
      <LoadingFullscreen
        loading={loadingTudo}
      />
      {!loadingTudo && (
        <div className='renderizar'>
          <Informacoes>
            <CabecalhoAdmin>
              <div className='avatar'>
                <img src={imagem ? `${API_ADDRESS}/${imagem}` : '/assets/images/avatar.jpg'} alt="foto de perfil" />
                <div className='camera'>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={e => setNovaImagem(e.target.files[0])}
                  />
                </div>
              </div>
              <div>
                <p>Seja bem-vindo(a)</p>
                <h1>{nome}</h1>
              </div>
            </CabecalhoAdmin>
            <Box>
              <GerenciarAluno>
                <div>
                  <h2>Editar Permissões</h2>
                  <div className='campos'>
                    <AvatarAluno>
                      <img
                        src={aluno.info.imagem ? `${API_ADDRESS}/${aluno.info.imagem}` : '/assets/images/avatar.jpg'}
                        alt="avatar aluno"
                      />
                    </AvatarAluno>
                    <Faixa>
                      <InputComLabel
                        placeholder='nome'
                        value={aluno.info.nome}
                        readOnly='readonly'
                        onChange={()=> 0}
                      />
                      <InputComLabel
                        placeholder='e-mail'
                        value={aluno.info.email}
                        readOnly='readonly'
                        onChange={()=> 0}
                      />
                      <InputComLabel
                        placeholder='celular'
                        value={aluno.info.celular}
                        readOnly='readonly'
                        onChange={()=> 0}
                      />
                      {window.innerWidth > '650' && (
                        <Button
                          width='fit-content'
                          padding='0 1rem'
                          onClick={handleSalvarAlteracoesAluno}
                        >
                          Salvar
                        </Button>
                      )}
                    </Faixa>
                    <Faixa>
                      <InputComLabel
                        placeholder='sexo'
                        value={aluno.info.sexo}
                        readOnly='readonly'
                        onChange={()=> 0}
                      />
                      <InputComLabel
                        placeholder='nascimento'
                        value={exibirNasc(aluno.info.nascimento)}
                        readOnly='readonly'
                        onChange={()=> 0}
                      />
                      <div className='situacao'>
                        <Checkbox
                          item='Ativo'
                          checked={alunoAtivo}
                          onChange={_ => setAlunoAtivo(!alunoAtivo)}
                        />
                      </div>
                    </Faixa>
                    {window.innerWidth <= '650' && (
                        <Button
                          width='fit-content'
                          padding='0 1rem'
                          onClick={handleSalvarAlteracoesAluno}
                        >
                          Salvar
                        </Button>
                      )}
                  </div>
                </div>
                <div className='clas'>
                  <h3>CLÃS</h3>
                  <Overflow>
                    {clas.map((item) => (
                      <CheckboxComPadding
                        key={item.id}
                        value={item.id}
                        item={item.info.nome}
                        checked={alunoClas.find(x => x === item.id)}
                        onChange={(e) => handleSelecionarCla(e.target.value)}
                      />
                    ))}
                  </Overflow>
                </div>
              </GerenciarAluno>
              <ListagemAlunos>
                <div className="header">
                  <h2>Gerenciar Alunos</h2>
                  <InputRedondo
                    value={pesquisaAluno}
                    onChange={e => setPesquisaAluno(e.target.value)}
                    placeholder='Pesquise por aluno'
                    align='center'
                    width='14rem'
                  />
                </div>
                <TabelaGerenciarAlunos
                  alunos={alunos}
                  onSelecionarAluno={handleSelecionarAluno}
                />
              </ListagemAlunos>
            </Box>
          </Informacoes>
        </div>
      )}
    </SApp>
  )
}

export default App;
