import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6em 3em 0em 3em; 

  background: linear-gradient(180deg, #272331 0%,  #181718 100%);  

    .agrupamento-textos {
        width: 33%;
        animation: fadeInLeft;
        animation-duration: 1.3s;

        .titulo-inicio {
            font-family: Mukta-bold;
            font-size: 3.5em;
            line-height: 1em;
        }
      
        .texto-inicio {
            font-family: Mukta-light;
            font-size: 1em;
            line-height: 1.4em;
            width: 90%;
            margin: 1em 0em;
        }

        .botao-inicio {
            font-family: Mukta-bold;
            border: 3px solid #da7a0c;
            transition: .3s;
            background-color: #da7a0c;
        }

        .botao-inicio:hover {
            transition: .3s;
            background-color: rgba(0, 0, 0, 0);
            border-color: #da7a0c;
        }
    }
  
    .agrupamento-imagem {
        width: 55%;

        .imagem-monges {
          animation: fadeInRight;
          animation-duration: 1.3s;

          @keyframes animationCloud {
            0% {
              transform: translateX(.7em);
            }
        
            50% {
              transform: translateX(0px);
            }
        
            100% {
              transform: translateX(.7em);
            }
          }
        
          .cloud {
            animation: animationCloud 7s infinite;
          }

          svg {
            height: 20em;
          }
        }
    }

    @media (min-width: 1920px) {
        .agrupamento-textos {
            width: 23%;
        }

        .agrupamento-imagem {
            width: 33%;
        }
    }

    @media (max-width: 1440px) {
        .agrupamento-textos {
            width: 30%;

            .texto-inicio {
              width: 93%;
            }
        }

        .agrupamento-imagem {
            width: 50%;
        }
    }

    @media (max-width: 1366px) {
        .agrupamento-textos {
            width: 33%;

            .texto-inicio {
              width: 90%;
            }
        }
    }

    @media (max-width: 1280px) {
        .agrupamento-textos {
            width: 34%;

            .texto-inicio {
              width: 93%;
            }
        }

        .agrupamento-imagem {
            width: 57%;
        }
    }

    @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        padding: 2em 2em 2em 2em;

        .agrupamento-textos {
            width: 100%;

            .titulo-inicio {
                font-size: 1.8em;
                text-align: center;
            }

            .texto-inicio {
                text-align: center;
                width: 100%;
                margin: 2em 0em;
            }

            .grupo-botao-inicio {
                display: flex;
                justify-content: center;
            }
        }

        .agrupamento-imagem {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 4em 0em;

            .imagem-monges {
                svg {
                    height: 11em;
                }
            }
        }
    }
`

export { Container }