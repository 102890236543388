import { useRef, useState } from 'react';
import StyledInput, { Container } from './index.styled';

export default function NovoComentario({ className, value, onChange, onKeyUp, animate }) {
  const [animating, setAnimating] = useState(false);
  const [open, setOpen] = useState(false);
  const input = useRef();

  return (
    <Container className={className} animate={animate} open={open}>
      <div
        className='icon'
        onClick={animate && !animating ? () => {
          setAnimating(true);
          setOpen(!open);
          input.current.focus()

          setTimeout(_ => setAnimating(false), 1000);
        } : null}>
      </div>
      
      <StyledInput
        ref={input}
        tamanho='80%'
        placeholder='Adicione um comentário'
        value={value}
        onChange={onChange}
        onKeyUp={e => {
          onKeyUp(e);
          if (e.key === 'Enter') {
            setOpen(false);
            onChange({ target: { value: '' } });
          }
        }}
        animate={animate}
        open={open}
      />
    </Container>
  )
}

