import { getApi } from './base';
const api = getApi();





export async function listarTreinos(claId, espiralId) {
  const r = await api().get(`admin/treinopratico/${claId}/${espiralId}/treino`);
  return r.data;
}



export async function deletarTreino(claId, espiralId, treinoId){
  const r = await api().delete(`admin/treinopratico/${claId}/${espiralId}/treino/${treinoId}`);
  return r.status;
}



export async function alterarOrdemTreino(claId, espiralId, treinoId, ordem){
  const r = await api().put(`admin/treinopratico/${claId}/${espiralId}/treino/${treinoId}/ordem`, { novaOrdem: ordem });
  return r.status;
}



export async function buscarTreinoPorId(claId, espiralId, treinoId) {
  const r = await api().get(`admin/treinopratico/${claId}/${espiralId}/treino/${treinoId}`);
  return r.data;
}



export async function alterarTreinoPratico(claId, espiralId, treinoId, treino) {
  const r = await api().put(`admin/treinopratico/${claId}/${espiralId}/treino/${treinoId}`, treino)
  return r.status;
}



export async function salvarNovoTreino(claId, espiralId, treino) {
  const r = await api().post(`admin/treinopratico/${claId}/${espiralId}/treino`, treino)
  return r.data;
}



export async function alterarAnexoTreino(claId, espiralId, treinoId, anexo) {
  const form = new FormData();
  form.append('anexo', anexo);

  const r = await api().put(`admin/treinopratico/${claId}/${espiralId}/treino/${treinoId}/anexo`, form, {
    headers: {
			'Content-Type': 'multipart/form-data',
		}
  });
  
  return r.status;
}


