import styled from "styled-components";


export const TituloUsuario1 = styled.h1`
	font-size: 34px;
	font-family: 'Poppins-bold';
  line-height: 42px;
	color: #fff;
	user-select: none;
  cursor: pointer;
  position: relative;
  width: fit-content;
`;



export const TituloUsuario2 = styled.h2`
	font-size: 20px;
	font-family: 'Poppins-bold';
	color: #fff;
	user-select: none;
  cursor: pointer;
  position: relative;
  width: fit-content;
`;


export const TituloUsuario3 = styled.h3`
	font-size: 22px;
	font-family: 'Poppins-bold';
	color: #fff;
	user-select: none;
  cursor: pointer;
  position: relative;
  width: fit-content;
`;

