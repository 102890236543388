import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import doIt from '../../../../servicos/doIt';
import './index.scss';


import TextArea from '../../../../components/styled/textArea/comLabel/index';
import Input, { InputIcone } from '../../../../components/styled/inputs/comLabel';
import { InputIconeEstatico } from '../../../../components/styled/inputs/comLabelEstatico';
import { TituloAdmin1, TituloAdmin2 } from '../../../../components/styled/textos/titulosAdmin';
import BotaoRedondo from '../../../../components/styled/botoes/redondo/index.styled';
import PaginaAdmin from '../../../../components/paginas/paginaAdmin';
import { menu } from '../menu';


import * as api from '../../../../api/admin/conteudoAPI';
import * as apiConta from '../../../../api/admin/contaAPI';






export default function Index() {
  const [cla, setCla] = useState();
  const [espiral, setEspiral] = useState();

  const [parte, setParte] = useState('');
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [capa, setCapa] = useState('');
  const [link, setLink] = useState('');
  const [correcao, setCorrecao] = useState(false);
  const [duracao, setDuracao] = useState(0);


  const navigate = useNavigate();
  const { cla: claId, espiral: espiralId, conteudo: conteudoId } = useParams();



  useEffect(() => {
    consultaClaEspiral();

    if (conteudoId) {
      buscarConteudoExistente();
    }
    //eslint-disable-next-line
  }, []);



  async function consultaClaEspiral() {
    doIt(async () => {
      const claEncontrado = await apiConta.buscarClaPorId(claId);
      if (!claEncontrado) throw new Error('Clã não encontrado');
      setCla(claEncontrado?.info?.nome);

      const espiralEncontrada = await apiConta.buscarEspiralPorId(claId, espiralId);
      if (!espiralEncontrada) throw new Error('Espiral não encontrada');
      setEspiral(espiralEncontrada?.nome);
    })
  }



  async function buscarConteudoExistente() {
    doIt(async () => {
      const { conteudo } = await api.consultarConteudo(claId, espiralId, conteudoId);
      if (!conteudo) throw new Error('Conteúdo não encontrado');

      setParte(conteudo.parte);
      setTitulo(conteudo.titulo);
      setDescricao(conteudo.descricao);
      setLink(conteudo.link);
      setCapa(conteudo.capa);
      setCorrecao(conteudo.correcao);
    },
      {
        onError: _ => navigate(`/admin/conteudo/cla/${claId}/espiral/${espiralId}/conteudos`)
      })
  }



  async function handleSalvarNovoConteudo() {
    doIt(async () => {
      const r = await api.salvarNovoConteudo(claId,espiralId,parte,titulo,descricao,link,correcao,capa,duracao);
      toast.success('Conteúdo adicionado com sucesso!');
      navigate(`/admin/conteudo/cla/${claId}/espiral/${espiralId}/conteudo/${r.id}/alterar`);
    })
  }



  async function handleEditarConteudo() {
    doIt(async () => {
      const r = await api.editarConteudo(claId,espiralId,conteudoId,parte,titulo,descricao,link,correcao,capa,duracao);

      if (r !== 204) throw new Error('Não foi possível salvar o conteúdo');
      toast.success('Conteúdo editado com sucesso!');
    })
  }





  return (
		<PaginaAdmin
			className='conteudo-novo-admin'
			carregando={espiral === undefined}
			menu={menu}>
			<ReactPlayer
				className='player'
				playing={false}
				volume={0}
				url={link}
				onDuration={setDuracao}
			/>


			<TituloAdmin2 onClick={() => navigate(`/admin/conteudo/cla/${claId}/espiral`)}>
				{cla}
			</TituloAdmin2>

			<TituloAdmin2
				onClick={() =>
					navigate(`/admin/conteudo/cla/${claId}/espiral/${espiralId}/conteudos`)
				}>
				{espiral}
			</TituloAdmin2>

			<TituloAdmin1>Conteúdo</TituloAdmin1>

			<section className='conteudo-inputs'>
				<Input
					mode='light'
					placeholder='Parte'
					value={parte}
					onChange={e => setParte(e.target.value)}
					required={false}
				/>
				<Input
					mode='light'
					placeholder='Título'
					value={titulo}
					onChange={e => setTitulo(e.target.value)}
				/>
				<TextArea
					mode='light'
					placeholder='Descrição'
					height='155px'
					value={descricao}
					onChange={e => setDescricao(e.target.value)}
				/>
				<InputIcone
					mode='light'
					placeholder='Link'
					icone='/assets/images/icones/link.svg'
					value={link}
					onChange={e => setLink(e.target.value)}
				/>
				<InputIconeEstatico
					mode='light'
					placeholder='Capa'
					descricao={
						capa?.length > 0 ? 'Armazenado na storage' : !capa ? 'Selecione' : 'Anexado'
					}
					type='file'
					accept='image/*'
					icone='/assets/images/icones/upload-select.svg'
					onChange={e => setCapa(e.target.files[0])}
				/>


				<div className='correcao'>
					<input
						type='checkbox'
						id='chk-correcao'
						checked={correcao}
						onChange={e => setCorrecao(e.target.checked)}
					/>
					<label htmlFor='chk-correcao'>Correção</label>
				</div>
			</section>


			<div className='botoes'>
				{conteudoId && (
					<BotaoRedondo
						className='novo'
						onClick={() => {
							navigate(`/admin/conteudo/cla/${claId}/espiral/${espiralId}/conteudo/novo`);
							navigate(0);
						}}>
						Novo
					</BotaoRedondo>
				)}

				<BotaoRedondo
					onClick={conteudoId ? handleEditarConteudo : handleSalvarNovoConteudo}>
					{conteudoId ? 'Alterar' : 'Salvar'}
				</BotaoRedondo>
			</div>
		</PaginaAdmin>
	);
}
