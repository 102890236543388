import styled from 'styled-components'

export const DarkPage = styled.div`
  background: rgb(36,36,36);
  background: linear-gradient(128deg, rgba(36,36,36,1) 0%, rgba(27,27,27,1) 100%);
  font-family: 'Poppins';

  background-repeat: none;
  background-size: cover;  
  min-height: 100vh;
  color: #fff;


  overflow: ${props => !props.carregando ? 'hidden' : 'auto'};
  animation: ${props => props.carregando === undefined ? 'unset' : props.carregando ? 'unset' : 'scroll 1s both ease 1s'};

  
  @keyframes scroll {
    100% { overflow: auto; }
  }


  
  .icone-carregando {
    position: fixed;
    left: 50px;
    top: 50px;
    width: 40px;
    height: auto;
  }


`