import * as React from 'react';
import * as Dnd from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Lista } from './index.styled.js';
import Card from '../card/index.js';
import move from '../services/move.js';

export default function DragList(props) {
  let itemsEmOrdem = props.items;
  
  if (itemsEmOrdem)
    itemsEmOrdem.sort((a, b) => (props.ordem(a) > props.ordem(b) ? 1 : -1));
  
  const [items, setItems] = React.useState(itemsEmOrdem);
  const [updated, setUpdated] = React.useState(true);


  React.useEffect(() => {
    let itemsEmOrdem = props.items;

    if(itemsEmOrdem)
      itemsEmOrdem.sort((a, b) => (props.ordem(a) > props.ordem(b) ? 1 : -1));
    
    setItems(itemsEmOrdem);
    // eslint-disable-next-line
  }, [props.items])



  return ( 
    <Dnd.DndProvider backend={HTML5Backend}>
      <Lista gap={props.gap} theme={props.theme}>

        {items && items.map((item, index) => (
          <Card
            className='drag-item'
            item={item}
            descricao={props.descricao}
            dataid={props.campoId ? props.campoId(item) : item.id}
            key={props.campoId ? props.campoId(item) : item.id}
            index={index}
            ordem={props.ordem}
            campoId={props.campoId}
            trocarOrdem={props.trocarOrdem}
            move={move}
            items={items}
            setItems={setItems}
            updated={updated}
            setUpdated={setUpdated}
            onChange={props.onOrdemAlterada}
          >
            {props.children(item, index)}
          </Card>
        ))}

      </Lista>
    </Dnd.DndProvider>
  )
}

