import styled from "styled-components";


const ContainerAprovacao = styled.main`
  width: 600px;
  min-height: 250px;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 20px;

  position: relative;

  background: #323232;
  
  padding: 17px;
  
  border-radius: 4px;
  
  
  .aprovar-todos {
    cursor: pointer;
    position: absolute;
    color: white;
    left: 10px;
    bottom: 10px;
  }


  @media(max-width: 768px) { 
    height: auto;
    min-height: 250px;
    max-width: 100%;     
    
    flex-direction: column;
    
    position: relative;
  
  }

`;


const ContainerEspiral = styled.div`
  width: 60%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  font-family: 'Poppins';
  font-weight: 700;
  color: white;
  text-align: center;
  position: relative;


  img{
    cursor: pointer;
  }



  h1{
    font-size: 32px;
  }





  & > div{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  
    
    & > img:nth-child(1){
      transform: rotate(180deg);
    }  
  }

  @media(max-width: 768px) { 
    width: 100%;
  }

`;


const ContainerItems = styled.div`
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  gap: 5px;
  
  
  
  .item{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    
    .item-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      color: white;
      font-family: "Poppins";
      font-weight: 600;
      text-align: right;
      transition: .5s;
      
      .nome-topico{
        align-items: center;
        color: #FFF;
      }
      .topico-nome-bloqueado {
        color: #4A4A4A;
      }
    }


    .bolinha {
      transform: scale(0, 0);
      transition: .2s;
    }


    .icone {
      width: 22px;
    }

    &:hover {
      cursor: pointer;
      
      .bolinha {
        transform: scale(1.7, 1.7);
      }
    }
   
  }

  @media(max-width: 768px) { 
    width: 100%;

    & > div{
      position: inherit;
      .item-container{
        width: 230px;
      }

      .nome-topico{
        align-items: right;
        color: ${props => props.bloqueado ? '#4A4A4A' : '#FFF'};
      }
    }
  }
`;


export { 
  ContainerAprovacao,
  ContainerEspiral,
  ContainerItems,
}