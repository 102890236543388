import styled from 'styled-components';


const Container = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 2em;


  & > div {   
    .titulo-devmonk {
        font-family: Montserrat-bold;
        font-size: 1.2em;
        color: #E0E0E0;
        margin-left: 3em;
    }
  }

 .cabecalho {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;

    .botao {
        font-family: Mukta-bold;

        background-color: #DA7A0C; 
        border: 2px solid #da7a0c;
        transition: .3s;

        padding: 1em;
    }

    .botao:hover {
        transition: .3s;
        background-color: rgba(0, 0, 0, 0);
        border-color: #da7a0c;
    }
 }

 .items-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 80%;

    div {
        font-family: Mirza-regular;
        font-size: 1em;
        color: #E0E0E0;
        cursor: pointer;
        transition: .3s;
    }

    div:hover {
        color: white;
        cursor: pointer;
    }

 }

    
 @media (max-width: 1024px) {
    & > div {
        .titulo-devmonk {
            margin-left: 0em;
        }
    }

    .cabecalho {
        justify-content: end;
    }

    .items-menu {
        display: none;
    }
 }
`

export { Container }
