import styled from "styled-components";


const HeaderStyled = styled.header`
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  
  
  img {
      width: 77px;
  }

  & > div:nth-child(1) {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span{
          color: white;
          font-weight: 600;
          font-size: 12px;
          position: relative;
          top: -9px;
        }
      }
  }

  @media (max-height: 600px){
    height: 30vh;  
  }

  @media (max-width: 500px){
    height: auto;  
  }


`;

export { HeaderStyled }