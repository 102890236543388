import styled from "styled-components";


const FundoContainer = styled.div`
  width: 100%;
  height: 100vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
 
  background-color: rgba(0, 0, 0, 0.803);
  cursor: pointer;
`;


const BoxContainer = styled.div`
  width: 100%;
  max-width: 607px;
  height: ${props => props.tipoAnotacao ? '300px' : '500px'};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;

  position: fixed;

  background: #F5F5F5;
  box-shadow: 0px 0px 30px #000000;
  
  border-radius: 25px;

  z-index: 5;
  cursor: default;

  div{
    width: 80%;
  }

  & > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
  }

  .titulos{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .content-container{
    height: ${props => props.tipoAnotacao === true ? 'auto' : '250px'};

    display: flex;
    justify-content: flex-start;
    align-items: inherit;
    flex-direction: column;
    gap: 15px;
    
    .container-comentario{
      width: 100%;
      height: 100%;
  
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      overflow-y: auto;
      gap: 15px;
  
      background: #FFFFFF;
      border: 1px solid #ACACAC;
      border-radius: 8px;
  
      padding: 15px;
  
      &::-webkit-scrollbar {
          width: 6px;
          border-radius: 999px;
      }
                    
      &::-webkit-scrollbar-thumb {
          background: #B0B0B0; 
          border-radius: 10px;
      }
      
      &::-webkit-scrollbar-thumb:hover {
          background: #555555; 
      }
  
      .comentario{
        width: 100%;
        display: flex;
        gap: 15px;
        color: black;
  
        .data{
          width: ${props => props.tipoAnotacao ? "50%" : "100px"};
          
          display: flex;
          justify-content: center;
          align-items: flex-end;
          flex-direction: column; 
          
          color: black;
          white-space: pre;
          font-weight: 700;
        }
  
        .text {
          width: 100%;
          display: flex;
          justify-content: ${props => props.tipoAnotacao === true ? 'center' : 'flex-start'};
          align-items: ${props => props.tipoAnotacao === true ? 'center' : 'inherit'} ;
          padding: 0px 5px;
        }
      }
    }
  }
`




export {
  FundoContainer,
  BoxContainer
}


