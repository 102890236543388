import React, { useState, useEffect } from 'react'
import doIt from '../../../servicos/doIt';
import { toast } from 'react-toastify';


import BoxOpcoes from './boxOpcoes';
import Botao from '../../../components/styled/botoes/redondo/index.styled';
import AprovarTodos from '../../modaisAcompanhamento/aprovarTodos/index.js';
import { ContainerAprovacao, ContainerEspiral, ContainerItems } from './index.styled';


import * as servicoAcompanhamento from '../../../api/admin/acompanhamentoAPI.js'




export default function CartaoAprovacao(props) {
  const [posEspiral, setPos] = useState(0);
  const [painelEscolhido, setPainelEscolhido] = useState();
  const [modalAprovarTodos, setModalAprovarTodos] = useState(false);


  useEffect(() => {
    setPainelEscolhido(undefined);
    // eslint-disable-next-line
  },[posEspiral]); 
  
  
  
  
  async function escolherPainelPraSerAberto(pos) {
    if(pos === painelEscolhido)
      setPainelEscolhido();
    else
      setPainelEscolhido(pos);
  }




  async function liberarEspiral() {
    doIt(async () => {
      const claId = props.aluno.claId;
      const alunoId = props.aluno.alunoId;
      const espiralId = props?.espirais[posEspiral].espiralId
      await servicoAcompanhamento.liberarDiscordEspiralPorAluno(claId, alunoId, espiralId);
      toast.success('Espiral liberada com sucesso!')
      props.reRenderInfo();
    }, {time: 1});
  }


  
  return (
    <ContainerAprovacao>
      
      {modalAprovarTodos &&
        <AprovarTodos
          reRenderInfo={props.reRenderInfo}
          setModalEntregaAberto={setModalAprovarTodos}
          titulo={'Aprovar todos concluídos'}
          aluno={props.aluno}
          espiralSelecionada={props.espirais[posEspiral]}
        />
      }
      
      
      <div className='aprovar-todos'>
        {props?.espirais[posEspiral].situacao === 'liberado' && 
          <span onClick={() => setModalAprovarTodos(true)}>Aprovar todos</span>
        }
      </div>

      <ContainerEspiral>
        
        <div>
          
          <img onClick={() => setPos(posEspiral !== 0 ? posEspiral - 1 : posEspiral)} src="/assets/images/icones/seta_comp_aprovacao.svg" alt="" />
          <h1> {props.espirais[posEspiral].nome} </h1>
          <img onClick={() => setPos(posEspiral < props.espirais.length-1 ? posEspiral + 1 : posEspiral)} src="/assets/images/icones/seta_comp_aprovacao.svg" alt="" />

        </div>
        
        {props?.espirais[posEspiral].situacao === 'bloqueado' && <Botao onClick={() => liberarEspiral()}>Liberar</Botao>}
        {props?.espirais[posEspiral].situacao === 'liberado' && <h2>Situação Geral</h2>}
      
      </ContainerEspiral>

      
      <ContainerItems bloqueado={props?.espirais[posEspiral].situacao === 'bloqueado'}>
        {props?.espirais[posEspiral]?.topicosEntregas.map((item, pos) => {
          
          return (
            <div className='item'>
              
              <div onClick={() => escolherPainelPraSerAberto(pos)} className='item-container'>
                <span className={`nome-topico topico-nome-${item.situacao}`}>{ item.nome }</span>
                {item.situacao === 'bloqueado' && <img className='icone' src='/assets/images/icones/lock.svg' alt="cadeado" /> }
                {item.situacao === 'liberado'  && <img className='icone' src='/assets/images/icones/lapis.svg' alt="lapis" /> }
                {item.situacao === 'corrigir'  && <img className='icone' src='/assets/images/icones/tool_acompanhamento.svg' alt="ferramenta" /> }
                {item.situacao === 'concluído' && <img className='icone' src='/assets/images/icones/green_check.svg' alt="foguinho" /> }
                {item.situacao === 'aprovado'  && <img className='icone' src='/assets/images/icones/red_fire.svg' alt="" /> }
                
                <img className='bolinha' src='/assets/images/icones/bolinhas.svg' alt="" />
                
              
              </div>
              <BoxOpcoes 
                reRenderInfo={props.reRenderInfo}

                aluno={props.aluno}
                topicoId={item.topicoId}
                espiralId={props?.espirais[posEspiral]?.espiralId}
                
                bloqueado={item.situacao === 'bloqueado'} 
                identificador={item.identificador} 
                open={pos === painelEscolhido}

                comentarioProf={item.feedback?.comentarios}
                comentarioAluno={item?.entrega?.comentarios ?? undefined}

                mensagensForum={Array.isArray(item.entrega) === true ? item.entrega : []}
                anotacao={item?.entrega}
                nome={item?.nome}

                posEspiral={posEspiral}
              
              />
            </div>
          );

        })}
      </ContainerItems>      
    </ContainerAprovacao>
  )
}
