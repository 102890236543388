export default function DragIcon(props) {
  return (
    <svg {...props} width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.5" cy="1.5" r="1.5" fill="#454545"/>
      <circle cx="7.5" cy="1.5" r="1.5" fill="#454545"/>
      <circle cx="1.5" cy="6.5" r="1.5" fill="#454545"/>
      <circle cx="7.5" cy="6.5" r="1.5" fill="#454545"/>
      <circle cx="1.5" cy="11.5" r="1.5" fill="#454545"/>
      <circle cx="7.5" cy="11.5" r="1.5" fill="#454545"/>
    </svg>
  )
}