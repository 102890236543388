import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import doIt from '../../../../servicos/doIt';
import './index.scss';


import { TituloAdmin1, TituloAdmin2, TituloAdmin3 } from '../../../../components/styled/textos/titulosAdmin';
import { ListaOrdenacao } from '../../../../components/listaOrdenacao';
import PaginaAdmin from '../../../../components/paginas/paginaAdmin';
import { menu } from '../menu';


import * as api from '../../../../api/admin/conteudoAPI';
import * as apiConta from '../../../../api/admin/contaAPI';
import confirmar from '../../../../components/confirm';




export default function Index() {
  const [cla, setCla] = useState();
  const [espiral, setEspiral] = useState();
  const [conteudo, setConteudo] = useState();


  const navigate = useNavigate();
  const { cla: claId, espiral: espiralId } = useParams();



  useEffect(() => {
    listagem();
    //eslint-disable-next-line
  }, []);



  function mapearConteudo(conteudo) {
    conteudo?.map(item => {
      if (item.conteudo.parte) {
        item.conteudo.titulo = `#${item.conteudo.parte} - ${item.conteudo.titulo}`;
      }

      return item;
    });
  }



  async function listagem() {
    doIt(async () => {
      const claEncontrado = await apiConta.buscarClaPorId(claId);
      if (!claEncontrado) throw new Error('Clã não encontrado');
      setCla(claEncontrado?.info?.nome);

      const espiralEncontrada = await apiConta.buscarEspiralPorId(claId, espiralId);
      if (!espiralEncontrada) throw new Error('Espiral não encontrada');
      setEspiral(espiralEncontrada?.nome);

      const conteudoEncontrado = await api.listarConteudos(claId, espiralId);
      if (!conteudoEncontrado[0]) toast.warn('Nenhum conteúdo foi encontrado');

      mapearConteudo(conteudoEncontrado);
      setConteudo(conteudoEncontrado);
    })
  }




  async function handleAlterarOrdem(conteudoId, ordem) {
    doIt(async () => {
      await api.alterarOrdemConteudo(claId, espiralId, conteudoId, ordem);
      const conteudoEncontrado = await api.listarConteudos(claId, espiralId);

      mapearConteudo(conteudoEncontrado);
      setConteudo(conteudoEncontrado);
    },
    {
      supressError: true
    })
  }




  async function deletar(conteudoId) {
    confirmar({
      titulo: 'Remover conteúdo',
      descricao: 'Tem certeza que deseja remover esse conteúdo?',
      onSim: _ =>
        doIt(async () => {
          const r = await api.deletarConteudo(claId, espiralId, conteudoId);
          if (r !== 204) throw new Error('Não foi possível deletar esse conteúdo');

          const conteudoEncontrado = await api.listarConteudos(claId, espiralId);
          setConteudo(conteudoEncontrado);
          toast.warn('Conteúdo removido');
        })
    })
  }




  return (
    <PaginaAdmin
      className='conteudo-lista-admin'
      carregando={conteudo === undefined}
      menu={menu}
    >

      <TituloAdmin2 onClick={() => navigate(`/admin/conteudo/cla/${claId}/espiral`)}>
        {cla}
      </TituloAdmin2>

      <TituloAdmin1>
        {espiral}
      </TituloAdmin1>


      <section>
        <div className='titulo'>
          <TituloAdmin3>Lista de Conteúdo</TituloAdmin3>
          <img alt='' src='/assets/images/icones/plus.svg' onClick={() => navigate(`/admin/conteudo/cla/${claId}/espiral/${espiralId}/conteudo/novo`)} />
        </div>

        <div className='lista'>
          <ListaOrdenacao
            items={conteudo}
            descricao={item => item.conteudo.titulo}
            ordem={item => item.conteudo.ordem}
            trocarOrdem={(x, o) => x.conteudo.ordem = o}
            onOrdemAlterada={(item, novaOrdem) => handleAlterarOrdem(item.id, novaOrdem)}
            onClick={item => navigate(`/admin/conteudo/cla/${claId}/espiral/${espiralId}/conteudo/${item.id}/alterar`)}
            onDeletar={item => deletar(item.id)}
          />
        </div>
      </section>

    </PaginaAdmin>
  );
}
