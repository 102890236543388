import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
  justify-content: flex-end;
  
  transition: ease-in;
  animation: ${p => !p.animate ? 'unset' : p.open ? 'abrir 1s forwards' : 'fechar 1s forwards'};
  width: ${p => p.animate ? '60px' : '16em'};


  .icon {
    transition: .3s ease-in;
    cursor: pointer;
    min-width: 40px;
    height: 36.5px;
    margin-right: 1em;
    background-color: #9F9F9F;
    border-radius: 30px 30px 30px 0px;
    transform: rotate(-1.15deg);
    filter: drop-shadow(0px 0px 15px rgba(226, 226, 226, 0.25))
  }
  .icon:hover {
    background-color: #D3C314;
  }


  @keyframes abrir {
    from {
      width: 60px;
    }
    to {
      width: 16em;
    }
  }

  @keyframes fechar {
    from {
      width: 16em;
    }
    to {
      width: 60px;
    }
  }

  
`



const StyledInputComentario = styled.input`
  
  transition: ease-in;
  animation: ${p => !p.animate ? 'unset' : p.open ? 'abrir1 1s forwards' : 'fechar1 1s forwards'};
  
  padding-left: 1em;

  max-width: ${(props) => props.tamanho};
  min-height: 3em;

  background-color: #383838;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  outline: none;
  border-radius: 2px;
  border: 1px solid #616161;
  
  color:  #A1A1A1;



  @keyframes abrir1 {
    from {
      opacity: 0;
      width: 0%;
    }
    to {
      opacity: 1;
      width: 100%;
    }
  }

  @keyframes fechar1 {
    0% {
      opacity: 1;
      width: 100%;
    }
    to {
      opacity: 0;
      width: 0%;
    }
  }


  
  ::-webkit-input-placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #A1A1A1;
  }


`;

export default StyledInputComentario;