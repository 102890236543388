import styled from 'styled-components';



const SApp = styled.div`
  display: flex;
  flex-direction: row;

  position: relative;

  justify-content: start;
  align-items: center;

  width: 100%;
  height: 100%;

  min-height: 100vh;
  
  background: linear-gradient(270deg, #282828 55.21%, #3A3A3A 100%);
  font-family: 'Poppins';
  
  .logo {
    position: absolute;
    top: 1rem;
    left: 1rem;

    width: 6rem;
    height: auto;
  }

  
  .menu {
    min-height: 100vh;
    width: 400px;
  }

  
  @media (max-width: 1024px) {
    .menu {
      min-height: unset;
      width: 100%;
    }
  }

  
  @media (max-width: 1024px) {
    padding-top: 120px;
  }
`;




export const Box = styled.div`
  display: flex; 
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  width: 100%;
  max-width: 50rem;

  position: relative;
  animation: entrada 1s both;

  padding-left: 0;

  @media (max-width: 1250px) { 
    padding-left: 0;
    padding: 0 2.5rem;
  }

  @media (min-width: 1024px) and (max-width: 1100px) {
     max-width: 40rem;
  }

  @keyframes entrada {
    from { 
      opacity: 0;
      bottom: -50px;
    }
    to {
      opacity: 1;
      bottom: 0px;   
    }
  }
`;




export const Aluno = styled.div`
  display: flex; 
  flex-direction: column;

  width: 100%;
`;




export const CabecalhoAluno = styled.div`
  position: relative;
  display: flex; 
  flex-direction: row;
  
  width: 100%;
  align-items: center;
  margin-bottom: 2rem;


  p {
    color: #8A8A8A;
    font-size: 18px;
    font-weight: 700;
  }

  h1 {
    color: #D3C314;
    font-size: 28px;
  }

  img {
    border-radius: 999px;
    height: 8rem;
    width: 8rem;
    margin-right: 1rem;
  }

  .avatar {
    position: relative;
    width: fit-content;
    height: fit-content;
  }

  
  
  .camera {
    position: absolute;
    bottom: .4rem;
    right: 1rem;
    cursor: pointer;
    
    background-image: url('/assets/images/icones/camera.png');
    background-repeat: no-repeat;
    background-position: 50% 45%;
    background-size: 1rem;
    background-color: #4A4A47;
    border: 3px solid #242424;
    border-radius: 999px;

    width: 2rem;
    height: 2rem;

    input[type=file]{
      display: none;
    }
  }


  @media (max-width: 1024px) {
    padding-left: 4rem;

    .camera {
      right: 0em;
    }
  }


  @media (max-width: 750px) { 
    flex-direction: column;
    gap: 2rem;
    padding-left: 0rem;

    .camera {
      right: 0em;
    }

    img { 
      margin-right: 0em;
    }

    div {
      width: 100%;
      text-align: center;
    }
  }



`;




export const Informacoes = styled.div`
  display: flex; 
  flex-flow: column nowrap;
  width: 100%;
  padding-left: 4rem;
  margin-top: 50px;
  gap: 20px;
  
  height: fit-content;

  >nav {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 5px;
    height: 36px;

    >img {
      cursor: ${p => p.rotinaBotao ? 'pointer' : 'default'};
      user-select: none;
      -webkit-user-drag: none;
      transition: .2s;
      opacity: ${p => p.rotinaBotao ? '1' : '0'};
      transform: ${p => p.rotinaBotao ? 'scale(1)' : 'scale(0.5)'};
      width: 36px;
      height: 36px;
    }

    >h2 {
      font-weight: 700;
      font-size: 22px;
      color: #D3C314;
      padding: 0;
      margin-left: 1rem;
      user-select: none;
    }
  }
    
  @media (max-width: 750px) { 
    padding-left: 0;
  }
`;



export const Overflow = styled.div`
  height: fit-content;
  width: 100%;

  overflow: hidden;
  overflow-x: auto;

  padding-right: 10px;
  padding-bottom: .5rem;


  ::-webkit-scrollbar {
    height: 10px;
    border-radius: 999px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #414141; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555555; 
  }
`;


export default SApp;