import styled from "styled-components";

const DEFAULT_WIDTH = 'auto';
const DEFAULT_HEIGHT = '52px';

const DEFAULT_DARK_MODE = '#303030';
const DEFAULT_LIGHT_MODE = '#FFFFFF';

const DEFAULT_TEXT_LIGHT = '#444242';
const DEFAULT_TEXT_DARK = '#FFFFFF';

const DEFAULT_BORDER_LIGHT = '#828282';
const DEFAULT_BORDER_DARK = '#464646';

const DEFAULT_PLACEHOLDER_DARK = '#BBBBBB';
const DEFAULT_PLACEHOLDER_LIGHT = '#828282';


const FieldStatic = styled.div`
	position: relative;
	width: ${props => (props.width ? props.width : DEFAULT_WIDTH)};
	height: ${props => (props.height ? props.height : DEFAULT_HEIGHT)};

	label {
		position: absolute;
		top: 50%;
		left: 0.9rem;
		transform: translateY(-50%);
		pointer-events: none;
		color: ${props =>
			props.mode === 'light' ? DEFAULT_PLACEHOLDER_LIGHT : DEFAULT_PLACEHOLDER_DARK};
		font-weight: 600;
		font-size: 12px;
		top: 1rem;
	}

	div {
		display: flex;
		flex-direction: row;
		align-items: center;

		width: 100%;
		height: 100%;
		padding: 15px .8rem 0 .8rem;

		color: ${props => (props.mode === 'light' ? DEFAULT_TEXT_LIGHT : DEFAULT_TEXT_DARK)};

		background: ${props =>
			props.mode === 'light' ? DEFAULT_LIGHT_MODE : DEFAULT_DARK_MODE};
		font-size: 16px;
		font-weight: 600;
		border: 1px solid
			${props => (props.mode === 'light' ? DEFAULT_BORDER_LIGHT : DEFAULT_BORDER_DARK)};
		border-radius: 8px;
	}

	button {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0.8rem;
		background-color: unset;
		border: unset;
		width: auto;
		height: 32%;
		cursor: pointer;
	}

	img {
		height: 100%;
		width: auto;
		-webkit-user-drag: none;
		user-select: none;
	}

	input {
		display: none;
	}
`;



export default FieldStatic;