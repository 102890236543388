import styled from "styled-components";


const PainelContainer = styled.div`
  width: 30%;
  min-height: 100vh;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 50px;

  position: fixed;
  top: 0;
  left: ${props => props.dir === 'esquerda' ? '0px' : 'inherit'};
  right: ${props => props.dir === 'direita' ? '0px' : 'inherit'};
  
  border-right: ${props => props.dir === 'esquerda' ? '3px solid #404040' : 'inherit'};
  border-left: ${props => props.dir === 'direita' ? '3px solid #404040' : 'inherit'};

  background: #1B1B1B;
  padding: 100px 35px;

  transition: .5s;
  transform: ${props => {
    if(props.dir === 'esquerda'){
      let x = props.open === true ? 'translate(0%, 0%)' : 'translate(-100%, 0%)';
      return x;
    }
    
    if (props.dir === 'direita'){
      let x = props.open === true ? 'translate(0%, 0%)' : 'translate(100%, 0%)' 
      return x;
    }

  }};

  & > div:nth-child(1){
    width: 100%;

    position: relative;

    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: white;
  }

  & > div:nth-child(2){
    width: 100%;
    height: calc(100vh - 230px);
    white-space: pre-line;
    color: white;
    overflow-y: scroll;
    font-size: 22px;
  
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 999px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #414141; 
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #555555; 
    }
  }
  

  @media (max-width: 720px) {
    width: 70%;

    & > div:nth-child(2){
      width: 100%;
    }
  }


  @media (max-height: 720px) {
    padding: 5% 15px;
  }
`;



const Setinha = styled.img`
  position: absolute;
  top: 40px;
  right: ${props => props.dir === 'esquerda' ? '-89px' : 'inherit'};
  left: ${props => props.dir === 'direita' ? '-89px' : 'inherit'};
  cursor: pointer;

  
  
  @media (max-width: 720px) {
    top: 0px;
  }
  
  @media (max-height: 720px) {
    padding-right: ${props => props.dir === 'esquerda' ? '15px' : 'inherit'};
    padding-left: ${props => props.dir === 'direita' ? '15px' : 'inherit'};
  }

`

export {
  PainelContainer,
  Setinha
}