import styled from "styled-components";

export const Wrapper = styled.div`
  .burger {

  }
  .logo_flutuante {
    justify-content: flex-start;
    top: 10px;
    left: 25px;
  }
  .logo_lateral {
    justify-content: center;
    top: 20px;
  }

  @media (max-width: ${p => p.mediaMaxWidth ?? '1024px'}) {
    display: ${p => p.open ? 'flex' : 'none'};
    position: fixed;
    
    &, .logo_flutuante, .logo_lateral {
      z-index: 11;
      top: 20px !important;
      left: calc(50% - 35px) !important;
    }
  }
`


export const LogoContainer = styled.div`
  display: flex;
  position: relative;  

  & :first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      height: 95px;
      width: auto;
    }
    
    div {
      position: relative;
      font-family: Montserrat-bold;
      font-size: 12px;
      top: -10px;
      color: #E1E1E1;
    }
  }

`

