import styled from "styled-components";

const StyledTerminal = styled.div `

  .low {
    height: 15vh;
  } 

  .high {
    height: 40vh;
    animation-duration: 4s;
    animation-name: transicao;
  }

  @keyframes transicao {
  0% {
    height: 15vh;
  }
  30% {
    height: 23vh;
  }
  70% {
    height: 37.64vh;
  }
  100% {
    height: 40vh;
  }
}

@keyframes transicaoResponsiva {
  0% {
    height: 15vh;
  }
  30% {
    height: 23vh;
  }
  70% {
    height: 31.64vh;
  }
  100% {
    height: 37vh;
  }
}

  .main {
    padding: 1em;
  }

  .padding {
    padding-bottom: 1em;
  }

  .padding input::-webkit-input-placeholder  {
    color: #C0C0C0;
    font-family:  FiraCode-regular !important; 
    font-size: 13px;
  }

  margin-top: 0em;
 
 
  .padding  input:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }

  .escolha input {
    border: 1px solid #c0c0c0;
    border-radius: 2px;
    background-color: transparent;
    color: #C0C0C0;
    width: 1.2em;
  }

  .escolha > input:focus {
    outline: none;
  }

  .padding input:hover {
    cursor: pointer;
  }

  .padding input {
    border: none;
    background-color: transparent;
    color: #C0C0C0;
  }

  
  div {
    font-family:  FiraCode-regular !important;
    font-weight: 300;
    font-size: 13px;
    line-height: 15.74px;
    color: #C0C0C0;
  }

  @media screen and (max-width: 1024px) {
    .high {
      height: 50vh !important;
    }

    .low {
      height: 18vh !important;
    }
  }



  @media screen and (max-height: 667px) {
    div {
      font-size: 12px;
    }

    .padding {
      padding-bottom: .1em;
    }

    .padding input::-webkit-input-placeholder  {
      font-size: 11px;
    }

    .high {
    height: 37vh;
    animation-duration: 4s;
    animation-name: transicaoResponsiva;
  }

  .escolha input { 
    height: 1em;
  }


  }
  

  
 

`


export { StyledTerminal }