


export const HomeIcone = () => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.24 11.7444C20.3658 10.6186 20.9983 9.09161 20.9983 7.49943C20.9983 5.90725 20.3658 4.38027 19.24 3.25443C18.1142 2.12859 16.5872 1.49609 14.995 1.49609C13.4028 1.49609 11.8758 2.12859 10.75 3.25443L4 10.0044V18.5044H12.5L19.24 11.7444Z" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 7.50391L1 21.5039" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.5 14.5039H8" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)




export const MeusCursosIcone = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L22 8.5V15.5L12 22L2 15.5V8.5L12 2Z" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 22V15.5" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 8.5L12 15.5L2 8.5" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2 15.5L12 8.5L22 15.5" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 2V8.5" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)


export const GerenciarAlunos = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23 20.9999V18.9999C22.9993 18.1136 22.7044 17.2527 22.1614 16.5522C21.6184 15.8517 20.8581 15.3515 20 15.1299" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const ContaIcone = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const SairIcone = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 21.5H5C4.46957 21.5 3.96086 21.2893 3.58579 20.9142C3.21071 20.5391 3 20.0304 3 19.5V5.5C3 4.96957 3.21071 4.46086 3.58579 4.08579C3.96086 3.71071 4.46957 3.5 5 3.5H9" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16 17.5L21 12.5L16 7.5" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M21 12.5H9" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
  </svg>
)


export const AnaliseIcone = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 7C5.65685 7 7 5.65685 7 4C7 2.34315 5.65685 1 4 1C2.34315 1 1 2.34315 1 4C1 5.65685 2.34315 7 4 7Z" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 19V7C4 9.38695 4.94821 11.6761 6.63604 13.364C8.32387 15.0518 10.6131 16 13 16" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)


export const RotinaIcone = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 4.5H5C3.89543 4.5 3 5.39543 3 6.5V20.5C3 21.6046 3.89543 22.5 5 22.5H19C20.1046 22.5 21 21.6046 21 20.5V6.5C21 5.39543 20.1046 4.5 19 4.5Z" stroke="#DDCC11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 2.5V6.5" stroke="#DDCC11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 2.5V6.5" stroke="#DDCC11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 10.5H21" stroke="#DDCC11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
