import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: 101vh;
	z-index: 20;
	background: #0000006e;
	display: grid;
	place-items: center;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	visibility: visible;
	opacity: 1;
	transition: 0.2s;

  visibility: ${props => !props.ativo ? 'hidden' : 'visible'};
  opacity: ${props => !props.ativo ? '0' : '1'};
    

	> main {
		background: #f5f5f5;
		width: auto;
		height: fit-content;
		box-shadow: 0 0 25px 3px #00000052;
		border-radius: 25px;
		padding: 80px 95px;
    z-index: 21;

		transition: 0.1s;
    transform: ${props => !props.ativo ? 'scale(0.7)' : 'scale(1)'};

		> .titulo-modal {
			color: #3973ba;
			font-size: 26px;
			font-weight: 700;
			user-select: none;
		}
		> .descricao-modal {
			color: #000;
			font-size: 14px;
			font-weight: 700;
			user-select: none;
			margin-bottom: 20px;
		}

    @media (max-width: 1024px) {
      padding: 50px 65px;
    }
	}
`;
