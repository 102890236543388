import styled from "styled-components";

const InputStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 3px;
  color: #3F3737;
  //padding: 2em 0em 1em 0em;
  font-size: 16px;


  height: 100%;
  
  .editor {
    font-family:  FiraCode-regular !important;
    font-size: 14px !important;
  }

  .editor {
    height: 100%;
  }
  

  

`;

export { InputStyle };
