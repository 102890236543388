import styled from 'styled-components';


export const Container = styled.div`
  padding: 25px 8px 8px 17px;
  width: 305px;
  height: 130px;
  background-color: #373737;
  border: 1px solid #000;
  border-radius: 3px;
  display: flex;
  flex-flow: column nowrap;
  user-select: none;
  flex: none;

  > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    gap: 17px;

    > img {
      width: 82px;
      height: 82px;
      border-radius: 100%;
      user-select: none;
      -webkit-user-drag: none;
    }
    > div {
      font-size: 12px;
      color: #fff;
      max-width: 65%;
      user-select: none;

      > h1 {
        font-size: 16px;
        max-width: 100%;
        text-overflow: ellipsis;
        text-align: start;
        word-wrap: wrap;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
      }

      > h2 {
        font-size: 12px;
        margin-top: 15px;        
      }

      .nao-vinculado {
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  > a {
    align-self: flex-end;
    justify-self: flex-end;
    font-family: Poppins-SemiBold;
    font-size: 12px;
    color: #fff;
  }
`
