import styled from "styled-components";

const DEFAULT_WIDTH = '100px';
const DEFAULT_HEIGTH = '30px';

const DEFAULT_TEXT_COLOR = '#FFF';
const DEFAULT_TEXT_SIZE = '16px';
const DEFAULT_TEXT_WEIGHT = '600';
const DEFAULT_BACKGROUND = '#B89B19';
const DEFAULT_PADDING = '0 .8rem 0 .8rem'


const BotaoRedondo = styled.button`
  display:flex;
  justify-content:center;
  align-items:center;
  border: inherit;
  cursor:pointer;

  width: ${props => props.largura ?? props.padding ? 'unset' : DEFAULT_WIDTH};
  height: ${props => props.altura ?? props.padding ? 'unset' : DEFAULT_HEIGTH};
  padding: ${props => props.padding ?? DEFAULT_PADDING};

  font-size: ${props => props.fontSize ?? DEFAULT_TEXT_SIZE};
  font-weight: ${props => props.fontWeight ?? DEFAULT_TEXT_WEIGHT};
  color: ${props => props.color ?? DEFAULT_TEXT_COLOR};
  
  background-color: ${props => props.background ?? DEFAULT_BACKGROUND};
  border-radius: 43px;

  &::disabled {
    cursor: not-allowed;
  }
`;

export default BotaoRedondo;