import { Link } from "react-router-dom";
import { LogoContainer, Wrapper } from "./styled";



export default function Logo({ tipo, open, mediaMaxWidth }) {
  
  function retornarLogo() {
    switch (tipo) {
      case 'flutuante': return '/assets/images/logos/dmklogousuarioamarelo.png';
      case 'lateral': return '/assets/images/logos/dmklogousuario.png';
      case 'burger': return '/assets/images/logos/dmklogoadmin.png'; 
    }
  }


  return (
    <Wrapper open={open} mediaMaxWidth={mediaMaxWidth}>
      <LogoContainer className={`logo_${tipo}`}>
      <Link to='/' className={`logo_${tipo}`}>
        <img src={retornarLogo()} alt="" />
        <div> Devmonk </div>
      </Link>
    </LogoContainer>
    </Wrapper>
  )
}