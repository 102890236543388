import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import './index.scss';


import Aguardando from './aguardando';
import Lendo from './lendo'
import Finalizado from '../treinoComecarQuiz/finalizado';
import ResultadoLeitura from './resultado';
import Correcao from './correcao';



export default function TreinoTeorico_LeituraController() {
  const [tela, setTela] = useState('aguardando');
  const [pergunta, setPergunta] = useState();
  const [dados, setDados] = useState();
  const [tempo, setTempo] = useState(0);
  const [vizualizacoes, setVizualizacoes] = useState({
    painelDireitaViews: 0,
    painelEsquerdaViews: 0,
  });

  
  const { cla, espiral, treino } = useParams();

  
  return (
    <main className='treino-leitura-controller' style={tela !== 'finalizado'  ? {backgroundImage: `url('/assets/images/backgrounds/background_mosteiro.png')`, backgroundColor: '#121212'}
                                                                              : {backgroundImage: `url('/assets/images/backgrounds/background_mongeNaMontanha.png')`, backgroundColor: '#121212'}}>
      
      {tela === 'aguardando' && 
        <Aguardando
          setTela={setTela}
          setPergunta={setPergunta}
          claId={cla}
          espiralId={espiral}
          treinoId={treino}
        />
      }

      {tela === 'lendo' && 
        //pergunta, vizualizacoes, tempo, setTempo, setVizualizacoes, setTela
        <Lendo 
          pergunta={pergunta}
          vizualizacoes={vizualizacoes}
          tempo={tempo}
          setTempo={setTempo}
          setVizualizacoes={setVizualizacoes}
          setTela={setTela}
        />
      }

      {tela === 'resultado' && 
        <ResultadoLeitura
          pergunta={pergunta}
          setTempo={setTempo}
          setVizualizacoes={setVizualizacoes}
          setPergunta={setPergunta}
          setTela={setTela}
          setDados={setDados} 
        />
      }

      {tela === 'correcao' && 
        <Correcao
          pergunta={pergunta}
          setTela={setTela}
        />
      }

      {tela === 'finalizado' &&
        <Finalizado 
          dados={dados}
        />
      }

    </main>
  )
}
