import styled from "styled-components"

const DEFAULT_WIDTH = 'auto'
const DEFAULT_HEIGHT = '42px'
const DEFAULT_PADDING = '0 .8rem 0 .8rem'
const DEFAULT_BACKGROUND = '#B89B19'
const DEFAULT_BORDER_RADIUS = '8px'
const DEFAULT_FONT_SIZE = '12px'

const BotaoArredondado = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: inherit;
  background: ${props => props.background ? props.background : DEFAULT_BACKGROUND};

  color: ${props => props.color ? props.color : '#FFFFFF'};

    width: ${props => props.width ? props.width : DEFAULT_WIDTH};
    height: ${props => props.height ? props.height : DEFAULT_HEIGHT};
    padding: ${props => props.padding ? props.padding : DEFAULT_PADDING};
    
    font-size: ${props => props.fontSize ? props.fontSize : DEFAULT_FONT_SIZE};
    font-weight: 700;
    border-radius: ${props => props.radius ? props.radius : DEFAULT_BORDER_RADIUS};
    border: none;
    cursor: pointer;
`

export default BotaoArredondado