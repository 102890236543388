import { AnaliseIcone, HomeIcone } from "../../../../components/menu/comps/icones";



export const menu = {
  podeSelecionar: false,
  items: [
    {
      nome: 'Acompanhamento',
      url: '/admin/acompanhamento/cla',
      icone: <HomeIcone/>
    }
  ],
  animarURLs: [
    '/admin/acompanhamento/cla'
  ]
}




export const menuEspiral = {
  podeSelecionar: false,
  items: [
    {
      nome: 'Acompanhamento',
      url: '/admin/acompanhamento/cla',
      icone: <HomeIcone/>
    },
    {
      nome: 'Análise Entregas',
      url: '/admin/acompanhamento/cla/:cla/analiseEntregas',
      icone: <AnaliseIcone/>
    }
  ],
  animarURLs: [
    '/admin/acompanhamento/cla'
  ]
}
