import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #18161A;
  height: 4em;

  font-family: Mukta-light;
  font-size: 1em;
`
export { Container }