import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import doIt from '../../../../../servicos/doIt';
import { menuEspiral as menu } from '../menu';
import './index.scss';


import PaginaAdmin from '../../../../../components/paginas/paginaAdmin';
import CompCartaoEntrega from '../../../../../components/cartaoEntrega';
import { TituloAdmin1, TituloAdmin2 } from '../../../../../components/styled/textos/titulosAdmin';


import * as servicosAcompanhamento from '../../../../../api/admin/acompanhamentoAPI';
import * as servicosConta from '../../../../../api/admin/contaAPI'





export default function Index() {
  const [cla, setCla] = useState();
  const [alunos, setAlunos] = useState(); 

  const { cla: claId } = useParams()

  

  useEffect(()=>{
    async function buscarCla() {
      doIt(async () => {
        const r = await servicosConta.buscarClaPorId(claId);
        setCla(r?.info?.nome)
      })
    }
    

    
    buscarCla();
    carregarAlunos();
    // eslint-disable-next-line
  },[]);
  
  
  
  
  async function carregarAlunos() {
    doIt(async () => {
      let r = await servicosAcompanhamento.listarCartoesEntrega(claId);
      setAlunos(r);
    });
  }

  
  
  return (
    <PaginaAdmin className="tela-analiseEntrega" menu={menu} carregando={alunos === undefined} >
      <TituloAdmin2 color='#3973BA'> { cla } </TituloAdmin2>
      <TituloAdmin1> Análise das entregas </TituloAdmin1>
      
      <div className='container'>
        {alunos?.map(x => {
          return(
            <CompCartaoEntrega 
              carregarAlunos={carregarAlunos} 
              aluno={x}
              mostarUsuario={true}            
            />
          );
        })}
      </div>
    
    </PaginaAdmin>
  )
}
