import { css, keyframes } from 'styled-components';

const fromBackKeyframes = keyframes`
  100% { 
    transform: scale(1);
    opacity: 1;
  }
`;

const fromBack = css`
  transform: scale(0);
  opacity: 0;
  
  animation: ${fromBackKeyframes} ${props => (props.index ?? 1) * 0.5}s forwards ease;
`;

export default fromBack;