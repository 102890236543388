export default async function move(fromId, fromOrdem, toOrdem, items, setItems, updated, setUpdated, getId, getOrder, replaceOrder) {
  let draft = items;

  const campoId = getId ? getId : x => x.id.toString();
  const campoOrdem = getOrder ? getOrder : x => x.ordem;
  const alterarOrdem = replaceOrder ? replaceOrder : (x, o) => x.ordem = o;


  const itemMovido = items.find(x => campoId(x) === fromId);
  const movidoParaCima = toOrdem > fromOrdem;



  let itensAlterar = [];

  if (movidoParaCima) 
    itensAlterar = draft.filter(x => campoOrdem(x) > campoOrdem(itemMovido) && campoOrdem(x) <= toOrdem);
  else
    itensAlterar = draft.filter(x => campoOrdem(x) >= toOrdem && campoOrdem(x) < campoOrdem(itemMovido));

  itensAlterar.forEach(x => alterarOrdem(x, campoOrdem(x) + (movidoParaCima?-1:1)))

  alterarOrdem(itemMovido, toOrdem);
  itensAlterar.push(itemMovido);

  itensAlterar.forEach(item => {
    const index = draft.findIndex(x => campoId(x) === campoId(item));
    alterarOrdem(draft[index], campoOrdem(item));
  })
  
  draft.sort((a, b) => (a.ordem > b.ordem ? 1 : -1));

  setItems(draft);

  setTimeout(() => {
    setUpdated(!updated)
  }, 100);
}