

export function Loading(props) {
  return (
    <>
      {props.carregando === true
        &&
        <img src='/assets/images/icones/loading-pulse-white.svg'
             className="icone-carregando"
             alt="" />
      }
    </>
  )
}

