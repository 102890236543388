import styled from 'styled-components';
import Checkbox from '../checkbox/index.js';

const StyledCheckboxComPadding = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: .8rem 1.5rem;
  
  background: linear-gradient(90deg, #3D3C3C 0%, rgba(38, 38, 38, 0) 100%);
`;

export default function CheckboxComPadding(props) {
  return ( 
    <StyledCheckboxComPadding>
      <Checkbox {...props}/>
    </StyledCheckboxComPadding>
  )
};