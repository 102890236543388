import React from 'react'
import doIt from '../../../servicos/doIt';

import { FundoContainer, BoxCointainer } from './index.styled'
import { TituloAdmin3 } from '../../styled/textos/titulosAdmin'
import Input from '../../styled/inputs/simples/index.styled'
import BotaoRedondo from '../../styled/botoes/redondo/index.styled'





export default function ModalEntrega(props) {
  
  async function checkEnter(e) {
    doIt(async () => {
      if (e.key === 'Enter') {
        props.aprovarEntregaEspiral();
        props.setModalEntregaAberto(false);
      }
      
      else if(e.key === 'Escape') {
        props.setModalEntregaAberto(false);
      }
    }, {time: 1});
  }
  

  
  return (
    <FundoContainer>
      <BoxCointainer>
        <div>
          <TituloAdmin3 color='#3A73B9'>{ props.titulo }</TituloAdmin3>
          <p>Informe um comentário interno sobre sua ação na entrega.</p>
        </div>
        
        <div className='input-modal'>
          <Input 
            mode='light' 
            width='100%' 
            height='39px' 
            fontSize='15px' 
            fontWeight='500'
            placeholder='Comentário'
            autoFocus
            value={props.alteracao.comentario}

            onChange={(e) => props.setAlteracao({...props.alteracao, comentario: e.target.value})}
            onKeyDown={(e) => checkEnter(e)}
          />
        </div>
        
        
        <div>
          <BotaoRedondo onClick={() => props.setModalEntregaAberto(false)} background='#3A73B9'> Fechar </BotaoRedondo>
          <BotaoRedondo onClick={() => props.aprovarEntregaEspiral()}background='#3A73B9'> Concluir </BotaoRedondo>
        </div>

      </BoxCointainer>
    </FundoContainer>
  )
}