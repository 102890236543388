import styled from 'styled-components';

const FundoContainer = styled.div`
  width: 100%;
  height: 100vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
 
  background-color: rgba(0, 0, 0, 0.803);
  cursor: pointer;

`;


const BoxCointainer = styled.div`
  width: 100%;
  max-width: 607px;
  height: 450px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;

  position: fixed;

  background: #F5F5F5;
  box-shadow: 0px 0px 30px #000000;
  
  border-radius: 25px;

  z-index: 5;
  cursor: default;

  
  & > div:nth-child(1) {
    text-align: start;
    
    p{
      font-weight: 600;
      color: black;
      text-align: start;
    }
  }

  & > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
  }
  
  div{
    width: 80%;
  }

  .info-container {
    height: 250px;

    display: flex;
    justify-content: flex-start;
    align-items: inherit;
    flex-direction: column;
    gap: 15px;

    .menu {
      width: 100%;
      height: 30px;

      display: flex;
      flex-direction: row;
      gap: 20px;

      .selecionado{
        color: #2284FB;
      }

      span{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #B5B5B5;
        cursor: pointer;

        font-weight: 600;
      }
    }


    .container-comentario{
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      overflow-y: auto;
      gap: 15px;

      background: #FFFFFF;
      border: 1px solid #ACACAC;
      border-radius: 8px;

      padding: 15px;

      &::-webkit-scrollbar {
          width: 6px;
          border-radius: 999px;
      }
                    
      &::-webkit-scrollbar-thumb {
          background: #B0B0B0; 
          border-radius: 10px;
      }
      
      &::-webkit-scrollbar-thumb:hover {
          background: #555555; 
      }

      .comentario{
        width: 100%;
        display: flex;
        gap: 15px;
        color: black;

        .data{
          width: 100px;
          
          display: flex;
          justify-content: center;
          align-items: flex-end;
          flex-direction: column; 
          
          color: black;
          white-space: pre;
          font-weight: 700;
        }
  
        .text {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          padding: 0px 5px;
        }
      }
    }
  }


  //*{
  //  border: 1px solid green;
  //}

`;  

export {
  FundoContainer,
  BoxCointainer
}