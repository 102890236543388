import React, { useState } from 'react'
import doIt from '../../../../servicos/doIt.js';
import { toast } from 'react-toastify';


import { BoxOpcoesContainer } from './index.styled.js';
import ModalEntrega from '../../../modaisAcompanhamento/entrega/index.js';
import ModalComentario from '../../../modaisAcompanhamento/comentario/index.js';
import ModalDesempenhoApenasDiscord from '../../../modaisAcompanhamento/desempenhoApenasDiscord/index.js';
import ModalDesempenhoConteudo from '../../../modaisAcompanhamento/desempenhoConteudo/index.js';
import ModalDesempenhoTreinoTeorico from '../../../modaisAcompanhamento/desempenhoTreinoTeorico/index.js';
import ModalDesempenhoTreinoPratico from '../../../modaisAcompanhamento/desempenhoTreinoPratico/index.js';


import * as servicosAcompanhamento from '../../../../api/admin/acompanhamentoAPI.js'



export default function BoxOpcoes(props) {
  const [mostrarOutrasOpcoes] = useState(props.identificador === 'treinoTeorico' || props.identificador === 'treinoPratico' || props.identificador === 'conteudoVideos');  
  const [modalEntregaAberto, setModalEntregaAberto] = useState(false);
  const [modalComentario, setModalComentario] = useState(false);
  const [modalDesempenhoApenasDiscord, setModalDesempenhoApenasDiscord] = useState(false);
  
  const [modalConteudo, setModalConteudo] = useState(false);
  const [desempenhoConteudo] = useState()

  const [modalTreinoTeorico, setModalTreinoTeorico] = useState(false);
  const [desempenhoTreinoTeorico] = useState();

  const [modalTreinoPratico, setModalTreinoPratico] = useState();
  const [desempenhoTreinoPratico] = useState();

  const [alteracao, setAlteracao] = useState({situacao: '', comentario: ''});
  const [tituloModal, setTituloModal] = useState('');

  

  async function escolherTituloEAcaoEAbriModal(titulo, acao) {
    doIt(async () => {
      setModalEntregaAberto(true);
      setTituloModal(titulo);
      setAlteracao({...alteracao, situacao: acao})
    }, {time: 1});
  }




  async function aprovarEntregaEspiral() {
    doIt(async () => {
      await servicosAcompanhamento.aprovarEntregaEspiral(props.aluno.claId, props.aluno.alunoId, props.espiralId, props.topicoId, alteracao);
      props.reRenderInfo();
      toast.success('Ação realizada com sucesso!');
      setModalEntregaAberto(false)
      setAlteracao({situacao: '', comentario: ''});

      // Quando tenta aprovar sem mandar o comando ele realiza a ação mas da um erro
      // Coloquei pra re-renderizar mesmo assim
    }, {time: 1, onError: () => props.reRenderInfo()});
  }


  
  
  async function escolherModalDesempenho() {
    doIt(async () => {
      if(props.identificador === 'anotacoesLeitura' || props.identificador === 'anotacoesConceitos' || props.identificador === 'forum') {
        setModalDesempenhoApenasDiscord(true)
      }
      
      else if(props.identificador === 'conteudoVideos') {
        setModalConteudo(true);
      }
      
      else if(props.identificador === 'treinoTeorico') {
        setModalTreinoTeorico(true);
      }
      
      else if(props.identificador === 'treinoPratico') {
        setModalTreinoPratico(true);
      }
      
    }, {time: 1});
  }



  
  async function carregarInformacoesDesempenho(setState) {
    doIt(async () => {
      if(props.identificador === 'conteudoVideos') {
        let r = await servicosAcompanhamento.analisarDesempenhoConteudo(props.aluno.claId, props.espiralId, props.aluno.alunoId);
        setState(r);
      }
      
      else if(props.identificador === 'treinoTeorico') {
        let r = await servicosAcompanhamento.analisarDesempenhoTreinoTeorico(props.aluno.claId, props.espiralId, props.aluno.alunoId);
        setState(r);
      }
      
      else if(props.identificador === 'treinoPratico') {
        let r = await servicosAcompanhamento.analisarDesempenhoTreinoPratico(props.aluno.claId, props.espiralId, props.aluno.alunoId);
        setState(r);
      }
    }, {time: 1});
  }
   

  
  return (
    <BoxOpcoesContainer open={props.open} >
      
      {modalEntregaAberto === true &&
        <ModalEntrega 
          setModalEntregaAberto={setModalEntregaAberto}
          setAlteracao={setAlteracao}
          aprovarEntregaEspiral={aprovarEntregaEspiral} 
          titulo={tituloModal}
          alteracao={alteracao}
        />
      }


      {modalComentario === true &&
        <ModalComentario 
          setModalComentario={setModalComentario}
          comentarioProf={props.comentarioProf}
          comentarioAluno={props.comentarioAluno}
          identificador={props.identificador}
        />
      }


      {modalDesempenhoApenasDiscord === true && 
        <ModalDesempenhoApenasDiscord
          identificador={props.identificador}

          mensagensForum={props.mensagensForum}
          anotacao={props.anotacao}
          nome={props.nome}

          setModalDesempenho={setModalDesempenhoApenasDiscord}
        />
      }

      
      {modalConteudo === true && 
        <ModalDesempenhoConteudo 
          setModalConteudo={setModalConteudo}
          desempenho={desempenhoConteudo}
          nome={props.nome}

          carregarInformacoesDesempenho={carregarInformacoesDesempenho}
        />
      }
      

      {modalTreinoTeorico === true && 
        <ModalDesempenhoTreinoTeorico 
          setModalTreinoTeorico={setModalTreinoTeorico}
          desempenho={desempenhoTreinoTeorico}
          nome={props.nome}
          canalFeedbackId={props.aluno.canalFeedbackId}

          carregarInformacoesDesempenho={carregarInformacoesDesempenho}
        />
      }
      
       
      {modalTreinoPratico === true && 
        <ModalDesempenhoTreinoPratico
          setModalTreinoPratico={setModalTreinoPratico}
          desempenho={desempenhoTreinoPratico}
          nome={props.nome}
          canalFeedbackId={props.aluno.canalFeedbackId}

          claId={props.aluno.claId}
          espiralId={props.espiralId}
          alunoId={props.aluno.alunoId}
          
          carregarInformacoesDesempenho={carregarInformacoesDesempenho}

        />
      }



      {/* Caso o tópico esteja bloqueado */}
        {props.bloqueado === true &&
        <>
          <div> <span className='item' onClick={() => escolherTituloEAcaoEAbriModal('Liberar', 'liberado')}> Liberar </span> </div>
        </>
      }
      
      {/* Caso o tópico não esteja bloqueado */}
      {props.bloqueado === false && 
        <>
          <div> 
            <span className='item' onClick={() => escolherModalDesempenho()}> Desempenho </span>
          </div>   
          
          <div>
            <span className='item' onClick={() => escolherTituloEAcaoEAbriModal('Aprovar', 'aprovado')}> Aprovar </span>
            <span className='item' onClick={() => escolherTituloEAcaoEAbriModal('Corrigir', 'corrigir')}> Corrigir </span>
            
            {mostrarOutrasOpcoes && 
              <>
                <span className='item' onClick={() => escolherTituloEAcaoEAbriModal('Liberar', 'liberado')}> Liberar </span>
                <span className='item' onClick={() => escolherTituloEAcaoEAbriModal('Bloquear', 'bloqueado')}> Bloquear </span>
              </>
            }
          </div>   
          
          <div> 
            <span className='item' onClick={() => setModalComentario(true)}> Comentários </span>
          </div>   
          

          {props.identificador === 'conteudoVideos' && 
            <div> 
              <span className='item'> Liberar Correção </span>
            </div>   
          }
        </>
      }

    </BoxOpcoesContainer>
  )
}
  