import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast  } from "react-toastify";


import { StyledTerminal } from "./styled";
import { BoxWithScroll } from "../styled/boxWithScroll";



import { enviarSolucaoDoTreino } from "../../api/usuario/treinoPraticoAPI";
import { obterInformacoesAlunoLogado } from "../../api/usuario/contaAPI";





export default function Terminal(props) {

  const [ situacao, setSituacao ] = useState('');
  const [ tempExc, setTempExc ] = useState('');
  const [ contador, setContador ] = useState(0);
  //const [ contPlaceholder, setContPlaceholder ] = useState(0);
  const [ click, setClick ] = useState(false);
  const [ infoUsu, setInfoUso ] = useState({});
  const [ escolhaTerminal, setEscolhaTerminal ] = useState('');

  

  

  const navi = useNavigate();


  let valorArquivos = [];

  useEffect(() => {
    infoAluno();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    props.tamanhoVs(click, situacao, contador);
    // eslint-disable-next-line
  }, [click, situacao, contador])


  




  async function infoAluno() {
    const r = await obterInformacoesAlunoLogado();
    setInfoUso(r.info);
    return r;
  }




  function direcionamento(valor, event) {


    if(event.key !== 'Enter') return;

   

    if(valor <= '2' && valor >= '1') {
      if(valor === '1') {
        setClick(false);
        setContador(0);
        setEscolhaTerminal('');
        setTempExc('');
        setSituacao('');
      } else if (valor === '2') {
        navi(-1)
      }
    } else {
      toast.warning('Resposta inválida!')
    }
  }

  


  async function enviarResposta() {

    

    try {
     if(props.fonte.length === 0) {
       return toast.warn('Escreva uma resposta!')
     }
      else if (!props.linguagem) {
        return toast.warn('Linguagem é obrigatória!')
     } else {
      if(click === true)
        return toast.warning('Resposta já foi enviada!');
         setClick(true);
         Timer(situacao); 
         const codigoFonte = props.fonte.map((item, i ) => ({name: props.arquivos[i], content: item }))
         const r = await enviarSolucaoDoTreino(props.params.cla,props.params.espiral, props.params.treino, props.linguagem, codigoFonte ,props.params.comentarios) 
          
         setSituacao(r.situacao);
         setTempExc(r.tempoExec);
         //apresentarAvaliacaoSolucao(r.situacao);
         
         return r;
       }
    } catch(err) {
       toast.warn(err.response.data.erro);
     }
   }


 

  function Timer(situacao) {
    let valor = 0  
    setInterval(() => {


      if(valor === 9)
        return;

      if(situacao.length === 0)
        setContador(3)

      valor += 1
      setContador(valor)
    }, 800)
  }



  

  


  
  return (
  <StyledTerminal>
    <BoxWithScroll className={click === false ? "low": "high"} style={{backgroundColor:"#181818", overflowY: 'hidden', borderTop: "none"}}> 
      <div className="main"> 
        <div className="padding"> (c) Devmonk Compiler. Todos os direitos reservados. </div>
        <div className="padding"> {infoUsu.nome +"@admk:~$"} <input placeholder="enviar" readOnly={true} onClick={() => enviarResposta()}/> </div>
          <div className="passos"> 
          {contador >= 1 ? <div className="passo-1"> [01/03] - Enviando solução........ </div> : null}
          {contador >= 2 ? <div className="passo-2"> [02/03] - Rodando testes.............. </div> : null}
          {contador >= 3 ? <div className="passo-3 padding"> [03/03] - Avaliando respostas............. </div> : null}
        </div>
        <div className="performace"> 
          {situacao.length !== 0 ? contador >= 4 ? <div className="resultado"> == Resultado == </div>: null : null}
          {situacao.length !== 0 ?  contador >= 5 ? <div className={situacao === 'Erro' ?  'padding' : "situacao"}> Situação....: {situacao} </div> : null : null} 
          {situacao !== 'Erro' && situacao.length !== 0 ? contador >= 5 ? <div className="padding"> Tempo.......: {tempExc} </div> : null : null}
          
        </div>
        <div className="escolhas"> 
          {situacao.length !== 0 ? contador >= 8 ? <div className="escolher"> O que deseja fazer? </div> : null : null}
          {situacao.length !== 0 ? contador >= 9 ? <div className="opcao-1"> [1] Iniciar uma nova solução </div> : null : null}
          {situacao.length !== 0 ? contador >= 9 ? <div className="padding"> [2] Voltar a lista de treinos </div> : null : null}
          {situacao.length !== 0 ? contador >= 9 ? <div className="escolha"> Digite a opção aqui: <input value={escolhaTerminal} onChange={e => setEscolhaTerminal(e.target.value)} onKeyPress={(key) => direcionamento(escolhaTerminal, key)}/> </div> : null : null}
        </div>
      </div>
    </BoxWithScroll>
  </StyledTerminal>
  )
}