import { getApi } from "./base";
const api = getApi('admin/treinoteorico');
const apiPublic = getApi();




export async function listarTreinosEPerguntas(cla, espiral, treino) {
  const admin = await api().get(`/${cla}/${espiral}/treino/${treino}`);
  return admin.data;
};




export async function listarTreinosPorEspiral(claId, espiralId) {
  const admin = await api().get(`/${claId}/${espiralId}/treino`);
  return admin.data;
}




export async function listaPerguntaEspecifica(claId, espiralId, treinoId, perguntaId) {
  const admin = await api().get(`/${claId}/${espiralId}/treino/${treinoId}/pergunta/${perguntaId}`);
  return admin.data;
}




export async function alterarOrdemPerguntas(claId, espiralId, treinoId, perguntaId, novaOrdem) {
  const admin = await api().put(`/${claId}/${espiralId}/treino/${treinoId}/pergunta/${perguntaId}/ordem`, { novaOrdem });
  return admin.data;
}




export async function cadastrarTreino(claId, espiralId, tipo, nome) {
  const admin = await api().post(`/${claId}/${espiralId}/treino`, {
    tipo: tipo,
    nome: nome
  })
  return admin.data
}




export async function cadastrarPergunta(claId, espiralId, treinoId, info) {
  const admin = await api().post(`/${claId}/${espiralId}/treino/${treinoId}/pergunta`, {
      info: {
        tipoTreino: info.info.tipoTreino,
        titulo: info.info.titulo,
        descricao: info.info.descricao,
      },
      assets: {
        pergunta: info.assets.pergunta,
        correcao: info.assets.correcao,
      },
      quiz: {
        tempoAnalise: info.quiz.tempoAnalise,
        tempoResposta: info.quiz.tempoResposta,
        tipoAlternativa: info.quiz.tipoAlternativa,
        alternativas: info.quiz.alternativas
      },
      leitura: {
        painelEsquerda: info.leitura.painelEsquerda,
        painelDireita: info.leitura.painelDireita
      }
    });
  return admin.data;
}




export async function editarTreino(claId, espiralId, treinoId, tipo, nome) {
  const admin = await api().put(`/${claId}/${espiralId}/treino/${treinoId}`, {
      tipo: tipo,
      nome: nome
  });
  return admin.data;
}




export async function editarPergunta(claId, espiralId, treinoId, perguntaId, perguntaInfo) {
  const r = await api().put(`/${claId}/${espiralId}/treino/${treinoId}/pergunta/${perguntaId}`, perguntaInfo);
  return r.data
}




export async function alterarTreinoOrdem(claId, espiralId, treinoId, novaOrdem) {
  const admin = await api().put(`/${claId}/${espiralId}/treino/${treinoId}/ordem`, { novaOrdem });
  return admin.data;
}




export async function deletarPergunta(claId, espiralId, treinoId, perguntaId) {
  const r = await api().delete(`/${claId}/${espiralId}/treino/${treinoId}/pergunta/${perguntaId}`);
  return r.data
}




export async function deletarTreino(claId, espiralId, treinoId) {
  const r = await api().delete(`/${claId}/${espiralId}/treino/${treinoId}`);
  return r.data
}




export async function pegarImagemAlternativa(imagem) {
  const r = await apiPublic().get(`${apiPublic.getUri()}/${imagem}`);
  return r.data
}




export async function enviarImagemStorage(imagem) {
  let formData = new FormData();
  formData.append('imagem', imagem);
  const r = await apiPublic().post(`public/assets/images/`, formData);
  return r.data;
}