import { InputStyle } from "./styled";
import Editor from "@monaco-editor/react";
import { theme } from "./editorTheme";
import { useEffect, useRef, useState } from "react";



export default function CodeEditor({ value, onChange, onPositionChange, disabled, defaultValue, myRef, glyph, language }) {
  const [position, setPosition] = useState();
  const editorRef = useRef();


  // eslint-disable-next-line
  useEffect(() => onPositionChange && onPositionChange(position), [position]);



  
  function change(e) {
    if (!disabled) {
      onChange(e);
    }
  }




  function lerLinguagem() {
    // eslint-disable-next-line
    switch (language) {
      case 'c': return 'c';
      case 'cs': return 'csharp';
      case 'js': return 'javascript';
      case 'java': return 'java';
      case 'py': return 'python';
    }
  }




  function customOptions(editor, monaco) {
    if (myRef)
        myRef.current = editor;
    
    editorRef.current = editor;

    monaco.editor.defineTheme('DevMonkTheme', theme);
    monaco.editor.setTheme('DevMonkTheme');

    editor.onDidChangeCursorPosition(e => {
      setPosition(e.position)
    })
  }



  
  return (
    <InputStyle>
      
      <Editor
        className="editor"
        language={lerLinguagem()}
        defaultValue={defaultValue}
        value={value}
        onChange={change}
        onMount={customOptions}
        options={{
          lineHeight: 22,
          padding: { top: 20 },
          lineDecorationsWidth: 25,
          lineNumbersMinChars: 4,
          readOnly: disabled,
          glyphMargin: false,
          scrollbar: {
            verticalScrollbarSize: 15,
            useShadows: true
          },
          minimap: {
            enabled: false
          }
        }}
      />

    </InputStyle>
  );
}
