import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './index.styled';

export function CardMembro({ titulo, id, imagem, link, onVincular }) {
	const notId = !id || !id.trim();

	return (
		<Container>
			<div>
				<img src={imagem} alt='' />
				<div>
					<h1>
						{titulo}
					</h1>
					<h2>
						Membro ID:
					</h2>
					<p className={notId ? 'nao-vinculado' : ''} onClick={notId ? onVincular : null}>
						{notId ? 'Vincular' : id}
					</p>
				</div>
			</div>
			<Link to={link}>Acessar</Link>
		</Container>
	);
}
