import React from 'react'

// Hooks
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Style
import './index.scss';

// Components
import { TituloUsuario1, TituloUsuario2, TituloUsuario3 } from '../../../../components/styled/textos/titulosUsuario';
import { menu } from '../menu';
import ItemLista from '../../../../components/listasTreinos/usuario/ItemLista';

// Api
import { buscarClaPorId, buscarEspiralPorId } from '../../../../api/usuario/contaAPI.js';
import { listarTreinosPorAluno } from '../../../../api/usuario/treinoTeoricoAPI';
import PaginaUsuario from '../../../../components/paginas/paginaUsuario';
import doIt from '../../../../servicos/doIt';





export default function TreinoTeorico_ListaTreino() {
  const [treinos, setTreinos] = useState();
  const [nomeCla, setNomeCla] = useState();
  const [nomeEspiral, setNomeEspiral] = useState();

  const navigate = useNavigate('');
  const { cla, espiral } = useParams();


  useEffect(() => {
    async function carregarCla() {
      doIt(async () => {
        const nomeCla = await buscarClaPorId(cla);
        const nomeEspiral = await buscarEspiralPorId(cla, espiral);
        setNomeCla(nomeCla?.info?.nome);
        setNomeEspiral(nomeEspiral?.nome);
      },{time: 1})
    }
    
    
    
    
    async function listarTreinos() {
      doIt(async () => {
        const r = await listarTreinosPorAluno(cla, espiral);
        setTreinos(r);
      })
    }
    


    carregarCla();
    listarTreinos();
    //eslint-disable-next-line
  }, []);



  return (
    <PaginaUsuario className='treino-teorico-treino' menu={menu} carregando={treinos === undefined}>

      <div className='treinos-container'>
        <div className='conteudo'>
          <div className='titulos'>
            
            <TituloUsuario2 onClick={() => navigate(`/treinoteorico/cla/${cla}/espiral`)} className='sub'>
              {nomeCla}
            </TituloUsuario2>
            
            <TituloUsuario1 className='principal'>
              {nomeEspiral}
            </TituloUsuario1>
          </div>

          <div className='cards-container'>
            <TituloUsuario3>Lista de treinos</TituloUsuario3>
            <div className="group-items">

              {treinos?.map(treino => {
                return (
                  <ItemLista
                    largura='100%'
                    altura='60px'
                    color='#C0C0C0'
                    backgroundColor='#232323'
                    treino='quiz'
                    nome={treino.nome}
                    tipo={treino.tipo}
                    situacao={treino.situacao}
                    qtdAcertos={treino.qtdAcertos}
                    qtdPerguntas={treino.qtdPerguntas}

                    onClick={() => {
                      if (treino.tipo === 'quiz' && treino.situacao !== 'bloqueado') navigate(`/treinoteorico/cla/${cla}/espiral/${espiral}/treino/${treino.id}/quiz`);
                      if (treino.tipo === 'leitura' && treino.situacao !== 'bloqueado') navigate(`/treinoteorico/cla/${cla}/espiral/${espiral}/treino/${treino.id}/leitura`)
                    }}
                  />
                );
              })}
            </div>
          </div>

        </div>
      </div>




    </PaginaUsuario>
  )
}
