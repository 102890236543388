import styled from "styled-components";

const StyledBoxCurso = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  scroll-snap-align: center;

  border: 1px solid #3c3a3a;
  box-sizing: border-box;
  border-radius: 5px;

  width: 19em;
  min-width: 19em;
  height: 18em;
  margin: 9px;
    
  background-color: rgba(0, 0, 0, 0.1);

  .tipo-curso {
    font-family: Mukta-bold;
    font-size: 1.3em;
    line-height: 1em;
    color:#CC9300;
    text-align: center;
    width: 7em;
  }

  .desc-curso {
    font-family: Mukta-regular;
    font-size: 14px;

    line-height: 1.4em;
    text-align: center; 
    width: 19em;
    height: 10em;
    margin-top: 2em;

    overflow: auto;
    padding: 0px 15px;
  }

  @media (width <= 1024px) {
    .desc-curso {
      width: 13em;
    }
  }

  .desc-curso::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: transparent;
  }

  .desc-curso::-webkit-scrollbar-thumb {
    background: #555555; 
    border-radius: 2px;
  }

  .desc-curso::-webkit-scrollbar-thumb:hover {
    background: #6b6b6b; 
  }
`;

export { StyledBoxCurso };
