import React, { useEffect, useState } from 'react';
import doIt from '../../../../servicos/doIt';
import './index.scss';

import CardCla from '../../../../components/cardCla/index'
import PaginaAdmin from '../../../../components/paginas/paginaAdmin';
import { Carrossel } from '../../../../components/carrossel';
import { TituloAdmin1, TituloAdmin2 } from '../../../../components/styled/textos/titulosAdmin';
import { menu } from '../menu'


import * as api from '../../../../api/admin/contaAPI';




export default function TreinoTeorico_Cla_Admin() {
  const [clas, setClas] = useState();

  useEffect(() => {
    async function claList() {
      doIt(async () => {
        const resp = await api.listarClas();
        setClas(resp);
      })
    }

    claList();
    //eslint-disable-next-line
  }, []);

  return (
    <PaginaAdmin
      className='treinoteorico_cla_admin'
      carregando={clas === undefined}
      menu={menu}
    >

      <div className='treinoteorico_cla_admin-sub'>
        <TituloAdmin1> Clãs </TituloAdmin1>

        <section className='clas'>
          {clas?.length === 0 ?
            <TituloAdmin2> Não há cursos cadastrados no momento. </TituloAdmin2>
            :
            <Carrossel>
              {clas?.map(({ info, estilo, id }, index) => (
                <CardCla
                  titulo={info.nome}
                  descricao={info.descricao}
                  background={estilo.fundo}
                  icone={estilo.icone}
                  rota='/admin/treinoteorico/cla/:cla/espiral'
                  parametros={[id]}
                  key={index}
                />
              ))}
            </Carrossel>
          }
        </section>
      </div>

    </PaginaAdmin>

  );

}
