import React from "react";
import { Container } from "./index.styled";
import 'animate.css';


import { AnimationOnScroll } from "react-animation-on-scroll";



export default function Metodo(props) {
  return(
    <Container id={props.id}>

        <div className="agrupamento-titulo">
            <div className="estrela"> <img src="/assets/images/icones/estrela1.svg" alt="imagem método"/> </div>
            
            <div className="titulo-metodo"> Método </div>
        </div>

        <div className="agrupamento-componentes">
            <AnimationOnScroll className="pai-primeiro-componente-metodo" animateIn="animate__fadeInRight" animateOnce={true}>
                <div className="componente-metodo"> 
                  
                      <div className="imagem-metodo-01"> <img src="/assets/images/icones/imagem-metodo-componente-01.svg" alt="imagem método"/> </div>
                      <div className="textos-metodo">
                          <div className="titulo-componente-01"> Teoria </div>
                          <div className="descricao-componente"> 
                              A teoria descreve as leis de forma sistematizada e possibilita agir em qualquer situação.
                          </div>
                      </div>
                  
                </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>            
                <div className="componente-metodo"> 
                    <div className="imagem-metodo-02"> <img src="/assets/images/icones/imagem-metodo-componente-02.svg" alt="imagem método"/> </div>
                    <div className="textos-metodo">
                        <div className="titulo-componente-02"> Prática </div>
                        <div className="descricao-componente"> 
                            A prática é a extensão da teoria, onde situações problema são apresentadas provando se a teoria foi dominada.
                        </div>
                    </div>
                </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInRight componente-metodo-responsivo" animateOnce={true}>            
                <div className="componente-metodo"> 
                    <div className="imagem-metodo-01"> <img src="/assets/images/icones/imagem-metodo-componente-01.svg" alt="imagem método"/> </div>
                    <div className="textos-metodo">
                        <div className="titulo-componente-01"> Treino </div>
                        <div className="descricao-componente">  
                            Treino não é exercício! Ele é o elo da Teoria com a Prática, concentrando em explorar, diversificar e antecipar raciocínios, formando modelos mentais a serem utilizados na prática.
                        </div>
                    </div>    
                </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>            
                <div className="componente-metodo"> 
                    <div className="imagem-metodo-02"> <img src="/assets/images/icones/imagem-metodo-componente-02.svg" alt="imagem método"/> </div>
                    <div className="textos-metodo">
                        <div className="titulo-componente-02"> Disciplina </div>
                        <div className="descricao-componente">  
                            O sucesso da aprendizagem exige organização, resilência e a habilidade moral de fazer o que deve ser feito mesmo que impulsos contrários atuem sobre a vontade.
                        </div>
                    </div>
                </div>
            </AnimationOnScroll>        
            
        </div>
    </Container>
  )
}