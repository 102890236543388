import styled from "styled-components";


export const Container = styled.div`  
  display: flex;
  
  color: #31332B;
  min-height: 100vh;
  font-family: 'Poppins';

  & > section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #FFFFFF 0%, #E0E0E0 100%);
  }

  ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
  background: #555555; 
  }

  ::-webkit-scrollbar-thumb:hover {
  background: #6b6b6b; 
  }



  overflow: hidden;
  animation: ${props => props.carregando === true ? 'unset' : 'scroll 1s both'};
  animation-delay: 1s;



  @keyframes scroll {
    100% { overflow: auto; }
  }

  @keyframes entrada {
    from { 
      opacity: 0;
      bottom: -50px;
    }
    to {
      opacity: 1;
      bottom: 0px;   
    }
  }



  & > section > main {
      flex-direction: column;
      align-items: flex-start;
      
      
      display: ${props => props.carregando ? 'none' : 'flex'};
      animation: ${props => props.carregando ? 'unset' : 'entrada 1s both'};
      padding: 80px 70px 85px 80px !important;
      position: relative;
      
  }


  .icone-carregando {
      position: fixed;
      left: 310px;
      top: 85px;
      width: 40px;
      height: auto;
  }



  @media (max-width: ${p => p.mediaMaxWidth ?? '1024px'}) {
    min-width: unset;
    
    & > section > main {
      padding: 40px 30px 92px 30px !important;
      overflow-x: auto;
      max-width: 100vw;
    }

    .icone-carregando {
      left: 20px;
    }
  }


`


