import Field from './index.styled.js';

export default function ApplyInput(props) {
  return (
    <Field mode={props.mode} width={props.width} height={props.height}>
      <input {...props} required placeholder='' onClick={undefined}/>
      <label>{props.placeholder}</label>
      <button onClick={props.onClick}><img src={props.imagem ?? "/assets/images/icones/apply.svg"} alt="apply icon" /></button>
    </Field>
  )
}

