import { HomeIcone } from "../../../components/menu/comps/icones";


export const menu = {
  podeSelecionar: false,
  items: [
    {
      nome: 'Conteúdo',
      url: '/admin/conteudo/cla',
      icone: <HomeIcone/>
    }
  ],
  animarURLs: [
    '/admin/conteudo/cla'
  ]
}


