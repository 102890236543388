function AbastecerCursos() {
  return [
    {
      titulo: "Programming Foundation" ,
      descricao: `Curso avançado que aborda os fundamentos da programação em uma visão pragmática, 
                definindo formalmente conceitos e comparando sua implementação com diversas linguagens. 
                Seja um autodidata para aprender qualquer linguagem e refine sua lógica com as boas práticas 
                da programação.`
    },
    {
      titulo: "JS, Syntax & Semanthics" ,
      descricao: `Conheça a linguagem Javascript sem ter conhecimento prévio em programação. 
                  Desenvolva e aperfeiçoe sua lógica para criar algoritmos computacionais.`
    },
    {
      titulo: "C#, Syntax & Semanthics" ,
      descricao: `Conheça a poderosa linguagem da plataforma DOTNET da Microsoft, 
                  sem ter conhecimento prévio em programação. Desenvolva e aperfeiçoe 
                  sua lógica para criar algoritmos computacionais.`
    }
  ]
}



function AbastescerDepoimentos() {
    return [
      {
        imagem: '/assets/images/imagens/ruan-barreto-depoimento.jpeg',
        depoimento: `Na Devmonk tive o prazer de participar do curso Fundamentos da programação, 
                    onde ganhei o conhecimento de toda a base e origem desse gigantesco e vasto mundo, 
                    com aulas em que as horas se tornavam minutos devido ao conteúdo ensinado ser de 
                    altíssima qualidade e direto ao ponto!`,
        nome: 'Ruan Barreto',
        cargo: 'Programador Backend Pleno',
        empresa: 'Accenture'
      },
      {
        imagem: '/assets/images/imagens/pedro-moreira-depoimento.jpeg',
        depoimento: `Os cursos dessa plataforma possuem uma didática incrível e de facil compreensão, 
                    trazendo sempre uma explicação aprofundada dos temas, o que ajuda a entender não 
                    somente como desenvolver as mais variadas aplicações, mas tambem o porque de desenvolver 
                    de determinadas formas.`,
        nome: 'Pedro H. Moreira',
        cargo: 'Programador Backend Pleno',
        empresa: 'Ingenico'
      },
      {
        imagem: '/assets/images/imagens/bruno-gomes.jpeg',
        depoimento: `Muito boa a forma de ensino! Posso dizer com toda certeza que o método utilizado, 
                    foi um dos principais motivos para me apaixonar e seguir na área de programação. 
                    Unindo muito bem a prática com a teoria, me permitiu aplicar imediatamente o que aprendi, 
                    dessa forma, me ajudou bastante a firmar todo o conteúdo. Além de que o professor estava 
                    sempre disponível para ajudar e tirar minhas dúvidas quando precisava.`,
        nome: 'Bruno Gomes',
        cargo: 'Programador Mobile Pleno',
        empresa: 'General claims'
      },
      {
        imagem: '/assets/images/imagens/sthefany-cristovam-da-silva.jpeg',
        depoimento: `O diferencial do DevMonk é focar nos conceitos, além da prática. A gente aprende e entende 
                     a estrutura de tudo, e isso faz a diferença quando precisamos encontrar soluções eficientes. 
                     Tive uma boa experiência, tanto com o professor quanto com meus colegas, que sempre me auxiliavam 
                     quando eu tinha alguma dúvida. Recomendo o curso 100% 🥰`,
        nome: 'Sthefany Cristovam da Silva',
        cargo: 'Programadora Fullstack Junior',
        empresa: 'PWI'
      },
      {
        imagem: '/assets/images/imagens/mayara-alves.jpeg',
        depoimento: `Adoro a metodologia de ensino, desafios pra mim é uma forma divertida de estudar e o 
                     Professor Bruno está sempre trazendo dicas que nos ajudam muito e está sempre presente para 
                     tirar dúvidas.`,
        nome: 'Mayara Alves',
        cargo: 'Programadora Mobile Junior',
        empresa: 'Pekus'
      },
      {
        imagem: '/assets/images/imagens/eduardo-vinícius-r-lima.jpeg',
        depoimento: `Os ensinamentos obtidos através do Devmonk são imensuráveis. A liberdade de expressar suas 
                     próprias opiniões, auxiliar nas soluções em conjunto e dividir o conhecimento com toda a equipe 
                     foram pontos fundamentais para a evolução, quando um compartilha todos aprendem. As habilidades 
                     e métodos de aprendizados desenvolvidos te auxiliam a ser um profissional confiante e destacado 
                     no mercado. Apenas gratidão por ter feito parte deste grupo! "Vamos ser ninjas!".`,
        nome: 'Eduardo Vinícius R. Lima',
        cargo: 'Desenvolvedor Backend Pleno',
        empresa: 'XDS'
      }
    ]
}

export { AbastecerCursos, AbastescerDepoimentos }