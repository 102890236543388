import './index.scss'
import Botao from '../../../components/styled/botoes/redondo/index.styled';


import { useNavigate } from "react-router-dom";


export default function Unauthorized() {

  const navigate = useNavigate();

  return (
    <main className='unauthorized-main'>
      <div className='unauthorized-text'>
        <span className='titulo'>401</span>
        <span>Desculpe, você não tem permissão para acessar essa página</span>
        <Botao className="botao" background={"#da7a0c"} padding={".5em 1.8em"} radius={"2em"} onClick={() => navigate('/')}> Voltar para a página inicial </Botao>
      </div>
      <div className='unauthorized-images'>
        <img className='estrela-1' src="/assets/images/icones/estrela1.svg" alt=''/>
        <img className='estrela-2' src="/assets/images/icones/estrela1.svg" alt=''/>
        <img className='templo-shaolin' src='/assets/images/backgrounds/templo-shaolin.svg' alt='templo-shaolin'/>
       <div className='monges-barras'> 
        <img className='monges' src='/assets/images/icones/monges-bloqueando.svg' alt='monges'/>
        <img className='barra-direita' src='/assets/images/icones/barra-monge-direita.svg' alt=''/>
        <img className='barra-esquerda' src='/assets/images/icones/barra-monge-esquerda.svg' alt=''/>
       </div>
      </div>
    </main>
  )
}