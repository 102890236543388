import { BrowserRouter, Routes, Route } from 'react-router-dom'

import * as imports from './routes.imports'


export default function AppRoutes() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<imports.Home />} />

        
        {/* CONTA */}
        <Route path='/conta/login' element={<imports.LoginAluno />} />
        <Route path='/conta/criarConta' element={<imports.CadastrarAluno />} />
        <Route path='/conta/verificarConta/:email' element={<imports.VerificarConta />} />
        <Route path='/conta/reset' element={<imports.ResetarSenha />} />
        <Route path='/conta/verificarCodigo/:email' element={<imports.VerificarCodigoResetaSenha />} />
        <Route path='/conta/novaSenha/:email/codigo/:codigo' element={<imports.AtualizarSenhaAlunoReset />} />

        <Route path='/conta' element={<imports.MinhaConta />} />
        <Route path='/conta/clas' element={<imports.MeusClas />} />
        <Route path='/conta/rotina' element={<imports.MinhaRotina />} />

        <Route path='/admin/conta/login' element={<imports.LoginAdmin />} />
        <Route path='/admin/conta/clas' element={<imports.GerenciarClas />} />
        <Route path='/admin/conta/alunos' element={<imports.GerenciarAlunos />} />


        
        {/* CONTEÚDO */}
        <Route path='/admin/conteudo/cla' element={<imports.Conteudo_Cla_Admin/>} />
        <Route path='/admin/conteudo/cla/:cla/espiral' element={<imports.Conteudo_Espiral_Admin />} />
        <Route path='/admin/conteudo/cla/:cla/espiral/:espiral/conteudos' element={<imports.Conteudo_Lista_Admin />} />
        <Route path='/admin/conteudo/cla/:cla/espiral/:espiral/conteudo/novo' element={<imports.Conteudo_Novo_Admin />} />
        <Route path='/admin/conteudo/cla/:cla/espiral/:espiral/conteudo/:conteudo/alterar' element={<imports.Conteudo_Novo_Admin />} />

        <Route path='/conteudo/cla' element={<imports.Conteudo_Cla_Aluno/>}/>
        <Route path='/conteudo/cla/:cla/espiral' element={<imports.Conteudo_Espiral_Aluno />}/>
        <Route path='/conteudo/cla/:cla/espiral/:espiral/lista' element={<imports.Conteudo_Espiral_Aluno_Listas />} />


        
        {/* ACOMPANHAMENTO */}
        <Route path='/admin/acompanhamento/cla' element={<imports.Discord_Cla />} />
        <Route path='/admin/acompanhamento/cla/:cla/espiral' element={<imports.Discord_Espiral />} />
        <Route path='/admin/acompanhamento/cla/:cla/espiral/:espiral/' element={<imports.Discord_Aluno />} />
        <Route path='/admin/acompanhamento/cla/:cla/analiseEntregas' element={<imports.Discord_AnaliseEntregas />} />
        <Route path='/admin/acompanhamento/cla/:cla/aluno/:aluno/' element={<imports.Discord_Aluno_Espiral/>} />
        <Route path='/admin/acompanhamento/cla/:cla/aluno/:aluno/espiral/:espiral' />


        
        {/* TREINO PRÁTICO */}
        <Route path='/admin/treinopratico/cla' element={<imports.TreinoPratico_Cla_Admin/>}/>
        <Route path='/admin/treinopratico/cla/:cla/espiral' element={<imports.TreinoPratico_Espiral_Admin/>}/>
        <Route path='/admin/treinopratico/cla/:cla/espiral/:espiral/treinos' element={<imports.TreinoPratico_ListarTreinos_Admin/>}/>
        <Route path='/admin/treinopratico/cla/:cla/espiral/:espiral/treino/novo' element={<imports.TreinoPratico_TreinoNovo_Admin/>}/>
        <Route path='/admin/treinopratico/cla/:cla/espiral/:espiral/treino/:treino/alterar' element={<imports.TreinoPratico_TreinoNovo_Admin/>}/>
        <Route path='/admin/treinopratico/cla/:cla/espiral/:espiral/aluno/:aluno/desempenho' element={<imports.TreinoPratico_RespostaUsuario/>} />

        <Route path='/treinopratico/cla' element={<imports.TreinoPratico_Cla/>}/>
        <Route path='/treinopratico/cla/:cla/espiral' element={<imports.TreinoPratico_Espiral/>}/> 
        <Route path='/treinopratico/cla/:cla/espiral/:espiral/lista' element={<imports.TreinoPratico_ListarTreinos/>}/>
        <Route path='/treinopratico/cla/:cla/espiral/:espiral/treino/:treino/compiler' element={<imports.TreinoPratico_Compiler/>} />
        

        
        {/* TREINO TEÓRICO */}
        <Route path='/admin/treinoteorico/cla' element={<imports.TreinoTeorico_Cla_Admin/>}/>
        <Route path='/admin/treinoteorico/cla/:cla/espiral' element={<imports.TreinoTeoricoAdmin_Espirais/>}/>
        <Route path='/admin/treinoteorico/cla/:cla/espiral/:espiral/treinos' element={<imports.TreinoTeorico_ListaTreino_Admin/>}/>
        
        <Route path='/admin/treinoteorico/cla/:cla/espiral/:espiral/treino/novo' element={<imports.TreinoTeorico_PerguntasLista/>}/>
        <Route path='/admin/treinoteorico/cla/:cla/espiral/:espiral/treino/:treino/alterar' element={<imports.TreinoTeorico_PerguntasLista/>}/>
 
        <Route path='/admin/treinoteorico/cla/:cla/espiral/:espiral/treino/:treino/pergunta/novo' element={<imports.TreinoTeorico_Quiz_Novo/>} />
        <Route path='/admin/treinoteorico/cla/:cla/espiral/:espiral/treino/:treino/pergunta/:pergunta/alterar' element={<imports.TreinoTeorico_Quiz_Novo/>} />

        <Route path='/treinoteorico/cla' element={<imports.TreinoTeorico_Cla />}/>
        <Route path='/treinoteorico/cla/:cla/espiral' element={<imports.TreinoTeorico_Espiral />} />
        <Route path='/treinoteorico/cla/:cla/espiral/:espiral/treinos' element={<imports.TreinoTeorico_ListaTreino/>} />
        <Route path='/treinoteorico/cla/:cla/espiral/:espiral/treino/:treino/quiz' element={<imports.TreinoTeorico_QuizController />}/>
        <Route path='/treinoteorico/cla/:cla/espiral/:espiral/treino/:treino/leitura' element={<imports.TreinoTeorico_LeituraController/>}/>

        
        {/* OUTROS */}
        <Route path='*' element={<imports.NotFound/>} />
        <Route path='unauthorized' element={<imports.Unauthorized/>} />

        {/* PDF */}
        <Route path='/pdf' element={<imports.PdfViewer/>}/>

      </Routes>
    </BrowserRouter>
  )
}