import React from 'react';
import { useNavigate } from 'react-router-dom';
import { API_ADDRESS } from '../../api/apiConfig';
import { BotaoSolido, Container } from './index.styled';


export default function CardCla({ icone, background, titulo, descricao, rota, parametros }) {
	const navigate = useNavigate();


  function fundoImagem() {
    if (!icone) return '/assets/images/avatar.jpg';
    return `${API_ADDRESS}/${icone}`
  }


	function irPara() {
		for (const item of parametros) {
			rota = rota.replace(/:[a-z]+/im, item);
		}
		navigate(rota);
  }
  


	return (
		<Container>
			<section>
				<img src={fundoImagem()} alt='' />
				<div style={{ background: background }} />
      </section>
      
			<main>
				<h1> {titulo} </h1>
        <p>{descricao}</p>
				<BotaoSolido background={background} onClick={irPara}>
					Entrar
				</BotaoSolido>
      </main>
		</Container>
  );
  

};

