import styled from "styled-components";


const BoxOpcoesContainer = styled.main`
  width: 150px;
  
  display: ${props => props.open ? 'flex' : 'none'};
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 7px;

  background: #323232;
  border-radius: 4px;

  position: absolute;
  top: 0px;
  right: -170px;

  font-size: 14px;
  font-weight: 500;

  padding: 10px;

  color: white;

  & div .item{
    display: flex;
    justify-content: flex-start;
    transition: .3s;
  }
  
  .item:hover{
    transition: .3s;
    transform: scale(1.05, 1.05);
  }
  
  
  & > div{
    width: 100%;
    border-bottom: 2px solid #4B4B4B;
  }
  

  & > div:last-child{
    border: none;
  }

`;

export {
  BoxOpcoesContainer
}