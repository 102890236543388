import styled from 'styled-components';

const FundoContainer = styled.div`
  width: 100%;
  height: 100vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
 
  background-color: rgba(0, 0, 0, 0.803);
  cursor: pointer;
`;


const BoxCointainer = styled.div`
  width: 100%;
  max-width: 607px;
  height: 339px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;

  position: fixed;

  background: #F5F5F5;
  box-shadow: 0px 0px 30px #00000023;
  
  border-radius: 25px;

  z-index: 5;
  cursor: default;

  .input-modal{
    input::placeholder{
      font-weight: 500;
      font-size: 15px;
    }
  }

  & > div:nth-child(1) {
    text-align: start;
    
    p{
      font-weight: 600;
      color: black;
      text-align: start;
    }
  }

  & > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
  }
  
  div{
    width: 80%;
  }


`;

export {
  FundoContainer,
  BoxCointainer
}