import * as React from 'react';
import styled from 'styled-components';

import { LoadingIcon } from './icon.js';

const StyledLoadingFullscreen = styled.div`
  display: flex;
  flex-direction: row;

  visibility: ${props => props.state ? 'visible' : 'hidden' };

  align-items: center;
  justify-content: center;
  
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 999;

  background-color: #000000A0;

  svg {
    margin: auto;
    display: block;
    shape-rendering: auto;
    width: 6rem;
    height: 6rem;
  }
`;

export default function LoadingFullscreen(props) {
  return (
    <StyledLoadingFullscreen state={props.loading}>
      <LoadingIcon/>
    </StyledLoadingFullscreen>
  )
}