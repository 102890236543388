import styled from "styled-components";


const ContainerUsuario = styled.main`
  width: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;

`;



const IconesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;

  img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

`;



const UserImage = styled.img`
  width: 92px;
  height: 92px;
  border-radius: 100%;
`;



const Titulo = styled.p`
  width: 100%;
  
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 700;
  text-align: center;
  text-overflow: ellipsis;
  
  display: -webkit-box;
  
  overflow: hidden;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  


  


  
`


const Descricao = styled.p`
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 400;
  text-align: center;
`



export { ContainerUsuario, IconesContainer, UserImage, Titulo, Descricao }