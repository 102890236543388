import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { menu } from '../menu';
import './index.scss';


import { TituloAdmin1, TituloAdmin2 } from '../../../../../components/styled/textos/titulosAdmin';
import { buscarImagem } from '../../../../../servicos/buscarImagem';
import { RotinaAluno } from '../../../../../components/rotinaAluno';
import { ComentarioMembro } from '../../../../../components/comentarioMembro';
import { MensagemAudio } from '../../../../../components/mensagemAudio';
import PaginaAdmin from '../../../../../components/paginas/paginaAdmin';
import Select from '../../../../../components/styled/select';
import InputComLabel from '../../../../../components/styled/inputs/comLabel';
import TextAreaComLabel from '../../../../../components/styled/textArea/comLabel';
import CartaoEntrega from '../../../../../components/cartaoEntrega';
import { BotaoRotina } from './index.styled';


import * as acompanhamentoApi from '../../../../../api/admin/acompanhamentoAPI';
import doIt from '../../../../../servicos/doIt';






export default function Index() {
  const [cla, setCla] = useState();
  const [aluno, setAluno] = useState();
  const [canalSelecionado, setCanalSelecionado] = useState();
  const [tituloMensagem, setTituloMensagem] = useState('');
  const [descricaoMensagem, setDescricaoMensagem] = useState('');
  const [carregando, setCarregando] = useState(false);
  const [mensagens, setMensagens] = useState();
  const [situacaoAluno, setSituacaoAluno] = useState();
  const [alunoRotina, setAlunoRotina] = useState(null);
  const [rotinaEditada, setRotinaEditada] = useState(false);

  const { cla: claId, aluno: alunoId } = useParams()
  const navigate = useNavigate();



  useEffect(_ => {
    carregarCla();
    carregarUsuario();
    carregarAlunoTeste();

    //eslint-disable-next-line
  }, []);



  function carregarCla() {
    doIt(async _ => {
      const claEncontrado = await acompanhamentoApi.buscarCla(claId);
      setCla(claEncontrado);
    });
  }


  function carregarUsuario() {
    doIt(async _ => {
      const usuarioEncontrado = await acompanhamentoApi.buscarAluno(claId, alunoId);
      if (!usuarioEncontrado.servidor) {
        toast.warn('Usuário não sincronizado');
      }
      setAluno(usuarioEncontrado);
      setAlunoRotina(usuarioEncontrado.rotina);

      const topicosMensagens = {};

      const { topicos } = usuarioEncontrado?.servidor?.canalAluno;

      topicosMensagens.orientacao = await carregarMensagens(
        usuarioEncontrado?.claId,
        usuarioEncontrado?.alunoId,
        topicos.find(i => i?.identificador === 'orientacao')?.topicoId
      );

      topicosMensagens.feedback = await carregarMensagens(
        usuarioEncontrado?.claId,
        usuarioEncontrado?.alunoId,
        topicos.find(i => i?.identificador === 'feedback')?.topicoId
      );

      topicosMensagens.jornada = await carregarMensagens(
        usuarioEncontrado?.claId,
        usuarioEncontrado?.alunoId,
        topicos.find(i => i?.identificador === 'jornada')?.topicoId
      );

      topicosMensagens.comprovantes = await carregarMensagens(
        usuarioEncontrado?.claId,
        usuarioEncontrado?.alunoId,
        topicos.find(i => i?.identificador === 'comprovantes')?.topicoId
      );

      setMensagens(topicosMensagens);
    }, { suppressError: true });
  }



  function enviarMensagem() {
    doIt(async _ => {
      if (!canalSelecionado) {
        toast.warn('Selecione um canal');
        return;
      }
      setCarregando(true);

      await acompanhamentoApi.enviarMensagemDiscord(
        canalSelecionado,
        tituloMensagem,
        descricaoMensagem
      );
      setCarregando(false);

      setTituloMensagem('');
      setDescricaoMensagem('');
      toast.success('Mensagem enviada com sucesso!');
    });
  }



  async function carregarMensagens(claId, alunoId, canal) {
    try {
      const mensagens = await acompanhamentoApi.buscarAlunoMensagensEmCanal(
        claId,
        alunoId,
        canal
      );
      return mensagens;
    } catch (err) { }
  }



  
  async function handleSalvarRotina() {
    doIt(async _ => {
      setCarregando(true);
			await acompanhamentoApi.alterarDiscordAlunoRotina(claId, alunoId, alunoRotina)
			toast.success('Rotina atualizada!');
		});

    setCarregando(false);
    setRotinaEditada(false);
  }



  function handleSincronizarMensagens() {
    doIt(async _ => {
      const msgs = await acompanhamentoApi.sincronizarMensagens(claId, alunoId);
      if (msgs.mensagensBaixadas === 0) {
        toast.success('Todas as mensagens já foram baixadas!');
      } else {
        carregarUsuario();
        toast.success('Mensagens sincronizadas com sucesso!');
      }
    });
  }



  function carregarAlunoTeste() {
    doIt(async () => {
      let r = await acompanhamentoApi.listarCartoesEntregaAluno(claId, alunoId);
      r = r[0] ?? {};

      const data = moment().diff(r?.ultimoFeedback, "days")

      if (isNaN(data))
        r.ultimoFeedback = `Nunca atualizado.`;

      else if (data === 0)
        r.ultimoFeedback = `Atualizado hoje.`;

      else
        r.ultimoFeedback = `última atualização há ${data} dia(s).`;


      setSituacaoAluno(r);
    });
  }




  return (
		<PaginaAdmin
			menu={menu}
			carregando={situacaoAluno === undefined}
			className='acompanhamento-aluno-admin'>
			<main>
				<section className='inicio-usuario'>
					<img
						src={buscarImagem(aluno?.imagem, '/assets/images/avatar.jpg')}
						alt='Imagem Usuário'
					/>
					<div>
						<TituloAdmin2 onClick={_ => navigate('/admin/acompanhamento/cla')}>
							{cla?.info?.nome}
						</TituloAdmin2>
						<TituloAdmin1>{aluno?.nome}</TituloAdmin1>
					</div>
				</section>

				<section className='cartao-entrega'>
					<p>
						{situacaoAluno !== undefined &&
							situacaoAluno.nome !== undefined &&
							situacaoAluno?.ultimoFeedback}
					</p>
					{situacaoAluno !== undefined && situacaoAluno.nome !== undefined && (
						<CartaoEntrega aluno={situacaoAluno} carregarAlunos={carregarAlunoTeste} />
					)}
				</section>

				<section className='mensagem'>
					<div className='titulo'>
						<h2>Enviar Mensagem</h2>
						<MensagemAudio canal={canalSelecionado} />
					</div>

					<div className='inputs'>
						<Select
							mode='light'
							defaultValue='default'
							placeholder='Canal'
							onChange={e => setCanalSelecionado(e.target.value)}
							disabled={carregando}>
							<option value='default' disabled hidden>
								Selecione
							</option>

							{aluno?.servidor?.canalAluno?.id ? (
								<option
									value={aluno?.servidor?.canalAluno?.id}
									key={aluno?.servidor?.canalAluno?.id}>
									Canal Principal
								</option>
							) : null}

							{aluno?.servidor?.canalAluno?.topicos?.map(item => (
								<option value={item.topicoId} key={item.topicoId}>
									{item?.nome}
								</option>
							))}
						</Select>

						<InputComLabel
							mode='light'
							placeholder='Titulo'
							value={tituloMensagem}
							onChange={e => setTituloMensagem(e.target.value)}
							autoFocus={true}
							onKeyDown={e => e.key === 'Enter' && enviarMensagem()}
							disabled={carregando}
						/>
						<TextAreaComLabel
							mode='light'
							placeholder='Descrição'
							value={descricaoMensagem}
							onChange={e => setDescricaoMensagem(e.target.value)}
							onKeyDown={e => e.key === 'Enter' && e.ctrlKey && enviarMensagem()}
							disabled={carregando}
						/>
					</div>
				</section>

				<section className='rotina'>
					<nav>
						<TituloAdmin2>Rotina</TituloAdmin2>
						<BotaoRotina
							src='/assets/images/icones/gray_check.svg'
							alt='Atualizar rotina'
							title={rotinaEditada ? 'Atualizar rotina' : ''}
							onClick={rotinaEditada && handleSalvarRotina}
							isVisible={rotinaEditada}
						/>
					</nav>

					<RotinaAluno
						rotina={alunoRotina}
						setRotina={setAlunoRotina}
						setEditado={setRotinaEditada}
						carregando={carregando}
					/>
				</section>

				<section className='mensagem-aluno'>
					<nav>
						<img
							src='/assets/images/icones/sincronizar.svg'
							alt='Sincronizar'
							onClick={_ => handleSincronizarMensagens()}
						/>
						<span>
							(Última Atualização 09/11 às 09h11)
							{/*
							 *
							 * //TODO
							 *
							 * formatar data última att
							 *
							 *
							 */}
						</span>
					</nav>

					<div className='principal'>
						<TituloAdmin1>Canal Principal</TituloAdmin1>
						{mensagens?.orientacao?.map((item, index) => (
							<ComentarioMembro
								imagem={item.mensagem?.autor === 'Devmonk' ? 'Devmonk' : aluno.imagem}
								nome={item.mensagem?.autor}
								data={item.mensagem?.criacao}
								conteudo={item.mensagem?.conteudo}
								key={index}
							/>
						))}
					</div>

					<div className='feedback'>
						<TituloAdmin1>Canal Feedback</TituloAdmin1>
						{mensagens?.feedback?.map((item, index) => (
							<ComentarioMembro
								imagem={item.mensagem?.autor === 'Devmonk' ? 'Devmonk' : aluno.imagem}
								nome={item.mensagem?.autor}
								data={item.mensagem?.criacao}
								conteudo={item.mensagem?.conteudo}
								key={index}
							/>
						))}
					</div>

					<div className='jornada'>
						<TituloAdmin1>Diário de Jornada</TituloAdmin1>
						{mensagens?.jornada?.map((item, index) => (
							<ComentarioMembro
								imagem={item.mensagem?.autor === 'Devmonk' ? 'Devmonk' : aluno.imagem}
								nome={item.mensagem?.autor}
								data={item.mensagem?.criacao}
								conteudo={item.mensagem?.conteudo}
								key={index}
							/>
						))}
					</div>

					<div className='comprovantes'>
						<TituloAdmin1>Comprovantes</TituloAdmin1>
						{mensagens?.comprovantes?.map((item, index) => (
							<ComentarioMembro
								imagem={item.mensagem?.autor === 'Devmonk' ? 'Devmonk' : aluno.imagem}
								nome={item.mensagem?.autor}
								data={item.mensagem?.criacao}
								conteudo={item.mensagem?.conteudo}
								key={index}
							/>
						))}
					</div>
				</section>
			</main>
		</PaginaAdmin>
	)
}
