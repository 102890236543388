import './index.scss'
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useNavigate } from "react-router-dom";

import Botao from '../../../components/styled/botoes/redondo/index.styled';





export default function NotFound() {

  const navigate = useNavigate();

  return(
    <main className='pagina-not-found'>
      <div className='monge'>
        <img src='/assets/images/icones/monge-vector.svg' alt='imagem-monge'/>
      </div>
      <div className='textos'>
        <div className='texto-401'>
          <span>404</span>  
          <img className='nuvens' src='/assets/images/icones/nuvens.svg' alt='imagem-nuvens' />
        </div>

        <div className='botoes-texto'>
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>
                <div className="barrinha"> <img src="/assets/images/icones/traco-ponto.svg" alt='imagem-traco-ponto'/> </div>
            </AnimationOnScroll>
          <span>Desculpe, a página que você procura não foi encontrada</span>
          <Botao className="botao" background={"#da7a0c"} padding={".5em 1.8em"} radius={"2em"} onClick={() => navigate('/')}> Voltar para a página inicial </Botao>

        </div>
        
      </div>
    </main>
  )
}