import { getApi } from './base.js';
const api = getApi('admin/conta');




export async function listarClas() {
  let clas = await api().get('/cla');

  return clas.data;
}


export async function listarEspiraisDeCla(claId) {
  let espirais = await api().get(`/cla/${claId}/espiral`);
  return espirais.data;
}



export async function buscarClaPorId(claId) {
  let espiral = await api().get(`/cla/${claId}/info`);
  return espiral.data;
}


export async function buscarEspiralPorId(claId, espiralId) {
  let espiral = await api().get(`/cla/${claId}/espiral/${espiralId}/info`);
  return espiral.data;
}








export async function loginAdmin(email, senha) {
  let admin = await api().post('/login', {
    email: email,
    senha: senha
  });
  
  return admin.data;
}



export async function editarAvatar(imagem) {
  const data = new FormData();
  data.append('imagem', imagem);

  await api().put('/avatar', data, {
    'Content-Type': 'multipart/form-data'
  });
}



export async function obterInformacoesAdministradorLogado() {
  let admin = await api().get('/');

  return admin.data;
}



export async function listarSistemas() {
  let sistemas = await api().get('/sistema');

  return sistemas.data;
}



export async function criarCla(nome, descricao, ativo, fundo, sistemas) {
  let idCriado = await api().post('/cla', {
    nome: nome,
    descricao: descricao,
    ativo: ativo,
    fundo: fundo,
    sistemas: sistemas
  });

  return idCriado.data;
}



export async function editarCla(claId, nome, descricao, ativo, fundo, sistemas) {
  await api().put(`/cla/${claId}`, {
    nome: nome,
    descricao: descricao,
    ativo: ativo,
    fundo: fundo,
    sistemas: sistemas
  });
}



export async function atualizarIconeCla(claId, icone) {
  const data = new FormData();
  data.append('icone', icone);
  
  await api().put(`/cla/${claId}/icone`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}



export async function deletarCla(claId) {
  await api().delete(`/cla/${claId}`);
}



export async function criarEspiral(claId, nome, descricao, ativo) {
  await api().post(`/cla/${claId}/espiral`, {
    nome: nome,
    descricao: descricao,
    ativo: ativo
  });
}



export async function editarEspiral(claId, espiralId, nome, descricao, ativo) {
  await api().put(`/cla/${claId}/espiral/${espiralId}`, {
    nome: nome,
    descricao: descricao,
    ativo: ativo
  });
}



export async function deletarEspiral(claId, espiralId) {
  await api().delete(`/cla/${claId}/espiral/${espiralId}`);
}



export async function atualizarOrdemEspiral(claId, espiralId, ordem) {
  await api().put(`/cla/${claId}/espiral/${espiralId}/ordem/${ordem}`);
}



export async function listarAlunos() {
  let alunos = await api().get('/alunos');

  return alunos.data;
}



export async function pesquisarAlunos(pesquisa) {
  let alunos = await api().get(`/alunos?pesquisa=${pesquisa}`);

  return alunos.data;
}



export async function alterarAtivoAluno(alunoId, ativo) {
  await api().put(`/alunos/${alunoId}`, {
    ativo: ativo
  });
}



export async function atualizarListaClasAluno(alunoId, clas) {
  await api().put(`/alunos/${alunoId}/cla`, {
    clas: clas
  });
}

