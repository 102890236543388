import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import doIt from '../../../../servicos/doIt'
import './index.scss';


import { TituloUsuario1, TituloUsuario2, TituloUsuario3 } from '../../../../components/styled/textos/titulosUsuario';
import NovoComentario from '../../../../components/novoComentario';
import PaginaUsuario from '../../../../components/paginas/paginaUsuario'
import ItemLista from '../../../../components/listasTreinos/usuario/ItemLista';
import DmkPlayer from '../../../../components/dmkPlayer';
import { menu } from '../menu';


import * as api from '../../../../api/usuario/conteudoAPI';
import * as apiConta from '../../../../api/usuario/contaAPI';









export default function Index() {
  const [cla, setCla] = useState();
  const [espiral, setEspiral] = useState();
  const [conteudos, setConteudos] = useState();

  const [comentario, setComentario] = useState('');
  const [videoSelecionado, setVideoSelecionado] = useState();



  const { cla: claId, espiral: espiralId } = useParams();
  const navigate = useNavigate();



  useEffect(() => {
    listagem();
    //eslint-disable-next-line
  }, []);





  async function listagem() {
    doIt(async _ => {
      const claEncontrado = await apiConta.buscarClaPorId(claId);
      if (!claEncontrado) throw new Error('Clã não encontrado');
      setCla(claEncontrado?.info?.nome);

      const espiralEncontrada = await apiConta.buscarEspiralPorId(claId, espiralId);
      if (!espiralEncontrada) throw new Error('Espiral não encontrada');
      setEspiral(espiralEncontrada?.nome);

      await carregarTreinos();
    })
  }



  async function carregarTreinos() {
    doIt(async _ => {
      const conteudosAlunos = await api.listarConteudosPorAluno(claId, espiralId);
      setConteudos(conteudosAlunos);
    })
  }



  async function dadosConteudo(video) {
    if (video.situacao === 'bloqueado') return;

    setVideoSelecionado(false);

    doIt(async _ => {
      const videoParaAssistir = await api.buscarLinkConteudoDeAluno(claId, espiralId, video.id);
      if (!videoParaAssistir) return toast.warn('Nenhum conteúdo foi encontrado');;
      setVideoSelecionado(videoParaAssistir);
    })
  }



  async function marcarFilmeComoAssistido() {
    if (!videoSelecionado || videoSelecionado.situacao === 'aprovado') return;
    doIt(async _ => {
      await api.concluirVideo(claId, espiralId, videoSelecionado.id);
      await carregarTreinos();

      setVideoSelecionado({ ...videoSelecionado, situacao: 'aprovado' });
      toast.success('Vídeo concluído com sucesso!');
    })
  }



  async function enviarComentario(event) {
    if (event.key !== 'Enter') return;
    doIt(async _ => {
      await api.comentarVideo(claId, espiralId, videoSelecionado.id, comentario);
      toast.success('Comentário enviado!');  
    })
  }





  return (
    <PaginaUsuario
      className='conteudo_cla_aluno'
      menu={menu}
      carregando={conteudos === undefined || videoSelecionado === false}
      mediaMaxWidth='1300px'
    >

      <TituloUsuario2 onClick={() => navigate(`/conteudo/cla/${claId}/espiral`)}> {cla} </TituloUsuario2>
      <TituloUsuario1> {espiral} </TituloUsuario1>



      <section>


        <div className='playlist'>
          {
            conteudos?.map(i => (
              <div>
                {
                  i.parte?.trim() ? <TituloUsuario3> {i.parte} </TituloUsuario3> : null
                }

                <div>
                {
                  i.conteudos?.map(j => (
                    <ItemLista
                      largura='100%'
                      altura='60px'
                      color='#C0C0C0'
                      treino='conteudo'
                      onClick={() => dadosConteudo(j)}
                      nome={j.titulo}
                      situacao={j.situacao}
                      mostrarTempo
                      tempo={j.tempo}
                      key={j.id}
                    />
                  ))
                }
                </div>
              </div>
            ))
          }
        </div>

        

        {videoSelecionado &&
          <section className='container-player'>
            <NovoComentario
              className='comentario'
              animate={true}
              value={comentario}
              onKeyUp={enviarComentario}
              onChange={e => setComentario(e.target.value)}
            />

            <DmkPlayer
              className='player'
              url={videoSelecionado.link}
              titulo={videoSelecionado.titulo}
              descricao={videoSelecionado.descricao}
              podeAvancar={videoSelecionado.situacao === 'aprovado'}
              onFimVideo={marcarFilmeComoAssistido}
            />
          </section>
        }


      </section>

    </PaginaUsuario>
  );

}

