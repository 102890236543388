import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import doIt from '../../../../servicos/doIt';
import './index.scss';



import { menu } from '../menu.js';
import { TituloUsuario1 } from '../../../../components/styled/textos/titulosUsuario';
import CardEspiralUsuarioDark from '../../../../components/cardEspiralUsuarioDark';
import PaginaUsuario from '../../../../components/paginas/paginaUsuario';




import * as api from '../../../../api/usuario/treinoPraticoAPI';
import * as apiConta from '../../../../api/usuario/contaAPI';





export default function TreinoPratico_Espiral() {
  const [espirais, setEspirais] = useState();
  const [cla, setCla] = useState();

  const { cla: claId } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    async function carregarEspirais() {
      doIt(async () => {
        const espiraisEncontradas = await api.listarEspirais(claId);
        setEspirais(espiraisEncontradas ?? []);
      });
    }

    async function carregarCla() {
      doIt(async () => {
        const claEncontrado = await apiConta.buscarClaPorId(claId)
        setCla(claEncontrado);
      });
    }

    carregarEspirais();
    carregarCla();
    //eslint-disable-next-line
  }, []);

  


  return (
    <PaginaUsuario className='treino-pratico-espirais' menu={menu} carregando={espirais === undefined}>

      <TituloUsuario1 onClick={() => navigate(`/treinopratico/cla`)}>
        {cla?.info?.nome}
      </TituloUsuario1>

      <div className='espirais'>
        {espirais?.map(({ id, nome, descricao, situacao, codAprovacao }, index) => (
          <CardEspiralUsuarioDark
            espiral={nome}
            classe={situacao}
            info={descricao}
            key={index}
            codAprovacao={codAprovacao}
            rota='/treinopratico/cla/:cla/espiral/:espiral/lista'
            parametros={[claId, id]}
          />
        ))}
      </div>

    </PaginaUsuario>
  )
}
