import React from "react";
import { useNavigate } from 'react-router-dom';

import { Container } from "./index.styled";
import Botao from '../../../../components/styled/botoes/redondo/index.styled' 





export default function Cabecalho() { 
  const navig = useNavigate(); 


  function scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }


  return(
      <Container>
          <div>
              <div className="titulo-devmonk"> DevMonk </div>
          </div>
          <div className="cabecalho">
              <div className="items-menu">
                  <div onClick={() => scrollTo('trilhas')}> Trilhas de Aprendizagem </div>
                  <div onClick={() => scrollTo('melhor')}> Seu Melhor </div>
                  <div onClick={() => scrollTo('metodo')}> Método </div>
                  <div onClick={() => scrollTo('depoimentos')}> Depoimentos </div>
              </div>
              <div onClick={() => navig("/conta/login")}> <Botao className="botao"> Entrar </Botao> </div>
          </div>
      </Container> 
  )
}