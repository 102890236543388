import { getApi } from './base.js';
const api = getApi('conteudo');



export async function listarEspirais(claId) {
	const r = await api().get(`/${claId}/espiral`);
	return r.data;
}



export async function buscarLinkConteudoDeAluno(claId, espiralId, video) {
  const r = await api().get(`/${claId}/espiral/${espiralId}/playlist/${video}`);
  return r.data;
}



export async function listarConteudosPorAluno(claId, espiralId) {
  const r = await api().get(`/${claId}/espiral/${espiralId}/playlist`);
	return r.data;
}



export async function concluirVideo(claId, espiralId, videoId) {
  const r = await api().post(`/${claId}/espiral/${espiralId}/playlist/${videoId}/concluir`);
  return r.data;
}

  

export async function comentarVideo(claId, espiralId, videoId, comentario) {
  const r = await api().post(`/${claId}/espiral/${espiralId}/playlist/${videoId}/comentario`, { comentario });
  return r.data;
}

  