import styled from 'styled-components';



export const Container = styled.div`
  width: fit-content;
  min-height: 265px;
  background: linear-gradient(89.81deg, #4E4E4E 22.47%, #151422 99.84%);

  display: flex;
  flex-flow: row nowrap !important;
  gap: 10px;

  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 20px 17px;
  user-select: none;
  -webkit-user-drag: none;

  >div {
    width: 120px;

    >h3 {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
    }

    >textarea {
      margin: 0;
      outline: none;
      border: none;
      background: #fff;
      width: 120px;
      height: 60px;
      padding: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      margin-top: 5px;
      resize: none;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: #c2c1c1;
        border-radius: 4px;
        cursor: default;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #b9b9b9;
      }
    }
  }
`;
