import styled from "styled-components"

const DEFAULT_WIDTH = 'auto';
const DEFAULT_HEIGHT = '50px';
const DEFAULT_COLOR = '#31332B'
const DEFAULT_BACKGROUND = 'linear-gradient(128deg, rgba(36,36,36,1) 0%, rgba(27,27,27,1) 100%);'



const Container = styled.div `  
  width: ${props => props.largura ?? DEFAULT_WIDTH };
  height: ${props => props.altura ?? DEFAULT_HEIGHT };
  color: ${props => props.color ?? DEFAULT_COLOR};

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${props => props.backgroundColor ?? DEFAULT_BACKGROUND};
  border: 1px solid #3F3F3F;
  border-bottom: none;
  
  padding: 0px 15px;
  cursor: pointer;


  :last-child {
    border-bottom: 1px solid #3F3F3F;
  }


  .nome {
    display: flex;
    justify-content: flex-start;
    align-items:center;
    gap: 15px;
    width: 70%;
    
    abbr{
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img{
      width: 20px;
    }

  }


  >.tempo {
    background: #3E3E3E;
    padding: 5px 9px;

    font-size: 12px;
    color: #C0C0C0;
    font-weight: 600;
    border-radius: 40px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 2px;
    user-select: none;

    >img {
      width: 14px;
      height: 14px;
      -webkit-user-drag: none;
      /* background-color: green; */
    }
  }  
`;


const Quiz = styled.div `
  width: 40%;
  display: flex;
  flex-direction:row-reverse;
  justify-content: space-between;
  align-items:center;
  gap: 10%;


  & > div:nth-child(3) {
    width: 80px;
    display: flex;
    justify-content:center;
    
    font-size: 14px;
    background-color: #3E3E3E;
    color: #C0C0C0;
    padding: 6px;
    border-radius: 20px;
    font-weight: 500;  
    text-transform: uppercase;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items:center;
    gap: 6px;
    color: #757575;
    font-weight: 700;    
  }
`



const Pratico = styled.div`
  width: 40%;
  display: flex;
  flex-direction:row-reverse;
  align-items:center;
  gap: 10%;

  .linguagens img {
    width: 25px;
    height: 25px;
    margin-right: .5em;
  }

  .linguagens {
    display: flex;
    align-items: center;
  }

`;


export {
  Container,
  Quiz,
  Pratico
}