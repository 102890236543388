import React, { useEffect, useState } from 'react';
import doIt from '../../../../../servicos/doIt';
import './index.scss';


import CardCla from '../../../../../components/cardCla';
import PaginaAdmin from '../../../../../components/paginas/paginaAdmin';
import { TituloAdmin1, TituloAdmin2 } from '../../../../../components/styled/textos/titulosAdmin';
import { Carrossel } from '../../../../../components/carrossel';
import { menu } from '../menu';


import * as api from '../../../../../api/admin/contaAPI';





export default function Index() {
	const [clas, setClas] = useState();



  useEffect(() => {
    listagem();
  }, []);



  function listagem() {
    doIt(async _ => {
      const r = await api.listarClas();
      setClas(r);
    });
  }



	return (
		<PaginaAdmin
			className='acompanhamento_cla_admin'
			menu={menu}
			carregando={clas === undefined}>
			<main>
				<TituloAdmin1 style={{ marginBottom: '90px' }}> Clãs </TituloAdmin1>

        <section className='clas'>
          {clas?.length === 0 ?
            <TituloAdmin2> Não há cursos cadastrados no momento. </TituloAdmin2>
            :
            <Carrossel>
              {clas?.map(({ info, estilo, id }, index) => (
                <CardCla
                  titulo={info.nome}
                  descricao={info.descricao}
                  background={estilo.fundo}
                  icone={estilo.icone}
                  rota='/admin/acompanhamento/cla/:cla/espiral'
                  parametros={[id]}
                  key={index}
                />
              ))}
            </Carrossel>
          }
				</section>
			</main>
		</PaginaAdmin>
	);
}
