import styled from 'styled-components';


export const Container = styled.div`
  width: 250px;
  min-width: 240px;
  min-height: 375px;
  background: #2C2A2A;
  transition: .3s;
  position: relative;


  &:hover {
    transform: scale(1.1);
  }


  &>section {
    width: 100%;
    height: 85px;
    position: relative;

    &>div {
      width: 100%;
      height: 100%;
    }

    &>img {
      width: 65px;
      height: 65px;
      border-radius: 100%;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }


  &>main {
    margin: auto;
    margin-top: 45.5px;
    width: 80%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    gap: 25px;

    button {
        margin-top: 20px;
    }
      
    h1 {
      color: #fff;
      font-size: 16px;
      height: 50px;

      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }


    p {
      margin-top: 10px;
      font-family: Poppins-regular;
      font-size: 12px;
      color: #fff;

      height: 50px;

      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    p:hover {
      overflow: auto;
      -webkit-line-clamp: unset;
    }

    p::-webkit-scrollbar {
      width: 6px;
      border-radius: 999px;
    }
    
    p::-webkit-scrollbar-thumb {
      background: #414141; 
      border-radius: 10px;
    }
    
    p::-webkit-scrollbar-thumb:hover {
      background: #555555; 
    }

    
  }



`




export const BotaoSolido = styled.button`
	font-family: ${props => props.fontFamily ?? 'Poppins-Bold'};
	font-size: ${props => props.fontSize ?? '16px'};
	color: ${props => props.color ?? '#fff'};
	background: ${props => props.background ?? '#3A73B9'};
	border-radius: ${props => props.borderRadius ?? '60px'};
	padding: ${props => props.padding ?? '5px 22px'};
	margin: ${props => props.margin ?? 'none'};
	outline: none;
	border: none;
	user-select: none;
	cursor: pointer;
`;
