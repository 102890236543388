import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;  
    padding: 4em 0em;
    background: linear-gradient(180deg, #18161A 0%, #221F25 100%); 
    
    .agrupamento-titulo {
        display: flex;
        flex-direction: column;

        animation: fadeInLeft;
        animation-duration: 1.3s;

        width: 50%;
        
        .animation {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;
            width: 90%;
        }

        .titulo {
          font-family: Mukta-bold;
          font-size: 3em;
          line-height: 1.2em;
          width: 6.5em;

          margin-top: 2em;
        }

        .barrinha {
          width: 20em;
          margin: 1em 0em;
        }

        .estrelinha-01 {
          width: 75%;
        }
    }

    .agrupamento-botao {
        display: flex;
        flex-direction: column;

        animation: fadeInRight;
        animation-duration: 1.3s;

        width: 50%;

        .animation {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 70%;
        }

        .estrelinha-02 {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }

        .estrela {
          width: 85%;
        }

        .estrela-grande {
          display: flex;
          justify-content: flex-end;
          width: 90%;
        }

        .estrela-grande > img {
          height: 4em;
        }

        button {
          font-family: Mukta-bold;
          font-size: 1.2em;
          background: #DA940C;
          border-radius: 2em;
          border: 3px solid #DA940C;
          transition: .3s;
        }

        button:hover {
          transition: .3s;
          background-color: rgba(0, 0, 0, 0);
          border-color: #DA940C;
        }
    }

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 33em;


      .agrupamento-titulo {
          align-items: center;
          width: unset;

          .animation {
            flex-direction: column-reverse;
            align-items: center;
            
            width: 100%;
          }

          .titulo {
            font-size: 1.8em;
            text-align: center;
            margin: 1em 0em;
          }

          .barrinha {
            display: none;
          }

          .estrelinha-01 {
              width: 80%;
          }
      }

      .agrupamento-botao {
          width: unset;

          .animation {
            width: unset;
          }

          .estrela {
            display: none;
          }

          .botao {
            margin: 1em 0em;
          }

          .botao > button {
            font-size: 1em;
          }

          .estrela-grande {
            justify-content: unset;
            width: unset;
          }

          .estrelinha-02 {
            width: 85%;
          }
      }
    }
`

export { Container }