import React, { useEffect, useState } from 'react';
import doIt from '../../../../servicos/doIt';
import './index.scss';


import PaginaAdmin from '../../../../components/paginas/paginaAdmin';
import { TituloAdmin1, TituloAdmin2 } from '../../../../components/styled/textos/titulosAdmin';
import { Carrossel } from '../../../../components/carrossel';
import CardCla from '../../../../components/cardCla/index'
import { menu } from '../menu.js';


import * as api from '../../../../api/admin/contaAPI';





export default function TreinoPratico_Cla() {
  const [clas, setClas] = useState();



  useEffect(() => {
    async function carregarClas() {
      doIt(async () => {
        const r = await api.listarClas();
        setClas(r ?? []);
      });
    }

    carregarClas();
    //eslint-disable-next-line
  }, []);




  return (
    <PaginaAdmin
      className='treinopratico_cla_admin'
      carregando={clas === undefined}
      menu={menu}
    >

      <div className='treinopratico_cla_admin-sub'>
        <TituloAdmin1> Clãs </TituloAdmin1>

        <section className='clas'>
          {clas?.length === 0 ?
            <TituloAdmin2> Não há cursos cadastrados no momento. </TituloAdmin2>
            :
            <Carrossel>
              {clas?.map(({ info, estilo, id }, index) => (
                <CardCla
                  titulo={info.nome}
                  descricao={info.descricao}
                  background={estilo.fundo}
                  icone={estilo.icone}
                  rota=':cla/espiral'
                  parametros={[id]}
                  key={index}
                />
              ))}
            </Carrossel>
          }
        </section>
      </div>

    </PaginaAdmin>

  );
}
