import React, { useState } from 'react'
import moment from 'moment';

import { ContainerUsuario, IconesContainer, UserImage, Titulo, Descricao } from './index.styled';
import { MensagemAudio } from '../../mensagemAudio';


import { API_ADDRESS } from '../../../api/apiConfig';


export default function CartaoUsuario(props) {
  const [diasPassados] = useState(moment().diff(props.ultimoFeedback, "days"));
  const [nucaAtualizada] = useState(isNaN(moment().diff(props.ultimoFeedback, "days")));



  function mostrarMensagem() {
    if(nucaAtualizada)
      return `Nunca atualizado.`
    else if(diasPassados === 0)
      return `Atualizado hoje.`
    else
      return `última atualização há ${diasPassados} dia(s).`
  }



  return (
    <ContainerUsuario>
      <UserImage src={`${API_ADDRESS}/${props.image}`}/>

      <Titulo> { props?.nome } </Titulo>
      <Descricao> {mostrarMensagem()}  </Descricao>
      
      <IconesContainer>
        <MensagemAudio canal={props.canalFeedbackId}/>
      </IconesContainer>

    </ContainerUsuario>
  )
}
