import React from 'react';
import { Container } from './index.styled.js'


import 'animate.css'
import { AnimationOnScroll } from "react-animation-on-scroll";





export default function SeuMelhor(props) {
  return(
    <Container id={props.id}>
        
          <div className='conteudo'>

              <div className='monge'> <img src="/assets/images/icones/monge-vector.svg" alt='imagem-monge'/> </div>

                <div className='agrupamento-textos'>
                    <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>
                        <div className='titulo'> O seu MELHOR!</div>

                        <div className='descricao'> 
                            Nosso método está alinhado com as mais novas teorias da aprendizagem e 
                            conta com uma academia de treinos validados e especializados. 
                        </div>
                    </AnimationOnScroll>
                </div>
             
          </div>

          <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true}>
              <div className='barrinha'> <img src="/assets/images/icones/barrinha-dourada-esquerda.svg" alt='barrinha-dourada'/> </div>
          </AnimationOnScroll>

    </Container>
  )
};