

import { HomeIcone } from "../../../components/menu/comps/icones";


export const menu = {
  podeSelecionar: true,
  items: [
    {
      nome: 'Treino Prático',
      url: '/treinopratico/cla',
      icone: <HomeIcone />,
      sempreSelecionado: true
    }
  ],
  animarURLs: [
    '/treinopratico/cla'
  ]
}
