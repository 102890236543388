import React, { useState } from 'react'
import { API_ADDRESS } from '../../../../api/apiConfig';

import { Container, Quiz, Pratico } from './index.styled';

const storage = `${API_ADDRESS}/storage/linguagem`;



export default function ItemLista(props) {
  const [podeMostrarAcertosErros] = useState(props.tipo === 'quiz' && props.situacao === 'aprovado');



  function calcularTempo(tempo) {
    if (isNaN(tempo)) {
      return '00:00';
    }
    
    const min = Math.floor(tempo / 60);
    const sec = tempo - min * 60;
    return `${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
  }





  return (
    <Container
      largura={props.largura}
      altura={props.altura}
      backgroundColor={props.backgroundColor}
      color={props.color}
      onClick={() => props.onClick()}
    >

      <div className='nome'>
        {props.situacao === 'aprovado' && <img src="/assets/images/icones/green_check.svg" alt="" />}
        {props.situacao === 'bloqueado' && <img src="/assets/images/icones/lock.svg" alt="" />}
        {props.situacao === 'liberado' && <img src="" alt="" />}
        <abbr title={props.nome}> {props.nome} </abbr>
      </div>


      {props.treino === 'quiz' &&
        <Quiz>
          <img src="/assets/images/icones/seta_cinza.svg" alt="" />
          <div> {podeMostrarAcertosErros && <><img src="/assets/images/icones/medalha.svg" alt="" /> <p>{props.qtdAcertos}/{props.qtdPerguntas}</p></>}</div>
          <div>{props.tipo}</div>
        </Quiz>
      }

      {props.treino === 'pratico' &&
        <Pratico>
          <img src="/assets/images/icones/seta_cinza.svg" alt="" />
          <div className='linguagens'>
            {props.linguagens?.map((i, index) =>
              <img key={index + 1} src={`${storage}/${i}`} alt="imagem linguagem" />
            )}
          </div>
        </Pratico>
      }

      {
        props.mostrarTempo === true ? (
          <div className='tempo'>
            <img src='/assets/images/icones/clock.svg' alt=''/>
            <span>{calcularTempo(Number(props.tempo))}</span>
          </div>
        ) : null
      }

      {props.treino === 'conteudo' &&
        <img src="/assets/images/icones/seta_cinza.svg" alt="" />
      }


    </Container>
  )
}
