import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


import SApp, { Box, Aluno, CabecalhoAluno, Informacoes, Overflow } from './index.styled.js'
import LoadingFullscreen from '../../../../components/loading/fullscreen/index.js'
import { Menu as MenuAluno } from '../../../../components/menu/index.js'
import { menu } from '../menu.js'
import { RotinaAluno } from '../../../../components/rotinaAluno/index.js';


import * as api from '../../../../api/usuario/contaAPI.js';
import doIt from '../../../../servicos/doIt.js';
import storage from 'local-storage';
import { API_ADDRESS } from '../../../../api/apiConfig.js';




function App() {
  const [nome, setNome] = useState('');
  const [imagem, setImagem] = useState('');
  const [rotina, setRotina] = useState([]);
  const [alunoId, setAlunoId] = useState('');

  const [novaImagem, setNovaImagem] = useState();

  const [loading, setLoading] = useState(false);
  const [loadingTudo, setLoadingTudo] = useState(true);
  const [carregando, setCarregando] = useState(false);
  const [rotinaEditada, setRotinaEditada] = useState(false);

  const navigate = useNavigate();
  const imgRef = useRef(null);



  useEffect(() => {
		logado();
    
		// eslint-disable-next-line
	}, []);



  useEffect(() => {
    obterTudo();

    // eslint-disable-next-line
  }, []);



  useEffect(_ => {
    if (novaImagem) {
      atualizarAvatarPerfil();
    }

    // eslint-disable-next-line
  }, [novaImagem]);



  function obterTudo() {
    doIt(async () => {
      const {info, id, rotina} = await api.obterInformacoesAlunoLogado();

      setNome(info.nome);
      setImagem(info.imagem);
      setAlunoId(id);
      setRotina(rotina);

      setLoadingTudo(false);
    }, {
      onError: function () {
        setLoadingTudo(false)
      }
    });
  }



  function atualizarAvatarPerfil() {
    doIt(async () => {
      setLoading(true);

      await api.editarAvatar(novaImagem);
      toast('Imagem atualizada com sucesso.');

      obterTudo();
      setLoading(false);
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }



  function logado() {
    if (!storage('dmk-user')) {
      navigate('/conta/login');
      toast('Usuário não está conectado, faça o login.');
    }
  }



  function handleSalvarRotina() {
    doIt(async _ => {
      setCarregando(true);
      await api.atualizarRotinaAluno(rotina);
      toast.success('Rotina atualizada!', {theme:'dark'});
    });
    
    setCarregando(false);
    setRotinaEditada(false);
  }





  return (
		<SApp>
			<MenuAluno className='menu' config={menu} tipo='flutuante' acesso='usuário' />
			<LoadingFullscreen loading={loading} />
			<LoadingFullscreen loading={loadingTudo} />
			{!loadingTudo && (
				<Box>
					<Aluno>
						<CabecalhoAluno>
							<div className='avatar'>
								<img
									src={imagem ? `${API_ADDRESS}/${imagem}` : '/assets/images/avatar.jpg'}
									alt='foto de perfil'
								/>
								<div className='camera' onClick={() => imgRef.current.click()} title={novaImagem?.name}>
									<input
										ref={imgRef}
										type='file'
										onChange={e => {
											setNovaImagem(e.target.files[0]);
										}}
										accept='image/*'
									/>
								</div>
							</div>
							<div>
								<p>Seja bem-vindo(a)</p>
								<h1>{nome}</h1>
							</div>
						</CabecalhoAluno>
						<Informacoes rotinaBotao={rotinaEditada}>
							<nav>
								<h2>Rotina Semanal</h2>
								<img
									src='/assets/images/icones/yellow_check.svg'
									alt='Atualizar rotina'
									title={rotinaEditada ? 'Atualizar rotina' : ''}
									onClick={rotinaEditada && handleSalvarRotina}
								/>
							</nav>
							<Overflow>
								<RotinaAluno
									rotina={rotina}
									setRotina={setRotina}
									aluno={alunoId}
									setEditado={setRotinaEditada}
									carregando={carregando}
								/>
							</Overflow>
						</Informacoes>
					</Aluno>
				</Box>
			)}
		</SApp>
	);
}

export default App;