import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 40px;
  width: 725px;
  margin-bottom: 35px;

  >.aluno {
    width: 65px;
    text-align: center;

    >img {
      border-radius: 100%;
      width: 62px;
      height: 62px;
      overflow: hidden;
      margin-bottom: auto;
      user-select: none;
      -webkit-user-drag: none !important;
    }

    >p {
      font-size: 10px;
      font-weight: 600;
      color: #514B4B;
      user-select: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  >.mensagem {
    border-bottom: 2px #D9D9D9 solid;
    width: 100%;

    >p {
      color: rgb(49, 51, 43);
      font-size: 12px;
      font-weight: 700;
      user-select: none;
    }

    >div {
      height: 55px;
      overflow-y: auto;
      font-size: 16px;
      color: #595656;
      font-weight: 500;
      word-wrap: break-word;
      white-space: normal;
      overflow-wrap: break-word;

      &::-webkit-scrollbar {
				width: 6px;
        height: 6px;
			}

			&::-webkit-scrollbar-thumb {
				background: #b0b0b0;
				border-radius: 4px;
				cursor: default;
			}

			&::-webkit-scrollbar-thumb:hover {
				background: #a0a0a0;
			}
    }
  }
`;
