import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import './index.scss';

import Aguardando from './aguardando';
import Analisando from './analisando';
import Respondendo from './respondendo';
import Resultado from './resultado';
import Correcao from './correcao';
import Finalizado from './finalizado';





export default function TreinoTeorico_QuizController() {
  const [tela, setTela] = useState('aguardando');
  const [pergunta, setPergunta] = useState();
  const [acertou, setAcertou] = useState(true);
  const [dados, setDados] = useState();
  
  
  const { cla, espiral, treino } = useParams();
  
  
  
  return (
    <main className='treino-quiz-controller' style={tela !== 'finalizado' ? {backgroundImage: `url('/assets/images/backgrounds/background_mosteiro.png')`, backgroundColor: '#121212'}
                                                                          : {backgroundImage: `url('/assets/images/backgrounds/background_mongeNaMontanha.png')`, backgroundColor: '#121212'}}>
      
      {tela === 'aguardando' && 
        <Aguardando 
          claId={cla} 
          espiralId={espiral} 
          treinoId={treino} 
          setPergunta={setPergunta} 
          setTela={setTela}
          tela={tela}
      />}
      
      {tela === 'analisando' && 
        <Analisando 
          pergunta={pergunta}
          setTela={setTela}
      /> }
      
      {tela === 'respondendo' && 
        <Respondendo 
          claId={cla} 
          espiralId={espiral} 
          treinoId={treino} 
          pergunta={pergunta} 
          setTela={setTela} 
          setAcertou={setAcertou}
      />}
      
      {tela === 'resultado' && 
        <Resultado 
          claId={cla} 
          espiralId={espiral} 
          treinoId={treino} 
          acertou={acertou} 
          setPergunta={setPergunta}
          pergunta={pergunta} 
          setTela={setTela}
          setDados={setDados}
      />}
      
      
      {tela === 'correcao' && 
        <Correcao
          setPergunta={setPergunta} 
          pergunta={pergunta}
          setTela={setTela}
          setDados={setDados}
        />
      }

      {tela === 'finalizado' && 
        <Finalizado 
          dados={dados}
        />
      }
    </main>
  )
}
