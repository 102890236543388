import * as React from 'react';
import * as api from '../../../../api/usuario/loginAPI.js';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import doIt from '../../../../servicos/doIt.js';




import SApp, { 
  Box,
  Estrelinha
} from './index.styled.js';
import LogoAmarela from '../../../../components/styled/logos/amarelo/index.js';
import Input from '../../../../components/styled/inputs/simples/index.styled.js';
import Button from '../../../../components/styled/botoes/arredondado/index.styled.js';
import LoadingFullscreen from '../../../../components/loading/fullscreen/index.js';





function App() {
  const [um, setUm] = React.useState();
  const [dois, setDois] = React.useState();
  const [tres, setTres] = React.useState();
  const [quatro, setQuatro] = React.useState();

  const [loading, setLoading] = React.useState(false);




  const navigate = useNavigate();

  const { email } = useParams();

  const inputU = React.useRef();
  const inputD = React.useRef();
  const inputT = React.useRef();
  const inputQ = React.useRef();




  async function botaoVerificarCodigo() {
    await doIt(async () => {
      setLoading(true);

      let codigo = `${um}${dois}${tres}${quatro}`;

      await api.verificarCodigoResetValido(email, codigo);

      navigate(`/conta/novaSenha/${email}/codigo/${codigo}`);
      toast('O código foi validado com sucesso.');
    }, {
      onError: function () {
        setLoading(true);
      }
    });
  }




  async function botaoEnviarNovoCodigo() {
    await doIt(async () => {
      setLoading(true);
      await api.enviarCodigoParaResetarSenha(email);
      toast('Novo código enviado com sucesso.');
      setLoading(false);
    }, {
      onError: function () {
        setLoading(false);
      }
    });
  }




  return (
    <SApp>
      <LoadingFullscreen
        loading={loading}
      />
      <Box>
        <LogoAmarela className='logo'/>
        <h1>Resetar senha</h1>
        <label>Informe o código recebido no e-mail</label>
        <div className='numeros'>
          <Input
            maxLength={1}
            value={um}
            ref={inputU}
            placeholder='0'
            onChange={e => {
              setUm(isNaN(Number(e.target.value)) ? um : e.target.value);
              if (e.target.value)
                inputD.current.focus();
            }}
          />
          <Input
            maxLength={1}
            value={dois}
            ref={inputD}
            placeholder='0'
            onChange={e => {
              setDois(isNaN(Number(e.target.value)) ? dois : e.target.value);
              if (e.target.value)
                inputT.current.focus();
            }}
          />
          <Input
            maxLength={1}
            value={tres}
            ref={inputT}
            placeholder='0'
            onChange={e => {
              setTres(isNaN(Number(e.target.value)) ? tres : e.target.value);
              if (e.target.value)
                inputQ.current.focus();
            }}
          />
          <Input
            maxLength={1}
            value={quatro}
            ref={inputQ}
            placeholder='0'
            onChange={e => setQuatro(isNaN(Number(e.target.value)) ? quatro : e.target.value)}
          />
        </div>
        <Button onClick={botaoVerificarCodigo}>Confirmar</Button>
        <Estrelinha src='/assets/images/icones/estrelinha.svg' />
        <div className='outros'>
          <p onClick={botaoEnviarNovoCodigo}>Enviar outro código</p>
        </div>
      </Box>
    </SApp>
  )
}

export default App;