import styled from "styled-components";


const FundoContainer = styled.div`
  width: 100%;
  height: 100vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
 
  background-color: rgba(0, 0, 0, 0.803);
  cursor: pointer;

`;


const BoxContainer = styled.div`
  width: 100%;
  max-width: 957px;
  height: 450px;

  padding: 50px 0px ;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  position: fixed;

  background: #F5F5F5;
  box-shadow: 0px 0px 30px #000000;
  
  border-radius: 25px;

  z-index: 5;
  cursor: default;

  & > div{
    width:80%;
  }

  .analise-desempenho-text {
    display: flex;
    align-items: center;
    gap: 5px;

    font-weight: 700;
    color: black;
    text-decoration: underline;
    cursor: pointer;
    
    a:visited {
      color: inherit;
    }
  }

  .setinha-voltar{
    position: absolute;
    top: 7px;
    left: 15px;

    width: 13px;

    cursor: pointer;
  }

  .item-resumo{
    width: 100%;
    
    display: flex;
    gap: 15px;

    span:last-child {
      display: flex;
      justify-content: center;
      gap: 7px;
    }

    span:first-child{
      width: 30%;
      display: flex;
      justify-content: flex-end;
      
      font-weight: 700;
    
    }
  }

  .item-detalhe{
    width: 100%;
    
    display: flex;
    justify-content: space-around;
    align-items:center;
    font-weight: 700;
    font-size: 13px;

    .linguagens {
      width: 20%;
      display: flex;
      text-overflow: ellipsis;
      overflow: hidden;
      gap: 5px;
    }
    
    div{
      width: 16%;
      
      display: flex;
      
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: pre;
      img{
        cursor: pointer;
      }
    
    } 
  }

  .botao-container{
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    & > div{
      width:90%;
    }
    .item-row{
      span:first-child{
        width: 50%;      
      }
    }
  }
  
`


export {
  FundoContainer,
  BoxContainer
}