import React, { useEffect, useState } from 'react';
import doIt from '../../../servicos/doIt';
import { Link } from 'react-router-dom';

import { FundoContainer, BoxContainer } from './index.styled';
import { TituloAdmin2, TituloAdmin3 } from '../../styled/textos/titulosAdmin';
import { TextContainer } from '../../modalTextContainer/index.styled';
import { ComentarioContainer } from '../../comentarioContainer/index.styled';
import { MensagemAudio } from '../../mensagemAudio';
import CaixaDeComentario from '../../comentarioContainer';
import BotaoRedondo from '../../styled/botoes/redondo/index.styled';


export default function ModalDesempenhoTreinoPratico(props) {
  const [comentariosAbertos, setComentariosAbertos] = useState(false);
  const [conteudoSelecionado, setConteudoSelecionado] = useState(0);
  const [desempenho, setDesempenho] = useState([]);


  
  function abrirComentario(pos) {
    doIt(async () => {
      setComentariosAbertos(true)
      setConteudoSelecionado(pos)
    }, {time: 1});
  }



  useEffect(() => {
    props.carregarInformacoesDesempenho(setDesempenho);
    // eslint-disable-next-line
  },[]);


  
  return (
    <FundoContainer>
      <BoxContainer>
        <div className='titulos'>
          <TituloAdmin3 color='#3973BA'> Análise de desempenho </TituloAdmin3>
          <TituloAdmin2> {props.nome} </TituloAdmin2>
        </div>
        
        
        <div className='analise-desempenho-text'>
          <Link target='_blank' to={`/admin/treinopratico/cla/${props.claId}/espiral/${props.espiralId}/aluno/${props.alunoId}/desempenho`}>Analisar código fonte</Link>
          <img src="/assets/images/icones/mini-grafico.svg" alt="" />
        </div>
        

        
        <TextContainer
          gap='10px'
        >
          {comentariosAbertos === true && 
            <>
              <img className='setinha-voltar' onClick={() => setComentariosAbertos(false)} src="/assets/images/icones/seta_voltar_comentario.svg" alt="" />
              <CaixaDeComentario arr={desempenho[conteudoSelecionado]?.comentarios} />
            </>
          }
          
          {comentariosAbertos === false &&
            <ComentarioContainer padding='0px'>
              {desempenho?.map((item, pos) => {
                let data;
                let mes;
                let horas;
                let minutos;
                if(item.treino.solucoes.length > 0){
                  data = new Date(item.treino.solucoes[0].concluido).toString().substring(8, 10)
                  mes = new Date(item.treino.solucoes[0].concluido).toString().substring(4, 7)
                  horas = new Date(item.treino.solucoes[0].concluido).toString().substring(16, 18);
                  minutos = new Date(item.treino.solucoes[0].concluido).toString().substring(19, 21);
                }
                
                return (
                  <>
                    <div className='item-detalhe'> 
                      <MensagemAudio canal={props.canalFeedbackId}/>
                      <div>{pos+1}. {item.treino.info.titulo}</div>
                      <div className='linguagens'> 
                        {item.treino.solucoes.map(item => <span> {item.linguagem.toUpperCase()} </span> )}
                        {item.treino.solucoes.length === 0 ? '-' : ''}
                      </div>
                      <div>{item.treino.solucoes.length > 0 ? `${data}/${mes} ás ${horas}h${minutos}` : '-'}</div>
                      <div onClick={() => abrirComentario(pos) }><img src="/assets/images/icones/comentario.svg" alt="" /> {item?.comentarios?.length > 10 ? item?.comentarios?.length : `${item?.comentarios?.length === 0 ? '0' : `0${item?.comentarios?.length ?? '0'}`}`} </div>
                    </div>
                  </>
                );
              })}
            </ComentarioContainer>
          }
        </TextContainer>



        <div className='botao-container'>
          <BotaoRedondo onClick={() => props.setModalTreinoPratico(false)} background='#3A73B9'> Fechar </BotaoRedondo>
        </div>
      </BoxContainer>      
    </FundoContainer>
  )
}
