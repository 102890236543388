import { HomeIcone } from "../../../components/menu/comps/icones";


export const menu = {
  podeSelecionar: false,
  items: [
    {
      nome: 'Treino Teórico',
      url: '/admin/treinoteorico/cla',
      icone: <HomeIcone/>
    }
  ],
  animarURLs: [
    '/admin/treinoteorico/cla'
  ]
}


