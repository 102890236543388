import { Container } from "./styled.js";
import storage from 'local-storage'

export function ImageUser(props) {
  
  function sigla() {
    return storage('dmk-user')?.user?.info?.nome[0];
  }

  return (
    <Container mediaMaxWidth={props.mediaMaxWidth}>
      {sigla()}
    </Container>
  )
}


