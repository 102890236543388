import React, { useEffect, useState } from 'react';
import './index.scss';
import doIt from '../../../../servicos/doIt';


import { TituloUsuario1, TituloUsuario2 } from '../../../../components/styled/textos/titulosUsuario';
import PaginaUsuario from '../../../../components/paginas/paginaUsuario';
import CardCla from '../../../../components/cardCla/index';
import { Carrossel } from '../../../../components/carrossel';
import { menu } from '../menu.js';


import * as api from '../../../../api/usuario/contaAPI';





export default function TreinoPratico_Cla() {
  const [clas, setClas] = useState();

  useEffect(() => {
    async function carregarClas() {
      doIt(async () => {
        const r = await api.listarClas();
        setClas(r);

      });
    }

    carregarClas();
    //eslint-disable-next-line
  }, []);




  return (
    <PaginaUsuario
      className='treino-pratico-cla'
      carregando={clas === undefined}
      menu={menu}>
      <div className='treinopratico_cla-sub'>
        <TituloUsuario1> Clãs </TituloUsuario1>

        <section className='clas'>
          {clas?.length === 0 ?
            <TituloUsuario2> Você não está matriculado em nenhum curso no momento. </TituloUsuario2>
            :
            <Carrossel>
              {clas?.map(({ info, estilo, id }, index) => (
                <CardCla
                  titulo={info.nome}
                  descricao={info.descricao}
                  background={estilo.fundo}
                  icone={estilo.icone}
                  rota='/treinopratico/cla/:cla/espiral'
                  parametros={[id]}
                  key={index}
                />
              ))}
            </Carrossel>
          }
        </section>
      </div>

    </PaginaUsuario>
  );
}
