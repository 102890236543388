import * as React from 'react';
import * as api from '../../../../api/usuario/loginAPI.js';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import doIt from '../../../../servicos/doIt.js';





import SApp, { 
  Box,
  Estrelinha
} from './index.styled.js';
import LogoAmarela from '../../../../components/styled/logos/amarelo/index.js';
import Input from '../../../../components/styled/inputs/simples/index.styled.js';
import Button from '../../../../components/styled/botoes/arredondado/index.styled.js';
import LoadingFullscreen from '../../../../components/loading/fullscreen/index.js';





function App() {
  const [codigo, setCodigo] = React.useState('');

  const [loading, setLoading] = React.useState(false);




  const navigate = useNavigate();
  
  const { email } = useParams();




  async function botaoVerificarConta() {
    await doIt(async () => {
      setLoading(true);
      await api.verificarConta(email, codigo);

      navigate(`/conta/login`);
      toast('Usuário verificado com sucesso.');
    }, {
      onError: function () {
        setLoading(false);
      }
    });
  }




  async function botaoEnviarNovoCodigo() {
    await doIt(async () => {
      setLoading(true);

      await api.enviarEmailVerificarConta(email);
      toast('Novo código enviado com sucesso.');

      setLoading(false);
    }, {
      onError: function () {
        setLoading(false);
      }
    });
  }




  return (
    <SApp>
      <LoadingFullscreen
        loading={loading}
      />
      <Box>
        <LogoAmarela className='logo'/>
        <h1>Verificar conta</h1>
        <Input
          placeholder='Código recebido por e-mail'
          type='text'
          value={codigo}
          onChange={e => setCodigo(e.target.value)}
        />
        <Button onClick={botaoVerificarConta}>Continuar</Button>
        <Estrelinha src='/assets/images/icones/estrelinha.svg' />
        <div className='outros'>
          <p onClick={botaoEnviarNovoCodigo}>Enviar outro código</p>
        </div>
      </Box>
    </SApp>
  )
}

export default App;