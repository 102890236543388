import { toast } from "react-toastify";
// import storage from 'local-storage';


export default async function doIt(action, options) {
  options = options || {};
  options.onError = options.onError || function () { };
  options.time = options.time || 700;
  options.suppressError = options.suppressError || false;

  
  try {
    if (options.time !== 0)
      await sleep(options.time);
      
    await action();
  }
  catch (err) {
    // if (err.response?.status === 401) {
    //   if(storage('dmk-user')) {
    //     toast.warning('Você não tem permissão para acessar essa página.');
    //     window.location = '/unauthorized';
    //   } 
    // }

    if (options.suppressError === false) {
      if (err.response)
        toast.error(err.response.data.erro);
      else
        toast.error(err.message);
    }
    
    options.onError(err);
  }  
}


const sleep = (wait=100) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, wait)
  })
}
