import styled from 'styled-components';

export const Lista = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap || '0px'};
  .drag-item {
    cursor: inherit;
  }

   .drag-item:last-child .drop-item {
    border-bottom: ${props => props.theme === 'black' ? 'unset' : '1px solid #ACACAC'};
  }

`;