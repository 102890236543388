import styled from 'styled-components';






const SApp = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  position: relative;

  width: 100%;
  height: 100%;

  min-width: 1200px;
  min-height: 100vh;
  
  background: linear-gradient(270deg, #282828 55.21%, #3A3A3A 100%);
  font-family: 'Poppins';
  
  .renderizar {
    display: flex;
    position: relative;
    
    width: fit-content;
    height: fit-content;
  }

  h2, h3 {
    color: #FFFFFF;
  }

  .warn {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
  }

  
  .menu {
    min-height: 100vh;
    width: 200px;
  }

  
  @media (max-width: 1024px) {
    min-width: unset;
    
    .menu {
      min-height: unset;
      width: 100%;
    }
  }
`;




export const Informacoes = styled.div`
  margin-left: 4rem;
  padding: 4rem 0;
  height: 100%;
  width: 100%;

  @media (max-width: 1024px) { 
    margin-left: 0px;
    padding: 4rem 2rem 6rem;
  }

`;




export const CabecalhoAdmin = styled.div`
  position: relative;
  display: flex; 
  flex-direction: row;
  
  align-items: center;

  margin-bottom: 2rem;

  p {
    color: #8A8A8A;
    font-size: 18px;
    font-weight: 700;
  }

  h1 {
    color: #D3C314;
    font-size: 28px;
  }

  img {
    border-radius: 999px;
    height: 8rem;
    width: 8rem;
    margin-right: 1rem;
  }

  .avatar {
    position: relative;
    width: fit-content;
    height: fit-content;
  }

  .camera {
    position: absolute;
    bottom: .4rem;
    right: 1rem;
    cursor: pointer;
    
    background-image: url('/assets/images/icones/camera.png');
    background-repeat: no-repeat;
    background-position: 50% 45%;
    background-size: 1rem;
    background-color: #4A4A47;
    border: 3px solid #242424;
    border-radius: 999px;

    width: 2rem;
    height: 2rem;

    input[type=file]{
      width: 100%;
      height: 100%;
      opacity: 0;
      
      cursor: pointer;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    width: 100%;

    align-items: flex-start;
  }
`;




export const Box = styled.div`

  position: relative;
  animation: entrada 1s both;

  padding-left: 4rem;

  @media (max-width: 1250px) {
    padding-left: 0
  }

  
  
  @keyframes entrada {
    from { 
      opacity: 0;
      bottom: -50px;
    }
    to {
      opacity: 1;
      bottom: 0px;   
    }
  }
`;




export const GerenciarAluno = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  .campos {
    padding-top: 1rem;
    padding-left: 2rem;

    display: flex;
    flex-direction: row;
    gap: 2rem;

    @media (max-width: 1024px) {
      padding-left: 0;
    }

    @media (max-width: 650px) {
      flex-direction: column;
      width: 100%;
    }
  }
  
  .situacao {
    padding-left: .8rem;
    padding-top: 1rem;
  }

  .clas > div {
    display: flex;
    flex-direction: column;
    gap: .4rem;
  }

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;




export const Overflow = styled.div`
  height: fit-content;
  width: 100%;
  max-height: 16rem;

  overflow-y: auto;
  overflow-x: hidden;

  padding-right: 10px;
  padding-bottom: 4rem;

  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 999px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #414141; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555555; 
  }
`;




export const ListagemAlunos = styled.div`
  margin-top: 5rem;
  max-width: 48rem;
  width: 100%;

  .header { 
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    .header { 
      flex-direction: column; 
      gap: 1rem;
      margin-bottom: 40px;
    }
    
  }
`;




export const AvatarAluno = styled.div`
  img {
    border-radius: 999px;
    height: 4rem;
    width: 4rem;
    margin-right: 1rem;
  }
`;




export const Faixa = styled.div`
  display: flex;
  flex-direction: column;
  gap: .4rem;

  max-width: 12rem;
  width: 100%;

  @media (max-width: 650px) {
    max-width: 100%;
  }
`;




export default SApp;