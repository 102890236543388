import React, { useEffect, useState } from 'react'
import ScrollContainer from "react-indiana-drag-scroll";
import doIt from '../../../../../servicos/doIt';
import './index.scss';


import Botao from '../../../../../components/styled/botoes/redondo/index.styled';
import MenuTreinoTeorico from '../../../../../components/menuTreinoTeorico';


import { API_ADDRESS } from '../../../../../api/apiConfig';
import * as servicosTeoricosUsuario from '../../../../../api/usuario/treinoTeoricoAPI';





export default function Respondendo({ claId, espiralId, treinoId, pergunta, setTela, setAcertou }) {
  const [alternativas] = useState(pergunta.quiz.alternativas);
  const [tempo, setTempo] = useState(0);
  const [selecionada, setSelecionada] = useState();
  const [podeResponder, setPodeResponder] = useState(true);

  
  useEffect(()=>{
    async function tempoEsgotado() {
      doIt(async () => {
        let r = await servicosTeoricosUsuario.gerenciarAvancar(claId, espiralId, treinoId, { 
          acao: 'resposta', 
          conteudo : {
            tempo: tempo,
            comentarios: [],
            idAlternativa: selecionada !== undefined ? alternativas[selecionada].id : null  
          }
        });
        
        setAcertou(r.conteudo.acertou);
        setTela('resultado');
      }, {time: 1})
    }
    if(pergunta.quiz.tempoResposta){
      if(tempo < pergunta.quiz.tempoResposta) {
        setTimeout(()=> setTempo(tempo + 1), 1000);
      } else if(podeResponder) {
        setPodeResponder(false);
        tempoEsgotado();
      }
    }
    checarScroll();
    //eslint-disable-next-line
  },[tempo])
  



  function checarScroll(pos) {
    const x = document.getElementsByClassName("alt")[pos];
    return x?.scrollHeight > x?.clientHeight;
  }



  async function responder() {
    setPodeResponder(false);
    doIt(async () => {
      if(podeResponder){
        if(selecionada === undefined || selecionada === null) throw new Error('Selecione uma alternativa!');
        const r = await servicosTeoricosUsuario.gerenciarAvancar(claId, espiralId, treinoId, { 
          acao: 'resposta', 
          conteudo : {
            tempo: tempo,
            comentarios: [],
            idAlternativa: alternativas[selecionada].id 
          }
        });

        setAcertou(r.conteudo.acertou);
        setTela('resultado');
      }
    }, {onError: () => setPodeResponder(true), time: 1});
  }




  function mostrarFoto(imagem) {
    if(typeof (imagem) === 'string')
      return API_ADDRESS + '/' + imagem;
    else 
      return URL.createObjectURL(imagem);  
  }

 
  return (    
    <main className='tela-respondendo'>
      <MenuTreinoTeorico />
      
      <div className='container'>

        <div className='timer-container'>
          <div className='texto'>Faça sua escolha</div>
            {pergunta.quiz.tempoResposta > 0 && 
              <div className='barra'>
                <div style={{width: ((tempo * 100) / pergunta.quiz.tempoResposta) + '%'}}></div>
              </div>
            }
        </div>
      
        <div className='alternativas'>
          {/* eslint-disable-next-line */}
          {alternativas.map((alternativa, pos) => {
            
            if(pergunta.quiz.tipoAlternativa === 'texto')
              
              return(
                <div className={`alternativa-texto ${selecionada === pos ? 'selected' : 'idle'}`} onClick={() => setSelecionada(pos)}>
                  <div style={checarScroll(pos) === false ? {alignItems: 'center'} : {alignItems: 'flex-start'}} className='alt'>{alternativa.valor}</div>
                </div>
              );
            
            else if(pergunta.quiz.tipoAlternativa === 'imagem')
              
              return(
                <ScrollContainer className={`alternativa-imagem ${selecionada === pos ? 'selected' : ''}`}>
                    <img onClick={() => setSelecionada(pos)} src={mostrarFoto(alternativa.valor)} alt="" />
                </ScrollContainer>
              )
            }

          )}

        </div>

        <div>
          <Botao 
            onClick={responder}
            largura='140px' 
            altura='40px'
            padding='10px 15px'
          >
              Confirmar
          </Botao>
        </div>
      </div>
    </main>
  )
}
