import React, { useEffect } from 'react';
import { useState } from 'react';


import { Bar, Container } from './index.styled';





export const GraficoBarras = ({ info, nome = () => null, valor = () => null }) => {
  const [max, setMax] = useState();

  
  // const info = [
  //   {espiral: 'Ambientação', qtd: 70, valor: 70, nome: 'Ambientação'},
  //   {espiral: 'Ambientação', qtd: 70, valor: 70, nome: 'Ambientação'},
  //   {espiral: 'Ambientação', qtd: 60, valor: 60, nome: 'Ambientação'},
  //   {espiral: 'Ambientação', qtd: 90, valor: 90, nome: 'Ambientação'},
  //   {espiral: 'Ambientação', qtd: 300, valor: 300, nome: 'Ambientação'},
  // ]


  useEffect(() => {
    if (info[0] !== undefined) calcularValores();

    //eslint-disable-next-line
  }, [info]);
  


  function calcularValores() {
    info.forEach(item => {
      item.valor = valor(item);
      item.nome = nome(item);
    });
    
    let maior = info[0].valor;

    for (let i of info) {
      i = valor(i);
      if (i > maior) maior = i;
    }

    setMax(maior);
  }


  function calcularAltura({valor}) {
    return valor / (max / 15.5) * 10 + 'px';
  }





	return (
		<Container>
			{info?.map((item, index) => (
				<div key={index}>
					<Bar height={calcularAltura(item)}>
						<span>{item.valor}</span>
						<div />
					</Bar>
					<span>{item.nome}</span>
				</div>
			))}
		</Container>
	)
};
