import styled from 'styled-components';

const SubTitulo = styled.a`
	font-size: 14px;
	font-family: Poppins-bold;
	color: #fff;
	user-select: none;
	width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export { SubTitulo };
