import React, { useState } from 'react'


import { PainelContainer, Setinha } from './index.styled';





export default function PainelLeitura(props) {
  const [titulo] = useState(props.dir === 'esquerda' ? 'ENTRADAS' 
                                                     : 'SAÍDAS');

  return (
    <PainelContainer {...props}>
      <div> 
        <p>{ titulo }</p>
        <Setinha src={props.src} {...props}/>
      </div>

      <div>
        {props.texto}
      </div>
    </PainelContainer>
  )
}