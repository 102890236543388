import * as React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import storage from 'local-storage';
import { API_ADDRESS } from '../../../../api/apiConfig.js';
import * as api from '../../../../api/usuario/contaAPI.js';
import doIt from '../../../../servicos/doIt.js';


import SApp, {
  Box,
  Aluno,
  CabecalhoAluno,
  Informacoes
} from './index.styled.js';



import InputComLabel from '../../../../components/styled/inputs/comLabel/index.js';
import Select from '../../../../components/styled/select/index.js'
import InputComBotao from '../../../../components/styled/inputs/comBotao/index.js';
import Button from '../../../../components/styled/botoes/arredondado/index.styled.js';
import {  Menu as MenuAluno} from '../../../../components/menu/index.js';
import LoadingFullscreen from '../../../../components/loading/fullscreen/index.js';
import { menu } from '../menu.js';







function App() {
  const [nome, setNome] = React.useState('');
  const [nascimento, setNascimento] = React.useState('');
  const [sexo, setSexo] = React.useState('');
  const [celular, setCelular] = React.useState('');
  const [imagem, setImagem] = React.useState('');

  const [novaImagem, setNovaImagem] = React.useState();

  const [email, setEmail] = React.useState('');
  const [senha, setSenha] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [loadingTudo, setLoadingTudo] = React.useState(true);




  const navigate = useNavigate();
  const imgRef = React.useRef(null);


  

  async function obterTudo() {
    await doIt(async () => {
      const aluno = await api.obterInformacoesAlunoLogado();

      setNome(aluno.info.nome);
      setNascimento(aluno.info.nascimento);
      setSexo(aluno.info.sexo);
      setCelular(aluno.info.celular);
      setEmail(aluno.info.email);
      setImagem(aluno.info.imagem);

      setLoadingTudo(false);
    }, {
      onError: function () {
        setLoadingTudo(false)
      }
    });
  }




  async function atualizarInformacoesPerfil() {
    await doIt(async () => {
      setLoading(true);

      await api.editarInformacoesAlunoLogado(nome, nascimento, sexo, celular);
      toast('Informações atualizadas com sucesso.');

      setLoading(false);

      await obterTudo();
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }

  


  async function atualizarEmailPerfil() {
    await doIt(async () => {
      setLoading(true);

      await api.atualizarEmailAlunoLogado(email);
      toast('E-mail atualizado com sucesso.');

      setLoading(false);

      await obterTudo();
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }

  

  async function atualizarSenhaPerfil() {
    await doIt(async () => {
      setLoading(true);

      await api.atualizarSenhaAlunoLogado(senha);
      toast('Senha atualizada com sucesso.');

      setLoading(false);

      setSenha('');
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }




  function atualizarAvatarPerfil() {
    doIt(async () => {
      setLoading(true);

      await api.editarAvatar(novaImagem);
      toast('Imagem atualizada com sucesso.');

      setLoading(false);

      obterTudo();
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }

  

  async function logado() {
    if (!storage('dmk-user')) {
      navigate('/conta/login');
      toast('Usuário não está conectado, faça o login.');
    }
  }




  React.useEffect(() => {
    logado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  React.useEffect(() => {
    obterTudo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  React.useEffect(() => {
    if (novaImagem) {
      atualizarAvatarPerfil();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novaImagem])


  

  return (
    <SApp>
      <MenuAluno
        className='menu'
        config={menu}
        tipo='flutuante'
        acesso='usuário'
      />
      <LoadingFullscreen
        loading={loading}
      />
      <LoadingFullscreen
        loading={loadingTudo}
      />
      {!loadingTudo && (
        <Box>
          <Aluno>
            <CabecalhoAluno>
              <div className='avatar'>
                <img src={imagem ? `${API_ADDRESS}/${imagem}` : '/assets/images/avatar.jpg'} alt="foto de perfil" />
                <div className='camera' onClick={_ => imgRef.current.click()} title={novaImagem?.name}>
                  <input
                    ref={imgRef}
                    type='file'
                    onChange={e => setNovaImagem(e.target.files[0])}
                    accept='image/*' />
                </div>
              </div>
              <div>
                <p>Seja bem-vindo(a)</p>
                <h1>{nome}</h1>
              </div>
            </CabecalhoAluno>
            <Informacoes>
              <div className='basicas'>
                <h2>Informações pessoais</h2>
                <InputComLabel
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                  placeholder='Nome'
                />
                
                <InputComLabel
                  value={nascimento}
                  type='date'
                  onChange={e => setNascimento(e.target.value)}
                  placeholder='Nascimento'
                />
                <Select placeholder='Sexo' value={sexo} onChange={e => setSexo(e.target.value)}>
                  <option disabled value=''>Selecione um genêro</option>
                  <option value='masculino'>Masculino</option>
                  <option value='feminino'>Feminino</option>
                  <option value='naodizer'>Prefiro não dizer</option>
                </Select>

                <InputComLabel
                  value={celular}
                  onChange={e => setCelular(e.target.value)}
                  placeholder='Celular'
                />

                <div className='botao'>
                  <Button width='fit-content' padding='0 1.4rem' onClick={atualizarInformacoesPerfil}>Salvar</Button>
                </div>
              </div>
              <div className='Login'>
                <h2>Login</h2>
                <InputComBotao
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onClick={atualizarEmailPerfil}
                  placeholder='Email'
                />

                <InputComBotao
                  value={senha}
                  onChange={e => setSenha(e.target.value)}
                  onClick={atualizarSenhaPerfil}
                  placeholder='Senha'
                  type='password'
                />
              </div>
            </Informacoes>
          </Aluno>
        </Box>
      )}
    </SApp>
  )
}

export default App;