export default function AlterarIcon(props) {
  return ( 
    <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_520)">
      <path d="M8.70825 3.16675H3.16659C2.74666 3.16675 2.34393 3.33356 2.047 3.6305C1.75007 3.92743 1.58325 4.33016 1.58325 4.75008V15.8334C1.58325 16.2533 1.75007 16.6561 2.047 16.953C2.34393 17.2499 2.74666 17.4167 3.16659 17.4167H14.2499C14.6698 17.4167 15.0726 17.2499 15.3695 16.953C15.6664 16.6561 15.8333 16.2533 15.8333 15.8334V10.2917" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.6458 1.97918C14.9607 1.66424 15.3879 1.4873 15.8333 1.4873C16.2787 1.4873 16.7058 1.66424 17.0208 1.97918C17.3357 2.29413 17.5126 2.72128 17.5126 3.16668C17.5126 3.61208 17.3357 4.03924 17.0208 4.35418L9.49992 11.875L6.33325 12.6667L7.12492 9.50002L14.6458 1.97918Z" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_1_520">
      <rect width="19" height="19" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}