import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import doIt from '../../../../servicos/doIt';

import CodeEditor from '../../../../components/codeEditor';
import BotaoAnexo from '../../../../components/styled/botaoAnexo';
import PdfViewer from '../../../../components/pdf';
import { Loading } from '../../../../components/paginas/paginaUsuario/loading';

import { StyledRespostaUsuario } from './styled';
import { DarkPage } from '../../../../components/styled/container/darkPage';
import { BoxWithScroll } from '../../../../components/styled/boxWithScroll';



// API
import * as apiConta from '../../../../api/usuario/contaAPI';
import * as apiAcompanhamento from '../../../../api/admin/acompanhamentoAPI';
import { buscarAluno } from '../../../../api/usuario/treinoPraticoAPI';

import { API_ADDRESS } from '../../../../api/apiConfig';
const storage = `${API_ADDRESS}/storage/linguagem`;





export default function TreinoPratico_RespostaUsuario() {
  const [nomeCla, setNomeCla] = useState('');
  const [nomeEspiral, setNomeEspiral] = useState('');

  const [treinos, setTreinos] = useState([]);
  const [treinoSelecionado, setTreinoSelecionado] = useState(0);
  const [linguagemSelecionada, setLinguagemSelecionada] = useState(0);
  const [ infoUsu, setInfoUsu ] = useState({});
  const [ arquivoSelecionado, setArquivoSelecionado ] = useState(0);
  const [ arquivos, setArquivos ] = useState([]);
  const [ trocandoArquivo, setTrocandoArquivo ] = useState(false);

  const [pdf, setPdf] = useState(false);



  const { cla: claId, espiral: espiralId, aluno: alunoId } = useParams();
  const navigate = useNavigate();







  async function listarTreinosAluno() {
    doIt(async _ => {
      const r = await apiAcompanhamento.analisarDesempenhoTreinoPratico(claId, espiralId, alunoId);
      setTreinos(r);
      setArquivos(r[treinoSelecionado].treino.arquivos)
    })
  }




  function atualizarArquivoSelecionado(i) {
    setTrocandoArquivo(true)
    setTimeout(() => setArquivoSelecionado(i), 0) 
   }

  function verSolucao(idxLinguagem) {
    setLinguagemSelecionada(idxLinguagem);
  }

  function alterarTreino(intencao) {

    let valor = treinoSelecionado;

  
    
    if(treinoSelecionado === 0 && intencao === 'anterior')
      return setTreinoSelecionado(treinos.length -1);
    else if(treinoSelecionado === treinos.length -1 && intencao === 'proximo')
      return setTreinoSelecionado(0);

    if(intencao === 'anterior')
      setTreinoSelecionado(valor - 1)
      
    else 
      setTreinoSelecionado(valor + 1)

    setLinguagemSelecionada(0)
  }

  async function infoAluno() {
    const r = await buscarAluno(alunoId);
    setInfoUsu(r.info);
    return r;
  }




  function keyPress(key) {
    let valor = treinoSelecionado;
    key = key.key
    
    if(key !== 'ArrowLeft' && key !==   "ArrowRight") 
      return;

    if(treinoSelecionado === 0 && key === 'ArrowLeft')
      return setTreinoSelecionado(treinos.length -1);
    else if(treinoSelecionado === treinos.length -1 && key === "ArrowRight")
      return setTreinoSelecionado(0);

    if(key === 'ArrowLeft')
      setTreinoSelecionado(valor - 1)
    else if(key === "ArrowRight")
      setTreinoSelecionado(valor + 1)
    

    setLinguagemSelecionada(0);
    setArquivoSelecionado(0);
    
  }




  async function buscarInformacoesCla() {
    doIt(async _ => {
      const cla = await apiConta.buscarClaPorId(claId);
      if (!cla) throw new Error('Clã não encontrado');
      setNomeCla(cla?.info?.nome);

      const espiral = await apiConta.buscarEspiralPorId(claId, espiralId);
      if (!espiral) throw new Error('Espiral não encontrada');
      setNomeEspiral(espiral?.nome);
    })
  }

  
  useEffect(() => {
    listarTreinosAluno();
  }, [treinoSelecionado])

   useEffect(() => {
    setTrocandoArquivo(false)
   }, [arquivoSelecionado])

  

  useEffect(() => {
    buscarInformacoesCla();
    listarTreinosAluno();
    infoAluno();
    // eslint-disable-next-line
  }, [])





  return (
    <DarkPage carregando={treinos.length === 0}>
      <Loading carregando={treinos.length === 0} />

      <StyledRespostaUsuario carregando={treinos.length === 0} onKeyUp={(key) => keyPress(key)} tabIndex="0">
        <div className="cabecalho">
          <div className="informacoes-treino">
            <div className='row-center'>
              <img src="/assets/images/icones/retornar.svg" alt="" className="setas" onClick={() => alterarTreino('anterior')} />
              <img src="/assets/images/logos/dmklogousuarioamarelobold.png" alt="" className="logo" />
              <img src="/assets/images/icones/avançar.svg" alt="" className="setas" onClick={() => alterarTreino('proximo')} />
            </div>
            <div className='nome-usuario'> {infoUsu.nome} </div>
            <div className="descricao-treino">
              <div onClick={() => navigate(`/treinopratico/cla/${claId}/espiral`)}> {nomeCla} </div>
              <div> {'>'} </div>
              <div onClick={() => navigate(`/treinopratico/cla/${claId}/espiral/${espiralId}/lista`)}> {nomeEspiral} </div>
            </div>
          </div>
        </div>


        <div className="interacao-user">
          <div className="informacoes-treino-user">
            
            <div className="row">
              <div className="titulo-treino">
              {treinos[treinoSelecionado]?.treino.info.ordem}. {treinos[treinoSelecionado]?.treino.info.titulo}
              </div>
              
              {treinos[treinoSelecionado]?.treino.info.anexo &&
                <BotaoAnexo
                  texto={pdf === false ? 'ABRIR ANEXO' : 'FECHAR ANEXO'}
                  onClick={() => { pdf === false ? setPdf(true) : setPdf(false) }}
                />
              }
            </div>

            
            {pdf === true
              ?
              <div className="pdf">
                <PdfViewer pdf={`${API_ADDRESS}/${treinos[treinoSelecionado]?.treino.info.anexo}`} />
              </div>
              :
              <BoxWithScroll className="boxScroll">
                {treinos[treinoSelecionado]?.treino.info.descricao}
              </BoxWithScroll>
            }
          </div>


          <div className="solucao-treino">
            <div className='row-between'>
              <div className="titulo-treino"> Solução </div>
              <div className='row wrap'>
                {treinos[treinoSelecionado]?.treino.solucoes?.map((item, i) =>
                  <div
                    className={`icon ${i === linguagemSelecionada ? 'selecionado' : ''}`} key={i + 1}
                    onClick={() => verSolucao(i)}
                  >
                    <img className='image' src={`${storage}/${item.linguagem}`} alt="" />
                  </div>
                )}
              </div>
            </div>
            
            <div className="vscode">
            <div className='row padding-top'>   {arquivos?.map((item, i) => <button onClick={() =>  atualizarArquivoSelecionado(i)} className={`arquivo ${i === arquivoSelecionado ? 'selecionadoB' : ''}`}> {item} </button> ) } </div>
              <CodeEditor
                language={treinos[treinoSelecionado]?.treino.solucoes[linguagemSelecionada]?.linguagem}
                value={treinos[treinoSelecionado]?.treino.solucoes[linguagemSelecionada]?.fonte?.[arquivoSelecionado]?.content}
              />
            </div>
                
          </div>
        </div>
      </ StyledRespostaUsuario>
    </DarkPage>
  )

}