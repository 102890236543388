import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import doIt from '../../../../servicos/doIt';
import './index.scss';

import CardEspiralUsuarioDark from '../../../../components/cardEspiralUsuarioDark';
import PaginaUsuario from '../../../../components/paginas/paginaUsuario';
import { TituloUsuario1 } from '../../../../components/styled/textos/titulosUsuario';
import { menu } from '../menu';

import * as api from '../../../../api/usuario/conteudoAPI';
import * as apiConta from '../../../../api/usuario/contaAPI';





export default function Index() {
  const [espirais, setEspirais] = useState();
	const [cla, setCla] = useState();
	
  const { cla: claId } = useParams();
  const navigate = useNavigate();
  

	useEffect(() => {
    async function listagem() {
      doIt(async () => {
        const claResp = await apiConta.buscarClaPorId(claId);
        setCla(claResp);

        const espiraisResp = await api.listarEspirais(claId);
        setEspirais(espiraisResp);
      })
    }

		listagem();
		//eslint-disable-next-line
  }, []);
  



	return (
		<PaginaUsuario className='conteudo_espiral_usuario' menu={menu} carregando={espirais === undefined}>
			
      <TituloUsuario1 onClick={() => navigate(`/conteudo/cla`)}> {cla?.info?.nome} </TituloUsuario1>


				<section className='espirais'>
					{espirais?.map(({ id, nome, descricao, situacao, codAprovacao }, index) => (
						<CardEspiralUsuarioDark
              espiral={nome}
              classe={situacao}
              info={descricao}
              codAprovacao={codAprovacao}
              key={index}
              rota='/conteudo/cla/:claId/espiral/:espiral/lista'
              parametros={[claId, id]}
						/>
            
					))}
				</section>
		</PaginaUsuario>
	);
}
