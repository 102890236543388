import styled from "styled-components";


const ContainerEntrega = styled.main`
  width: auto;
  max-width: 100%; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 30px;
  margin-top: 80px;
  
  
  @media(max-width: 1024px) { 
    flex-direction: column;
  }
`;


export {
  ContainerEntrega
}