import styled from 'styled-components';

const StyledDropItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: ${props => props.height || '50px'};

  padding: .8rem;

  background: ${props => props.bg || 'linear-gradient(90deg, #262626 0%, rgba(38, 38, 38, 0) 100%)'};
  border: ${props => props.border || 'none'};
  border-bottom: unset;


  .children {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    
  }


  .content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width:90%;
  }



  abbr {
    width:90%;
    color: ${props => props.color || '#000'};
    font-weight: 500;
    font-size: 16px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }

  svg path {
    stroke: ${props => props.strokeColor || '#000'};
  }

  .drag-click-icon path {
    stroke: #535353 !important;
  }
`;

export default StyledDropItem;