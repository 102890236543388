import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "./index.styled";
import 'animate.css';


import { AnimationOnScroll } from "react-animation-on-scroll";
import Botao from '../../../../components/styled/botoes/arredondado/index.styled.js';






export default function ComeceDeGraca(props) {
  const navig = useNavigate();



  
  return(
    <Container id={props.id}>
        
        <div className="agrupamento-titulo">
            <AnimationOnScroll className="animation" animateIn="animate__fadeInLeft" animateOnce={true}>
                <div className="titulo"> Comece agora, crie sua conta! </div>
                <div className="barrinha"> <img src="/assets/images/icones/barrinha-dourada-completa.svg" alt="imagem-barrinha-dourada"/> </div>
                <div className="estrelinha-01"> <img src="/assets/images/icones/estrelinha.svg" alt="imagem-estrelinha"/> </div>
            </AnimationOnScroll>
        </div>
        
        <div className="agrupamento-botao">
            <AnimationOnScroll className="animation" animateIn="animate__fadeInRight" animateOnce={true}>
                <div className="estrelinha-02"> <img src="/assets/images/icones/estrelinha.svg" alt="imagem-estrelinha"/> </div>
                <div className="estrela"> <img src="/assets/images/icones/estrela1.svg" alt="imagem-estrela"/> </div>
                <div className="botao" onClick={() => navig("/conta/login")}> <Botao padding={"1.3em 1.5em"}> Quero Começar! </Botao> </div>
                <div className="estrela-grande"> <img src="/assets/images/icones/estrela-grande.svg" alt='imagem-estrela-grande'/> </div>
            </AnimationOnScroll>
        </div>

    </Container>
  )
}