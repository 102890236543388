import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './index.scss'



export default function confirmar({ titulo, descricao, onSim, onNao }) {
  const options = {
    title: titulo,
    message: descricao,
    buttons: [
      { label: 'Sim', onClick: onSim },
      { label: 'Não', onClick: onNao }
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    overlayClassName: "comp-confirm"
  };
  
  confirmAlert(options);
}


