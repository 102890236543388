import styled from "styled-components";


const Container = styled.div`
  width: 100%;
  height: 100vh;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.803);
  backdrop-filter: blur(10px);
  overflow: auto;

`;

const Image = styled.img`
  width: 80%;

  @media (max-width: 720px){
    width: 100%;
  }

  @media (max-height: 720px){
    width: 90%;
  }

  @media (max-height: 520px){
    width: 80%;
  }
`;

export {
  Container,
  Image
}