import { HomeIcone, MeusCursosIcone, RotinaIcone } from "../../../components/menu/comps/icones";


export const menu = {
  podeSelecionar: true,
  items: [
    {
      nome: 'Minha conta',
      url: '/conta',
      icone: <HomeIcone />
    },
    {
      nome: 'Meus cursos',
      url: '/conta/clas',
      icone: <MeusCursosIcone />
    },
    {
      nome: 'Minha rotina',
      url: '/conta/rotina',
      icone: <RotinaIcone />
    }
  ],
  animarURLs: [
    '/conta',
    '/conta/clas',
    '/conta/rotina'
  ],
  esconderConta: true,
  esconderSair: false
}


