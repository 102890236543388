import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import doIt from "../../../../servicos/doIt";
import './index.scss';
import 'animate.css';


import { TituloAdmin1, TituloAdmin2, TituloAdmin3 } from "../../../../components/styled/textos/titulosAdmin";
import { InputIconeEstatico } from "../../../../components/styled/inputs/comLabelEstatico";
import { menu } from '../menu.js';
import PaginaAdmin from "../../../../components/paginas/paginaAdmin";
import InputComLixeira from "../../../../components/styled/inputs/comLixeira";
import Input from '../../../../components/styled/inputs/comLabel';
import InputComBotao from '../../../../components/styled/inputs/comBotao/index.js';
import TextArea from '../../../../components/styled/textArea/comLabel/index';
import Botao from '../../../../components/styled/botoes/redondo/index.styled';
import confirmar from "../../../../components/confirm";


import * as api from "../../../../api/admin/treinoPraticoAPI";
import * as apiConta from "../../../../api/admin/contaAPI";





export default function TreinoNovo() {
  const [cla, setCla] = useState();
  const [espiral, setEspiral] = useState();


  const [titulo, setTitulo] = useState("");
  const [anexo, setAnexo] = useState({});
  const [descricao, setDescricao] = useState("");
  const [testeEntrada, setTesteEntrada] = useState("");
  const [testeSaida, setTesteSaida] = useState("");
  const [testes, setTestes] = useState([]);
  const [index, setIndex] = useState(-1);
  const [arquivo, setArquivo] = useState("");
  const [arquivos, setArquivos] = useState([]);
  //eslint-disable-next-line
  const [ordem, setOrdem] = useState();


  const navig = useNavigate();
  const { cla: claId, espiral: espiralId, treino: treinoId } = useParams();



  useEffect(() => {
    listagem();
    //eslint-disable-next-line
  }, []);


  

  async function listagem() {
    doIt(async () => {
      const claEncontrado = await apiConta.buscarClaPorId(claId);
      if (!claEncontrado) throw new Error('Clã não encontrado');
      setCla(claEncontrado?.info?.nome);

      const espiralEncontrada = await apiConta.buscarEspiralPorId(claId, espiralId);
      if (!espiralEncontrada) throw new Error('Espiral não encontrada');
      setEspiral(espiralEncontrada?.nome);

      if (treinoId)
        await buscarTreino();
      else 
        setTestes([]);
    })

  }
  




  async function buscarTreino() {
    doIt(async () => {
      const resp = await api.buscarTreinoPorId(claId, espiralId, treinoId);

      setTitulo(resp.pergunta.titulo);
      setDescricao(resp.pergunta.descricao);
      setAnexo(resp.pergunta.anexo);
      setOrdem(resp.pergunta.ordem);
      setTestes(resp.testes);
      setArquivos(resp.arquivos);
    })
  }




  async function salvarTreino() {
    doIt(async () => {
      const jsonTreino = {
        pergunta: {
          titulo: titulo,
          descricao: descricao
        },
        testes: testes,
        arquivos: arquivos
      };

      const treinoId = await api.salvarNovoTreino(claId, espiralId, jsonTreino);

      if (typeof (anexo) === 'object') {
        await api.alterarAnexoTreino(claId, espiralId, treinoId, anexo)
      }

      toast.success("Treino Inserido com sucesso!");
      navig(`/admin/treinopratico/cla/${claId}/espiral/${espiralId}/treino/${treinoId}/alterar`);
    })
  }




  async function alterarTreino() {

    doIt(async () => {
      const treino = await api.buscarTreinoPorId(claId, espiralId, treinoId);
      
      const jsonTreino = {
        pergunta: {
          titulo: titulo,
          descricao: descricao,
          ordem: treino.pergunta.ordem,
          anexo: treino.pergunta.anexo
        },
        testes: testes,
        arquivos: arquivos
      };

      await api.alterarTreinoPratico(claId, espiralId, treinoId, jsonTreino);

      if (typeof (anexo) === 'object') {
        await api.alterarAnexoTreino(claId, espiralId, treinoId, anexo)
      }

      toast.success("Treino alterado com sucesso!");
    });
  }




  async function carregarTestes(teste, i) {
    doIt(async () => {
      setTesteEntrada(teste.entrada);
      setTesteSaida(teste.saida);
      setIndex(i);
    });
  }




  async function adicionarTestes() {
    doIt(async () => {
      if (!testeEntrada || !testeSaida || testeEntrada.trim().length <= 0 || testeSaida.trim().length <= 0)
        return toast.error("Testes vazios não são permitidos!");
      
      testes.push({ entrada: testeEntrada, saida: testeSaida });

      setTesteEntrada("");
      setTesteSaida("");
    });
  }




  async function editarTestes() {
    doIt(async () => {
      if (!testeEntrada || !testeSaida || testeEntrada.trim().length <= 0 || testeSaida.trim().length <= 0)
        return toast.error("Testes vazios não são permitidos!");

      testes[index] = { entrada: testeEntrada, saida: testeSaida };
      setTestes([...testes]);

      setTesteEntrada("");
      setTesteSaida("");
      setIndex(-1);
    });
  }




  async function deletarTestes(teste) {
    confirmar({
      titulo: 'Remover Teste',
      descricao: 'Tem certeza que deseja remover esse Teste?',
      onSim: _ =>
        doIt(async () => {
          let resposta;

          resposta = testes.filter((item) => item !== teste);
          setTestes(resposta);

          setTesteEntrada("");
          setTesteSaida("");
          setIndex(-1);
        })
    })
  }




  async function adicionarArquivos() {
    doIt(async () => {
      if (!arquivo || arquivo.trim().length <= 0)
        return toast.error("Arquivos vazios não são permitidos!");

      if(arquivos.includes(arquivo))
        return toast.error("Arquivos iguais não são permitidos!");

      arquivos.push(arquivo)
      setArquivo("")
    })
  }




  async function deletarArquivos(arquivo) {
      doIt(async () => {
        let resposta;

        resposta = arquivos.filter((item) => item !== arquivo);
        setArquivos(resposta);
      })
  }





  return (
    <PaginaAdmin
      className='treino-pratico-novo-admin'
      carregando={cla === undefined || (treinoId !== undefined && testes === undefined) || (treinoId && !titulo)}
      menu={menu}>

      <div>
        <TituloAdmin2 onClick={() => navig(`/admin/treinopratico/cla/${claId}/espiral`)}>
          {cla}
        </TituloAdmin2>
      </div>

      <TituloAdmin2 onClick={() => navig(`/admin/treinopratico/cla/${claId}/espiral/${espiralId}/treinos`)}>
        {espiral}
      </TituloAdmin2>

      <TituloAdmin1> Treino </TituloAdmin1>


      <div className="treino-pratico-inputs">
        <section className="section">

          <TituloAdmin3> Pergunta </TituloAdmin3>

          <Input
            mode='light'
            placeholder='Título'
            value={titulo}
            onChange={e => setTitulo(e.target.value)}
          />

          <div className="agrupamento-anexo">
            <InputIconeEstatico
              id='anexo'
              type='file'
              accept='.pdf'
              mode='light'
              placeholder='Anexo'
              descricao={anexo?.length > 0 ? 'Armazenado na storage' : !anexo?.name ? 'Selecione' : 'Anexado'}
              icone='/assets/images/icones/upload-select.svg'
              onChange={e => setAnexo(e.target.files[0])}
            />
            <div className="lixeira-anexo" onClick={() => setAnexo({})}> <img src="/assets/images/icones/lixeira.svg" alt="imagem lixeira" /> </div>
          </div>

          <div>
            <InputComBotao
              mode='light'
              placeholder='Arquivos'
              imagem="/assets/images/icones/plus.svg"
              value={arquivo}
              onChange={e => setArquivo(e.target.value)}
              onClick={_ => adicionarArquivos()}
            />
            <div className="arquivos-adicionados">
              {arquivos?.length > 0 ? arquivos.map(item => 
                <Botao 
                  className="botao-arquivo"
                  onClick={() => deletarArquivos(item)}
                  padding="0.2em 0.8em" 
                  color="#FFFF"
                  background="#31332b"
                  fontWeight="400"
                  fontSize="0.9em"
                > 
                  { item } 
                </Botao>
               ) : ""}
            </div>
          </div>
          

          <TextArea
            mode='light'
            placeholder='Descrição'
            height='387px'
            value={descricao}
            onChange={e => setDescricao(e.target.value)}
          />

        </section>

        <section>

          <TituloAdmin3> Novo Testes </TituloAdmin3>

          <div className="agrupamento-teste">

            <TextArea
              mode='light'
              placeholder='Entrada'
              height='100%'
              width='49%'
              value={testeEntrada}
              onChange={(e) => setTesteEntrada(e.target.value)}
            />

            <TextArea
              mode='light'
              placeholder='Saída'
              height='100%'
              width='49%'
              value={testeSaida}
              onChange={(e) => setTesteSaida(e.target.value)}
            />

          </div>


          <div className="botao-adicionar">
            <Botao
              onClick={index >= 0 ? editarTestes : adicionarTestes}
              background={"#3973BA"}
              padding={".5em 1.3em"}
              radius={"2em"} >
              {index >= 0 ? "Alterar" : "Adicionar"}
            </Botao>
          </div>


          {testes?.length > 0 &&
            <div>

              <div className="input-testes">
                <TituloAdmin3> Bateria de Testes </TituloAdmin3>

                <InputComLixeira
                  item={testes}
                  width={"100%"}
                  deletarTestes={deletarTestes}
                  carregarTestes={carregarTestes}
                />

              </div>

              <div className="botao">
                {treinoId &&
                  <Botao onClick={() => {
                    navig(`/admin/treinopratico/cla/${claId}/espiral/${espiralId}/treino/novo`)
                    navig(0);
                  }} background={"#3973BA"} padding={".5em 1.6em"} radius={"2em"}> Novo </Botao>
                }
                <Botao onClick={treinoId ? alterarTreino : salvarTreino} background={"#3973BA"} padding={".5em 1.6em"} radius={"2em"}>
                  	{treinoId ? 'Alterar' : 'Salvar'}
                </Botao>
              </div>

            </div>
          }
        </section>
      </div>

    </PaginaAdmin>
  );
}
