import React, { useState } from 'react'
import doIt from '../../../../../servicos/doIt';
import { toast } from 'react-toastify';
import './index.scss';


import TextoContainer from '../../../comps/TextoContainer';
import Botao from '../../../../../components/styled/botoes/redondo/index.styled';
import StyledInput from '../../../../../components/novoComentario/index.styled';
import MenuTreinoTeorico from '../../../../../components/menuTreinoTeorico';


import * as servicosTeoricosUsuario from '../../../../../api/usuario/treinoTeoricoAPI';
import { useParams } from 'react-router-dom';






export default function ResultadoLeitura({ pergunta, setTempo, setVizualizacoes, setPergunta, setTela, setDados }) {
  const [comentario, setComentario] = useState('');

  const {cla, espiral, treino} = useParams();

  
  async function verificarEnter(e) {
    doIt(async () => {
      if (e.key === 'Enter') {
        await servicosTeoricosUsuario.enviarComentario(cla, espiral, treino, pergunta.info.id, comentario);
        setComentario('');
        toast.dark('Comentário enviado!', {autoClose: 1000});
      }
    }, {time: 1});
  }
  


  async function proximaLeitura() {
    doIt(async () => {
      setTempo(0);
      setVizualizacoes({ painelDireitaViews: 0, painelEsquerdaViews: 0 });
      
      
      const proximaPergunta = await servicosTeoricosUsuario.gerenciarAvancar(cla, espiral, treino, {acao: 'proximo'});
      setPergunta(proximaPergunta.conteudo);
      
      if(proximaPergunta.acao === 'finalizado'){
        setDados({viewsPainelDireita: proximaPergunta.conteudo.viewsPainelDireita, viewsPainelEsquerda: proximaPergunta.conteudo.viewsPainelEsquerda, tipo: 'leitura'});
        setTela('finalizado')
      }else{
        setTela('lendo')
      }
    }, {time: 1});
  }
  

  
  return (
    <main className='tela-resultado-leitura'>
      <MenuTreinoTeorico />
      <div className='container'>

        <div>
          <StyledInput value={comentario} onKeyDown={(e) => verificarEnter(e)} onChange={(e)=>setComentario(e.target.value)} placeholder='Digite seu comentário aqui' tamanho='300px'/>
          
            <TextoContainer
              titulo={'Ótimo!'}
              descricao={'Leia bem o código e só abra a resposta quando tiver certeza.'}
            />
            <Botao onClick={() => proximaLeitura()} largura='120px' altura='40px' padding='10px 15px'> Continuar </Botao>  
        </div>
        
        <div>
          {pergunta.assets.correcao && 
            <>
              <p> Ou você pode </p>
              <Botao onClick={() => setTela('correcao')} background='#373737' color='#B7BB0A' padding='10px 15px'> Ver correção </Botao>
            </>
          }
        </div>
      </div>
    </main>
  )
}
