import Table, {
  Line,
  Th,
  Td,
  Overflow
} from '../index.js';

export default function TabelaGerenciarAlunos(props) {
  return (
    <Overflow style={{maxWidth: '85vw'}}>
      <Table>
        <thead>
          <Line color='#7D7D7D'>
            <Th size='6'>clã</Th>
            <Th size='2'>situação</Th>
            <Th size='2'>clã<span>'s</span></Th>
          </Line>
        </thead>
        <tbody>
          {props.alunos.map((item, index) => (
            <Line
              color='#D0D0D0'
              key={item.id}
              hover={true}
              bg={_ => index % 2 === 0 ? '#232323' : '#2E2E2E'}
              onClick={_ => props.onSelecionarAluno(item)}
            >
              <Td>{item.info.nome}</Td>
              <Td>{item.ativo ? 'Ativo' : 'Inativo'}</Td>
              <Td>{Array.isArray(item.clas) ? item.clas.length : 0}</Td>
            </Line>
          ))}
          </tbody>
      </Table>
    </Overflow>
  )
}