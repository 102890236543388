import styled from 'styled-components';


export const Container = styled.div`
  min-height: 220px;
  width: fit-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  overflow: visible;
  justify-content: space-between;
  gap: 10px;
  user-select: none;
  -webkit-user-drag: none;

  > div {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;

    >span {
      width: 35px;
      height: 35px;
      overflow: visible;
      rotate: -45deg;
      font-family: Poppins-Bold;
      font-size: 10px;
      color: #525252;
      white-space: nowrap;
      direction: rtl;
    }
  }
`;


export const Bar = styled.div`
  color: #7D7676;
  font-size: 10px;
  font-family: Poppins-Bold;
  width: 35px;
  text-align: center;
  margin-bottom: 5px;

  > span {
    color: #7D7676;
    font-size: 10px;
    font-family: Poppins-Bold;
    margin-bottom: 3px;
  }

  >div {
    width: 35px;
    background-color: #474747;
    height: ${p => p.height ?? '0px'};
  }
`;
