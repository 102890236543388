import { MenuItemContainer } from "./styled";


export default function MenuItem({ icone, nome, tipo, action, selecionado, index, open, animar }) {

  return (
    <MenuItemContainer
      onClick={action}
      selecionado={selecionado}
      tipo={tipo}
      index={index}
      open={open}
      animar={animar}
    >
      {icone}
      <div>
        {nome}
      </div>
    </MenuItemContainer>
  )
}