import React, { useEffect } from 'react';
import { useRef } from 'react';
import { Container } from './index.styled';





export function Modal({ ativo, setAtivo, children, override, titulo, descricao, className, scroll }) {
  const ref = useRef();



  useEffect(() => {
    if (scroll) {
      return;
    }

		if (ativo) document.body.style.overflow = 'hidden';
    else {
      setTimeout(() => document.body.style.overflow = 'unset', 50);
    };
	}, [ativo, scroll]);



  const exit = e => {
		if (e.target === ref.current) setAtivo(!ativo);
  };
  

	return (
    <Container
      ref={ref}
      ativo={ativo}
      onClick={e => exit(e)}
      className={className}>
      <main style={{ ...override }}>
        <h1 className='titulo-modal'>{titulo}</h1>
        <h2 className='descricao-modal'>{ descricao }</h2>
        <section>
          {children}
        </section>
      </main>
		</Container>
	);
}
