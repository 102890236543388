import styled from "styled-components"

const StyledMeuCla = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  
  .container {
    display: flex;
    flex-direction: row;
  }

  .container-column {
    display: flex;
    flex-direction: column;
  }
  
  .w100 {
    width: 100%;
  }

  .w50 {
    width: 50%;
  }

  .situacao {
    max-width: 20rem;
    width: 100%;
  }

  .sistemas {
    width: 100%;
  }

  h2 {
    font-weight: 700;
    font-size: 22px;
    color: #D3C314;
    padding: 0;
    margin: 0;
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    color: #7D7D7D;
    text-shadow: 0px 0px 10px #000000;
    padding: 0;
    margin: 0;
  }

  p {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
    padding: 0;
    margin: 0;
  }

  .box {
    gap: 4px;
  }

  .line {
    gap: 4px;

    align-items: center;
  }

  .linha {
    height: 1px;
    width: 90%;
    margin: 20px 0px;

    background-color: #474747;
  }

  @media (max-width: 520px) { 
    .exib {
      flex-direction: column;
      gap: 2rem;
    }
  }
`;

const CardMeuCla = props => {
  return (
    <StyledMeuCla className={"container-column w100 " + props.className}>
      <h2>{props.item.info.nome}</h2>
      <div className="container w100 exib">
        <div className="container-column box situacao">
          <h3>Situação</h3>
          <p>{props.item.info.ativo ? 'Ativo' : 'Não ativo'}</p>
          <p>Desde {new Date(props.desde).toLocaleDateString()}</p>
          <p>Último acesso em {new Date(props.ultimo).toLocaleDateString()}</p>
        </div>
        <div className="container-column box sistemas">
          <h3>Sistemas</h3>
          {props.item.sistemas.map((item) => (
            <div key={item.id} className="container w100 line">
              <p>{item.nome}</p>
              <a href={`${item.acesso}/${props.item.id}/espiral`} target='blank' rel='noopener noreferrer'>
                <img src="/assets/images/icones/external-link.png" alt="external link icon" />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="linha" />
    </StyledMeuCla>
  )
}

export default CardMeuCla