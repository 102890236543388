import styled from "styled-components";




function fundo(props) {
  if (props.tipo === 'lateral') return '#403F3C';
  else return 'transparent';
}




export const Container = styled.div`
  //grid-area: menu;
  display: flex;
  flex-direction: column;

  width: 300px;
  min-width: 300px;
  
  background: ${fundo};

  @media (max-width: ${p => p.mediaMaxWidth ?? '1024px'}) {
    position: absolute;
    width: 100%;
    min-height: 0px;
    top: 0px;
  }


`







function fundoBurger(props) {
  if (props.tipo === 'admin') return 'rgb(49 49 49 / 95%)';
  else return 'rgb(28 28 28 / 85%)';
}



export const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  
  .flutuante {
    position: relative;
    margin-top: 250px;
    left: 80px;
  }
  .lateral {
    position: relative;
    margin-top: 100px;
    margin-bottom: 50px;
    justify-content: space-between;
  }
`


export const FlutuanteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  @media (max-width: ${p => p.mediaMaxWidth ?? '1024px'}) {
    animation: ${p => p.open === undefined ? 'hide 0s forwards' : p.open ? 'show .3s forwards' : 'hide .3s forwards'};

    align-items: center;
    justify-content: flex-start !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    position: fixed !important;
    
    z-index: ${p => p.open ? '10' : '-1'} !important;
    top: 0px !important;
    left: 0px !important;
    
    width: 100vw;
    height: 100vh;
    padding-top: 180px;

    background: ${fundoBurger};
    backdrop-filter: blur(25px);

    & > div {
      align-items: center;
    }
  }

  @keyframes show {
    from { display: none; opacity: 0 }
    to { display: flex; opacity: 1 }
  }

  @keyframes hide {
    from { display: flex; opacity: 1; }
    to { display: none; opacity: 0; }
  }



`


