import { useRef } from 'react';
import Field from './index.styled.js';

function SelectQuadrado({ mode, placeholder, value, onChange, children}) {
  const select = useRef();


  return (
    <Field mode={mode}>
      <select ref={select} value={value} onChange={onChange} placeholder=''>
        {children}
      </select>
      
      <label>{placeholder}</label>
      <img src='/assets/images/icones/expand.svg' alt='icone select'/>
    </Field>
  )
}

export default SelectQuadrado;