import * as React from 'react';
import * as api from '../../../../api/admin/contaAPI.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import storage from 'local-storage';
import doIt from '../../../../servicos/doIt.js';





import SApp, { 
  Box,
  Estrelinha
} from './index.styled.js';
import LogoAmarela from '../../../../components/styled/logos/amarelo/index.js';
import Input from '../../../../components/styled/inputs/simples/index.styled.js';
import Button from '../../../../components/styled/botoes/arredondado/index.styled.js';
import LoadingFullscreen from '../../../../components/loading/fullscreen/index.js';





function App() {
  const [email, setEmail] = React.useState('');
  const [senha, setSenha] = React.useState('');

  const [loading, setLoading] = React.useState(false);




  const navigate = useNavigate();
  const [queryString] = useSearchParams();


  
  React.useEffect(() => {
    const info = storage('dmk-user');

    if(info?.user?.role === 'admin') {
      navigate('/admin/conta/clas');
    } else if (info) {
      navigate('/conta/clas');
    }
    
    //eslint-disable-next-line
  }, [])



  async function botaoEntrar() {
    await doIt(async () => {
      setLoading(true);
      const admin = await api.loginAdmin(email, senha);
      const user = decode(admin.token.split('.')[1]);
      
      storage('dmk-user', {
        token: admin.token,
        user: user
      });

      setTimeout(() => {
        if (queryString.has('redirect'))
          navigate(queryString.get('redirect'));
        else
          navigate('/admin/conta/clas');
      }, 1500);
    }, {
      onError: function () {
        setLoading(false);
      }
    });
  }


  function decode(dados) {
    return JSON.parse(decodeURIComponent(escape(atob(dados))));
  }


  return (
    <SApp>
      <LoadingFullscreen
        loading={loading}
      />
      <Box>
        <LogoAmarela className='logo' />
        <h1>Login Admin</h1>
        <Input
          placeholder='E-mail'
          type='text'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Input
          placeholder='Senha'
          type='password'
          value={senha}
          onChange={e => setSenha(e.target.value)}
        />
        <Button onClick={botaoEntrar}>Entrar</Button>
        <Estrelinha src='/assets/images/icones/estrelinha.svg' />
      </Box>
    </SApp>
  )
}

export default App;