import React, { useEffect, useState } from 'react';
import doIt from '../../../../servicos/doIt'
import './index.scss';


import { TituloUsuario1, TituloUsuario2 } from '../../../../components/styled/textos/titulosUsuario';
import PaginaUsuario from '../../../../components/paginas/paginaUsuario';
import CardCla from '../../../../components/cardCla';
import { Carrossel } from '../../../../components/carrossel';
import { menu } from '../menu';



import * as api from '../../../../api/usuario/contaAPI';







export default function Index() {
  const [clas, setClas] = useState();

  useEffect(() => {
    async function listagem() {
      doIt(async () => {
        const r = await api.listarClas();
        setClas(r);
      })
    }
    listagem();
    //eslint-disable-next-line
  }, []);




  return (
    <PaginaUsuario
      className='conteudo-cla'
      carregando={clas === undefined}
      menu={menu}>
      <div className='conteudo_cla-sub'>
        <TituloUsuario1> Clãs </TituloUsuario1>

        <section className='clas'>
          {clas?.length === 0 ?
            <TituloUsuario2> Você não está matriculado em nenhum curso no momento. </TituloUsuario2>
            :
            <Carrossel>
            {clas?.map(({ info, estilo, id }, index) => (
              <CardCla
                titulo={info.nome}
                descricao={info.descricao}
                background={estilo.fundo}
                icone={estilo.icone}
                rota='/conteudo/cla/:cla/espiral'
                parametros={[id]}
                key={index}
              />
            ))}
          </Carrossel>
          }
        </section>
      </div>

    </PaginaUsuario>
  );
}
