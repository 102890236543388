import * as React from 'react';
import Field from './index.styled.js';

function Checkbox(props) {
  const [updated, setUpdated] = React.useState(false);

  React.useEffect(() => {
    setUpdated(!updated)
    //eslint-disable-next-line
  }, [props.key, props.checked]);
  

  return (
    <Field onClick={_ => setUpdated(!updated)}>
      <label>
        {props.item}
        <input {...props} type='checkbox' />
        <span />
      </label>
    </Field>
  )
}

export default Checkbox;