import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import doIt from '../../../../servicos/doIt';
import './index.scss';


import { ListaOrdenacao } from '../../../../components/listaOrdenacao';
import { TituloAdmin1, TituloAdmin2, TituloAdmin3 } from '../../../../components/styled/textos/titulosAdmin';
import { menu } from '../menu';
import PaginaAdmin from '../../../../components/paginas/paginaAdmin';
import confirmar from '../../../../components/confirm'


import * as api from '../../../../api/admin/treinoPraticoAPI';
import * as apiConta from '../../../../api/admin/contaAPI';




export default function TreinoPratico_ListarTreinos_Admin() {
  const [cla, setCla] = useState();
  const [espiral, setEspiral] = useState();
  const [treinos, setTreinos] = useState();


  const navigate = useNavigate();
  const { cla: claId, espiral: espiralId } = useParams();



  useEffect(() => {
    listagem();
    //eslint-disable-next-line
  }, []);




  async function listagem() {
    doIt(async () => {
      const claEncontrado = await apiConta.buscarClaPorId(claId);
      if (!claEncontrado) throw new Error('Clã não encontrado');
      setCla(claEncontrado?.info?.nome);

      const espiralEncontrada = await apiConta.buscarEspiralPorId(claId, espiralId);
      if (!espiralEncontrada) throw new Error('Espiral não encontrada');
      setEspiral(espiralEncontrada?.nome);

      const treinosEncontrados = await api.listarTreinos(claId, espiralId);
      if (treinosEncontrados.length < 0 || !treinosEncontrados) toast.warn('Nenhum Treino foi encontrado');
      setTreinos(treinosEncontrados);
    })
  }




  async function handleAlterarOrdem(treinoId, ordem) {
    doIt(async () => {
      await api.alterarOrdemTreino(claId, espiralId, treinoId, ordem);
      const treinosEncontrados = await api.listarTreinos(claId, espiralId);
      setTreinos(treinosEncontrados);
      toast.success('Ordem alterada com sucesso!');
    },
    {
      supressError: true
    });
  }




  async function deletar(treinoId) {
    confirmar({
      titulo: 'Remover Treino Prático',
      descricao: 'Tem certeza que deseja remover esse Treino Prático?',
      onSim: _ =>
        doIt(async () => {
          await api.deletarTreino(claId, espiralId, treinoId);
          const treinos = await api.listarTreinos(claId, espiralId)

          setTreinos(treinos);
          toast.warn('Treino removido!');
        })
    })
  }




  return (
    <PaginaAdmin
      className='treino-pratico-lista-admin'
      carregando={treinos === undefined}
      menu={menu}>

      <TituloAdmin2 onClick={() => navigate(`/admin/treinopratico/cla/${claId}/espiral`)}>
        {cla}
      </TituloAdmin2>

      <TituloAdmin1>
        {espiral}
      </TituloAdmin1>


      <section>
        <div className='titulo'>
          <TituloAdmin3>Lista de Treinos</TituloAdmin3>
          <img
            alt=''
            src='/assets/images/icones/plus.svg'
            onClick={() => navigate(`/admin/treinopratico/cla/${claId}/espiral/${espiralId}/treino/novo`)}
          />
        </div>


        <div className='lista'>
          <ListaOrdenacao
            items={treinos}
            descricao={item => item.pergunta.titulo}
            ordem={item => item.pergunta.ordem}
            trocarOrdem={(item, ordem) => item.pergunta.ordem = ordem}
            onOrdemAlterada={(item, novaOrdem) => handleAlterarOrdem(item.id, novaOrdem)}
            onClick={item => navigate(`/admin/treinopratico/cla/${claId}/espiral/${espiralId}/treino/${item.id}/alterar`)}
            onDeletar={item => deletar(item.id)}
          />
        </div>
      </section>

    </PaginaAdmin>
  );
}
