import React, { useState } from 'react'


import { FundoContainer, BoxContainer } from './index.styled';
import { TituloAdmin2, TituloAdmin3 } from '../../styled/textos/titulosAdmin';
import BotaoRedondo from '../../styled/botoes/redondo/index.styled';




export default function ModalDesempenhoApenasDiscord(props) {
  const [anotacoes] = useState(props.identificador === 'anotacoesConceitos' || props.identificador === 'anotacoesLeitura');
  


  return (
    <FundoContainer>
      <BoxContainer tipoAnotacao={anotacoes}>
        <div className='titulos'>
          <TituloAdmin3 color='#3973BA'> Analise desempenho </TituloAdmin3>
          <TituloAdmin2> {props.nome} </TituloAdmin2>
        </div>

        {anotacoes && 
          <div className='content-container'>
            <div className='container-comentario'>
              <div className='comentario'>
                <div className='data'>
                  <span>Enviado em</span>
                  <span> { props.anotacao?.atualizacao?.substring(0,10).replaceAll('-', '/') } às {props.anotacao?.atualizacao?.substring(11,16).replaceAll(':', 'h')}</span>
                </div>
                <div className='text'> <a rel="noreferrer" target="_blank" href={props?.anotacao?.valor ?? ''}> <BotaoRedondo largura='60%' background='#3A73B9'> Acessar anotações </BotaoRedondo> </a> </div>
              </div>
            </div>
          </div>
        }

        {anotacoes === false && 
          <div className='content-container'>
            <div className='container-comentario'>
              {props.mensagensForum?.map(item => {
                return(
                  <div className='comentario'>
                    <div className='data'>
                      <span>{ item.atualizacao?.substring(0,10).replaceAll('-', '/') }</span>
                      <span>{ item.atualizacao?.substring(11,16).replaceAll(':', 'h') }</span>
                    </div>
                    <div className='text'> {item.valor} </div>
                  </div>
                )
              })}
            </div>
          </div>
        }

        

        <div>
          <BotaoRedondo onClick={() => props.setModalDesempenho(false)} background='#3A73B9'> Fechar </BotaoRedondo>
        </div>
      </BoxContainer>
    </FundoContainer>
  )
}
