import React from 'react'

// Hooks
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Style
import { menu } from '../menu.js';
import './index.scss';

// Components
import { TituloUsuario1, TituloUsuario2, TituloUsuario3 } from '../../../../components/styled/textos/titulosUsuario.js';
import ItemLista from '../../../../components/listasTreinos/usuario/ItemLista/index.js';
import PaginaUsuario from '../../../../components/paginas/paginaUsuario';
import doIt from '../../../../servicos/doIt.js';

// Api
import * as api from '../../../../api/usuario/treinoPraticoAPI';
import * as apiConta from '../../../../api/usuario/contaAPI';





export default function TreinoPratico_ListarTreino() {
  const [lista, setLista] = useState();
  const [cla, setCla] = useState('');
  const [espiral, setEspiral] = useState('')



  const { cla: claId, espiral: espiralId } = useParams();
  const navigate = useNavigate();




  useEffect(() => {
    async function carregarTreinos() {
      doIt(async _ => {
        const r = await api.listarTreinos(claId, espiralId);
        setLista(r);
      })
    }

    async function buscarInformacoesCla() {
      doIt(async _ => {
        const claEncontrado = await apiConta.buscarClaPorId(claId);
        if (!claEncontrado) throw new Error('Clã não encontrado');
        setCla(claEncontrado?.info?.nome);

        const espiralEncontrada = await apiConta.buscarEspiralPorId(claId, espiralId);
        if (!espiralEncontrada) throw new Error('Espiral não encontrada');
        setEspiral(espiralEncontrada?.nome);
      })
    }

    carregarTreinos();
    buscarInformacoesCla();
    //eslint-disable-next-line
  }, []);





  return (
    <PaginaUsuario
      className='pagina-treinopratico-lista'
      menu={menu}
      carregando={lista === undefined}
    >

      <TituloUsuario2 onClick={() => navigate(`/treinopratico/cla/${claId}/espiral`)}> {cla} </TituloUsuario2>
      <TituloUsuario1> {espiral} </TituloUsuario1>

      <section>
        <TituloUsuario3> Lista de Treinos </TituloUsuario3>

        {lista?.map((item, index) =>
          <ItemLista
            largura='100%'
            altura='60px'
            color='#C0C0C0'
            treino='pratico'
            onClick={() => item.situacao !== 'bloqueado' && navigate(`/treinopratico/cla/${claId}/espiral/${espiralId}/treino/${item.id}/compiler`)}
            nome={item.titulo}
            linguagens={item.linguagens}
            situacao={item.situacao}
            key={index + 1}
          />
        )}

      </section>

    </PaginaUsuario>
  )
}
