import { getApi } from "./base";
const api = getApi();



export async function listarEspiraisAluno(cla) {
  const r = await api().get(`/treinoteorico/${cla}/espiral`)
  return r.data;
}


export async function listarTreinosPorAluno(claId, espiralId) {
  const r = await api().get(`/treinoteorico/${claId}/espiral/${espiralId}/treinos`);
  return r.data
}


export async function gerenciarAvancar(claId, espiralId, treinoId, info) {
  const r = await api().post(`/treinoteorico/${claId}/espiral/${espiralId.toString()}/treino/${treinoId}/avancar`, info);
  return r.data
}


export async function pegarImagemPergunta(imagem) {
  return `${api().getUri()}${imagem}`
}


export async function enviarComentario(claId, espiralId, treinoId, perguntaId, comentario) {
  const r = await api().post(`/treinoteorico/${claId}/espiral/${espiralId}/treino/${treinoId}/pergunta/${perguntaId}/comentario`, {
    comentario: comentario
  })
  return r.data
}