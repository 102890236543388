import styled from "styled-components";





export const Container = styled.div`
  box-shadow: 0px 3px 10px 0px #545454;
  //border: 1px solid #3F3F3F;
  color: #fff;
  position: relative;


  width: 1026px !important;
  .dmk-react-player {
    width: 1024px !important;
    height: 576px !important;
  }

  
    
  
  @media (max-width: 1500px) {
    width: 962px !important;
      .dmk-react-player {
      width: 960px !important;
      height: 540px !important;
    }
  }

  @media (max-width: 1400px) {
    width: 856px !important;
      .dmk-react-player {
      width: 854px !important;
      height: 480px !important;
    }
  }
  
  @media (max-width: 900px) {
    width: 642px !important;
      .dmk-react-player {
      width: 640px !important;
      height: 360px !important;
    } 
  }

  @media (max-width: 700px) {
    width: calc(100vw - 68px) !important;
      .dmk-react-player {
      width: calc(100vw - 70px) !important;
      height: 360px !important;
    } 
  }
  
`




export const Painel = styled.section`
  padding: 0px 0px;


  section {
    padding: 15px 22px 22px 22px;
    display: flex;
    justify-content: space-between;
    color: #dfdfdf; 
  }

  /* * { outline: 1px solid green; } */

  .slider-tempo {
    left: 5px !important;
    max-width: calc(100% - 10px);
  }

  .descricao {
    padding: 20px 22px 22px 11px;
    justify-content: flex-start;
    border-top: 1px solid #414141;
    gap: 9px;
    
    >div:nth-child(1) {      
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      >span {
        width: fit-content;
        height: fit-content;

        background: #515151;
        color: #d2d2d2;

        font-size: 11px;
        font-weight: 700;
        user-select: none;

        padding: 4px 8px;
        border-radius: 20px;
      }
    }

    >div:nth-child(2) {
      font-size: 14px;

      a {
        color: inherit !important;

        &:link {
          text-decoration: underline;
        }
      }
    }
  }





  .titulo { 
    display: flex; 
    justify-content: flex-start; 
    align-items: center;
    gap: 20px;
    width: 58%;
    user-select: none;
    
    svg {
      height: 52px;
      min-height: 52px;
    }

    div {
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }




  .opcoes { 
    display: flex; 
    justify-content: flex-start; 
    align-items: center;
    gap: 15px;

    svg {
      height: 18px;
      min-height: 18px;
    }

    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
    }

    .tempo {
      width: 50px;
      user-select: none;
    }

    .tempo-icone:hover {
      cursor: default;
    }

    .tempo-icone:hover path {
      stroke: #c0c0c0;
    }

  }



  .velocidade {
    cursor: pointer;
    transition: .3s;
    width: 30px;
    user-select: none;
  }
  .velocidade:hover {
    color: #b7bb0a;
  }






  @media (max-width: 900px) {
    .titulo {
      div {
        width: 70%;
      }
    }
  }
  
  @media (max-width: 700px) {
    section {
      flex-direction: column;
      gap: 10px;
    }
    .titulo {
      width: 100%;
      
      div {
        width: 85%; 
      }
    }
    .opcoes {
      justify-content: flex-end;
    }
  }






  svg {
    cursor: pointer;
  }
  svg path {
    transition: .3s;
  }

  svg:hover path {
    stroke: #B7BB0A;
  }
  svg:hover .path {
    fill: #B7BB0A;
  }



  .slider-volume {
    width: 40px;
  }

  .slider-tempo {
    position: relative;
    left: 0px;
    top: 3px;
  }

  .rc-slider-disabled {
    background-color: unset;
  }

  


`