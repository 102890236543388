import React, { useState } from "react";
import { Container } from "./index.styled";


import BoxCurso from '../../comps/boxCurso';
import { Carrossel } from '../../../../components/carrossel' 


import { AbastecerCursos } from "../../JsonResponse";
import 'animate.css'
import { AnimationOnScroll } from "react-animation-on-scroll";





export default function TrilhasDeAprendizagem(props) {
  const [cursos] = useState(AbastecerCursos())
  


  
  return(
    <Container id={props.id}>
      
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>
            <div className="estrela"> <img src="/assets/images/icones/estrela1.svg" alt="estrela"/> </div>
        </AnimationOnScroll>

        <div className="agrupamento-conteudo-trilhas">
            <div className="titulo-trilhas"> Trilhas de Aprendizagem </div>
            <div className="descricao-trilhas"> 
                Nossos cursos são planejados para cobrirem o gap atual existente no ensino de programação. 
          No abismo que existe entre os primeiros algoritmos ao conhecimento de um novo framework de mercado.
          O aluno precisa dominar a base presente no tripé (Fundamento x Linguagem x Lógica) para não se tornar um seguidor maquinal de códigos.
            </div>
            <div className="agrupamento-trilhas">
              <Carrossel manterCarrossel={true}>
                {cursos.map((curso, index) => (
                    <BoxCurso key={index + 1} titulo={curso.titulo} descricao={curso.descricao}/>
                  ))
                }
              </Carrossel>
            </div>
        </div>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>
            <div className="barrinha"> <img src="/assets/images/icones/barrinha-dourada.svg" alt="barrinha-dourada"/> </div>
        </AnimationOnScroll>
        
    </Container>
  )
}