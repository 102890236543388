import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import doIt from '../../../../../servicos/doIt';
import './index.scss';
import ReactPlayer from 'react-player/youtube'


import TextoContainer from '../../../comps/TextoContainer';
import Botao from '../../../../../components/styled/botoes/redondo/index.styled';
import MenuTreinoTeorico from '../../../../../components/menuTreinoTeorico';
import { LoadingIcon } from '../../../../../components/loading/fullscreen/icon';


import * as servicosTeoricosUsuario from '../../../../../api/usuario/treinoTeoricoAPI';





export default function Correcao({ setPergunta, pergunta, setTela, setDados }) {
  const [delayAntesDoVideo, setDelayAntesDoVideo] = useState(30);
  
  const { cla, espiral, treino } = useParams();

  
  useEffect(() => {
    if (delayAntesDoVideo > 0) {
      setDelayAntesDoVideo(delayAntesDoVideo - 1);
    
    }
    //eslint-disable-next-line
  },[delayAntesDoVideo]);




  async function proximaPergunta() {
    doIt(async () => {
      const r = await servicosTeoricosUsuario.gerenciarAvancar(cla, espiral, treino, {acao: 'proximo'});
      setPergunta(r.conteudo);
      
      if(r.acao === 'proximo')
        setTela('analisando');
      else
        setTela('finalizado');
        setDados({acertos: r.conteudo.acertos, perguntas: r.conteudo.perguntas, passou: r.conteudo.passou, tipo: 'quiz'});
    }, {time: 1})
  }



  return (
    <main className='tela-correcao'>
      <MenuTreinoTeorico />
      
      <div className='container'>
        <div className='content'>
          <div className='descricao'>
            <TextoContainer 
              titulo={pergunta.info.titulo}
              descricao={pergunta.info.descricao}
              altura='150px'
              alinhamento={'left'}
            />

            <Botao onClick={() => proximaPergunta()} background='#373737' color='#B7BB0A' padding='10px 15px'> Continuar </Botao>

          </div>

          <div className='video-container'>
            {delayAntesDoVideo <= 0 && <ReactPlayer width={'100%'} height={'100%'}  playing={false} loop={false} muted={false} controls={true} url={pergunta.assets.correcao} /> }
            {delayAntesDoVideo > 0 && <LoadingIcon /> }
          </div>
        </div>
        </div>
    </main>
)
}
