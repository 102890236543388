import styled from 'styled-components';



const ComentarioContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  gap: 7px;
  font-size: 16px;

  padding: ${props => props.padding ?? '15px'};
  
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 999px;
  }
                    
  &::-webkit-scrollbar-thumb {
      background: #B0B0B0; 
      border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
      background: #555555; 
  }

  .comentario{
    width: 100%;
    display: flex;
    gap: 15px;
    color: black;

    .data{
      width: 100px;
      
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column; 
      
      color: black;
      white-space: pre;
      font-weight: 700;
    }

    .text {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0px 5px;
    }
  }
`;


export {
  ComentarioContainer
}