import React, { useRef } from 'react';
import {Container} from './index.styled.js';


export function Carrossel({ manterCarrossel, children, override }) {
	const ref = useRef();

	return (
		<Container manterCarrossel={manterCarrossel} className='comp-carrossel-espiral' style={override}>
			<img
				src='/assets/images/icones/seta-direita.svg'
				style={{ rotate: '180deg' }}
				alt=''
				onClick={() => {
					ref.current.scrollLeft -= ref.current.offsetWidth;
				}}
			/>
			<div ref={ref}>{children}</div>
			<img
				src='/assets/images/icones/seta-direita.svg'
				alt=''
				onClick={() => {
					ref.current.scrollLeft += ref.current.offsetWidth;
				}}
			/>
		</Container>
	);
}
