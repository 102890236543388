import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import doIt from '../../../../servicos/doIt';
import './index.scss';


import { Carrossel } from '../../../../components/carrossel';
import { TituloAdmin1, TituloAdmin3 } from '../../../../components/styled/textos/titulosAdmin';
import CardEspiral from '../../../../components/cardEspiral';
import PaginaAdmin from '../../../../components/paginas/paginaAdmin';
import { menu } from '../menu.js'


import * as api from '../../../../api/admin/contaAPI';





export default function Index() {
	const [espirais, setEspirais] = useState();
	const [cla, setCla] = useState();


	const { cla: claId } = useParams();
  const navigate = useNavigate();



	useEffect(() => {
		async function listagem() {
      doIt(async () => {
				const claEncontrado = await api.buscarClaPorId(claId);
				setCla(claEncontrado?.info?.nome);

				const espiraisEncontradas = await api.listarEspiraisDeCla(claId);
				setEspirais(espiraisEncontradas);
			});
    }

		listagem();
    //eslint-disable-next-line
	}, []);




  
	return (
    <PaginaAdmin className='treino_pratico_espiral_admin' carregando={espirais === undefined} menu={menu}>

        <TituloAdmin1 onClick={() => navigate(`/admin/treinopratico/cla`)}>
          {cla}
        </TituloAdmin1>
          
        <TituloAdmin3>
          Espirais
        </TituloAdmin3>

        <section className="espirais">
          <Carrossel>
            {espirais?.map(({ nome, descricao, id }, index) => (
              <CardEspiral
                titulo={nome}
                descricao={descricao}
                rota='/admin/treinopratico/cla/:cla/espiral/:espiral/treinos'
                parametros={[claId, id]}
                botaoFundo='#3973BA'
                key={index}
              />
            ))}
          </Carrossel>
        </section>
        
    </PaginaAdmin>
	);
}
