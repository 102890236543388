import styled from 'styled-components';

export const Container = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 3px solid #23C6C6;
  user-select: none;
  -webkit-user-drag: none;
  cursor: pointer;
  overflow: hidden;
`;
