import styled from 'styled-components'

const StyledCompiler = styled.div`
  overflow: hidden;
  position: relative;

  display: ${props => props.carregando ? 'none' : 'block'};
  animation: ${props => props.carregando ? 'unset' : 'entrada 1s both'};


  @keyframes entrada {
    from { 
      opacity: 0;
      bottom: -50px;
    }
    to {
      opacity: 1;
      bottom: 0px; 
    }
  }

  @keyframes Shake {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }
        
        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
        }
        
        40%, 60% {
            transform: translate3d(4px, 0, 0);
        }
    }


  .shake {
        & > * {
            animation: Shake .6s;
        }
    }

  
  .cabecalho {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 2.4em;
    padding-bottom: 2.4em;
  }

  .comentario {
    position: absolute;
    left: 2em;
  }

  .logo {
    height: 100px;
    width: auto;
  }

  .informacoes-treino {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  .interacao-user {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 4em;
    height: calc(100vh - 210px);

  }


  .informacoes-treino-user {
    display: flex;
    flex-direction: column;
    width: 45%;
    
  }

  .solucao-treino {
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  
  .descricao-treino {
    display: flex;
    flex-direction: row;

    font-family: Poppins;
    font-size: .9em;
    font-weight: 700;
    color: #696969;

    div { 
      margin: .2em;
    }

    div:nth-child(odd) {
      cursor: pointer;
    }
  }
  

  .row {
    display: flex;
    flex-direction: row; 
  }

  

  .titulo-treino {
    font-family: Poppins;
    font-size: 1.2em;
    font-weight: 800;
    color: #FFFFFF;
    margin-bottom: 1em;
    margin-right: 1em;
    max-width: 70%;
    text-align: left;
    
  }

  .select {
    margin-bottom: 1em;
  }

  .desc-box-info {
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px;
    color: #C0C0C0;
    padding: 1em;
  }

  .vscode {
    height: calc(100% - 2.4em); 
    background-color: #242424 !important;
  }


  



  .vscode-low {
    height: 99%;
    background-color: #242424 !important;
    
  }

  .pdf {
    flex-grow: 1;
    overflow: auto;
  }


  .boxScroll {
    flex-grow: 1;
    padding: 0em;
    overflow: auto;
    padding: 1em;
    white-space: pre-line;
  }



  .boxScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #383838 !important;
  }

  .boxScroll::-webkit-scrollbar-track {
    background-color: #202020;
  }

  .arquivo {
    border-radius: 28px;
    color: #C0C0C0;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    padding: 0em 1em;
    background-color: #4B4B4B;
    border: none;
    margin: 1em .5em;
    cursor: pointer;
    outline: none;
  }

  .relative {
    flex-grow:  1;
    position: relative !important;
    border: 2px solid #3F3F3F;
    border-bottom: none;
    box-shadow: 0px 0px 18px 1px rgba(160, 160, 160, 0.1);
    background-color: #242424 !important;
    padding-top: ${props => !props.sizeVsCode ? '2.4em' : '0em' }; 
  }

  .padding-top {
    position: absolute;
    height: 2.4em;
    z-index: 1;
    top: 0em;
  }

  .selecionado {
    background-color: #867D4A !important;
  }

 




  @media(max-width: 1024px) {

  .comentario {
    position: absolute;
    left: 0em;
    top: 13em;
  }

  .interacao-user {
    display: flex;
    flex-direction: column;
    margin-top: 4em;
    height: auto;
    align-items: center;
  }

  .informacoes-treino-user {
    width: 90%;
    margin: 1em;
    margin-top: 2em;
  }

  .boxScroll {
    height: 20em;
  }

  .solucao-treino {
    width: 90%;
    margin: 1em;
  }

  .pdf {
    height: 80vh;
  }

  .relative  {
    height: 20em;
  }
  

  

  } 





`


export { StyledCompiler }
