import React, { useState } from 'react';
import { Container } from './index.styled.js';


import Cabecalho from './comps/cabecalho';
import Inicio  from "./sections/inicio";
import TrilhasDeAprendizagem from './sections/trilhasDeAprendizagem';
import SeuMelhor from './sections/seuMelhor';
import Metodo from './sections/metodo';
import Depoimentos from './sections/depoimentos';
import ComeceDeGraca from './sections/comeceDeGraca';
import Rodape from './sections/rodape/index.js';





export default function LandingPage() {
  const [classe, setClasse] = useState('hidden');



  const scrollTop = () =>{
      window.scrollTo({top: 0, behavior: 'smooth'});
  };




  window.onscroll = function() {scrollFunction()};




  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        setClasse('back-to-top');
    } else {
        setClasse('hidden');
    }
  }



  
  return(
    <Container>

        <Cabecalho />
        <Inicio id=""/>
        <TrilhasDeAprendizagem id="trilhas"/>
        <SeuMelhor id="melhor"/>
        <Metodo id="metodo"/>
        <Depoimentos id="depoimentos"/>
        <ComeceDeGraca />
        <Rodape/>

        <div onClick={() => scrollTop()}> <button className={classe} id="button"/> </div>
    </Container>
  )
}