import React from "react";
import { Container } from "./index.styled";





export default function Rodape() {
  const anoAtual = new Date().getFullYear();


  

   return(
      <Container>
          Devmonk {anoAtual} - Todos os direitos reservados
      </Container>
   )
}