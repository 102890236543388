import { getApi } from './base';
const api = getApi();




export async function buscarCla(claId) {
	const r = await api().get(`/admin/acompanhamento/${claId}`);
	return r.data;
}


export async function buscarEspiral(claId, espiralId) {
	const r = await api().get(
		`/admin/acompanhamento/${claId}/espiral/${espiralId}/detalhes`
	);
	return r.data;
}


export async function listarEspirais(claId) {
	const r = await api().get(`/admin/acompanhamento/${claId}/espiral`);
	return r.data;
}


export async function listarEspiraisDiscord(claId) {
	const r = await api().get(`/admin/acompanhamento/${claId}/espiral`);
	return r.data;
}


export async function listarQuantidadeAlunosPorEspiral(claId) {
	const r = await api().get(`/admin/acompanhamento/${claId}/alunosPorEspiral`);
	return r.data;
}


export async function listarMembrosEmCla(claId) {
	const r = await api().get(`/admin/acompanhamento/${claId}/membro`);
	return r.data;
}


export async function listarMembrosEmEspiral(claId, espiralId) {
	const r = await api().get(`/admin/acompanhamento/${claId}/espiral/${espiralId}/membro`);
	return r.data;
}


export async function sincronizarEspiral(
	claId,
	espiralId,
	qtdTopicoLeitura,
	qtdTopicoForum
) {
	const r = await api().put(
		`/admin/acompanhamento/${claId}/espiral/${espiralId}/sincronizar`,
		{
			qtdTopicoLeitura: Number(qtdTopicoLeitura),
			qtdTopicoForum: Number(qtdTopicoForum),
		}
	);
	return r.status;
}


export async function sincronizarServidor(
	claId,
	servidorId,
	boasVindasId,
	personalDevId,
	espiraisId,
	ajudaId,
	grupoEstudoId
) {
	const r = await api().put(`admin/acompanhamento/${claId}/sincronizar`, {
		servidor: {
			id: servidorId,

			categorias: {
				boasVindasId,
				personalDevId,
				espiraisId,
				ajudaId,
				grupoEstudoId,
			},
		},
	});
	return r.status;
}


export async function sincronizarMembro(claId, alunoId, alunoDiscordId) {
  const r = await api().put(`/admin/acompanhamento/${claId}/aluno/${alunoId}/${alunoDiscordId}/sincronizar`);
  return r.status;
}


export async function buscarMembrosSemVinculo(claId) {
  const r = await api().get(`admin/acompanhamento/${claId}/membrosSemVinculo`);
  return r.data;
}


export async function aprovarEntregaEspiral(cla, aluno, espiral, topico, alteracao) {
  const r = await api().put(`admin/acompanhamento/${cla}/aluno/${aluno}/espiral/${espiral}/topico/${topico}/situacao`, {
    situacao: alteracao.situacao,
    comentario: alteracao.comentario
  });
  return r.data;
}


export async function listarCartoesEntregaAluno(claId, alunoId) {
  const r = await api().get(`/admin/acompanhamento/${claId}/aluno/${alunoId}/analiseEntregas`);
  return r.data;
}


export async function listarCartoesEntrega(claId) {
  const r = await api().get(`/admin/acompanhamento/${claId}/analiseEntregas`);
  return r.data;
}


export async function liberarDiscordEspiralPorAluno(claId, alunoId, espiralId) {
  const r = await api().put(`/admin/acompanhamento/${claId}/aluno/${alunoId}/espiral/${espiralId}/liberar`);
  return r.data;
}


export async function enviarMensagemDiscord(canalId, titulo, mensagem) {
	const r = await api().post(`/admin/acompanhamento/discord/canal/${canalId}/mensagem`, {
		titulo,
		mensagem,
	});
	return r.status;
}


export async function enviarMensagemAudioDiscord(canalId, audio) {
	const form = new FormData();
	form.append('audio', audio);

	const r = await api().post(
		`/admin/acompanhamento/discord/canal/${canalId}/audio`,
		form,
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
  );
  
  return r.status;
}


export async function editarDiscordEspiral(claId, espiralId, espiral, topicos, ajuda) {
  const dados = {
		espiral: {
			titulo: espiral.titulo,
			descricao: espiral.descricao,
			iconeUrl: espiral.iconeUrl,
			imagemUrl: espiral.imagemUrl,
			topicos: {
				conceitos: topicos.conceitos,
				conteudo: topicos.conteudo,
				treinoPratico: topicos.treinoPratico,
				treinoTeorico: topicos.treinoTeorico,
				anotacoesConceitos: topicos.anotacoesConceitos,
				anotacoesLeitura: topicos.anotacoesLeitura,
				forums: topicos.forums,
			},
		},
		ajuda: {
			titulo: ajuda.titulo,
			descricao: ajuda.descricao,
			iconeUrl: ajuda.iconeUrl,
			imagemUrl: ajuda.imagemUrl,
		},
	};

  const r = await api().put(`/admin/acompanhamento/${claId}/espiral/${espiralId}/mensagem`, dados);
  return r.status;
}


export async function buscarAluno(claId, alunoId) {
  const r = await api().get(`/admin/acompanhamento/${claId}/aluno/${alunoId}/detalhes`);
  return r.data;
}


export async function buscarAlunoMensagensEmCanal(claId, alunoId, canal) {
  const r = await api().get(`/admin/acompanhamento/${claId}/aluno/${alunoId}/canal/${canal}/mensagens`);
  return r.data;
}


export async function sincronizarMensagens(claId, alunoId) {
  const r = await api().post(`/admin/acompanhamento/${claId}/aluno/${alunoId}/sincronizarMensagens`);
  return r.data;
}


export async function alterarDiscordAlunoRotina(claId, alunoId, rotina) {
  const r = await api().put(`/admin/acompanhamento/${claId}/aluno/${alunoId}/rotina`, {
    ...rotina
  });
  return r.status;
}


export async function analisarDesempenhoConteudo(claId, espiralId, alunoId) {
  const r = await api().get(`/admin/acompanhamento/conteudo/${claId}/espiral/${espiralId}/aluno/${alunoId}/desempenho`);
  return r.data;
}


export async function analisarDesempenhoTreinoTeorico(claId, espiralId, alunoId) {
  const r = await api().get(`/admin/acompanhamento/treinoteorico/${claId}/espiral/${espiralId}/aluno/${alunoId}/desempenho`);
  return r.data;
}


export async function analisarDesempenhoTreinoPratico(claId, espiralId, alunoId) {
  const r = await api().get(`/admin/acompanhamento/treinopratico/${claId}/espiral/${espiralId}/aluno/${alunoId}/desempenho`);
  return r.data;
}


