import { css, keyframes } from 'styled-components';

const fromLeftKeyframes = keyframes`
  100% { 
    left: 0; 
    opacity: 1;
  }
`;

const fromLeft = css`
  position: relative;

  left: -100px;
  opacity: 0;

  animation: ${fromLeftKeyframes} ${props => (props.index ?? 1) * 0.2}s forwards ease;
`;


export default fromLeft;