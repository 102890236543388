import React from 'react';
import { buscarImagem } from '../../servicos/buscarImagem';
import { Container } from './index.styled';

export function ComentarioMembro({ imagem, nome, data = new Date(), conteudo }) {
  
  function formatarData(data) {
    data = new Date(data);
    const hoje = new Date();

    let diff = hoje.getTime() - data.getTime();   
    diff = Math.floor(diff / (1000 * 60 * 60 * 24));   

    switch (diff) {
      case 0:
        return 'Hoje';
      case 1:
        return 'Ontem';
      default:
        return `Há ${diff} dias`;
    }
  }
  
  



  return (
		<Container>
			<div className='aluno'>
        <img src={imagem === 'Devmonk' ? '/assets/images/logos/logo_rounded.png' : buscarImagem(imagem, '/assets/images/avatar.jpg')} alt='' />
        <p>{ nome }</p>
			</div>

			<div className='mensagem'>
        <p>{ formatarData(data) }</p>
				<div>
					{conteudo}
				</div>
			</div>
		</Container>
	);
}
