import * as React from 'react';
import * as api from '../../../../api/usuario/loginAPI.js';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import doIt from '../../../../servicos/doIt.js';





import SApp, { 
  Box,
  Estrelinha
} from './index.styled.js';
import LogoAmarela from '../../../../components/styled/logos/amarelo/index.js';
import Input from '../../../../components/styled/inputs/simples/index.styled.js';
import Button from '../../../../components/styled/botoes/arredondado/index.styled.js';
import LoadingFullscreen from '../../../../components/loading/fullscreen/index.js';





function App() {
  const [nome, setNome] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [senha, setSenha] = React.useState('');

  const [loading, setLoading] = React.useState(false);




  const navigate = useNavigate();




  async function botaoCadastrarAluno() {
    await doIt(async () => {
      setLoading(true);
      await api.cadastrarAluno(nome, email, senha);
      navigate(`/conta/verificarConta/${email}`);
      toast('Usuário cadastrado no sistema.');
    }, {
      onError: function () {
        setLoading(false);
      }
    });
  }




  return (
    <SApp>
      <LoadingFullscreen
        loading={loading}
      />
      <Box>
        <LogoAmarela className='logo'/>
        <h1>Criar Conta</h1>
        <Input
          placeholder='Nome'
          type='text'
          value={nome}
          onChange={e => setNome(e.target.value)}
        />
        <Input
          placeholder='E-mail'
          type='text'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Input
          placeholder='Senha'
          type='password'
          value={senha}
          onChange={e => setSenha(e.target.value)}
        />
        <Button onClick={botaoCadastrarAluno}>Criar</Button>
        <Estrelinha src='/assets/images/icones/estrelinha.svg' />
        <div className='links'>
          <Link to='/conta/login'>Fazer login</Link>
        </div>
      </Box>
    </SApp>
  )
}

export default App;