import styled from "styled-components";


export const TituloAdmin1 = styled.h1`
	font-size: 34px;
	font-family: 'Poppins-bold';
  line-height: 42px;
	color: ${props => props.color ?? '#31332b'};
	user-select: none;
  cursor: pointer;
  position: relative;
  width: fit-content;
`;



export const TituloAdmin2 = styled.h2`
	font-size: 20px;
	font-family: 'Poppins-bold';
	color: ${props => props.color ?? '#31332b'};
	user-select: none;
  cursor: pointer;
  position: relative;
  width: fit-content;
`;


export const TituloAdmin3 = styled.h3`
	font-size: 22px;
	font-family: 'Poppins-bold';
	color: ${props => props.color ?? '#31332b'};
	user-select: none;
  cursor: pointer;
  position: relative;
  width: fit-content;
`;

