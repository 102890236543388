import Table, {
  Line,
  Th,
  Td,
  Overflow
} from '../index.js';

export default function TabelaGerenciarClas(props) {
  return (
    <Overflow>
      <Table>
        <thead>
          <Line color='#7D7D7D'>
            <Th size='4'>clã</Th>
            <Th size='4'>criação</Th>
            <Th size='2'/>
          </Line>
        </thead>
        <tbody>
          {props.clas.map((item, index) => (
            <Line
              color='#D0D0D0'
              key={item.id}
              hover={true}
              bg={_ => index % 2 === 0 ? '#232323' : '#2E2E2E'}
              onClick={_ => props.onSelecionar(item)}
            >
              <Td>{item.info.nome}</Td>
              <Td>{new Date(item.criacao).toLocaleDateString()}</Td>
              <Td>
                <svg style={{marginRight: '.4rem', cursor: 'pointer'}} onClick={_ => props.onAlterar(item)} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1_281)">
                  <path d="M9.20996 3.16663H3.66829C3.24837 3.16663 2.84564 3.33344 2.54871 3.63037C2.25178 3.92731 2.08496 4.33003 2.08496 4.74996V15.8333C2.08496 16.2532 2.25178 16.6559 2.54871 16.9529C2.84564 17.2498 3.24837 17.4166 3.66829 17.4166H14.7516C15.1716 17.4166 15.5743 17.2498 15.8712 16.9529C16.1681 16.6559 16.335 16.2532 16.335 15.8333V10.2916" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M15.1475 1.97918C15.4624 1.66424 15.8896 1.4873 16.335 1.4873C16.7804 1.4873 17.2075 1.66424 17.5225 1.97918C17.8374 2.29413 18.0143 2.72128 18.0143 3.16668C18.0143 3.61208 17.8374 4.03924 17.5225 4.35418L10.0016 11.875L6.83496 12.6667L7.62663 9.50002L15.1475 1.97918Z" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1_281">
                  <rect width="19" height="19" fill="white" transform="translate(0.501709)"/>
                  </clipPath>
                  </defs>
                </svg>
                <svg style={{cursor: 'pointer'}} onClick={_ => props.onDeletar(item)} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.375 4.75H3.95833H16.625" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.33325 4.75004V3.16671C6.33325 2.74678 6.50007 2.34405 6.797 2.04712C7.09393 1.75019 7.49666 1.58337 7.91659 1.58337H11.0833C11.5032 1.58337 11.9059 1.75019 12.2028 2.04712C12.4998 2.34405 12.6666 2.74678 12.6666 3.16671V4.75004M15.0416 4.75004V15.8334C15.0416 16.2533 14.8748 16.656 14.5778 16.953C14.2809 17.2499 13.8782 17.4167 13.4583 17.4167H5.54159C5.12166 17.4167 4.71893 17.2499 4.422 16.953C4.12507 16.656 3.95825 16.2533 3.95825 15.8334V4.75004H15.0416Z" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7.91675 8.70837V13.4584" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M11.0833 8.70837V13.4584" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </Td>
            </Line>
          ))}
        </tbody>
      </Table>
    </Overflow>
  )
}