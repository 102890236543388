import Slider from 'rc-slider'


export default function DmkSlider({ min, max, value, onChange, disabled, className, onMouseDown, onMouseUp }) {
  return (
    <span onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
    <Slider
      className={className}
      disabled={disabled}
      value={value}
      onChange={onChange}
      min={min}
      max={max}

      trackStyle={{
        backgroundColor: '#B7BB0A',
        height: '3px'
      }}
      railStyle={{
        backgroundColor: '#545454',
        height: '3px'
      }}
      handleStyle={{
        display: 'none'
      }}
    />
    </span>
  )
}


