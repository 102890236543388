import { GerenciarAlunos, MeusCursosIcone, HomeIcone } from "../../../components/menu/comps/icones";


export const menu = {
  podeSelecionar: true,
  items: [
    {
      nome: 'Clãs',
      url: '/admin/conta/clas',
      icone: <HomeIcone />
    },
    {
      nome: 'Gerenciar Alunos',
      url: '/admin/conta/alunos',
      icone: <GerenciarAlunos />
    },
    {
      nome: 'Conteúdo',
      url: '/admin/conteudo/cla',
      icone: <MeusCursosIcone />
    },
    {
      nome: 'Treino Teórico',
      url: '/admin/treinoteorico/cla',
      icone: <MeusCursosIcone />
    },
    {
      nome: 'Treino Prático',
      url: '/admin/treinopratico/cla',
      icone: <MeusCursosIcone />
    },
    {
      nome: 'Acompanhamento',
      url: '/admin/acompanhamento/cla',
      icone: <MeusCursosIcone />
    },
  ],
  animarURLs: [
    '/conta',
    '/conta/clas'
  ],
  esconderConta: true,
  esconderSair: false
}


