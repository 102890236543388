import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import storage from 'local-storage';
import './styled.js';

import { Cabecalho } from "../../cabecalho/cabecalhoAluno";
import { Container } from "./styled.js";
import { Menu } from "../../menu";
import { Loading } from "./loading";





export default function PaginaUsuario(props) {
  // eslint-disable-next-line
  const [verificado, setVerificado] = useState(false);


  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const valor = storage('dmk-user');
    if (!valor) {
      navigate('/conta/login?redirect=' + location.pathname);
    }
    else {
      setVerificado(true);
    }

    document.getElementsByTagName("body")[0].classList.remove("light");
    document.getElementsByTagName("body")[0].classList.add("dark");
    // eslint-disable-next-line
  }, [])




  // if (!verificado)
  //   return <div></div>
  // else
    return (
      <Container {...props} className=''>
        <Menu tipo='flutuante' acesso='usuário' config={props.menu} mediaMaxWidth={props.mediaMaxWidth} />
        <section>
          <Cabecalho mediaMaxWidth={props.mediaMaxWidth} />
          <Loading carregando={props.carregando} />

          <main className={props.className}>
            {props.children}
          </main>
        </section>
      </Container>
    )
}


