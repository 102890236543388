import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 80vh;
    background: linear-gradient(180deg, #18161A 0%, #18161A 100%);  

    .titulo {
        display: flex;
        justify-content: center;

        font-family: Mukta-bold;
        font-size: 3.5em;
    }

    .agrupamento-depoimento {
        display: flex;
        flex-direction: row;
        justify-content: center;  
        width: 71vw; 
    }

    .comp-carrossel-espiral {
      & > div {
        scroll-snap-type: x mandatory;
      }
    }

    @media (max-width: 1280px) {
        height: 70vh;
    }

    @media (max-width: 1024px) {
      padding: 5em 0em;
      height: 100vh;

      .titulo {
          font-size: 1.8em;
      }

      .agrupamento-depoimento {
          width: 90vw; 
      }
    } 
`

export { Container }