import React, { useState } from "react";
import { Container } from "./index.styled";


import { AbastescerDepoimentos } from "../../JsonResponse";
import { Carrossel } from '../../../../components/carrossel' 
import BoxDepoimento from "../../comps/boxDepoimento";





export default function Depoimentos(props) {
  const [depoimentos] = useState(AbastescerDepoimentos());


  


  return(
      <Container id={props.id}>

          <div className="titulo"> Depoimentos </div>

          <div className="agrupamento-depoimento">
              <Carrossel manterCarrossel={true}>
                  {depoimentos?.map((item, index) => (
                      <BoxDepoimento key={index + 1} item={item}/>
                  ))}
              </Carrossel>      
          </div>

      </Container>
  )
}