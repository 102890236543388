import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube'
import localStorage from 'local-storage';
import doIt from '../../../../servicos/doIt';
import './index.scss';


import { TituloAdmin2, TituloAdmin3 } from '../../../../components/styled/textos/titulosAdmin';
import { toast } from 'react-toastify';
import { Modal } from '../../../../components/modal'
import { menu } from '../menu';
import InputTextarea from '../../../../components/styled/textArea/comLabel/index';
import Select from '../../../../components/styled/select/index';
import InputComLabel from '../../../../components/styled/inputs/comLabel';
import Button from '../../../../components/styled/botoes/arredondado/index.styled';
import PaginaAdmin from '../../../../components/paginas/paginaAdmin';
import FullScreenImage from '../../../../components/fullScreenImage';


import { API_ADDRESS } from '../../../../api/apiConfig';
import * as api from '../../../../api/admin/treinoTeoricoAPI.js';
import * as apiConta from '../../../../api/admin/contaAPI.js';





function TreinoTeorico_Quiz_Novo() {
  const [nomeCla, setNomeCla] = useState('');
  const [nomeEspiral, setNomeEspiral] = useState('');
  const [tipoTreino, setTipoTreino] = useState('');
  const [tema, setTema] = useState();
  const [descricao, setDescricao] = useState('');
  const [tipoAlternativa, setTipoAlternativa] = useState('');
  const [tempoAnalise, setTempoAnalise] = useState(0);
  const [tempoResposta, setTempoResposta] = useState(0);
  const [imagemPergunta, setImagemPergunta] = useState('');
  const [linkCorrecao, setLinkCorrecao] = useState('');
  const [alternativas, setAlternativas] = useState([
    { id: '', valor: '', correta: false },
    { id: '', valor: '', correta: false },
    { id: '', valor: '', correta: false },
    { id: '', valor: '', correta: false }]);
  const [alternativasImagem, setAlternativasImagem] = useState([
    { id: '', valor: '', correta: false },
    { id: '', valor: '', correta: false }]);
  const [painelEsquerdo, setPainelEsquerdo] = useState('');
  const [painelDireito, setPainelDireito] = useState('');
  const [imagemEmTelaCheia, setImagemEmTelaCheia] = useState(false);
  const [modal, setModal] = useState(false);

  const modalRef = useRef(null);

  const navigate = useNavigate();

  const { cla, espiral, treino, pergunta } = useParams();


  useEffect(() => {
    carregarTitulos();
    //if para caso for EDIÇÃO de pergunta
    // eslint-disable-next-line no-sequences
    if (cla, espiral, treino, pergunta) {
      if (localStorage('dmk-user')) {
        carregarInfo();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  async function carregarTitulos() {
    doIt(async () => {
      const tipo = await api.listarTreinosEPerguntas(cla, espiral, treino);
      const nomeCla = await apiConta.buscarClaPorId(cla);
      const nomeEspiral = await apiConta.buscarEspiralPorId(cla, espiral);

      setNomeCla(nomeCla?.info?.nome);
      setNomeEspiral(nomeEspiral?.nome);
      setTipoTreino(tipo?.session?.tipo);
    })
  }




  async function carregarInfo() {
    doIt(async () => {
      const infoAlteracao = await api.listaPerguntaEspecifica(cla, espiral, treino, pergunta);
      setTema(infoAlteracao?.pergunta?.info?.titulo);
      setDescricao(infoAlteracao?.pergunta?.info?.descricao);
      setTipoAlternativa(infoAlteracao?.pergunta?.quiz?.tipoAlternativa);
      setTempoAnalise(infoAlteracao?.pergunta?.quiz?.tempoAnalise);
      setTempoResposta(infoAlteracao?.pergunta?.quiz?.tempoResposta);
      setImagemPergunta(infoAlteracao?.pergunta?.assets?.pergunta);
      setLinkCorrecao(infoAlteracao?.pergunta?.assets?.correcao);
      setPainelEsquerdo(infoAlteracao?.pergunta?.leitura ? infoAlteracao?.pergunta?.leitura?.painelEsquerda : '');
      setPainelDireito(infoAlteracao?.pergunta?.leitura ? infoAlteracao?.pergunta?.leitura?.painelDireita : '');
      setAlternativas(infoAlteracao?.pergunta?.quiz?.alternativas)
      setAlternativasImagem(infoAlteracao?.pergunta?.quiz?.alternativas)
    })
  }



  //Funcionalidades de imagem
  function colocarImagem() {
    document.getElementById('imagemPergunta').click();
  }




  function colocarImagemAlternativaUm() {
    document.getElementById('imagem-alternativaUm').click();
  }




  function colocarImagemAlternativaDois() {
    document.getElementById('imagem-alternativaDois').click();
  }




  function mostrarFoto(Imagem) {
    if (typeof (Imagem) === 'string') {
      return API_ADDRESS + '/' + Imagem;
    } else {
      return URL.createObjectURL(Imagem);
    }
  }




  async function mudarPergunta(valor) {
    doIt(async () => {
      const r = await api.enviarImagemStorage(valor);
      setImagemPergunta(r.url);
      return r.url;
    })
  }




  async function mudarAlternativa(valor, correta, pos) {
    doIt(async () => {
      if (tipoAlternativa === 'imagem' && typeof (valor) === 'object') {
        let r = await api.enviarImagemStorage(valor);
        valor = r.url;
      }

      let arr = tipoAlternativa === 'texto' ? [...alternativas]
        : [...alternativasImagem];
      arr[pos] = {
        ...arr[pos],
        valor: valor,
        correta: correta
      }

      tipoAlternativa === 'texto' ? setAlternativas(arr)
        : setAlternativasImagem(arr);
    }, { time: 1 });
  }




  async function enviarPergunta() {
    doIt(async () => {
      let perguntaInfo = {
        info: {
          tipoTreino: tipoTreino,
          titulo: tema,
          descricao: descricao
        },
        assets: {
          pergunta: imagemPergunta,
          correcao: linkCorrecao
        },
        quiz: {
          tempoAnalise: Number(tempoAnalise),
          tempoResposta: Number(tempoResposta),
          tipoAlternativa: tipoAlternativa,
          alternativas: tipoAlternativa === 'texto' ? alternativas
            : alternativasImagem
        },
        leitura: {
          painelEsquerda: painelEsquerdo,
          painelDireita: painelDireito,
        }
      }
      const resposta = pergunta ? await api.editarPergunta(cla, espiral, treino, pergunta, perguntaInfo)
        : await api.cadastrarPergunta(cla, espiral, treino, perguntaInfo);

      if (pergunta) {
        toast.success('Pergunta alterada!')
      } else {
        toast.success('Pergunta cadastrada!')
        navigate(`/admin/treinoteorico/cla/${cla}/espiral/${espiral}/treino/${treino}/pergunta/${resposta.id}/alterar`);
      }
    });
  }




  function navegarCriarClick() {
    setTema('');
    setDescricao('');
    setAlternativas([
      { id: '', valor: '', correta: false },
      { id: '', valor: '', correta: false },
      { id: '', valor: '', correta: false },
      { id: '', valor: '', correta: false }]);

    setAlternativasImagem([
      { id: '', valor: '', correta: false },
      { id: '', valor: '', correta: false }]);
    setPainelDireito('');
    setPainelEsquerdo('');
    setLinkCorrecao('');
    setImagemPergunta('');
    setTempoAnalise(0);
    setTempoResposta(0);
    navigate(`/admin/treinoteorico/cla/${cla}/espiral/${espiral}/treino/${treino}/pergunta/novo`);
  }


  function portaModal(porta) {
    setModal(porta);
  }



  return (
    <main className="TreinoTeorico_Quiz_Novo">
      <div className='imagem-fullscreen'>
        {imagemEmTelaCheia &&
          <FullScreenImage
            url={mostrarFoto(imagemPergunta)}
            isOpen={setImagemEmTelaCheia}
          />
        }
      </div>
      <PaginaAdmin className="base-page" menu={menu} carregando={nomeCla === '' || (tema === undefined && pergunta !== undefined)}>
        <Modal
          ref={modalRef}
          ativo={modal}
          setAtivo={setModal}
          titulo='Vídeo'
          descricao='Informe o link do vídeo'>
          <div className='modal'>
            <InputComLabel placeholder='Link' mode='light' value={linkCorrecao} onChange={e => setLinkCorrecao(e.target.value)} />
            <Button color="#FFF" background="#3973BA" padding="0.5em 2.5em" radius="59px" onClick={() => portaModal(false)}>Víncular</Button>
          </div>
        </Modal>

        <div className='conteudo_quiz'>
          <div className='conteudo_geral'>
            <div className="conteudo_titulo">
              <div className='titulos'>
                <TituloAdmin2 onClick={() => navigate(`/admin/treinoteorico/cla/${cla}/espiral`)} className='titulo_cla'>
                  {nomeCla}
                </TituloAdmin2>
              </div>
              <div className='titulos'>
                <TituloAdmin2 onClick={() => navigate(`/admin/treinoteorico/cla/${cla}/espiral/${espiral}/treino/${treino}/alterar`)} className='titulo_cla'>
                  {nomeEspiral}
                </TituloAdmin2>
              </div>
            </div>

            <div className="conteudo_pergunta">
              <div className="quiz">
                <div className="quiz_pergunta">
                  <TituloAdmin3>Pergunta</TituloAdmin3>

                  <InputComLabel placeholder='Tema' mode='light' value={tema} onChange={e => setTema(e.target.value)} />

                  <InputTextarea placeholder='Descrição' height={'10em'} mode='light' padding={'0.5em 2rem 0.5em .8rem'} value={descricao} onChange={e => setDescricao(e.target.value)} />

                  {tipoTreino === 'quiz' &&
                    <div className='inputs-tipo-quiz'>
                      <Select mode='light' placeholder='Tipo alternativas' width={'100%'} value={tipoAlternativa} onChange={e => setTipoAlternativa(e.target.value)}>
                        <option disabled={pergunta}>Escolha um tipo</option>
                        <option disabled={tipoAlternativa === 'imagem' && pergunta} value="texto">Texto</option>
                        <option disabled={tipoAlternativa === 'texto' && pergunta} value="imagem">Imagem</option>
                      </Select>

                      <div className="tempo_pergunta">
                        <InputComLabel type='number' placeholder='Tempo análise' mode='light' width={'100%'} value={tempoAnalise} onChange={e => setTempoAnalise(e.target.value)} />

                        <InputComLabel type='number' placeholder='Tempo resposta' mode='light' width={'100%'} value={tempoResposta} onChange={e => setTempoResposta(e.target.value)} />
                      </div>
                    </div>
                  }

                  {tipoTreino === 'quiz' &&
                    <div className='quiz_pergunta'>

                      <TituloAdmin3 className='titulo_alternativas'>Alternativas</TituloAdmin3>

                      {tipoAlternativa === 'texto' &&
                        <div className='alternativas-tipos-espaço'>

                          {alternativas.map((item, pos) =>
                              <div className='alternativas_input' key={pos}>
                                <InputTextarea placeholder={`Alternativa ${pos + 1}`} type="text" mode='light' height={'5em'} padding={'0.5em 2rem 0.5em .8rem'} width={'100%'} value={item.valor} onChange={e => mudarAlternativa(e.target.value, item.correta, pos)} />
                                <input type="checkbox" id='alternativa' checked={item.correta} value={item.correta} onChange={e => mudarAlternativa(item.valor, e.target.checked, pos)} />
                              </div>
                          )}
                        </div>
                      }
                      {tipoAlternativa === 'imagem' &&
                        <div className='alternativas-tipos-espaço'>

                          {alternativasImagem.map((item, pos) =>
                              <div className='alinhamento_imagem_input' key={pos}>
                                <div onClick={pos === 0 ? colocarImagemAlternativaUm : colocarImagemAlternativaDois} className='alternativas_input_imagem'>

                                  {item.valor &&
                                    <img className='imagem_selecionada' src={mostrarFoto(item.valor)} alt='' />
                                  }

                                  {!item.valor &&
                                    <img className='imagem_selecionada' src='/assets/images/icones/upload.svg' alt=''/>
                                  }

                                  <input type='file' id={pos === 0 ? 'imagem-alternativaUm' : 'imagem-alternativaDois'} onChange={e => mudarAlternativa(e.target.files[0], item.correta, pos)} />
                                </div>
                                <input type="checkbox" checked={item.correta} value={item.correta} onClick={e => mudarAlternativa(item.valor, e.target.checked, pos)} />
                              </div>
                          )}
                        </div>
                      }

                    </div>
                  }


                  {tipoTreino === 'leitura' &&
                    <div className='quiz_pergunta'>
                      <TituloAdmin3 className='titulo_alternativas'>Painel de leitura</TituloAdmin3>

                      <div className='alternativas_input'>
                        <InputTextarea placeholder='Painel Esquerda' type="text" mode='light' height={'13em'} padding={'0 2rem 0 .8rem'} width={'100%'} value={painelEsquerdo} onChange={e => setPainelEsquerdo(e.target.value)} />
                      </div>

                      <div className='alternativas_input'>
                        <InputTextarea placeholder='Painel Direita' type="text" mode='light' height={'13em'} padding={'0 2rem 0 .8rem'} width={'100%'} value={painelDireito} onChange={e => setPainelDireito(e.target.value)} />
                      </div>
                    </div>
                  }
                </div>
              </div>

              <div className='arquivos'>
                <div className='arquivos_posicionamento'>
                  <TituloAdmin3>
                    Imagem
                    {imagemPergunta &&
                      <img className="svg_alterar" src="/assets/images/icones/editar.svg" alt="editar" onClick={() => colocarImagem()} />
                    }
                  </TituloAdmin3>
                  <div onClick={() => imagemPergunta ? '' : colocarImagem()} className='input_arquivo'>

                    {!imagemPergunta &&
                      <img src="/assets/images/icones/upload.svg" alt="upload" />
                    }

                    {imagemPergunta &&
                      <img className="imagemPergunta" onClick={() => setImagemEmTelaCheia(true)} src={mostrarFoto(imagemPergunta)} alt="imagemPergunta" />
                    }

                    <input type="file" id="imagemPergunta" onChange={e => mudarPergunta(e.target.files[0])} />
                  </div>
                </div>

                <div className='arquivos_posicionamento'>
                  <TituloAdmin3>
                    Vídeo Correção
                    {linkCorrecao &&
                      <img className="svg_alterar" src="/assets/images/icones/editar.svg" alt="editar" onClick={() => portaModal(true)} />
                    }
                  </TituloAdmin3>
                  <div className='input_arquivo_correcao' onClick={() => portaModal(true)}>
                    {!linkCorrecao &&
                      <img src="/assets/images/icones/upload.svg" alt="upload" />
                    }
                    {linkCorrecao &&
                      <ReactPlayer className="video_correcao" controls={true} url={linkCorrecao} />
                    }
                  </div>

                </div>

                {pergunta &&
                  <div className='botoes_alterar'>
                    <Button onClick={() => navegarCriarClick()}>Novo</Button>
                    <Button onClick={() => enviarPergunta()}>Alterar</Button>
                  </div>
                }

                {!pergunta &&
                  <div className='botoes_alterar'>
                    <Button onClick={() => enviarPergunta()}>Salvar</Button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </PaginaAdmin>
    </main>
  );
}

export default TreinoTeorico_Quiz_Novo;