import styled from 'styled-components';



const SApp = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;

  position: relative;

  width: 100%;
  height: 100%;

  min-height: 100vh;
  
  background: linear-gradient(270deg, #282828 55.21%, #3A3A3A 100%);
  font-family: 'Poppins';
  
  .renderizar {
    display: flex;
    position: relative;
    
    width: fit-content;
    height: fit-content;

    max-width: 50rem;
    width: 100%;
  }

  h2, h3 {
    color: #FFFFFF;
  }

  .warn {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
  }

  
  .menu {
    min-height: 100vh;
    width: 400px;
  }

  
  @media (max-width: 1024px) {
    justify-content: center;
    align-items: center;
    
    .menu {
      min-height: unset;
      width: 100%;
    }
  }

`;




export const Informacoes = styled.div`
  margin-left: 4rem;  
  padding: 4rem 0;
  height: 100%;
  width: 100%;

  @media (max-width: 1250px) { 
    margin-left: 0;
  }

  @media (max-width: 850px) {
    padding: 4rem 2rem 6rem;
  }
`;




export const CabecalhoAdmin = styled.div`
  position: relative;
  display: flex; 
  flex-direction: row;
  
  align-items: center;

  margin-bottom: 2rem;

  p {
    color: #8A8A8A;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
  }

  h1 {
    color: #D3C314;
    font-size: 28px;
    width: 100%;
  }

  img {
    border-radius: 999px;
    height: 8rem;
    width: 8rem;
    margin-right: 1rem;
  }

  .avatar {
    position: relative;
    width: fit-content;
    height: fit-content;
  }

  .camera {
    position: absolute;
    bottom: .4rem;
    right: 1rem;
    cursor: pointer;
    
    background-image: url('/assets/images/icones/camera.png');
    background-repeat: no-repeat;
    background-position: 50% 45%;
    background-size: 1rem;
    background-color: #4A4A47;
    border: 3px solid #242424;
    border-radius: 999px;

    width: 2rem;
    height: 2rem;

    input[type=file]{
      width: 100%;
      height: 100%;
      opacity: 0;
      
      cursor: pointer;
    }
  }

  @media (max-width: 850px) {
    flex-direction: column;
    width: 100%;

    align-items: flex-start;
  }
`;




export const Box = styled.div`
  padding-left: 4rem;

  position: relative;
  animation: entrada 1s both;


  @media (max-width: 1250px) {
    padding-left: 0;
    flex-direction: column;
    display: flex;
    width: 100%;
  }

  
  @keyframes entrada {
    from { 
      opacity: 0;
      bottom: -50px;
    }
    to {
      opacity: 1;
      bottom: 0px;   
    }
  }
`;




export const GerenciarCla = styled.div`
  width: 100%;
  margin-bottom: 0px;

  .navegacao {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 30px;
    margin-bottom: 10px;
  }
`;




export const Overflow = styled.div`
  height: fit-content;
  width: 100%;
  max-height: 16rem;

  overflow: hidden;
  overflow-y: auto;

  padding-right: 10px;
  padding-bottom: 4rem;

  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 999px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #414141; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555555; 
  }
`;




export const InformacoesBasicas = styled.div`
  height: 450px;

  .fields {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    @media (max-width: 850px) {
      flex-direction: column;
      width: 100%;
    }
  }

  @media (max-width: 850px) { 
    width: 100%;
    height: auto;
  }
`;




export const CampoCla = styled.div`
  display: flex;
  flex-direction: column;
  gap: .4rem;

  max-width: 18rem;
  width: 100%;

  @media (max-width: 850px) {
    max-width: 100%;
  }

  
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;




export const CampoEspiral = styled.div`
  display: flex;
  flex-direction: column;
  gap: .4rem;

  max-width: 20rem;
  width: 100%;


  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 850px) {
      max-width: unset;
    }
`;




export const IconeCla = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  margin-bottom: .5rem;

  img {
    border-radius: 999px;
    height: 4rem;
    width: 4rem;
    margin-right: 1rem;
  }

  .camera {
    position: absolute;
    bottom: .2rem;
    right: .7rem;

    background-image: url('/assets/images/icones/camera.png');
    background-repeat: no-repeat;
    background-position: 50% 45%;
    background-size: .75rem;
    background-color: #4A4A47;
    border: 3px solid #242424;
    border-radius: 999px;

    width: 1.5rem;
    height: 1.5rem;

    input[type=file]{
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
`;




export const Espirais = styled.div`

  display: flex;
  flex-direction: row;
  gap: 4rem;

  height: 450px;
  width: 100%;

  .gerenciar-espirais {
    max-width: 20rem;
    width: 100%;
  }

  @media (max-width: 850px) {
    flex-direction: column;
    height: auto;

    .gerenciar-espirais {
      max-width: unset;
    }
  }
`;




export const Sistemas = styled.div`
  margin-top: 50px;
  max-width: 16rem;
  width: 100%;

  .listagem {
    display: flex;
    flex-direction: column;
    gap: .4rem;

    width: 100%;  
  }

  @media (max-width: 850px) {
    max-width: 100%;
  }
`;




export const ListagemClas = styled.div`
  margin-top: 8rem;
  max-width: 48rem;
  width: 100%;
  
`;




export const Navegacao = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  border: 0;

  font-weight: 700;
  font-size: 14px;
  
  color: ${props => props.state(props.nav) ? '#F2F2F2' : '#535353'};

  cursor: pointer;
`;




export default SApp;