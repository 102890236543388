import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 37vw;
  height: 9.8em;
  overflow-y: auto;

  padding: 1em 1em 1em 0em; 

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #ACACAC;
  }

  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: ${props => props.width};

    input {
      cursor: unset;
      border-color: #828282;
      border-bottom: none;
      font-weight: 600;
    }

    input:focus {
      box-shadow: 0 0 0 0;
      outline: 0;
    }
  }

  .input:last-child input {
    border-bottom: 1px solid #828282;
  }

  .input input::placeholder {
    font-size: 16px;
    font-weight: 600;
  }
  
  

  .seta {
      position: relative;
      right: 1.5em;
  }

  .lixeira {
    visibility: hidden;
  }

  .input:hover {
    .lixeira {
      visibility: visible;
    }
  }

  @media (min-width: 1920px) {
    width: 40.5vw;
  }
  
  @media (max-width: 1024px) {
    width: 100%;

    .lixeira {
      visibility: visible;
    }
  }
`;

export { Container }