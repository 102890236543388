import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Container, H1 } from './index.styled';




export default function CardEspiralUsuarioDark({classe, rota, espiral, info, codAprovacao, parametros}) {
  const navigate = useNavigate();




  function irPara() {
    if (classe === 'bloqueado') return;

		for (const item of parametros) {
			rota = rota.replace(/:[a-z]+/im, item);
		}
		navigate(rota);
  }




  function iconeCartao() {
    // eslint-disable-next-line
    switch (classe) {
      case 'aprovado': return <img className='aprovado' src='/assets/images/icones/fire.svg' alt='' />;
      case 'corrigir': return <img className='corrigir' src='/assets/images/icones/tool.svg' alt='' />;
      case 'concluído': return <img className='concluído' src='/assets/images/icones/check.svg' alt='' {...copiarCodigoAprovacao()} />;
      case 'bloqueado': return <img className='bloqueado' src='/assets/images/icones/lock.svg' alt='' />;
    }
  }
  



  function copiarCodigoAprovacao() {
    return { style: { cursor: 'pointer' }, title: `Cód. Aprovação: ${codAprovacao}`, onClick: () => { navigator.clipboard.writeText(codAprovacao);  toast.info('Código de aprovação copiado')} }
  }




  function estilo() {
    return classe === 'bloqueado'
      ? { color: '#484848' }
      : { color: '#ffffff' }
  }




  return (
    <Container classe={classe}>
      
      <div style={estilo()} className="espiral-container">
        <div className='espiral-icones'>
          <H1 bloqueado={classe === 'bloqueado'}>{espiral}</H1>
          {iconeCartao()}
        </div>
        <div className='espiral-infos'>
          <p>{info}</p>
        </div>
      </div>

      <div className='espiral-acesso'>
        <Button
          bloqueado={classe === 'bloqueado'}
          onClick={irPara}>
          Acessar
        </Button>
      </div>
    </Container>
  )
}

