import { getApi } from './base.js';
const api = getApi('conta');




export async function listarClas() {
  let clas = await api().get('/cla');
  return clas.data;
}


export async function buscarClaPorId(claId) {
  const cla = await api().get(`/${claId}/info`);
  return cla.data;
}


export async function buscarEspiralPorId(claId, espiralId) {
  const admin = await api()(`/${claId}/espiral/${espiralId}`);
  return admin.data;
}






export async function obterInformacoesAlunoLogado() {
  let conta = await api().get('/');
  return conta.data;
}


export async function editarInformacoesAlunoLogado(nome, nascimento, sexo, celular) {
  await api().put('/', {
    nome: nome,
    nascimento: nascimento,
    sexo: sexo,
    celular: celular
  });
}


export async function editarAvatar(imagem) {
  const data = new FormData();
  data.append('imagem', imagem);

  await api().put('/avatar', data, {
    'Content-Type': 'multipart/form-data'
  });
}


export async function listarClasAlunoLogado() {
  let clas = await api().get('/cla');

  return clas.data;
}


export async function atualizarEmailAlunoLogado(email) {
  await api().put('/email', {
    email: email
  });
}


export async function atualizarSenhaAlunoLogado(senha) {
  await api().put('/novasenha', {
    senha: senha
  });
}


export async function atualizarRotinaAluno(rotina) {
  const r = await api().put('rotina', { rotina });
  return r.status;
}
