import { BurgerContainer, MenuBurgerIcon } from "./styled";


export default function MenuBurger({ open, setOpen, mediaMaxWidth }) {  
  return (
    <BurgerContainer mediaMaxWidth={mediaMaxWidth}>
      <MenuBurgerIcon className={open ? 'open' : ''} onClick={() => setOpen(!open)}>
        <span></span>
        <span></span>
        <span></span>
      </MenuBurgerIcon>
    </BurgerContainer>
  )
}