import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "./index.styled";
import 'animate.css';


import Botao from '../../../../components/styled/botoes/redondo/index.styled';
import { Monges } from '../../comps/monges';
import { AnimationOnScroll } from "react-animation-on-scroll";






export default function Início() {
  const navig = useNavigate();




  return(
    <Container>
        
        <div className="agrupamento-textos">
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true}>
                <div className="titulo-inicio"> TEORIA ALIADA À PRÁTICA </div>
                <div className="texto-inicio"> 
                  Nosso método está alinhado com as mais novas teorias da aprendizagem e 
                  conta com uma academia de treinos validados e especializados no desenvolvimento 
                  das habilidades essenciais para ser um programador diferenciado no mercado de trabalho. 
                </div>
                <div className="grupo-botao-inicio" onClick={() => navig("/conta/login")}> <Botao className="botao-inicio" padding='.3em 1.5em'> Começar agora! </Botao> </div>
            </AnimationOnScroll>
        </div>
        
        <div className="agrupamento-imagem">
          <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>
            <div className="imagem-monges"> <Monges /> </div>
          </AnimationOnScroll>  
        </div>
    </Container>
  )
}