import { getApi } from './base';
const api = getApi();



export async function listarEspirais(claId) {
  const r = await api().get(`treinopratico/${claId}/espiral`);
  return r.data;
}



export async function listarTreinos(claId, espiralId) {
  const r = await api().get(`treinopratico/${claId}/espiral/${espiralId}/treino`);
  return r.data;
}




export async function listarLinguagensTreino(){
  const r = await api().get('treinopratico/linguagem/treino');
  return r.data;
}



export async function buscarTreino(claId, espiralId, treinoId) {
  const r = await api().get(`treinopratico/${claId}/espiral/${espiralId}/treino/${treinoId}`);
  return r.data;
}



export async function listarLinguagens()  {
    const r = await api().get(`treinopratico/linguagem`);
    return r.data;
}



export async function enviarSolucaoDoTreino(claId, espiralId, treinoId, linguagem, fonte, comentarios)  {
    const r = await api().post(`treinopratico/${claId}/espiral/${espiralId}/treino/${treinoId}/solucao`, { linguagem: linguagem, fonte: fonte, comentarios: comentarios });
    return r.data;
}


export async function listarSolucoesTreinoPratico(claId, espiralId, treinoId) {
  const r = await api().get(`treinopratico/${claId}/clan/${espiralId}/espiral/${treinoId}/treino/solucoes`);
  return r.data
}


export async function buscarAluno(alunoId) {
  const r = await api().get(`treinopratico/infoAluno/${alunoId}`);
  return r.data;
}
