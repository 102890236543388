import { useRef } from 'react';
import FieldStatic from './index.styled.js';


export function InputIconeEstatico(props) {
	const capInputRef = useRef();

	return (
		<FieldStatic
			mode={props.mode}
			width={props.width}
			height={props.height}
			onClick={_ => capInputRef.current.click()}>
			<div>{props.descricao}</div>
			<label>{props.placeholder}</label>
			<button onClick={props.onClick}>
				<img src={props.icone} alt='' />
			</button>
			<input {...props} ref={capInputRef} />
		</FieldStatic>
	);
}



