import { getApi } from './base.js';
const api = getApi('conta');

export async function cadastrarAluno(nome, email, senha) {
  await api().post('', {
    nome: nome,
    email: email,
    senha: senha
  });
}


export async function logarAluno(email, senha) {
  const conta = await api().post('/login', {
    email: email,
    senha: senha
  });
  return conta.data;
}


export async function enviarEmailVerificarConta(email) {
  await api().post('/verificar', {
    email: email
    
  });
}


export async function verificarConta(email, codigo) {
  await api().post(`/verificar/${codigo || '0000'}`, {
    email: email
  });
}


export async function enviarCodigoParaResetarSenha(email) {
  await api().post('/reset', {
    email: email
  });
}

export async function verificarCodigoResetValido(email, codigo) {
  await api().post(`/reset/${codigo}`, {
    email: email
  });
}

export async function enviarNovaSenhaComCodigoReset(email, senha, codigo) {
  await api().post(`/reset/${codigo}/novasenha`, {
    email: email,
    senha: senha
  });
}