import styled from "styled-components";



const TextContainer = styled.div`
    width: ${props => props.largura ?? 'inherit'};
    height: 300px;
    
    display: flex;
    justify-content: ${props => props.justifyContent ?? 'inherit'};
    flex-direction: ${props => props.flexDirection ?? 'column'};
    align-items: ${props => props.alignItems ?? 'inherit'};
    overflow-y: auto;
    gap: ${props => props.gap ?? '0px'};

    background: #FFFFFF;
    border: 1px solid #ACACAC;
    border-radius: 8px;

    position: relative;
    padding: ${props => props.padding ?? '15px'};

    color: black;
`;

const MenuOpcoes = styled.div`
  height: 30px;

  display: flex;
  flex-direction: row;
  gap: 20px;
  
  span{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    
  }
  span:first-child {
    color: ${props => props.selecionado === 'resumo' ? '#2284FB' : '#B5B5B5'};
  }
  span:last-child{
    color: ${props => props.selecionado === 'detalhes' ? '#2284FB' : '#B5B5B5'};
  }
`

export {
  TextContainer,
  MenuOpcoes
}