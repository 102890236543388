import React, { useEffect } from 'react'
import { toast } from 'react-toastify';
import doIt from '../../../../../servicos/doIt';
import './index.scss';


import TextoContainer from '../../../comps/TextoContainer';
import MenuTreinoTeorico from '../../../../../components/menuTreinoTeorico';


import * as servicosTeoricosUsuario from '../../../../../api/usuario/treinoTeoricoAPI';





export default function Aguardando({ claId, espiralId, treinoId, setPergunta, setTela }) {



  useEffect(()=>{
    async function iniciarTreino() {
      doIt(async () => {
        await servicosTeoricosUsuario.gerenciarAvancar(claId, espiralId, treinoId, {acao: 'iniciar'});
        const proximaPergunta = await servicosTeoricosUsuario.gerenciarAvancar(claId, espiralId, treinoId, {acao: 'proximo'});
        setPergunta(proximaPergunta.conteudo);
        toast.dark('Bom treino!', {autoClose: 1000});
        setTimeout(() => setTela('analisando'), 3000);
      }, {time: 1})
    }
    iniciarTreino();
    //eslint-disable-next-line
  },[]);
  


  return (
    <main className='tela-aguardando'>
      <MenuTreinoTeorico />
      <div className='container'>
        <TextoContainer 
          titulo="Aguardando"
          descricao="Se concentre! Seu treino já vai começar."
        />
      </div>
    </main>
  )
}
