import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  min-width: 700px;
  border-collapse: collapse;
`;

export const Line = styled.tr`
  height: 52px;
  background: ${props => props.bg};

  &:hover {
    background: ${props => props.hover ? '#585858' : 'unset'};
  }

  * {
    color: ${props => props.color};
  }
`;

export const Th = styled.th`
  width: ${props => `${props.size}0%`};
  height: 100%;

  padding-left: 2rem;

  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
`;

export const Td = styled.td`
  height: 100%;
  padding-left: 2rem;

  font-weight: 700;
  font-size: 14px;
  text-align: left;
`;

export const Overflow = styled.div`
  height: fit-content;
  width: 100%;
  max-height: 16rem;
  padding-right: 20px;
  padding-bottom: 20px;

  overflow: auto;

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    border-radius: 999px;
  }

  ::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
  }
  
  ::-webkit-scrollbar-thumb {
    background: #414141; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555555; 
  }
`;

export default Table;