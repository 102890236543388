import React from "react";
import { StyledBoxCurso } from "./styled";





export default function BoxCurso(props) {
  return (
    <StyledBoxCurso>
      <div className="tipo-curso"> {props.titulo} </div>
      <div className="desc-curso"> {props.descricao} </div>
    </StyledBoxCurso>
  );
}
