import * as React from 'react';





import useQuery from '../../../servicos/useQuery.js';
import doIt from '../../../servicos/doIt.js';
import PdfViewer from '../../../components/pdf/index.js';





export default function App() {
  const [pdf, setPdf] = React.useState('');
  
  const query = useQuery();

  React.useEffect(() => {
    doIt(() => {
      setPdf(query.get('arquivo'));
    })
    //eslint-disable-next-line
  }, [])


  return ( 
    <div style={{height:'100vh', width: '100%'}}>
      {pdf && <PdfViewer pdf={pdf} />}
    </div>
  )
}