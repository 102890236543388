import Field from './index.styled.js';

export default function InputComLabel(props) {
  return (
    <Field mode={props.mode} width={props.width} height={props.height} value={props.value}>
      <input {...props} onClick={undefined} placeholder={''} required />
      <label>{props.placeholder}</label>
    </Field>
  )
}


export function InputIcone(props) {
	return (
		<Field mode={props.mode} width={props.width} height={props.height}>
			<input {...props} required placeholder='' onClick={e => e.preventDefault()} style={{paddingRight: '45px'}} />
			<label>{props.placeholder}</label>
			<button onClick={props.onClick}>
				<img src={props.icone} alt='' />
			</button>
		</Field>
	);
}

