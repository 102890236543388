import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: center;

    position: relative;
    z-index: 0;
    
    height: 75px;
    min-height: 75px;

    background-color: ${props => props.tema ? "#212121" : "#F3F3F3"};
    box-shadow: 0px 3px 4px rgba(168, 168, 168, 0.25);
    user-select: none;

    .mensagem {
      font-family: Poppins-semibold;
      color: #888888;
      
    }

    .group-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 90%; 
        padding: 0.7em;
    }

    
`

export { Container }