import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row; 
  
  align-items: center;
  justify-content: center;

  scroll-snap-align: center;
  width: 100%;
  min-width: 1200px;


  .imagem-depoimento {
      margin-right: 3em;
  }

  .imagem-depoimento > img {
      height: 13em;
      width: 13em;
      border-radius: 12.5em;
      object-fit: cover;
  }

  .agrupamento-textos {
    display: flex;
    flex-direction: row;

    .imagem-aspas {
      margin-right: 2em;
    }

    .imagem-aspas-direita {
      margin-left: 1em;
    }

    .textos {
      .depoimento {
        font-family: Mukta-light;
        font-size: 1em;
        max-width: 28em;
        width: 100%;
      }

      .info-pessoa {
        margin-top: 1em;

        div {
          font-family: Mukta-bold;
          color: #FFC01D;
        }
      }
    }
  }


  @media (max-width: 1350px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1280px) {
    .imagem-depoimento {
      margin-right: 0em;
      margin-bottom: 2em;
    }
  }


  @media (max-width: 1024px) {
    min-width: 90vw;
          
    .imagem-depoimento {
      margin: 1em 0em;
      margin-right: 0em;
    }

    .imagem-depoimento > img {
      height: 10em;
      width: 10em;
    }

    .agrupamento-textos {
      .aspas {
        display: none;
      }

      .textos {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .depoimento {
          max-width: 69%;
          text-align: center;
        }

        .info-pessoa {
          display: flex;
          flex-direction: column;
          align-items: center;

          div {
          text-align: center;
          max-width: 12em;
        }
        }
      }
    }

  }

  @media (max-width: 600px) {
    .aspas {
      display: none;
    }
  }
`

export { Container }