import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    background-image: url('/assets/images/backgrounds/montanhas.png');
    background-size: 100% 100%; 

    .conteudo {
      display: flex;
      flex-direction: row;
      margin: auto 2em;
    }

    @keyframes animate {
    0% {
      transform: translateY(1em);
    }

    50% {
      transform: translateY(0px);
    }

    100% {
      transform: translateY(1em);
    }
  }

    .monge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60%;

      animation: animate 3s infinite ease-in-out;
    }

    .agrupamento-textos {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 40%;

      .titulo {
        font-family: Mukta-bold;
        font-size: 3.5em;
        text-align: end;
        line-height: 1em;
        width: 6em;
      }

      .descricao {
        font-family: Mukta-light;
        font-size: 1em;
        text-align: end;
        width: 15em;
        margin-left: 6em;
      }
    }

    .barrinha {
        animation: fadeInLeft;
        animation-duration: 1.3s; 
    }

    
    @media (max-width: 1024px) {
      .conteudo {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .monge > img {
        height: 13em;
      }

      .agrupamento-textos {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 3em;

        animation: fadeInRight;
        animation-duration: 1.3s;

        .titulo {
          font-size: 1.8em;
          text-align: center;
          margin-bottom: 1em;
          width: 100%;
        }

        .descricao {
          text-align: center;
          width: 100%;
          max-width: 30em;
          margin: 0px;
        }
      }
    }
`

export { Container }