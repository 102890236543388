import styled from "styled-components";

export const Container = styled.section`
  position: relative;
  top: -10em;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    height: 15em;
    margin-bottom: 2em;
  }
`