import "./index.scss";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import localStorage from "local-storage";
import doIt from "../../../../servicos/doIt";

import { TituloAdmin1, TituloAdmin2, TituloAdmin3 } from "../../../../components/styled/textos/titulosAdmin";
import { ListaOrdenacao } from "../../../../components/listaOrdenacao";
import PaginaAdmin from "../../../../components/paginas/paginaAdmin";
import confirmar from "../../../../components/confirm";
import { menu } from '../menu';


import * as apiConta from "../../../../api/admin/contaAPI";
import * as api from "../../../../api/admin/treinoTeoricoAPI";



function TreinoTeorico_ListaTreino_Admin() {
  const [nomeCla, setNomeCla] = useState("");
  const [nomeEspiral, setNomeEspiral] = useState("");
  const [treinos, setTreinos] = useState("");

  const navigate = useNavigate();

  const { cla, espiral } = useParams();

  useEffect(() => {
    if (localStorage('dmk-user')) {
      carregarTitulos();
    }
    // eslint-disable-next-line
  }, []);




  async function carregarTitulos() {
    doIt(async () => {
      const nomeCla = await apiConta.buscarClaPorId(cla);
      const nomeEspiral = await apiConta.buscarEspiralPorId(cla, espiral);
      const treinos = await api.listarTreinosPorEspiral(cla, espiral);
      setNomeCla(nomeCla?.info?.nome);
      setNomeEspiral(nomeEspiral?.nome);
      setTreinos(treinos);
    });
  }




  async function handleAlterarOrdem(treinoId, ordem) {
    doIt(
      async () => {
        await api.alterarTreinoOrdem(cla, espiral, treinoId, ordem);
        const treinos = await api.listarTreinosPorEspiral(cla, espiral);
        setTreinos(treinos);
      },
      {
        supressError: true,
      }
    );
  }




  async function deletar(treinoId) {
    confirmar({
      titulo: 'Remover treino',
      descricao: 'Tem certeza que deseja remover esse treino?',
      onSim: _ =>
      doIt(async () => {
        await api.deletarTreino(cla, espiral, treinoId);

        const treinoEncontrado = await api.listarTreinosPorEspiral(cla, espiral);
        setTreinos(treinoEncontrado);
        toast.warn('Treino removido');
      })
    })
    
    
  }






  return (
    <PaginaAdmin className="listaTreino_Principal" menu={menu} carregando={treinos === ''}>
      <div className="conteudo" id="main">
        <div className="titulos">
          <TituloAdmin3
            color="#3973BA"
            onClick={() => navigate(`/admin/treinoteorico/cla/${cla}/espiral`)}>
            {nomeCla}
          </TituloAdmin3>
          <TituloAdmin1
            onClick={() => navigate(`/admin/treinoteorico/cla`)}
          >
            {nomeEspiral}
          </TituloAdmin1>
        </div>
        <div className="listagem_treinos">
          <div className="titulo_Lista">
            <TituloAdmin2>Lista de treinos</TituloAdmin2>
            <img src="/assets/images/icones/plus.svg" onClick={() => navigate(`/admin/treinoteorico/cla/${cla}/espiral/${espiral}/treino/novo`)} alt='' />
          </div>
          <ListaOrdenacao
            items={treinos}
            descricao={(item) => item.session.nome}
            ordem={(item) => item.session.ordem}
            trocarOrdem={(item, ordem) => item.session.ordem = ordem}
            onOrdemAlterada={(item, novaOrdem) => handleAlterarOrdem(item.id, novaOrdem)}
            onClick={(item) => navigate(`/admin/treinoteorico/cla/${cla}/espiral/${espiral}/treino/${item.id}/alterar`)}
            onDeletar={(item) => deletar(item.id)}
          />
        </div>
      </div>
    </PaginaAdmin>
  );
}

export default TreinoTeorico_ListaTreino_Admin;
