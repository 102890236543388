import styled from "styled-components"

const Field = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;

    cursor: pointer;
    
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    padding-left: 22px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    color: ${props => props.color || '#FFFFFF'};
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 15px;
    width: 15px;
    background: #303030;
    border: 1px solid #464646;
    border-radius: 3px;
  }

  :hover input ~ span {
    background-color: #414141;
  }

  span:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    top: 1px;
    left: 4.4px;
    width: 4px;
    height: 7px;
    border: solid #C8C8C8;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export default Field;