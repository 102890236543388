import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import doIt from '../../../../servicos/doIt';


import NovoComentario from '../../../../components/novoComentario'
import SelectQuadrado from '../../../../components/styled/selectQuadrado';
import CodeEditor from '../../../../components/codeEditor';
import Terminal from '../../../../components/terminal';
import  BotaoAnexo from '../../../../components/styled/botaoAnexo';
import PdfViewer from '../../../../components/pdf';
import { Loading } from '../../../../components/paginas/paginaUsuario/loading';
import confetti from "canvas-confetti";

import { StyledCompiler } from './styled';
import { DarkPage } from '../../../../components/styled/container/darkPage';
import { BoxWithScroll } from '../../../../components/styled/boxWithScroll';



//API
import * as apiConta from '../../../../api/usuario/contaAPI';
import { listarLinguagens } from '../../../../api/usuario/treinoPraticoAPI';
import { buscarTreino } from '../../../../api/usuario/treinoPraticoAPI';
import { API_ADDRESS } from '../../../../api/apiConfig';





export default function TreinoPratico_Compiler() {
  const [nomeCla, setNomeCla] = useState('');
  const [nomeEspiral, setNomeEspiral] = useState('');
  
  const [linguagens, setLinguagens] = useState();
  const [linguagem, setLinguagem] = useState(-1);
  const [code, setCode] = useState('');

  const [infoPergunta, setInfoPergunta] = useState();
  const [resposta, setResposta] = useState([]);
  const [sizeVsCode, setSizeVscode] = useState(false);
  
  const [comentario, setComentario] = useState('');
  const [comentarios, setComentarios] = useState([]);
  const [ arquivos, setArquivos ] = useState([]);  
  const [ arquivoSelecionado, setArquivoSelecionado ] = useState(0); 

  const [ trocandoArquivo, setTrocandoArquivo ] = useState(false);

  const [ situacao, setSituacao ] = useState('');
  
  const [ pdf, setPdf ] = useState(false);
  



  const { cla: claId, espiral: espiralId, treino: treinoId } = useParams();
  const navigate = useNavigate();


  let objParams = {
    cla: claId,
    espiral: espiralId,
    treino: treinoId,
    comentarios: comentarios
  }


  async function listarLinguagem() {
    doIt(async _ => {
      const r = await listarLinguagens();
      setLinguagens(r)
    })
  }



  async function listarTreino() {
    doIt(async _ => {
      const r = await buscarTreino(claId, espiralId, treinoId);
      setInfoPergunta(r[0].pergunta);
      setArquivos(r[0].arquivos);
      setResposta(r[0].arquivos.map(item => ''))
    })
  }

 function atualizarResposta(code) {
  if(!trocandoArquivo) {
    resposta[arquivoSelecionado] = code;
    setResposta([...resposta]);
  }
 }

 function atualizarArquivoSelecionado(i) {
  setTrocandoArquivo(true)
  setTimeout(() => setArquivoSelecionado(i), 250) 
 }


 useEffect(() => {
  setTrocandoArquivo(false)
 }, [arquivoSelecionado])
  




  async function buscarInformacoesCla() {
    doIt(async _ => {
      const claEncontrado = await apiConta.buscarClaPorId(claId);
      if (!claEncontrado) throw new Error('Clã não encontrado');
      setNomeCla(claEncontrado?.info?.nome);

      const espiralEncontrada = await apiConta.buscarEspiralPorId(claId, espiralId);
      if (!espiralEncontrada) throw new Error('Espiral não encontrada');
      setNomeEspiral(espiralEncontrada?.nome);
    })
  }


  
  function fire() {
    var end = Date.now() + 3 * 1000;
  
    var colors = ["#bb0000", "#ffffff"];
  
    (function frame() {
      confetti({
        particleCount: 2,
        spread: 35,
        origin: { x: 0, y: 0.6 },
        colors: colors
      });
      confetti({
        particleCount: 2,
        spread: 35,
        origin: { x: 1, y: 0.6 },
        colors: colors
      });
  
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }



  function tamanhoVs(click, situacao, contador) {
    if (click === true) {
      setSizeVscode(true)
    } else {
      setSizeVscode(false)
      setSituacao('')
    }

 


    if(contador >= 6) {
      if(situacao === 'aprovado') 
        fire();
        else setSituacao(situacao)
    }
  }

  function enviarComentario(event) {
    if(event.key !== 'Enter' || comentario.length === 0) return;
    setComentarios([...comentarios, comentario])
  }


  



  useEffect(() => {
    listarLinguagem();
    buscarInformacoesCla();
    listarTreino();
    // eslint-disable-next-line
  }, [])




  useEffect(() => {
    if (linguagem !== -1) {
      resposta[0] = linguagens[linguagem].vscode
      setResposta([...resposta])
      setCode(linguagens[linguagem].code)
    }
    // eslint-disable-next-line
  }, [linguagem])





  

  return (
    <DarkPage carregando={linguagem === undefined || infoPergunta === undefined}>
      <Loading carregando={linguagem === undefined || infoPergunta === undefined} />

      <StyledCompiler carregando={linguagem === undefined || infoPergunta === undefined} sizeVsCode={sizeVsCode}>
        <div className={`${situacao !== 'aprovado' && situacao.length > 0 ? 'shake' : ''}`}>
          

          <div className="cabecalho">
            <div className="comentario">
              <NovoComentario
              value={comentario}
              onKeyUp={enviarComentario}
              onChange={e => setComentario(e.target.value)}
              />
            </div>
            <div className="informacoes-treino">
              <img src="/assets/images/logos/dmklogousuarioamarelobold.png"  alt="" className="logo"/>
              <div className="descricao-treino">
                <div onClick={() => navigate(`/treinopratico/cla/${claId}/espiral`)}> {nomeCla} </div>
                <div> {'>'} </div>
                <div onClick={() => navigate(`/treinopratico/cla/${claId}/espiral/${espiralId}/lista`)}> {nomeEspiral} </div>
              </div>
            </div>
          </div>

          
          <div className="interacao-user">
            <div className="informacoes-treino-user">
              <div className="row"> 
                <div className="titulo-treino">
                  {infoPergunta?.titulo ?? '-'}
                </div>
                {!infoPergunta?.anexo  ? null :  <BotaoAnexo texto={ pdf === false ? 'ABRIR ANEXO' : 'FECHAR ANEXO'}   onClick={() => { pdf === false ? setPdf(true) : setPdf(false) } }   /> }
              </div>

              
              {
                pdf === true  ? 
                  <div className="pdf"> 
                    <PdfViewer pdf={ `${ API_ADDRESS }/${ infoPergunta?.anexo }` }   />
                  </div> 
                :
                <BoxWithScroll className="boxScroll">
                    { infoPergunta?.descricao }  
                </BoxWithScroll> 
              }
            </div>

            
            <div className="solucao-treino">
              <div className="titulo-treino"> Solução </div>

              <div className='select'>
                <SelectQuadrado
                  value={linguagem}
                  onChange={e => setLinguagem(e.target.value)}
                  defaultValue={"selecione a linguagem"}
                >
                  <option  value={-1} disabled> Selecione a linguagem </option>
                  {linguagens?.map((item, i) =>
                    <option key={i} disabled={!sizeVsCode ? false : true} value={i}> {item.nome} </option>
                  )}
                </SelectQuadrado>
              </div>

              <div className='relative'> 
              <div className={sizeVsCode === false ? "vscode" : "vscode-low"}>
              {!sizeVsCode ? <div className='row padding-top'>   {arquivos?.map((item, i) => <button onClick={() =>  atualizarArquivoSelecionado(i)} className={`arquivo ${i === arquivoSelecionado ? 'selecionado' : ''}`}> {item} </button> ) } </div> : null}
                <CodeEditor
                  language={code}
                  value={resposta[arquivoSelecionado]}
                  onChange={code => atualizarResposta(code)}
                />
              </div>
              </div>

              <Terminal
                linguagem={code}
                fonte={resposta}
                params={objParams}
                arquivos={arquivos}
                tamanhoVs={tamanhoVs} />

            </div>
          </div>

        </div>
      </StyledCompiler>
    </DarkPage>
  )
}