import styled from "styled-components";

const BoxWithScroll = styled.div`



    padding: 1em;
    stroke: Solid #3C3A3A;
    box-shadow: 0px 0px 18px 1px rgba(160, 160, 160, 0.1);
    background-color:  "#242424";
    overflow-y: auto;
    border: 2px solid #3F3F3F;
  

  



  ::-webkit-scrollbar  {
     background: transparent;
     height: 3px;
     width: 8px;
  } 


  ::-webkit-scrollbar-track {
    background-color:  #171717;    
  }

  




`


export { BoxWithScroll }