import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import doIt from '../../../../servicos/doIt';
import './index.scss'


import { ListaOrdenacao } from '../../../../components/listaOrdenacao/index.js'
import InputComLabel from '../../../../components/styled/inputs/comLabel/index.js';
import Select from '../../../../components/styled/select/index.js'
import confirmar from '../../../../components/confirm';
import BotaoRedondo from '../../../../components/styled/botoes/redondo/index.styled'
import { TituloAdmin1, TituloAdmin2, TituloAdmin3 } from '../../../../components/styled/textos/titulosAdmin';
import PaginaAdmin from '../../../../components/paginas/paginaAdmin';
import { menu } from '../menu';


import * as api from '../../../../api/admin/treinoTeoricoAPI.js';
import * as apiConta from '../../../../api/admin/contaAPI'




export default function TreinoTeorico_PerguntasLista() {
  const [nomeCla, setNomeCla] = useState('');
  const [nomeEspiral, setNomeEspiral] = useState('');
  const [perguntas, setPerguntas] = useState();
  const [nomeTreino, setNomeTreino] = useState('');
  const [tipoTreino, setTipoTreino] = useState('');



  const { cla, espiral, treino } = useParams();
  const navigate = useNavigate();



  useEffect(() => {
    carregarTitulos();
    if (treino) {
      carregarInfo();
    }
    //eslint-disable-next-line
  }, []);




  async function carregarTitulos() {
    doIt(async () => {

      const nomeCla = await apiConta.buscarClaPorId(cla);
      if (!nomeCla) throw new Error('Clã não encontrado')
      setNomeCla(nomeCla?.info?.nome);

      const nomeEspiral = await apiConta.buscarEspiralPorId(cla, espiral);
      if (!nomeEspiral) throw new Error('Espiral não encontrada');
      setNomeEspiral(nomeEspiral?.nome);
    })
  }




  async function carregarInfo() {
    doIt(async () => {
      const ids = await api.listarTreinosEPerguntas(cla, espiral, treino);

      setPerguntas(ids.perguntas);
      setNomeTreino(ids.session.nome)
      setTipoTreino(ids.session.tipo)
    })
  }




  async function handleSalvarNovoTreino() {
    doIt(async () => {
      const r = await api.cadastrarTreino(
        cla,
        espiral,
        tipoTreino,
        nomeTreino
      );
      toast.success('Novo treino adicionado com sucesso!');
      navigate(`/admin/treinoteorico/cla/${cla}/espiral/${espiral}/treino/${r.insertedId}/alterar`)
    })
  }




  async function handleEditarTreino() {
    doIt(async () => {
      await api.editarTreino(cla, espiral, treino, tipoTreino, nomeTreino);
      toast.success('Treino editado com sucesso!');
    })
  }




  async function handleAlterarOrdem(perguntaId, ordem) {
    doIt(async () => {
      await api.alterarOrdemPerguntas(cla, espiral, treino, perguntaId, ordem);
      const ids = await api.listarTreinosEPerguntas(cla, espiral, treino);
      setPerguntas(ids.perguntas);
    },
    {
      supressError: true
    })
  }




  async function deletar(perguntaId) {
    confirmar({
      titulo: 'Remover pergunta',
      descricao: 'Tem certeza que deseja remover essa pergunta?',
      onSim: _ =>
        doIt(async () => {
          await api.deletarPergunta(cla, espiral, treino, perguntaId);

          const perguntaEncontrada = await api.listarTreinosEPerguntas(cla, espiral, treino);
          setPerguntas(perguntaEncontrada.perguntas);
          toast.warn('Pergunta removida');
        })
    })

  }




  
  return (
    <PaginaAdmin
      className='treino-teorico-perguntas-lista'
      carregando={nomeCla === '' && perguntas === undefined}
      menu={menu}
    >

      <div className='perguntas-lista'>
        <div className='conteudo-perguntas-lista'>

          
          <div className='titulos-cla-espiral'>
            <TituloAdmin2 onClick={() => navigate(`/admin/treinoteorico/cla/${cla}/espiral`)}>
              {nomeCla}
            </TituloAdmin2>
            <TituloAdmin1 onClick={() => navigate(`/admin/treinoteorico/cla/${cla}/espiral/${espiral}/treinos`)}>
              {nomeEspiral}
            </TituloAdmin1>
          </div>


          <div className='treino-titulo-inputs'>
            <TituloAdmin3>Treino</TituloAdmin3>
            <div className='agrupamento-inputs-treino'>
              <InputComLabel
                value={nomeTreino}
                mode={'light'}
                placeholder='Nome'
                onChange={e => setNomeTreino(e.target.value)} />

              <Select mode={"light"} placeholder='Tipo treino' onChange={e => setTipoTreino(e.target.value)} value={tipoTreino}>
                <option value='' hidden disabled>Selecione</option>
                <option disabled={treino} value='leitura'>Leitura</option>
                <option disabled={treino} value='quiz'>Quiz</option>
              </Select>
            </div>
          </div>


          <div className='lista-perguntas'>

            <div className='titulo-container-perguntas'>
              <TituloAdmin3>Lista de Perguntas</TituloAdmin3>
              {treino &&
                <img src='/assets/images/icones/plus.svg' alt='' onClick={() => navigate(`/admin/treinoteorico/cla/${cla}/espiral/${espiral}/treino/${treino}/pergunta/novo`)} />
              }
            </div>

            <div className='perguntas-dnd'>
              <ListaOrdenacao
                items={perguntas}
                descricao={item => item.info.titulo}
                ordem={item => item.info.ordem}
                campoId={item => item.info.id}
                trocarOrdem={(item, ordem) => item.info.ordem = ordem}
                onOrdemAlterada={(item, novaOrdem) => handleAlterarOrdem(item.id, novaOrdem)}
                onClick={item => navigate(`/admin/treinoteorico/cla/${cla}/espiral/${espiral}/treino/${treino}/pergunta/${item.info.id}/alterar`)}
                onDeletar={item => deletar(item.info.id)}
              />
            </div>

          </div>


          <div className='container-botao-salvar'>
            <BotaoRedondo background={"#3973BA"} padding={"11px 30px"} onClick={treino ? handleEditarTreino : handleSalvarNovoTreino}>
              Salvar
            </BotaoRedondo>

          </div>


        </div>
      </div>
    </PaginaAdmin>
  )
}