import DragList from './dragList/index.js';
import DropItem from './dropItem/index.js';

// icones
import AlterarIcon from './icons/alterar.js';
import ClickIcon from './icons/click.js';
import DeletarIcon from './icons/deletar.js';


export function ListaOrdenacao(props) {
  return (
    <DragList {...props}>
      {(item) => (
        <DropItem
          theme='white'
          bg='#FFFFFF'
          border='1px solid #ACACAC'
          color={props.color}
          icon='#535353'
          item={item}
          descricao={props.descricao}
        >
          {props.onAlterar && (
            <AlterarIcon className='alterar-icone' style={{cursor: 'pointer'}} onClick={_ => props.onAlterar(item)} />
          )}
          {props.onDeletar && (
            <DeletarIcon className='remover-icone' style={{cursor: 'pointer'}} onClick={_ => props.onDeletar(item)} />
          )}
          {props.onClick && (
            <ClickIcon className='selecionar-icone' style={{cursor: 'pointer'}} onClick={_ => props.onClick(item)} />
          )}
        </DropItem>
      )}
    </DragList>
  )
}



export function ListaOrdenacaoBlack(props) {
  return (
    <DragList {...props} theme='black'>
      {(item, index) => (
        <DropItem
          color='#E1E1E1'
          strokeColor='#E1E1E1'
          theme='black'
          item={item}
          descricao={props.descricao}
        >
          {props.onAlterar && (
            <AlterarIcon style={{cursor: 'pointer'}} onClick={_ => props.onAlterar(item, index)} />
          )}
          {props.onDeletar && (
            <DeletarIcon style={{cursor: 'pointer'}} onClick={_ => props.onDeletar(item, index)} />
          )}
          {props.onClick && (
            <ClickIcon style={{cursor: 'pointer'}} onClick={_ => props.onClick(item, index)} />
          )}
        </DropItem>
      )}
    </DragList>
  )
}