import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, #18131B 0%, #342538 51.56%, #18161A 100%);
    padding: 4em 0em;

    .agrupamento-titulo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;

        .estrela {
          margin-left: 5em;
        }

        .titulo-metodo {
          font-family: 'Mukta-bold';
          font-size: 3.5em;
        }
    }


    .agrupamento-componentes {
        width: 50%;

        .pai-primeiro-componente-metodo > div {
            margin-bottom: 0em;
        } 

        .componente-metodo {
            display: flex;
            flex-direction: row;
            animation: fadeInRight;
            animation-duration: 1.3s;
            margin-top: 1em; 
            
            .imagem-metodo-01 > img {
                margin-right: 2.5em;
                height: 6.5em;
            }

            .imagem-metodo-02 > img {
                margin-right: 3.5em;
                height: 8.5em;
            }

            .textos-metodo {

                .titulo-componente-01 {
                    font-family: Mukta-bold;
                    font-size: 2.3em;
                    color: #FC6681;
                }

                .titulo-componente-02 {
                    font-family: Mukta-bold;
                    font-size: 2.3em;
                    color: #C36CED;
                }

                .descricao-componente {
                    font-family: Mukta-light;
                    width: 22em;
                }
              
            }
        }
    }

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 3em;
      padding-bottom: 8em;

      .agrupamento-titulo {
          width: 100%;
          margin: 4em 0em;

          .estrela {
             display: none;
          }

          .titulo-metodo{
            font-size: 1.8em;
          }
      }

      .agrupamento-componentes {
          width: 100%;

          .componente-metodo-responsivo{
              margin-top: 2em;
          }
          
          .componente-metodo {
              display: flex;
              flex-direction: column;
              justify-content: center;

              .imagem-metodo-01 {
                  display: flex;
                  justify-content: center;
                  margin-bottom: 2em;

                  img {
                    margin-right: 0em;
                  }
              }

              .imagem-metodo-02 {
                  display: flex;
                  justify-content: center;
                  margin-bottom: 2em;

                  img {
                    margin-right: 0em;
                }
              }

              .textos-metodo {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .titulo-componente-01 {
                      font-size: 1.8em;
                      text-align: center;
                  }

                  .titulo-componente-02 {
                    font-size: 1.8em;
                    text-align: center;
                  }

                  .descricao-componente {
                    text-align: center;
                    min-width: 25%;
                  }
              }

         }
      }
    }
`

export { Container }