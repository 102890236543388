import React, { useState } from 'react'
import doIt from '../../../../../servicos/doIt';
import { toast } from 'react-toastify';
import confetti from 'canvas-confetti';
import './index.scss';


import TextoContainer from '../../../comps/TextoContainer';
import Botao from '../../../../../components/styled/botoes/redondo/index.styled';
import StyledInput from '../../../../../components/novoComentario/index.styled';
import MenuTreinoTeorico from '../../../../../components/menuTreinoTeorico';


import * as servicosTeoricosUsuario from '../../../../../api/usuario/treinoTeoricoAPI';
import { useEffect } from 'react';






export default function Resultado({ claId, espiralId, treinoId, acertou, setPergunta, pergunta, setTela, setDados }) {
  const [comentario, setComentario] = useState('');



  useEffect(()=>{
    doIt(async ()=> {
      if(acertou)
        confetti({particleCount: 100, spread: 70, origin: { y: 0.6 }});

    }, {time: 1})
    //eslint-disable-next-line
  },[]);




  async function verificarEnter(e) {
    doIt(async () => {
      if (e.key === 'Enter') {
        await servicosTeoricosUsuario.enviarComentario(claId, espiralId, treinoId, pergunta.info.id, comentario);
        setComentario('');
        toast.dark('Comentário enviado!', {autoClose: 1000});
      }
    }, {time: 1});
  }
  


  async function proximaPergunta() {
    doIt(async () => {
      const r = await servicosTeoricosUsuario.gerenciarAvancar(claId, espiralId, treinoId, {acao: 'proximo'});
      setPergunta(r.conteudo);
      
      if(r.acao === 'proximo')
        setTela('analisando');
      else
        setTela('finalizado');
        setDados({acertos: r.conteudo.acertos, perguntas: r.conteudo.perguntas, passou: r.conteudo.passou, tipo: 'quiz'});
    }, {time: 1});
  }
  


  return (
    <main className='tela-resultado'>
      <MenuTreinoTeorico />
      <div className='container'>

        <div className={`${acertou ? '' : 'shake'}`}>
          <StyledInput value={comentario} onKeyDown={(e) => verificarEnter(e)} onChange={(e)=>setComentario(e.target.value)} placeholder='Digite seu comentário aqui' tamanho='300px'/>
          
          
          {acertou === true && 
            <>
              <TextoContainer
                titulo={'Parabéns!!'}
                descricao={'Você acertou!! Vamos para a próxima'}
              />
              <Botao onClick={() => proximaPergunta()} largura='120px' altura='40px' padding='10px 15px'> Continuar </Botao>
            </>
          }

          {acertou === false && 
            <>
              {pergunta.assets.correcao && 
                <>
                  <TextoContainer
                    titulo={'Ops!!'}
                    descricao={'Confira a correção para tirar suas dúvidas.'}
                  />
                  <Botao onClick={() => setTela('correcao')} largura='120px' altura='40px' padding='10px 15px'> Correção </Botao>
                </>
              }
              
              {!pergunta.assets.correcao && 
                <>
                <TextoContainer
                  titulo={'Ops!!!'}
                  descricao={'Está tudo bem, se concentra e vai!'}
                />
                <Botao onClick={() => proximaPergunta()} largura='120px' altura='40px' padding='10px 15px'> Continuar </Botao>
              </>
              }
            </>
          }
        </div>
        
        {pergunta.assets.correcao && 
        <div className={`${acertou ? '' : 'shake'}`}>
          {acertou === true && 
            <>
              <p> Ou você pode </p>
              <Botao onClick={() => setTela('correcao')} background='#373737' color='#B7BB0A' padding='10px 15px'> Ver correção </Botao>
            </>
          }
          
          {acertou === false && 
            <>
              <p> Ou você pode </p>
              <Botao onClick={() => proximaPergunta()} background='#373737' color='#B7BB0A' padding='10px 15px'> Continuar </Botao>
            </>
          }
        </div>
        }

      </div>
    </main>
  )
}
