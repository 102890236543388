import styled from "styled-components"

const DEFAULT_ALIGN_ITEMS = "center";
const DEFAULT_WIDTH = 'auto';
const DEFAULT_HEIGHT = '52px';
const DEFAULT_PADDING = '0 .8rem 0 .8rem';

const DEFAULT_DARK_MODE = '#303030';
const DEFAULT_LIGHT_MODE = '#FFFFFF';

const DEFAULT_TEXT_LIGHT = '#444242';
const DEFAULT_TEXT_DARK = '#FFFFFF';

const DEFAULT_BORDER_LIGHT = '#ACACAC';
const DEFAULT_BORDER_DARK = '#464646';

const DEFAULT_PLACEHOLDER_DARK = '#BBBBBB';
const DEFAULT_PLACEHOLDER_LIGHT = '#828282';

const Input = styled.input`
    display: flex;
    flex-direction: row;
    align-items: ${props => props.alignItems ? props.alignItems : DEFAULT_ALIGN_ITEMS};

    color: ${props => props.mode === 'light' ? DEFAULT_TEXT_LIGHT : DEFAULT_TEXT_DARK};

    width: ${props => props.width ? props.width : DEFAULT_WIDTH};
    height: ${props => props.height ? props.height : DEFAULT_HEIGHT};
    padding: ${props => props.padding ? props.padding : DEFAULT_PADDING};
    
    background: ${props => props.mode === 'light' ? DEFAULT_LIGHT_MODE : DEFAULT_DARK_MODE};
    border: 1px solid ${props => props.mode === 'light' ? DEFAULT_BORDER_LIGHT : DEFAULT_BORDER_DARK};
    border-radius: ${props => props.radius ?? "8px"};

    font-size: ${props => props.fontSize ?? '16px'};
    font-weight: ${props => props.fontWeight ?? '700'};
    outline: none;

    ::placeholder {
        color: ${props => props.mode === 'light' ? DEFAULT_PLACEHOLDER_LIGHT : DEFAULT_PLACEHOLDER_DARK};
        font-weight: 700;
        font-size: 12px;
    }
`

export default Input;

