import Field from './index.styled.js';

function TextAreaComLabel(props) {
  return (
    <Field mode={props.mode} width={props.width} height={props.height} value={props.value}>
      <textarea {...props} onClick={undefined} placeholder={''} required />
      <label>{props.placeholder}</label>
    </Field>
  )
}

export default TextAreaComLabel;