import styled from 'styled-components';

//const DEFAULT_WIDTH = 'auto';
//const DEFAULT_HEIGHT = '44px';

const DEFAULT_DARK_MODE = '#242424';
const DEFAULT_LIGHT_MODE = '#FFFFFF';

const DEFAULT_TEXT_LIGHT = '#444242';
const DEFAULT_TEXT_DARK = '#C0C0C0';

const DEFAULT_BORDER_LIGHT = '#828282';
const DEFAULT_BORDER_DARK = '#3F3F3F';

const DEFAULT_PLACEHOLDER_DARK = '#BBBBBB';
const DEFAULT_PLACEHOLDER_LIGHT = '#828282';

const DEFAULT_ACTIVE_DARK = '#787878';
const DEFAULT_ACTIVE_LIGHT = '';

const Field = styled.div`
  position: relative;

  label {
    position: absolute; 
    top: 50%;
    left: .9rem;

    transform: translateY(-50%);

    pointer-events: none;

    color: ${props => props.mode === 'light' ? DEFAULT_PLACEHOLDER_LIGHT : DEFAULT_PLACEHOLDER_DARK};
    font-weight: 600;
    font-size: 14px;
    
    transition: .25s ease-in-out;
  }

  select {
    display: flex;
    flex-direction: row;
    align-items: center;
    appearance: none;
    position: relative;
    outline: none;
    
    padding: 12px 16px;

    width: 100%;

    color: ${props => props.mode === 'light' ? DEFAULT_TEXT_LIGHT : DEFAULT_TEXT_DARK};
    background: ${props => props.mode === 'light' ? DEFAULT_LIGHT_MODE : DEFAULT_DARK_MODE};
    border: 2px solid ${props => props.mode === 'light' ? DEFAULT_BORDER_LIGHT : DEFAULT_BORDER_DARK};
    
    font-size: 16px;
    font-weight: 600;
    border-radius: 2px;
  }

  option {
    border-radius: 0px;
  }

  img {
    position: absolute;
    top: 27px;
    transform: translateY(-50%);
    right: 1rem;
    height: 12px;
    width: auto;
    pointer-events: none;
  }
  
  select:focus ~ label, select:valid ~ label{
    font-size: 12px;
    top: 1rem;
    color: ${props => props.mode === 'light' ? DEFAULT_ACTIVE_LIGHT : DEFAULT_ACTIVE_DARK};
  }


 

`

export default Field;