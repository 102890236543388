import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    gap: 20px;

    & > img {
        padding: 10px;
        user-select: none;
        -webkit-user-drag: none;
        cursor: pointer;
    }

    & > div {
        padding: 0px 20px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        gap: 20px;
        overflow-x: scroll;
        overflow-y: visible;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }
    }


    @media (max-width: 1024px) {
        & > img {
            display: ${props => props.manterCarrossel ?  'block' :  'none' };
            align-self: center;            
        }
        & > div {
            justify-content: ${props => props.manterCarrossel ?  'flex-start' :  'center' };
            flex-wrap: ${props => props.manterCarrossel ?  'unset' :  'wrap' };;
            overflow-x: ${props => props.manterCarrossel ?  'scroll' :  'auto' };
            scroll-snap-type: x mandatory;
            gap: 50px
        }

    }


`