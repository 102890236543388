import styled from "styled-components";


export const BurgerContainer = styled.div`

  display: none;

  @media (max-width: ${p => p.mediaMaxWidth ?? '1024px'}) {
    display: flex;
    position: absolute;
    top: 20px;
    right: 25px;
    z-index: 12;
  }
`



export const MenuBurgerIcon = styled.div`
  width: 26px;
  height: 20px;
  position: relative;
  z-index: 11;

  margin: 10px auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  user-select: none;


  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #8b8b8b;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }



  span:nth-child(1) {
    top: 0px;
    transform-origin: left center;
  }

  span:nth-child(2) {
    top: 8px;
    transform-origin: left center;
  }

  span:nth-child(3) {
    top: 16px;
    transform-origin: left center;
  }

  &.open span:nth-child(1) {
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
  }

  &.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg); 
    top: 15px;
    left: 8px;
  }


`