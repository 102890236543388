import * as React from 'react';
import * as Dnd from 'react-dnd';
import { Item } from './index.styled.js';

export default function Card(props) {
  const ref = React.useRef();

  const [{ isDragging }, dragRef] = Dnd.useDrag({
    item: {
      id: props.dataid,
      index: props.index,
      ordem: props.ordem(props.item)
    },
    type: 'CARD',
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    async end(item) {
      if (props.onChange)
        props.onChange(item, props.ordem(props.item));
    }
  });

  const [, dropRef] = Dnd.useDrop({
    accept: 'CARD', 
    async hover(item, monitor) {
      const draggedId = item.id;
      const draggedIndex = item.index;
      const draggedOrdem = item.ordem;

      const targetIndex = props.index;
      const targetOrdem = props.ordem(props.item);

      if (draggedIndex === targetIndex)
        return;
      
      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = (targetSize.bottom - targetSize.top) / 2;

      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;

      if (draggedIndex < targetIndex && draggedTop < targetCenter)
        return;
      
      if (draggedIndex > targetIndex && draggedTop > targetCenter)
        return;
      
      await props.move(
        draggedId,
        draggedOrdem,
        targetOrdem,
        props.items,
        props.setItems,
        props.updated,
        props.setUpdated,
        props.campoId,
        props.ordem,
        props.trocarOrdem
      );

      item.index = targetIndex; 
    }
  })

  dragRef(dropRef(ref));

  return (
    <Item {...props} ref={ref} isDragging={isDragging} onChange={() => {}} />
  )
}
