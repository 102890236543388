import doIt from '../../../../../servicos/doIt';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import './index.scss'


import BotaoStyled from '../../../../../components/styled/botoes/redondo/index.styled';
import TextoContainer from '../../../comps/TextoContainer';
import MenuTreinoTeorico from '../../../../../components/menuTreinoTeorico';
import PainelLeitura from '../../../../../components/painelLeitura';
import FullScreenImage from '../../../../../components/fullScreenImage';


import * as servicosTeoricosUsuario from '../../../../../api/usuario/treinoTeoricoAPI.js';





export default function Lendo({ pergunta, vizualizacoes, tempo, setTempo, setVizualizacoes, setTela}) {
  const [painelDirOpen, setPainelDirOpen] = useState(false);
  const [painelEsqOpen, setPainelEsqOpen] = useState(false);
  const [mostrarPainelDir] = useState(pergunta.leitura.painelDireita !== '')
  const [mostrarPainelEsq] = useState(pergunta.leitura.painelEsquerda!== '')
  const [url, setUrl] = useState('');
  const [imagemEmTelaCheia, setImagemEmTelaCheia] = useState(false); 
  const [podeContinuar, setPodeContinuar] = useState(true);
  
  const {cla, espiral, treino} = useParams();
  
  
  
  useEffect(() => {
    async function mostrarImagem() {
      doIt(async () => {
        let x = await servicosTeoricosUsuario.pegarImagemPergunta(pergunta.assets.pergunta);
        setUrl(x);
      }, {time: 1});
    }
    
    setTimeout(()=> setTempo(tempo + 1), 1000);
    
    mostrarImagem();
    //eslint-disable-next-line
  },[tempo]);



  function abrirFecharEcontarViews(contador, taAberto, setViews, setOpen, dir) {
    if(dir === 'esquerda')
      setViews({...vizualizacoes, painelEsquerdaViews: taAberto ? contador += 0 : contador += 1});
    else
      setViews({...vizualizacoes, painelDireitaViews: taAberto ? contador += 0 : contador += 1});
    
    setOpen(!taAberto);
  }

  


  async function continuar() {
    setPodeContinuar(false);

    doIt(async () => {
     if(podeContinuar) {
       await servicosTeoricosUsuario.gerenciarAvancar(cla, espiral, treino, { 
        acao: 'resposta',
        conteudo: {
          tempo: tempo,
          comentarios: [],
          viewsPainelDireita: vizualizacoes.painelDireitaViews,
          viewsPainelEsquerda: vizualizacoes.painelEsquerdaViews
        }
      })
      
      setTempo(0);
      setVizualizacoes({ painelDireitaViews: 0, painelEsquerdaViews: 0 });
      
      
      setTela('resultado')
     }
    }, {time: 1});
  }



  return (
    <main className='tela-lendo'>
        <MenuTreinoTeorico />
        
        {imagemEmTelaCheia && 
          <FullScreenImage 
            url={url}
            isOpen={setImagemEmTelaCheia}
          />
        }

        {mostrarPainelEsq &&
          <PainelLeitura
            dir='esquerda'
            src='/assets/images/icones/setinhaAbrirPainelEsquerdo.svg'
            open={painelEsqOpen} 
            texto={pergunta.leitura.painelEsquerda}
            onClick={() => abrirFecharEcontarViews(vizualizacoes.painelEsquerdaViews, painelEsqOpen, setVizualizacoes, setPainelEsqOpen, 'esquerda')}
          />
        }

        {mostrarPainelDir &&
          <PainelLeitura
            dir='direita'
            src='/assets/images/icones/setinhaAbrirPainelDireito.svg'
            open={painelDirOpen} 
            texto={pergunta.leitura.painelDireita}
            onClick={() => abrirFecharEcontarViews(vizualizacoes.painelDireitaViews, painelDirOpen, setVizualizacoes, setPainelDirOpen, 'direita')}
          />
        }
        

        <div className='container'>
          <div className='content'>
            <div className='descricao'>
              <TextoContainer 
                titulo={pergunta.info.titulo}
                descricao={pergunta.info.descricao}
                altura='150px'
                alinhamento={'left'}
              />  
              <div className='botoes'>
                <BotaoStyled onClick={continuar} background='#B89B19' color='#FFF' padding='10px 15px'> Continuar </BotaoStyled>
                
              </div>
            </div>


            <div className='imagem'>
              <img onClick={()=>setImagemEmTelaCheia(true)} src={url} alt="" />
            </div>

          </div>
        </div>
    </main>
  )
}
