export const PlayIcone = (props) => (
  <svg {...props} viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.0013 43.75C33.5072 43.75 42.8346 34.236 42.8346 22.5C42.8346 10.7639 33.5072 1.25 22.0013 1.25C10.4954 1.25 1.16797 10.7639 1.16797 22.5C1.16797 34.236 10.4954 43.75 22.0013 43.75Z" stroke="#B7B7B7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path className="path" d="M17.832 14L30.332 22.5L17.832 31V14Z" fill="#B7B7B7" />
  </svg>
)

export const PauseIcone = (props) => (
  <svg {...props} viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="path" d="M20.6363 16H17V28H20.6363V16Z" fill="#B7B7B7" />
    <path className="path" d="M26.9996 16H23.3633V28H26.9996V16Z" fill="#B7B7B7" />
    <path d="M21.8333 43.5C33.3393 43.5 42.6667 33.986 42.6667 22.25C42.6667 10.5139 33.3393 1 21.8333 1C10.3274 1 1 10.5139 1 22.25C1 33.986 10.3274 43.5 21.8333 43.5Z" stroke="#B7B7B7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const AumentarIcone = (props) => (
  <svg {...props} viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 1H2.25C1.55964 1 1 1.3731 1 1.83333V7.66667C1 8.1269 1.55964 8.5 2.25 8.5H11C11.6904 8.5 12.25 8.1269 12.25 7.66667V1.83333C12.25 1.3731 11.6904 1 11 1Z" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const DiminuirIcone = (props) => (
  <svg {...props} viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7778 1H2.22222C1.54721 1 1 1.39797 1 1.88889V8.11111C1 8.60203 1.54721 9 2.22222 9H10.7778C11.4528 9 12 8.60203 12 8.11111V1.88889C12 1.39797 11.4528 1 10.7778 1Z" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const VolumeIcone = (props) => (
  <svg {...props} viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.625 1.04395L3.5 3.54395H1V7.29395H3.5L6.625 9.79395V1.04395Z" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.6672 1C12.8389 2.17205 13.4971 3.76147 13.4971 5.41875C13.4971 7.07603 12.8389 8.66545 11.6672 9.8375M9.46094 3.20625C10.0468 3.79227 10.3759 4.58699 10.3759 5.41563C10.3759 6.24426 10.0468 7.03898 9.46094 7.625" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const MudoIcone = (props) => (
  <svg {...props} viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.63 1L3.50222 3.57143H1V7.42857H3.50222L6.63 10V1Z" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.4664 3.52441L9.46094 7.31299" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.46094 3.52441L13.4664 7.31299" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)

export const TempoIcone = (props) => (
  <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.54688 2.81836V6.22745L8.27415 7.36381" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)