import styled from 'styled-components';
import fromBack from '../../../../components/animations/fromBack';





const SApp = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  min-height: 100vh;
  
  background: linear-gradient(270deg, #282828 55.21%, #3A3A3A 100%);
  font-family: 'Poppins';
`;




export const Box = styled.div`
  ${fromBack}

  display: flex;
  flex-direction: column;

  align-items: center;
  gap: .5rem;

  position: relative;
  height: fit-content;
  width: 20rem;
  
  @media (min-width: 1920px) {
    top: -100px;
  }

  h1 {
    font-size: 32px;
    color: #FFF;
    margin-bottom: .4rem;
  }

  .logo {
    width: 8rem;
    height: auto;
    margin-bottom: 1.4rem;
  }
 
  input, button {
    width: 100%;
  }

  .outros {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;

    a {
      font-weight: 700;
      font-size: 12px;
      color: #FFFFFF;
    }
  }
`;




export const Estrelinha = styled.img`
  position: absolute;
  width: 1.4rem;
  height: auto;

  top: 43%;
  right: 0;
`;




export default SApp;