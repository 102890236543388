import styled from 'styled-components'

const  StyledRespostaUsuario = styled.div`
  overflow: hidden;
  position: relative;

  display: ${props => props.carregando ? 'none' : 'block'};
  animation: ${props => props.carregando ? 'unset' : 'entrada 1s both'};


  @keyframes entrada {
    from { 
      opacity: 0;
      bottom: -50px;
    }
    to {
      opacity: 1;
      bottom: 0px; 
    }
  }

 


 

  
  .cabecalho {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 2.4em;
    padding-bottom: 2.4em;
  }

  

  .logo {
    height: 100px;
    width: auto;
  }

  .informacoes-treino {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  .interacao-user {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 4em;
    height: calc(100vh - 237px);
  }


  .informacoes-treino-user {
    display: flex;
    flex-direction: column;
    width: 45%;
    
  }

  .solucao-treino {
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  
  .descricao-treino {
    display: flex;
    flex-direction: row;

    font-family: Poppins;
    font-size: .9em;
    font-weight: 700;
    color: #696969;

    div { 
      margin: .2em;
      margin-top: 0em;
    }

    div:nth-child(odd) {
      cursor: pointer;
    }
  }

  .nome-usuario {
    padding-top: .7em;
  }
  

  .row {
    display: flex;
    flex-direction: row; 
  }
  
  .padding-top {
    position: absolute;
    height: 2.4em;
    z-index: 1;
    top: 0em;
  }

 
  

  .row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  .row-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  

  .titulo-treino {
    font-family: Poppins;
    font-size: 1.2em;
    font-weight: 800;
    color: #FFFFFF;
    margin-bottom: 1em;
    margin-right: 1em;
    max-width: 70%;
    text-align: left;
    
  }

 

  .desc-box-info {
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px;
    color: #C0C0C0;
    padding: 1em;
  }

  .vscode {
    padding-top: 1.7em;
    flex-grow: 1;
    height: 40.1vh; 
    border: 2px solid #3F3F3F;
    box-shadow: 0px 0px 18px 1px rgba(160, 160, 160, 0.1);
    background-color: #242424 !important;
    position: relative;
  }


  .pdf {
    flex-grow: 1;
    overflow: auto;
  }


  .boxScroll {
    flex-grow: 1;
    padding: 0em;
    overflow: auto;
    padding: 1em;
    white-space: pre-line;
  }



  .boxScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #383838 !important;
  }

  .boxScroll::-webkit-scrollbar-track {
    background-color: #202020;
  }

  .icon {
    padding: 0px 2px;
    margin: .4em 8px;
  }

  .selecionado { 
    border-radius: 3px;
    border-bottom: 3px solid #c5d138;
  }

  .setas {
    padding: 0em 2em;
    cursor: pointer;
  }


  .image {
    cursor: pointer;
  }

  .arquivo {
    border-radius: 28px;
    color: #C0C0C0;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    padding: 0em 1em;
    background-color: #4B4B4B;
    border: none;
    margin: 1em .5em;
    cursor: pointer;
    outline: none;
  }


  .selecionadoB {
    background-color: #867D4A !important;
  }




  @media(max-width: 1024px) {


  .interacao-user {
    display: flex;
    flex-direction: column;
    margin-top: 4em;
    height: auto;
    align-items: center;
  }

  .informacoes-treino-user {
    width: 90%;
    margin: 1em;
    margin-top: 2em;
  }

  .boxScroll {
    height: 20em;
  }

  .solucao-treino {
    width: 90%;
    margin: 1em;
  }

  .pdf {
    height: 80vh;
  }


  } 

  @media(max-width: 500px) {
    
    .wrap {
      width: 136px;
      flex-wrap: wrap;
    }
  }





`


export { StyledRespostaUsuario }
