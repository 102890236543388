import React, { useState } from 'react'
import doIt from '../../../servicos/doIt';
import { toast } from 'react-toastify';

import { FundoContainer, BoxCointainer } from './index.styled'
import { TituloAdmin3 } from '../../styled/textos/titulosAdmin'
import Input from '../../styled/inputs/simples/index.styled'
import BotaoRedondo from '../../styled/botoes/redondo/index.styled'


import * as servicosAcompanhamento from '../../../api/admin/acompanhamentoAPI.js'


export default function AprovarTodos(props) {
  const [acaoPraRealizar, setAcaoPraRealizar] = useState({ situacao: "aprovado", comentario: '' });


  async function checkEnter(e) {
    doIt(async () => {
      if (e.key === 'Enter') {
        aprovarConcluidos()
        props.setModalEntregaAberto(false);
      }
      
      else if(e.key === 'Escape') {
        props.setModalEntregaAberto(false);
      }
    }, {time: 1});
  }
  

  async function aprovarConcluidos() {
    doIt(async () => {
      props.setModalEntregaAberto(false);
      for(let topico of props.espiralSelecionada.topicosEntregas) {
        if(topico.situacao === 'concluído') {
          await servicosAcompanhamento.aprovarEntregaEspiral(props.aluno.claId, props.aluno.alunoId, props.espiralSelecionada.espiralId, topico.topicoId, acaoPraRealizar);
        }
      }
      props.reRenderInfo();
      toast.success(`Entregas do ${props.aluno.nome} aprovadas`);
    }, {time: 1});
  }

  
  return (
    <FundoContainer>
      <BoxCointainer>
        <div>
          <TituloAdmin3 color='#3A73B9'>{ props.titulo }</TituloAdmin3>
          <p>Informe um comentário interno sobre sua ação na entrega.</p>
        </div>
        
        <div className='input-modal'>
          <Input 
            mode='light' 
            width='100%' 
            height='39px' 
            fontSize='15px' 
            fontWeight='500'
            placeholder='Comentário'
            autoFocus
            value={acaoPraRealizar.comentario}

            onChange={(e) => setAcaoPraRealizar({...acaoPraRealizar, comentario: e.target.value})}
            onKeyDown={(e) => checkEnter(e)}
          />
        </div>
        
        
        <div>
          <BotaoRedondo onClick={() => props.setModalEntregaAberto(false)} background='#3A73B9'> Fechar </BotaoRedondo>
          <BotaoRedondo onClick={() => aprovarConcluidos()}background='#3A73B9'> Concluir </BotaoRedondo>
        </div>

      </BoxCointainer>
    </FundoContainer>
  )
}