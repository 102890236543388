import styled from "styled-components";
import fromLeft from "../../../animations/fromLeft";



function color(props) {
  if (props.selecionado) return '#DDCC11';
  return '#D9D9D9';
}

function fundo(props) {
  if (props.selecionado && props.tipo === 'flutuante') return '#252323';
  else if (props.selecionado && props.tipo === 'lateral') return '#373333';
  return 'inherit';
}

function largura(props) {
  if (props.tipo === 'lateral') return '100%';
  return '256px';
}

function borda(props) {
  if (props.tipo === 'lateral') return '0px';
  return '8px';
}


export const MenuItemContainer = styled.div`
  ${p => p.animar ? fromLeft : ''}

  width: ${largura};
  height: 64px;
  border-radius: ${borda};

  font-size: 14px;
  font-weight: 500;
  background-color: ${fundo};

  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0px 0px 25px;

  
  cursor: pointer;

  & > div {
    margin-left: 25px;
    color: ${color};
    position: relative;
    z-index: 0;
  }

  & > svg path {
    stroke: ${color};
  }


  &::before {
    transform: scaleX(0);
    transform-origin: bottom right;
  }

  &:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &::before {
    content: " ";
    display: block;
    position: absolute;

    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;

    z-index: 1;
    background: rgba(00, 00, 00, 15%);
    transition: transform .3s ease-in;
  }

  ${props => props.selecionado && (`
    ::after {
      content: " ";
      display: block;
      position: absolute;
      border-radius: inherit;

      top: 0; 
      right: 0; 
      bottom: 0; 
      left: 0;

      background-color: #2C2C2C;
      z-index: -2;
    }

    svg path {
      stroke: #DDCC11;
    }
  `)}




  @media (max-width: 1024px) {
    justify-content: center;
    background-color: #2a2a2a;
    border-radius: 8px !important; 
    width: 96%;

    display: ${p => p.open ? 'flex' : 'none'};

    & > div {
      width: 300px;
    }
  }

  
`


