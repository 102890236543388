import React, { useEffect, useState } from 'react'
import doIt from '../../../servicos/doIt';

import { FundoContainer, BoxContainer } from './index.styled';
import { TituloAdmin2, TituloAdmin3 } from '../../styled/textos/titulosAdmin';
import { TextContainer, MenuOpcoes } from '../../modalTextContainer/index.styled';
import { ComentarioContainer } from '../../comentarioContainer/index.styled';
import { MensagemAudio } from '../../mensagemAudio';
import CaixaDeComentario from '../../comentarioContainer';
import BotaoRedondo from '../../styled/botoes/redondo/index.styled';


export default function ModalDesempenhoTreinoTeorico(props) {
  const [comentariosAbertos, setComentariosAbertos] = useState(false);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState('resumo');
  const [conteudoSelecionado, setConteudoSelecionado] = useState(0);
  const [desempenho, setDesempenho] = useState({});


  function abrirComentario(pos) {
    doIt(async () => {
      setComentariosAbertos(true)
      setConteudoSelecionado(pos)
    }, {time: 1});
  }




  useEffect(() => {
    props.carregarInformacoesDesempenho(setDesempenho);
    // eslint-disable-next-line
  },[]);


  
  return (
    <FundoContainer>
      <BoxContainer>
        <div className='titulos'>
          <TituloAdmin3 color='#3973BA'> Análise de desempenho </TituloAdmin3>
          <TituloAdmin2> {props.nome} </TituloAdmin2>
        </div>
        
        <MenuOpcoes selecionado={opcaoSelecionada}>
          <span onClick={() => setOpcaoSelecionada('resumo')}> {comentariosAbertos ? '' : 'Resumo'} </span>
          <span onClick={() => setOpcaoSelecionada('detalhes')}> {comentariosAbertos ? '' : 'Detalhes'} </span>
        </MenuOpcoes>

        {opcaoSelecionada === 'resumo' && 
          <TextContainer
            padding='0px'
            gap='10px'
          >
            {comentariosAbertos === false && 
              <>
                <div className='item-resumo'> <span>Total de Treinos: </span> <span> {desempenho?.resumo?.totalTreinosConcluidos ?? '' } de {desempenho?.resumo?.totalTreinos ?? ''}  </span> </div>
                <div className='item-resumo'> <span>Perguntas respondidas:</span> <span> {desempenho?.resumo?.totalPerguntas}  </span> </div>
                <div className='item-resumo'> <span>Acertos:</span> <span> {desempenho?.resumo?.totalAcertos}  </span> </div>
                <div className='item-resumo'> <span>Erros:</span> <span> {desempenho?.resumo?.totalErros}  </span> </div>
                <div className='item-resumo'> <span>Comentários:</span> <span> { desempenho?.detalhes?.reduce((acc, cur) => acc + cur?.comentarios?.length ,0) } <img src="/assets/images/icones/comentario.svg" alt="" /></span> </div>
              </>
            }
          </TextContainer>
        }


        {opcaoSelecionada === 'detalhes' && 
          <TextContainer
            gap='10px'
          >
            {comentariosAbertos === true && 
              <>
                <img className='setinha-voltar' onClick={() => setComentariosAbertos(false)} src="/assets/images/icones/seta_voltar_comentario.svg" alt="" />
                <CaixaDeComentario arr={desempenho?.detalhes[conteudoSelecionado]?.comentarios} />
              </>
            }
            
              {comentariosAbertos === false &&
                <ComentarioContainer padding='0px'>
                  {desempenho.detalhes?.sort((a, b) => a.ordem - b.ordem)?.map((item, pos) => {
                    let data;
                    let mes;
                    let horas;
                    let minutos;
                    if(item.dataConclusao){
                      data = new Date(item.dataConclusao).toString().substring(8, 10)
                      mes = new Date(item.dataConclusao).toString().substring(4, 7)
                      horas = new Date(item.dataConclusao).toString().substring(16, 18);
                      minutos = new Date(item.dataConclusao).toString().substring(19, 21);
                    }
                    
                    return (
                      <>
                        <div className='item-detalhe'> 
                          <MensagemAudio canal={props.canalFeedbackId}/>
                          <div>{pos+1}. {item.nome}</div>
                          <div>{item.dataConclusao ? `${item.tipo.toUpperCase()}` : '-'}</div>
                          <div>{item.dataConclusao ? item.tipo === 'quiz' ? `${item.acertos} DE ${item.acertos + item.erros}` : `${item.tempo}S` : '-'}</div>
                          <div>{item.dataConclusao ? `${data}/${mes} ás ${horas}h${minutos}` : '-'}</div>
                          <div onClick={() => abrirComentario(pos) }><img src="/assets/images/icones/comentario.svg" alt="" /> {item.comentarios.length > 10 ? item.comentarios.length : `${item.comentarios.length === 0 ? '0' : `0${item.comentarios.length}`}`} </div>
                        </div>
                      </>
                    );
                  })}
                </ComentarioContainer>
              }
          </TextContainer>
        } 



        <div className='botao-container'>
          <BotaoRedondo onClick={() => props.setModalTreinoTeorico(false)} background='#3A73B9'> Fechar </BotaoRedondo>
        </div>
      </BoxContainer>      
    </FundoContainer>
  )
}
