import styled from 'styled-components';


export const Container = styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2px;
  user-select: none;
  
  .icone_gravador {
    width: 26px;
    height: 26px;
    -webkit-user-drag: none;
    cursor: pointer;
    padding: 2px;

    &:disabled {
    cursor: not-allowed !important;
    }
  }
`;
