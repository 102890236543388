import styled from "styled-components";


const StyledBotaoAnexo = styled.div `


  button {
    padding: .5em 1em .5em 1em;
    background-color: #373737;
    color: #B7BB0A;
    font-family: Poppins;
    font-size: .6em;
    font-weight: 800;
    border:  1px solid #555624;
    border-radius: 20.9189px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  img {
    padding-right: 0.4em;
  }



`


export { StyledBotaoAnexo }