import styled from 'styled-components';

export const InputsContainer = styled.div`
	background: #fff;
	border: 1px solid #acacac;
	border-radius: 8px;
	width: 100%;
	padding: 30px 30px 30px;
	display: flex;
	flex-flow: row nowrap;
	gap: 30px;

	& > div:first-child {
		width: 20%;
		min-width: fit-content;
		display: flex;
		flex-flow: column nowrap;
		gap: ${props => props.gap ?? ''};

		& > h2 {
			font-size: 14px;
			text-align: right;
			height: ${props => props.lineSize ?? '23px'};
			user-select: none;
		}
	}

	& > div:last-child {
		width: 100%;
		display: flex;
		flex-flow: column nowrap;
		gap: ${props => props.gap ?? ''};

		& > input,
		& > textarea,
		& > p {
			width: 100%;
			outline: none;
			border: none;
			border: 0 0 1px 0 solid #000;
			font-size: 14px;
			text-decoration: underline 1px solid;
			height: ${props => props.lineSize ?? '23px'};
			resize: none;

			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-thumb {
				background: #b0b0b0;
				border-radius: 4px;
				cursor: default;
			}

			&::-webkit-scrollbar-thumb:hover {
				background: #a0a0a0;
			}
		}

    & > textarea {
      height: 45px !important;
    }

		& > input {
			height: ${props => props.lineSize ?? '23px'};
		}
	}
`;
