import React from 'react'

// Compenents
import { Container, Titulo, Descricao, Barra} from './index.styled';


export default function TextoContainer(props) {
  return (
    <Container alinhamento={props.alinhamento} largura={props.largura}>
      <Titulo fontWeight='600' fontSize='26px'>
        {props.titulo}
      </Titulo>
      
      <Barra></Barra>
      
      <Descricao altura={props.altura}>
        {props.descricao}
      </Descricao>
    
    </Container>
  )
}
