import styled from "styled-components";


const Container = styled.div`
  background: linear-gradient(180deg, #272331 0%,  #181718 100%);  
  overflow-x: hidden;
  color: #E0E0E0;

  @keyframes hidden-out {
    from {
      right: 4em;
    } 
    
    to {
      right: -4em;
    }
  }

  @keyframes back-to-top-in {
    from {
      right: -4em;
    } 
    
    to {
      right: 4em;
    }
  }

  .back-to-top {
    display: block;
    align-items: center;
    justify-content: center;

    position: fixed;
    bottom: 2em;
    right: 4em;

    animation: back-to-top-in;
    animation-duration: 1s;

    height: 3em;
    width: 3em;
    z-index: 100;

    border-radius: 50%;
    border: 2px solid #DA940C;
    background: #18161A;
    cursor: pointer;
  }

  .hidden {
    display: block;
    align-items: center;
    justify-content: center;

    position: fixed;
    bottom: 2em;
    right: -4em;

    animation: hidden-out;
    animation-duration: 1s;

    height: 3em;
    width: 3em;
    z-index: 100;

    border-radius: 50%;
    border: 2px solid #DA940C;
    background: #18161A;
  }


  .back-to-top::after {
    content: "▲";
    color: #DA940C;
  }

  .hidden::after {
    content: "▲";
    color: #DA940C;
  }

  @media (max-width: 1024px) {

    @keyframes hidden-out {
      from {
        right: 2em;
      } 
      
      to {
        right: -4em;
      }
    }

    @keyframes back-to-top-in {
      from {
        right: -4em;
      } 
      
      to {
        right: 2em;
      }
    }

    .back-to-top {
      right: 2em;
    }
  }
`

export { Container }