import React, { useState } from 'react'


import { FundoContainer, BoxCointainer } from './index.styled'
import { TituloAdmin3 } from '../../styled/textos/titulosAdmin'
import BotaoRedondo from '../../styled/botoes/redondo/index.styled'





export default function ModalComentario(props) {
  const [selecionado, setSelecionado] = useState('professor');
  
  
  
  return (
    <FundoContainer>
        <BoxCointainer>
          <div>
            <TituloAdmin3 color='#3A73B9'>Comentários</TituloAdmin3>
            <p>Veja os comentários da entrega.</p>
          </div>

          <div className='info-container'>
            <div className='menu'>
              <span onClick={() => setSelecionado('professor')} className={selecionado === 'professor' ? 'selecionado' : '' }>Professor</span>
              
              {props.identificador !== 'forum' && <span onClick={() => setSelecionado('aluno')} className={selecionado === 'aluno' ? 'selecionado' : '' }>Aluno</span> }
            </div>
            
            <div className='container-comentario'>
              
              {selecionado === 'professor' && 
                props.comentarioProf?.map(item => {
                  return(
                    <div className='comentario'>
                      <div className='data'>
                        <span>{ item.criacao?.substring(0,10).replaceAll('-', '/') }</span>
                        <span>{ item.criacao?.substring(11,16).replaceAll(':', 'h') }</span>
                      </div>
                      <div className='text'>{item.descricao}</div>
                    </div>
                  );
                })
              }

              {selecionado === 'aluno' && 
                props.comentarioAluno?.map(item => {
                  return(
                    <div className='comentario'>
                      <div className='data'>
                        <span>{ item.criacao?.substring(0,10).replaceAll('-', '/') }</span>
                        <span>{ item.criacao?.substring(11,16).replaceAll(':', 'h') }</span>
                      </div>
                      <div className='text'>{item.descricao}</div>
                    </div>
                  );
                })
              }
              
            </div>
          </div>

          <div>
            <BotaoRedondo onClick={() => props.setModalComentario(false)} background='#3A73B9'> Fechar </BotaoRedondo>
          </div>
        </BoxCointainer>
    </FundoContainer>
  )
}