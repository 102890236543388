import React from 'react'


import { ComentarioContainer } from './index.styled'


export default function CaixaDeComentario(props) {
  
  
  return (
    <ComentarioContainer>
     {
       props.arr?.map(item => {
        return(
          <>
            <div className='comentario'>
              <div className='text'> - {item}</div>
            </div>
          </>
        );
      })
     }
    </ComentarioContainer>
  )
}
