import StyledDropItem from './index.styled.js';

// icones
import DragIcon from '../icons/drag.js';
import DragBlackIcon from '../icons/dragBlack.js';

export default function DropItem(props) {
  return (
    <StyledDropItem {...props} className="drop-item">
      <div className='content'>
        {props.theme === 'black' && (
          <DragBlackIcon style={{cursor: 'grab'}} />
        )}
        {props.theme === 'white' && (
          <DragIcon style={{cursor: 'grab'}} />
        )}
        <abbr title={props.descricao(props.item)}>
          {props.descricao(props.item)}
        </abbr>
      </div>
      <div className='children'>
        {props.children}
      </div>
    </StyledDropItem>
  )
}