import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import doIt from '../../../../servicos/doIt';
import './index.scss';


import CardEspiral from '../../../../components/cardEspiral'
import PaginaAdmin from '../../../../components/paginas/paginaAdmin';
import { Carrossel } from '../../../../components/carrossel';
import { TituloAdmin1 } from '../../../../components/styled/textos/titulosAdmin';
import { TituloAdmin3 } from '../../../../components/styled/textos/titulosAdmin';
import { menu } from '../menu'


import * as api from '../../../../api/admin/contaAPI';



export default function TreinoTeoricoAdmin_Espirais() {

  const [espirais, setEspirais] = useState()
  const [cla, setCla] = useState();

  const { cla: claId } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    async function espiraisList() {
      doIt(async () => {
        const claEncontrado = await api.buscarClaPorId(claId);
        setCla(claEncontrado?.info?.nome);

        const resp = await api.listarEspiraisDeCla(claId);
        setEspirais(resp);
      })
    }

    espiraisList();
    //eslint-disable-next-line
  }, []);




  return (
    <PaginaAdmin
      className='treinoteorico-espiral-admin'
      carregando={espirais === undefined}
      menu={menu}
    >

      <TituloAdmin1 onClick={() => navigate(`/admin/treinoteorico/cla`)}>
        {cla}
      </TituloAdmin1>

      <TituloAdmin3>
        Espirais
      </TituloAdmin3>

      <section className='espirais'>
        <Carrossel>
          {espirais?.map((item) => (
            <CardEspiral
              key={item.id}
              rota='/admin/treinoteorico/cla/:cla/espiral/:espiral/treinos'
              titulo={item.nome}
              descricao={item.descricao}
              parametros={[claId, item.id]}
              botaoFundo='#3973BA'
            />
          ))}
        </Carrossel>
      </section>

    </PaginaAdmin>
  )

}