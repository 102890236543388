import styled from "styled-components";


const Container = styled.div`
  width: ${props => props.largura ?? '300px'};
  max-width: 100% ;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.alinhamento ?? 'center'};
  text-align: ${props => props.alinhamento ?? 'center'};

  & > div:nth-child(3) {
    margin-top:15px;
  }


  @media(max-width: 720px) {
    align-items: center;
    text-align: center;
  }

`;

const Titulo = styled.div`

  width: ${props => props.largura ?? 'auto'};
  height: ${props => props.altura ?? 'auto'};

  font-size: ${props => props.fontSize ?? '16px'};
  font-weight:${props => props.fontWeight ?? '400'};
  color: ${props => props.color ?? '#FFF'};

  line-height: 29px;
  /* white-space: nowrap;
  text-overflow: clip;
  overflow: scroll; */
  
  &:hover {
    
    
  }

  ::-webkit-scrollbar {
        width: 9px;
        height: 9px;
        border-radius: 999px;
  }

  ::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
  }
  
  ::-webkit-scrollbar-thumb {
    background: #414141; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555555; 
  }

  @media(max-width: 720px) {
    white-space: normal;
    align-items: center;
    text-align: center;
  }

  @media (max-height: 520px){
    font-size: 25px;
  }

`;

const Descricao = styled.div`

  width: auto;
  height: ${props => props.altura ?? 'auto'};

  font-size: 18px;
  font-weight: 500;
  
  color: #FFF;

  overflow-y: scroll;

  ::-webkit-scrollbar {
        width: 9px;
        height: 9px;
        border-radius: 999px;
  }

  ::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
  }
  
  ::-webkit-scrollbar-thumb {
    background: #414141; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555555; 
  }

  @media (max-height: 520px){
    font-size: 18px;
  }


`;


const Barra = styled.div`
  width: ${props => props.largura ?? '100%'};
  height: 6px;
  
  background-color: #B7BB0A;
  border-radius: 5px;
  margin-top: 10px;
`;


export {
  Container,
  Titulo,
  Descricao,
  Barra

}