import storage from 'local-storage'
import { useState } from 'react';
import { toast } from 'react-toastify';
import { matchPath, useLocation, useNavigate, useParams } from "react-router-dom";

import { ContaIcone, SairIcone } from "./comps/icones";
import { Container, FlutuanteContainer, Wrapper } from "./styled";

import MenuItem from "./comps/item";
import MenuBurger from './burger';
import Logo from './comps/logo';





export function Menu({ config, acesso, tipo, className, mediaMaxWidth }) {
  const [open, setOpen] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();


  function ir(url) {
    for (let campo in params) {
      url = url.replace(new RegExp(`:${campo}`, 'im'), params[campo]);
    }
    setOpen(false);
    navigate(url);
  }


  function contaURL() {
    if (acesso === 'admin') return '/admin/conta/clas';
    else return '/conta';
  }

  function loginURL() {
    if (acesso === 'admin') return '/admin/conta/login';
    else return '/conta/login';
  }



  function desconectar() {
    try {
      storage.clear();
      setTimeout(() => ir(loginURL()), 500)
    } catch (err) {
      toast(err.message);
    }
  }


  function selecionado(item) {
    return (location.pathname === item.url && config.podeSelecionar === true)
      || item.sempreSelecionado;
  }


  function animar() {
    return config.animarURLs.some(x => matchPath(x, location.pathname));
  }



  return (
    <Container tipo={tipo} className={className} mediaMaxWidth={mediaMaxWidth}>

      <Wrapper tipo={tipo}>
        <MenuBurger open={open} setOpen={setOpen} mediaMaxWidth={mediaMaxWidth} />
        <Logo tipo={tipo} open={open} mediaMaxWidth={mediaMaxWidth} />

        <FlutuanteContainer
          className={tipo}
          open={open}
          tipo={acesso}
          mediaMaxWidth={mediaMaxWidth}
        >
          <div>
            {config.items.map((item, idx) =>
              <MenuItem
                key={idx}
                nome={item.nome}
                action={() => ir(item.url)}
                icone={item.icone}
                tipo={tipo}
                index={idx + 1}
                open={open}
                selecionado={selecionado(item)}
                animar={animar()}
              />
            )}
          </div>
          <div>
            {!config.esconderConta &&
              <MenuItem
                tipo={tipo}
                nome='Conta'
                action={() => ir(contaURL())}
                icone={<ContaIcone />}
                index={config.items.length + 1}
                open={open}
                animar={animar()}
              />
            }
            {!config.esconderConta}
            <MenuItem
              tipo={tipo}
              nome='Sair'
              action={() => desconectar()}
              icone={<SairIcone />}
              index={config.items.length + 2}
              open={open}
              animar={animar()}
            />
          </div>
        </FlutuanteContainer>
      </Wrapper>
    </Container>
  )

}