import * as React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';





export default function PdfViewer(props) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin(props);

  return ( 
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
      <Viewer
        theme={{
          theme: 'dark',
        }}
        plugins={[
          defaultLayoutPluginInstance
        ]}
        fileUrl={props.pdf}
      />
    </Worker>
  )
}