import React from "react";
import { Container } from "./index.styled";





export default function BoxDepoimento(props) {
  return(
    <Container className="componente-depoimento">
        <div className="imagem-depoimento"> <img src={props.item.imagem} alt="imagem-depoimento"/> </div>

        <div className="agrupamento-textos">
            <div className="imagem-aspas aspas"> <img src="/assets/images/icones/aspas-depoimento-01.svg" alt="imagem-aspas"/> </div>

            <div className="textos">
                <div className="depoimento"> {props.item.depoimento} </div>

                <div className="info-pessoa">
                    <div> {props.item.nome} </div>
                    <div> {props.item.cargo} <span> na empresa </span> {props.item.empresa} </div> 
                </div>

            </div>

            <div className="imagem-aspas-direita aspas"> <img src="/assets/images/icones/aspas-depoimento-02.svg" alt="imagem-aspas"/> </div>
        </div>  
    </Container>
  )
}