import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BotaoSolido, Container } from './index.styled';


export default function CardEspiral({ atualizacao, titulo, qtdAlunos, descricao, rota, parametros, botaoFundo, botaoCor, cla }) {
	const navigate = useNavigate();

  function irPara() {
    if (!parametros) return;

		for (const item of parametros) {
			rota = rota.replace(/:[a-z]+/im, item);
		}
		navigate(rota, { state: { cla: cla, espiral: titulo } });
	}



	return (
		<Container>
			<h3> {atualizacao} </h3>
      <h1> {titulo} </h1>
      
			<div>
				<p>{descricao}</p>
      </div>
      
      <BotaoSolido
        background={botaoFundo ?? '#3EB72A'}
        padding='5px 23px'
        color={botaoCor ?? '#fff'}
        fontSize='16px'
        onClick={irPara}>
        Entrar
      </BotaoSolido>
		</Container>
  );
  
  
};
