import Input from "../simples/index.styled";
import { Container } from "./index.styled";





export default function InputComLixeira(props) { 
  return(
    <Container width={props.width}> 
      {props.item.map((i, t) =>
        <div className="input" key={t + 1}>
          <div className="input" onClick={() => props.carregarTestes(i, t)}>
            <Input
              mode="light"
              placeholder={`Teste ${t + 1}`}
              readOnly={true}
              width={props.width}
              radius="none" />
            <div className="seta"> <img src="/assets/images/icones/seta_cinza.svg" alt="imagem seta" /> </div>
          </div>
          <div className="lixeira" onClick={() => props.deletarTestes(i)}>
            <img src="/assets/images/icones/lixeira.svg" alt="imagem lixeira" />
          </div>
        </div>
      )}
    </Container>
  )
}
