import { HomeIcone } from "../../../components/menu/comps/icones";


export const menu = {
  podeSelecionar: true,
  items: [
    {
      nome: 'Conteúdo',
      url: '/conteudo/cla',
      icone: <HomeIcone />,
      sempreSelecionado: true
    }
  ],
  animarURLs: [
    '/conteudo/cla'
  ]

}


