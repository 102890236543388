import styled from 'styled-components';



const SApp = styled.div`
  display: flex;
  flex-direction: row;

  position: relative;

  justify-content: start;
  align-items: center;

  width: 100%;
  height: 100%;

  min-height: 100vh;
  
  background: linear-gradient(270deg, #282828 55.21%, #3A3A3A 100%);
  font-family: 'Poppins';

  .menu {
    min-height: 100vh;
    width: 300px;
  }

  .logo {
    position: absolute;
    top: 1rem;
    left: 1rem;

    width: 6rem;
    height: auto;
  }

  @media (max-width: 1024px) {
    .menu {
      min-height: unset;
      width: 100%;
    }
  }

  @media (max-width: 750px) { 
    padding: 8rem 0 4rem;
  }

`;




export const Box = styled.div`
  display: flex; 
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  position: relative;
  animation: entrada 1s both;

  max-width: 50rem;
  width: 100%;

  padding-left: 8rem;

  @media (max-width: 1250px) { 
    padding-left: 0;
    padding: 0 4rem;
  }

  
  @keyframes entrada {
    from { 
      opacity: 0;
      bottom: -50px;
    }
    to {
      opacity: 1;
      bottom: 0px;   
    }
  }
`;




export const Aluno = styled.div`
  display: flex; 
  flex-direction: column;
  width: 100%;
`;




export const CabecalhoAluno = styled.div`
  position: relative;
  display: flex; 
  flex-direction: row;

  align-items: center;

  width: 100%;

  margin-bottom: 2rem;

  p {
    color: #8A8A8A;
    font-size: 18px;
    font-weight: 700;
  }

  h1 {
    color: #D3C314;
    font-size: 28px;
  }

  img {
    border-radius: 999px;
    height: 8rem;
    width: 8rem;
    margin-right: 1rem;
  }

  .avatar {
    position: relative;
    width: fit-content;
    height: fit-content;
  }

  .camera {
    position: absolute;
    bottom: .4rem;
    right: 1rem;

    background-image: url('/assets/images/icones/camera.png');
    background-repeat: no-repeat;
    background-position: 50% 45%;
    background-size: 1rem;
    background-color: #4A4A47;
    border: 3px solid #242424;
    border-radius: 999px;

    width: 2rem;
    height: 2rem;
    cursor: pointer;

    input[type=file]{
      display: none;
    }
  }

  @media (max-width: 750px) { 
    flex-direction: column;
    gap: 2rem;

    div {
      width: 100%;
      text-align: center;
    }

    .camera {
      right: 0em;
    }

    img { 
      margin-right: 0em;
    }
  }
`;




export const Informacoes = styled.div`
  display: flex; 
  flex-direction: row;
  gap: 4rem;

  width: 100%;

  padding-left: 4rem;

  h2 {
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: .6rem;
  }

  div {
    display: flex; 
    flex-direction: column;
    gap: .4rem;
    width: 100%;
  }

  .botao {
    display: flex; 
    flex-direction: row;

    width: 100%;

    margin-top: 2rem;
  }

  @media (max-width: 750px) { 
    padding: 0;
    flex-direction: column-reverse;
    align-items: center;
  }
`;




export default SApp;