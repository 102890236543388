import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    
    grid-area: header;

    user-select: none;
    margin-top: 10px;

    .mensagem {
      font-family: Poppins-semibold;
      color: #888888;
      
    }

    .group-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 98%; 
        padding: 0.7em;
    }

    @media (max-width: ${p => p.mediaMaxWidth ?? '1024px'}) {
      justify-content: flex-start;
      margin-left: 20px;
      margin-top: 20px;
    }
`

export { Container }