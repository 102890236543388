import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import doIt from '../../../../../servicos/doIt';
import './index.scss';


import Botao from '../../../../../components/styled/botoes/redondo/index.styled';
import TextoContainer from '../../../comps/TextoContainer';
import MenuTreinoTeorico from '../../../../../components/menuTreinoTeorico';
import FullScreenImage from '../../../../../components/fullScreenImage';
import { LoadingIcon } from '../../../../../components/loading/fullscreen/icon';


import * as servicosTeoricosUsuario from '../../../../../api/usuario/treinoTeoricoAPI';





export default function Analisando({ pergunta, setTela }) {
  const [timer, setTimer] = useState(pergunta.quiz.tempoAnalise);
  const [url, setUrl] = useState('');
  const [delayToShow, setDelayToShow] = useState(1);
  const [imagemEmTelaCheia, setImagemEmTelaCheia] = useState(false); 
  

  useEffect(()=>{
    async function mostrarImagem() {
      doIt(async () => {
        let url = await servicosTeoricosUsuario.pegarImagemPergunta(pergunta.assets.pergunta);
        setUrl(url);
      }, {time: 1});
    }


    mostrarImagem();
    //eslint-disable-next-line
  },[]);




  useEffect(()=>{
    if(delayToShow > 0)
      setTimeout(() => setDelayToShow(delayToShow - .5), 500);
    //eslint-disable-next-line
  },[delayToShow]);




  useEffect(() => {
    if(pergunta.quiz.tempoAnalise > 0) {
      if(timer > 0 ){
        setTimeout(() => setTimer(timer - 1), 1000)
      }
      else {
        toast.dark('Prepare-se pra responder!', {autoClose: 1000});
        setTimeout(() => setTela('respondendo'), 1500);
      }
    }
    //eslint-disable-next-line
  },[timer]);



  return (
      <main className='tela-analisando'>
        <MenuTreinoTeorico />
        
        {imagemEmTelaCheia &&
          <FullScreenImage 
            url={url}
            isOpen={setImagemEmTelaCheia}
          />
        }

        <div className='container'>
          <div className='descricao'>
            <TextoContainer 
              titulo={pergunta.info.titulo}
              descricao={pergunta.info.descricao}
              largura='500px'
              altura='150px'
              alinhamento='left'
            />
            {pergunta.quiz.tempoAnalise === 0 && 
             <Botao onClick={() => setTela('respondendo')} largura='140px' altura='40px' padding='10px 15px'> Responder </Botao>
            }
            
            {pergunta.quiz.tempoAnalise > 0 && 
              <div className='timer'>{timer}</div>
            }
          </div>

          <div className='imagem'>
            {delayToShow > 0 && <LoadingIcon />}
            {delayToShow <= 0 && <img onClick={() => setImagemEmTelaCheia(true)} src={url} alt='imagem-pergunta' />}
          </div>
        </div>
      </main>
  )
}