import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { menu } from '../menu';
import './index.scss';



import PaginaAdmin from '../../../../../components/paginas/paginaAdmin';
import { CardMembroRedondo } from '../../../../../components/cardMembroRedondo';
import { Modal } from '../../../../../components/modal';
import { TituloAdmin1, TituloAdmin2, TituloAdmin3 } from '../../../../../components/styled/textos/titulosAdmin';
import BotaoRedondo from '../../../../../components/styled/botoes/redondo/index.styled';
import TextAreaComLabel from '../../../../../components/styled/textArea/comLabel';
import InputComLabel from '../../../../../components/styled/inputs/comLabel';
import Select from '../../../../../components/styled/select';
import { InputsContainer } from '../../../comps/inputsContainer';
import { MensagemAudio } from '../../../../../components/mensagemAudio';



import * as acompanhamentoApi from '../../../../../api/admin/acompanhamentoAPI';
import { buscarImagem } from '../../../../../servicos/buscarImagem';
import doIt from '../../../../../servicos/doIt';







export default function Index() {
  const [cla, setCla] = useState();
  const [espiralDados, setEspiralDados] = useState();
  const [membros, setMembros] = useState([]);
  const [canalSelecionado, setCanalSelecionado] = useState();
  const [tituloMensagem, setTituloMensagem] = useState('');
  const [descricaoMensagem, setDescricaoMensagem] = useState('');
  const [carregando, setCarregando] = useState(false);

  
  const [modal, setModal] = useState(false);
  const [catSelecionada, setCatSelecionada] = useState(1);
  
  // Espiral
  const [tituloEspiral, setTituloEspiral] = useState('');
  const [descricaoEspiral, setDescricaoEspiral] = useState('');
  //eslint-disable-next-line
  const [iconeEspiral, setIconeEspiral] = useState(''); const [imagemEspiral, setImagemEspiral] = useState('');
  
  // Tópicos
  const [conceitos, setConceitos] = useState('');
  const [conteudo, setConteudo] = useState('');
  const [treinoTeorico, setTreinoTeorico] = useState('');
  const [treinoPratico, setTreinoPratico] = useState('');
  const [anotacoesConceitos, setAnotacoesConceitos] = useState('');
  const [anotacoesLeitura, setAnotacoesLeitura] = useState('');
  const [linkLeituras, setLinkLeituras] = useState('');
  const [forums, setForums] = useState('');

  // Ajuda
  const [tituloAjuda, setTituloAjuda] = useState('');
  const [descricaoAjuda, setDescricaoAjuda] = useState('');
  //eslint-disable-next-line
  const [iconeAjuda, setIconeAjuda] = useState(''); const [imagemAjuda, setImagemAjuda] = useState('');
  

  const { cla: claId, espiral: espiralId } = useParams();
  const navigate = useNavigate();



  useEffect(_ => {
    carregarCla();
    carregarEspiral();
    carregarMembros();

    //eslint-disable-next-line
  }, []);



  function gerarDataFormatada(criacao) {
    if (!criacao || !criacao.trim()) return 'Nunca';
    criacao = new Date(criacao).toLocaleString('pt-BR');
    return criacao.substring(0, 4) + criacao.substring(11, 15);
  }



  function carregarCla() {
    doIt(async _ => {
      const claEncontrado = await acompanhamentoApi.buscarCla(claId);
      setCla(claEncontrado);
    });
  }
  


  function carregarEspiral() {
		doIt(
			async _ => {
				const espiralEncontrada = await acompanhamentoApi.buscarEspiral(claId, espiralId);

				if (!espiralEncontrada.servidor)
					toast.warning('Essa espiral não foi sincronizada');

				setEspiralDados(espiralEncontrada);

				const { espiral, ajuda } = espiralEncontrada?.mensagem;

				setTituloEspiral(espiral?.titulo);
				setDescricaoEspiral(espiral?.descricao);
				setIconeEspiral(espiral?.iconeUrl);
				setImagemEspiral(espiral?.imagemEspiral);
				setConceitos(espiral?.topicos?.conceitos.join(' • '));
				setConteudo(espiral?.topicos?.conteudo);
				setTreinoTeorico(espiral?.topicos?.treinoTeorico);
				setTreinoPratico(espiral?.topicos?.treinoPratico);
				setAnotacoesConceitos(espiral?.topicos?.anotacoesConceitos);
				setAnotacoesLeitura(espiral?.topicos?.anotacoesLeitura?.descricao);
				setLinkLeituras(espiral?.topicos?.anotacoesLeitura?.links?.join('\n'));
				setForums(espiral?.topicos?.forums?.join('\n'));

				setTituloAjuda(ajuda?.titulo);
				setDescricaoAjuda(ajuda?.descricao);
				setIconeAjuda(ajuda?.iconeUrl);
				setImagemAjuda(ajuda?.imagemUrl);
			},
			{ suppressError: true }
		);
  }
  


  function carregarMembros() {
    doIt(async _ => {
      const membrosEncontrados = await acompanhamentoApi.listarMembrosEmEspiral(claId, espiralId);
      setMembros(membrosEncontrados);
    });
  }


  function enviarMensagem() {
    doIt(async _ => {
			if (!canalSelecionado) {
				toast.warn('Selecione um canal');
				return;
			}
			setCarregando(true);

			await acompanhamentoApi.enviarMensagemDiscord(
				canalSelecionado,
				tituloMensagem,
				descricaoMensagem
			);
			setCarregando(false);

			setTituloMensagem('');
			setDescricaoMensagem('');
			toast.success('Mensagem enviada com sucesso!');
		});
  }



  function handleSalvarEspiral() {
		const espiral = {
			titulo: tituloEspiral,
			descricao: descricaoEspiral,
		};

		const topicos = {
			conceitos: conceitos.split(' • '),
			conteudo,
			treinoPratico,
			treinoTeorico,
			anotacoesConceitos,
			anotacoesLeitura: {
				descricao: anotacoesLeitura,
				links: linkLeituras.split('\n'),
			},
			forums: forums.split('\n'),
		};

		const ajuda = {
			titulo: tituloAjuda,
			descricao: descricaoAjuda,
		};

    doIt(async _ => {
			await acompanhamentoApi.editarDiscordEspiral(
				claId,
				espiralId,
				espiral,
				topicos,
				ajuda
      );
      
      carregarEspiral();

      toast.success('Espiral editada com sucesso!');
		});
  }





  return (
		<PaginaAdmin
			menu={menu}
			carregando={cla === undefined}
			className='acompanhamento-aluno-admin'>
			<Modal ativo={modal} setAtivo={setModal} className='modal'>
				<TituloAdmin1>{espiralDados?.nome}</TituloAdmin1>
				<nav>
					<span
						className={catSelecionada === 1 ? 'selecionado' : ''}
						onClick={_ => setCatSelecionada(1)}>
						Básico
					</span>
					<span
						className={catSelecionada === 2 ? 'selecionado' : ''}
						onClick={_ => setCatSelecionada(2)}>
						Conteúdo
					</span>
					<span
						className={catSelecionada === 3 ? 'selecionado' : ''}
						onClick={_ => setCatSelecionada(3)}>
						Servidor
					</span>
				</nav>

				<article className={catSelecionada === 1 ? 'cat1' : ''}>
					<InputsContainer>
						<div>
							<h2>Id:</h2>
							<h2>Nome:</h2>
							<h2>Ordem:</h2>
							<h2>Descrição:</h2>
						</div>

						<div>
							<p>{espiralDados?.espiralId}</p>
							<p>{espiralDados?.nome}</p>
							<p>{espiralDados?.ordem}</p>
							<p style={{ height: '60px', overflow: 'auto' }}>
								{espiralDados?.descricao}
							</p>
						</div>
					</InputsContainer>
				</article>

				<article className={catSelecionada === 2 ? 'cat2' : ''}>
					<h3>Espiral</h3>
					<InputsContainer className='i1'>
						<div>
							<h2>Título:</h2>
							<h2>Descrição:</h2>
						</div>
						<div>
							<input
								type='text'
								placeholder='Seja bem-vindo!'
								value={tituloEspiral}
								onChange={e => setTituloEspiral(e.target.value)}
							/>
							<input
								type='text'
								placeholder='Está pronto para avançar nos conceitos de programação?  Então #SangueNosOioo! Lembre-se que...'
								value={descricaoEspiral}
								onChange={e => setDescricaoEspiral(e.target.value)}
							/>
						</div>
					</InputsContainer>

					<h3>Tópicos</h3>
					<InputsContainer lineSize='47px' gap='20px'>
						<div>
							<h2>Conceitos:</h2>
							<h2>Conteúdo:</h2>
							<h2>Treino Teórico:</h2>
							<h2>Treino Prático:</h2>
							<h2>Anotações Conteúdo:</h2>
							<h2>Anotações Leituras:</h2>
							<h2>Link Leituras:</h2>
							<h2>Fóruns:</h2>
						</div>
						<div>
							<textarea
								rows='2'
								placeholder='• item1 • item2 • item3 • item4 • item1 • item2 • item3 • item4 • item5 • item2 • item3 • item4'
								value={conceitos}
								onChange={e => setConceitos(e.target.value)}
							/>
							<textarea
								rows='2'
								placeholder='Alguma descrição bem top sobre esse assunto. Alguma descrição bem top sobre esse assunto'
								value={conteudo}
								onChange={e => setConteudo(e.target.value)}
							/>
							<textarea
								rows='2'
								placeholder='Alguma descrição bem top sobre esse assunto. Alguma descrição bem top sobre esse assunto'
								value={treinoTeorico}
								onChange={e => setTreinoTeorico(e.target.value)}
							/>
							<textarea
								rows='2'
								placeholder='Alguma descrição bem top sobre esse assunto. Alguma descrição bem top sobre esse assunto'
								value={treinoPratico}
								onChange={e => setTreinoPratico(e.target.value)}
							/>
							<textarea
								rows='2'
								placeholder='Alguma descrição bem top sobre esse assunto. Alguma descrição bem top sobre esse assunto'
								value={anotacoesConceitos}
								onChange={e => setAnotacoesConceitos(e.target.value)}
							/>
							<textarea
								rows='2'
								placeholder='Alguma descrição bem top sobre esse assunto. Alguma descrição bem top sobre esse assunto'
								value={anotacoesLeitura}
								onChange={e => setAnotacoesLeitura(e.target.value)}
							/>
							<textarea
								rows='2'
								placeholder={
									'http://linkdaleitura01.com.br\nhttp://linkdaleitura01.com.br'
								}
								value={linkLeituras}
								onChange={e => setLinkLeituras(e.target.value)}
							/>
							<textarea
								rows='2'
								placeholder={
									'Descriç!ao da pergunta 01 do fórum\nDescriç!ao da pergunta 02 do fórum'
								}
								value={forums}
								onChange={e => setForums(e.target.value)}
							/>
						</div>
					</InputsContainer>

					<h3>Ajuda</h3>
					<InputsContainer className='i3'>
						<div>
							<h2>Título:</h2>
							<h2>Descrição:</h2>
						</div>

						<div>
							<input
								type='text'
								placeholder='Seja bem-vindo!'
								value={tituloAjuda}
								onChange={e => setTituloAjuda(e.target.value)}
							/>
							<textarea
								rows='2'
								placeholder='Está pronto para avançar nos conceitos de programação?  Então #SangueNosOioo! Lembre-se que...'
								value={descricaoAjuda}
								onChange={e => setDescricaoAjuda(e.target.value)}
							/>
						</div>
					</InputsContainer>
					<BotaoRedondo
						padding='5px 30px'
						background='#3A73B9'
						disabled={carregando}
						onClick={handleSalvarEspiral}>
						Salvar
					</BotaoRedondo>
				</article>

				<article className={catSelecionada === 3 ? 'cat3' : ''}>
					<div>
						<h3>Canal Espiral</h3>
						<InputsContainer>
							<div>
								<h2>Id:</h2>
								<h2>Criação:</h2>
							</div>
							<div>
								<p>{espiralDados?.servidor?.canalEspiral?.id}</p>
								<p>
									{gerarDataFormatada(
										espiralDados?.servidor?.canalEspiral?.ultAtualizacao
									)}
								</p>
							</div>
						</InputsContainer>

						<h3>Canal Ajuda</h3>
						<InputsContainer>
							<div>
								<h2>Id:</h2>
								<h2>Criação:</h2>
							</div>
							<div>
								<p>{espiralDados?.servidor?.canalAjuda?.id}</p>
								<p>
									{gerarDataFormatada(espiralDados?.servidor?.canalAjuda?.ultAtualizacao)}
								</p>
							</div>
						</InputsContainer>
					</div>

					<div>
						<h3>Tópicos Espiral</h3>
						<InputsContainer>
							<div>
								<h2>Conteúdo:</h2>
								<h2>Treino Teórico:</h2>
								<h2>Treino Prático:</h2>
								<h2>Anotações Conteúdo:</h2>
								<h2>Anotações Leituras:</h2>
								<h2>Fórum:</h2>
							</div>
							<div>
								<p>
									{
										espiralDados?.servidor?.canalEspiral?.topicos?.find(
											i => i.identificador === 'conteudoVideos'
										)?.topicoId
									}
								</p>
								<p>
									{
										espiralDados?.servidor?.canalEspiral?.topicos?.find(
											i => i.identificador === 'treinoTeorico'
										)?.topicoId
									}
								</p>
								<p>
									{
										espiralDados?.servidor?.canalEspiral?.topicos?.find(
											i => i.identificador === 'treinoPratico'
										)?.topicoId
									}
								</p>
								<p>
									{
										espiralDados?.servidor?.canalEspiral?.topicos?.find(
											i => i.identificador === 'anotacoesConceitos'
										)?.topicoId
									}
								</p>
								<p>
									{
										espiralDados?.servidor?.canalEspiral?.topicos?.find(
											i => i.identificador === 'anotacoesLeitura'
										)?.topicoId
									}
								</p>
								{espiralDados?.servidor?.canalEspiral?.topicos
									?.filter(i => i.identificador === 'forum')
									?.map(i => (
										<p key={i.topicoId}>{i.topicoId}</p>
									))}
							</div>
						</InputsContainer>
					</div>
				</article>
			</Modal>

			<main>
				<TituloAdmin2
					color='#3973BA'
					onClick={_ => navigate('/admin/acompanhamento/cla')}>
					{cla?.info?.nome}
				</TituloAdmin2>
				<TituloAdmin1 onClick={_ => setModal(true)}>
					<span>{espiralDados?.nome}</span>
					<img
						src='/assets/images/icones/alterar.svg'
						alt=''
						className='titulo-alterar-icone'
					/>
				</TituloAdmin1>

				<section className='membros'>
					<TituloAdmin3>Membros ({membros?.length})</TituloAdmin3>
					<div>
						{membros?.map((item, index) => (
							<CardMembroRedondo
								key={index}
								title={item.nome}
								onClick={_ =>
									navigate(`/admin/acompanhamento/cla/${claId}/aluno/${item.alunoId}`)
								}
								src={buscarImagem(item.imagem, '/assets/images/avatar.jpg')}
							/>
						))}
					</div>
				</section>

				<section className='mensagem'>
					<div className='titulo'>
						<h2>Enviar Mensagem</h2>
						<MensagemAudio canal={canalSelecionado} />
					</div>

					<div className='inputs'>
						<Select
							mode='light'
							defaultValue='default'
							placeholder='Canal'
							onChange={e => setCanalSelecionado(e.target.value)}
							disabled={carregando}>
							<option value='default' disabled hidden>
								Selecione
							</option>

							{espiralDados?.servidor?.canalEspiral?.topicos?.map(item => (
								<option value={item.topicoId} key={item.topicoId}>
									{item?.nome}
								</option>
							))}
						</Select>

						<InputComLabel
							mode='light'
							placeholder='Titulo'
							value={tituloMensagem}
							onChange={e => setTituloMensagem(e.target.value)}
							autoFocus={true}
							onKeyDown={e => e.key === 'Enter' && enviarMensagem()}
							disabled={carregando}
						/>
						<TextAreaComLabel
							mode='light'
							placeholder='Descrição'
							value={descricaoMensagem}
							onChange={e => setDescricaoMensagem(e.target.value)}
							onKeyDown={e => e.key === 'Enter' && e.ctrlKey && enviarMensagem()}
							disabled={carregando}
						/>
					</div>
				</section>
			</main>
		</PaginaAdmin>
	)
}
