import styled from 'styled-components';


export const BotaoRotina = styled.img`
  user-select: none;
  -webkit-user-drag: none;
  transition: .2s;
  opacity: ${p => p.isVisible ? '1' : '0'};
  transform: ${p => p.isVisible ? 'scale(1)' : 'scale(0.5)'};
  cursor: ${p => p.isVisible ? 'pointer' : 'default'};
`;
