import React, { useState } from 'react'
import doIt from '../../servicos/doIt';


import { ContainerEntrega } from './index.styled';
import CartaoUsuario from './cartaoUsuario';
import CartaoAprovacao from './cartaoAprovacao';


import * as servicosAcompanhamento from '../../api/admin/acompanhamentoAPI.js'




export default function CompCartaoEntrega(props) {
  const [aluno, setAluno] = useState(props?.aluno);

  async function reRenderInfo() {
    doIt(async () => {
      const [r] = await servicosAcompanhamento.listarCartoesEntregaAluno(props.aluno.claId, props.aluno.alunoId);
      setAluno(r);
    
    }, {time: 1});
  }



  return (
    <ContainerEntrega>
      {props?.mostarUsuario === true &&
        <CartaoUsuario 
          canalFeedbackId={aluno?.canalFeedbackId}
          nome={aluno?.nome}
          image={aluno?.imagem}
          ultimoFeedback={aluno?.ultimoFeedback}
        />
      }

      <CartaoAprovacao 
        reRenderInfo={reRenderInfo}
        aluno={aluno}
        espirais={aluno?.espirais}
      />
    
    </ContainerEntrega>
  )
}