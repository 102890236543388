import * as React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import storage from 'local-storage';
import { API_ADDRESS } from '../../../../api/apiConfig.js';
import * as api from '../../../../api/admin/contaAPI.js';
import doIt from '../../../../servicos/doIt.js';





import SApp, {
  Informacoes,
  CabecalhoAdmin,
  Box,
  GerenciarCla,
  Espirais,
  InformacoesBasicas,
  ListagemClas,
  Sistemas,
  CampoCla,
  CampoEspiral, 
  IconeCla,
  Navegacao,
  Overflow
} from './index.styled.js';


import InputComLabel from '../../../../components/styled/inputs/comLabel/index.js';
import Checkbox from '../../../../components/styled/inputs/checkbox/index.js';
import TextAreaComLabel from '../../../../components/styled/textArea/comLabel/index.js';
import Button from '../../../../components/styled/botoes/arredondado/index.styled.js';
import CheckboxComPadding from '../../../../components/styled/inputs/checkboxComPadding/index.js';
import TabelaGerenciarClas from '../../../../components/tabelas/gerenciarClas/index.js';
import LoadingFullscreen from '../../../../components/loading/fullscreen/index.js';
import { ListaOrdenacaoBlack } from '../../../../components/listaOrdenacao/index.js';
import { Menu as MenuAdmin } from '../../../../components/menu/index.js';
import { menu } from '../menu.js';
import confirmar from '../../../../components/confirm/index.js';




function App() {
  const [nome, setNome] = React.useState('');
  const [imagem, setImagem] = React.useState('');

  const [selecionado, setSelecionado] = React.useState('informacoes');

  const [editarCla, setEditarCla] = React.useState(false);
  const [editarEspiral, setEditarEspiral] = React.useState(false);

  const [espirais, setEspirais] = React.useState([]);
  const [clas, setClas] = React.useState([]);

  const [novaImagem, setNovaImagem] = React.useState();

  const [sistemas, setSistemas] = React.useState([{ id: '', nome: '' }]);
  const [sistemasSelecionados, setSistemasSelecionados] = React.useState([]);

  const [claId, setClaId] = React.useState('');
  
  const [claImagem, setClaImagem] = React.useState();
  const [claLinkImagem, setClaLinkImagem] = React.useState('');

  const [claNome, setClaNome] = React.useState('');
  const [claCor, setClaCor] = React.useState('');
  const [claDescricao, setClaDescricao] = React.useState('');
  const [claAtivo, setClaAtivo] = React.useState(false);

  const [espiralId, setEspiralId] = React.useState('');
  const [espiralNome, setEspiralNome] = React.useState('');
  const [espiralDescricao, setEspiralDescricao] = React.useState('');
  const [espiralAtivo, setEspiralAtivo] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [loadingTudo, setLoadingTudo] = React.useState(true);

  const [updated, setUpdated] = React.useState(false);




  const navigate = useNavigate();




  async function obterTudo() {
    await doIt(async () => {
      const administrador = await api.obterInformacoesAdministradorLogado();
      const sistemas = await api.listarSistemas();
      const clas = await api.listarClas();

      setNome(administrador.info.nome);
      setImagem(administrador.info.imagem);

      setSistemas(sistemas);
      setClas(clas);

      setLoadingTudo(false);
    }, {
      onError: function () {
        setLoadingTudo(false)
      }
    });
  }




  function redefinirCampos() {
    setEditarCla(false);
    setEditarEspiral(false);
    setClaId('');
    setClaImagem();
    setClaLinkImagem('');
    setClaNome('');
    setClaCor('');
    setClaDescricao('');
    setSistemasSelecionados([]);
    setEspirais([]);
    setEspiralNome('');
    setEspiralDescricao('');
    setEspiralAtivo(false);
    setSistemasSelecionados([]);
    setClaAtivo(false);
    setUpdated(!updated);
  }




  function renderizarImagem() {
    if(claImagem.length > 0) {
      return URL.createObjectURL(claImagem[0]);
    }
  }




  async function atualizarAvatarPerfil() {
    await doIt(async () => {
      setLoading(true);

      await api.editarAvatar(novaImagem);
      toast('Imagem atualizada com sucesso.');

      setLoading(false);

      await obterTudo();
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }




  async function criarNovoCla() {
    await doIt(async () => {
      setLoading(true);
      
      const idCriado = await api.criarCla(claNome, claDescricao, claAtivo, claCor, sistemasSelecionados);
      toast('Clã criado com sucesso.');
      if (claImagem) {
        try {
          await api.atualizarIconeCla(idCriado, claImagem[0]);
          toast('Ícone salvo com sucesso.');
        } catch (err) {
          toast(err.response.data.erro);
        }
      }
      redefinirCampos();
      await obterTudo();

      setLoading(false);
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }




  async function atualizarCamposCla() {
    await doIt(async () => {
      setLoading(true);
      
      await api.editarCla(claId, claNome, claDescricao, claAtivo, claCor, sistemasSelecionados);
      toast('Clã editado com sucesso.');
      
      if(claImagem?.length === 0 || claImagem?.length === 1){
        await atualizarIconeDeCla();
      }
      
      
      redefinirCampos();
      await obterTudo();
      setLoading(false);
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }




  async function atualizarIconeDeCla() {
    await doIt(async () => {
      setLoading(true);

      await api.atualizarIconeCla(claId, claImagem[0]);
      toast('Ícone atualizado com sucesso.');
      
      setLoading(false);
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }




  async function handleClaSalvar() {
    editarCla ? await atualizarCamposCla() : await criarNovoCla()
  }




  async function handleSelecionarSistema(sistemaId) {
    const array = sistemasSelecionados;
    const itemPos = array.indexOf(sistemaId);

    setSistemasSelecionados([]);

    if (itemPos < 0)
      array.push(sistemaId);
    else
      array.splice(itemPos, 1);
    
    setSistemasSelecionados([...array]);
  }




  async function handleClaAlterar(item) {
    if (claId !== item.id) {
      redefinirCampos();
      setClaId(item.id);
  
      let espirais = await api.listarEspiraisDeCla(item.id);
      setEspirais(espirais);
      
      setSistemasSelecionados([]);
      setSistemasSelecionados([...item.sistemas]);

      setClaLinkImagem(item.estilo.icone);

      setClaNome(item.info.nome);
      setClaDescricao(item.info.descricao);
      setClaCor(item.estilo.fundo);
      setClaAtivo(item.info.ativo);
      
      

      if (selecionado === 'informacoes')
        setEditarCla(true);
    }
  }




  async function handleClaDeletar(item) {
    confirmar({
      titulo: 'Deletar clã',
      descricao: 'Tem certeza que deseja deletar o clã?',
      onSim: _ => 
        doIt(async () => {
          setLoading(true);
    
          await api.deletarCla(item.id);
          toast('Clã deletado com sucesso.');
          await obterTudo();
          
          setLoading(false);
        }, {
          onError: function () {
            setLoading(false)
          }
        })
    })
  }




  async function handleEspiralCriar() {
    await doIt(async () => {
      setLoading(true);
      
      if (claId) {
        if (editarEspiral) {
          await api.editarEspiral(claId, espiralId, espiralNome, espiralDescricao, espiralAtivo);
          toast('Espiral editada com sucesso.');
          setEditarEspiral(false);
          setEspiralId('')
          setEspiralNome('');
          setEspiralDescricao('');
          setEspiralAtivo(false);
        } else {
          await api.criarEspiral(claId, espiralNome, espiralDescricao, espiralAtivo);
          toast('Espiral criada com sucesso.');
          setEditarEspiral(false);
          setEspiralId('');
          setEspiralNome('');
          setEspiralDescricao('');
          setEspiralAtivo(false);
        }
        const espirais = await api.listarEspiraisDeCla(claId);
        setEspirais(espirais);
      } else
        toast('Selecione um clã para editar antes de criar uma espiral');

      setLoading(false);
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }




  async function handleEspiralEditar(item) {
    try {
      if (claId) {
        setEspiralId(item.id);
        setEspiralNome(item.nome);
        setEspiralDescricao(item.descricao);
        setEspiralAtivo(item.ativo);
        setEditarEspiral(true);
      } else
        toast('Selecione um clã para editar antes de criar uma espiral');
    } catch (err) {
      toast(err.response.data.erro);
    }
  }




  async function handleEspiralDeletar(item) {
    confirmar({
      titulo: 'Deletar espiral',
      descricao: 'Tem certeza que deseja deletar a espiral?',
      onSim: _ =>
        doIt(async () => {
          setLoading(true);
          
          if (claId) {
            await api.deletarEspiral(claId, item.id);
            toast('Espiral deletada com sucesso.');
            const espirais = await api.listarEspiraisDeCla(claId);
            setEspirais(espirais);
          } else
            toast('Selecione um clã para editar antes de criar uma espiral');
    
          setLoading(false);
        }, {
          onError: function () {
            setLoading(false)
          }
        })
    })
  }




  async function handleOrdemAlterada(item, ordem) {
    await doIt(async () => {
      if (claId) {
        await api.atualizarOrdemEspiral(claId, item.id, ordem);
        toast('Ordem alterada com sucesso.');
      } else
        toast('Selecione um clã para editar antes de criar uma espiral');
    });
  }




  async function logado() {
    if (!storage('dmk-user')) {
       navigate('/admin/conta/login');
       toast('Usuário administrador não está conectado, faça o login.');
    }
  }




  React.useEffect(() => {
    logado();

    //eslint-disable-next-line
  }, []) 
  



  React.useEffect(() => {
    obterTudo();
    
    //eslint-disable-next-line
  }, [])
  



  React.useEffect(() => {
    if (novaImagem && typeof(novaImagem) !== 'string') {
      async function atualizar() {
        await atualizarAvatarPerfil();
        setNovaImagem();
        await obterTudo();
      }
      atualizar();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novaImagem])



  
  return (
    <SApp>
      <MenuAdmin
        className='menu'
        config={menu}
        tipo='flutuante'
        acesso='admin'
      />
      <LoadingFullscreen
        loading={loading}
      />
      <LoadingFullscreen
        loading={loadingTudo}
      />
      {!loadingTudo && (
        <div className='renderizar'>
          <Informacoes>
            <CabecalhoAdmin>
              <div className='avatar'>
                <img src={imagem ? `${API_ADDRESS}/${imagem}` : '/assets/images/avatar.jpg'} alt="foto de perfil" />
                <div className='camera'>
                  <input
                    type='file'
                    onChange={e => setNovaImagem(e.target.files[0])}
                    accept='image/*' />
                </div>
              </div>
              <div>
                <p>Seja bem-vindo(a)</p>
                <h1>{nome}</h1>
              </div>
            </CabecalhoAdmin>
            <Box>
              <GerenciarCla>
                <h2>{editarCla ? 'Alterando' : 'Novo'} Clã</h2>
                <div className='navegacao'>
                  <Navegacao
                    nav={selecionado}
                    state={(s) => s === 'informacoes'}
                    onClick={async _ => {
                      setSelecionado('informacoes');
                      if (claId)
                        setEditarCla(true);
                    }}
                  >
                    Informações básicas
                  </Navegacao>

                  <Navegacao
                    nav={selecionado}
                    state={(s) => s === 'espirais'}
                    onClick={async _ => {
                      setSelecionado('espirais');
                    }}
                  >
                    Espirais
                  </Navegacao> 
                </div>
              </GerenciarCla>
              {selecionado === 'informacoes' && (
                <InformacoesBasicas>
                  <div className='fields'>
                    <CampoCla>
                      <IconeCla>
                        {claImagem && (<img
                          src={claImagem.length ? renderizarImagem() : '/assets/images/avatar.jpg'}
                          alt="icone do cla"
                        />)}
                        {!claImagem && (<img
                          src={claLinkImagem ? `${API_ADDRESS}/${claLinkImagem}` : '/assets/images/avatar.jpg'}
                          alt="icone do cla"
                        />)}
                        <div className='camera'>
                          <input
                            type='file'
                            files={claImagem}
                            onChange={e => setClaImagem(e.target.files)}
                            accept='image/*' />
                        </div>
                      </IconeCla>
                      <InputComLabel
                        value={claNome}
                        onChange={e => setClaNome(e.target.value)}
                        placeholder='Nome'
                      />
                      <InputComLabel
                        value={claCor}
                        onChange={e => setClaCor(e.target.value)}
                        placeholder='Cor de fundo'
                      />
                      <TextAreaComLabel
                        value={claDescricao}
                        onChange={e => setClaDescricao(e.target.value)}
                        placeholder='Descricao'
                      />
                      <div>
                        <Checkbox
                          checked={claAtivo}
                          onChange={_ => setClaAtivo(!claAtivo)}
                          item='Ativo'
                          color='#FFFFFF'
                        />
                        <Button onClick={handleClaSalvar}>Salvar</Button>
                      </div>
                    </CampoCla>
                    <Sistemas>
                      <h3>Sistemas</h3>
                        {sistemas.length <= 0 && (<p className='warn'>Nenhum sistema encontrado.</p>)}
                          <Overflow className='listagem'>
                            {sistemas.map((item) => (
                              <CheckboxComPadding
                                key={item.id}
                                value={item.id}
                                item={item.nome}
                                checked={sistemasSelecionados.find(x => x === item.id)}
                                onChange={(e) => handleSelecionarSistema(e.target.value)}
                              />
                            ))}
                          </Overflow>
                    </Sistemas>
                  </div>
                </InformacoesBasicas>
              )}
              {selecionado === 'espirais' && (
                <Espirais>
                  <CampoEspiral>
                    <h3>{editarEspiral ? 'Alterando' : 'Novo'}  Espiral</h3>
                    <InputComLabel
                      value={espiralNome}
                      onChange={e => setEspiralNome(e.target.value)}
                      placeholder='Nome'
                    />
                    <TextAreaComLabel
                      value={espiralDescricao}
                      onChange={e => setEspiralDescricao(e.target.value)}
                      placeholder='Descrição'
                    />
                    <div>
                      <Checkbox
                        checked={espiralAtivo}
                        onChange={_ => setEspiralAtivo(!espiralAtivo)}
                        item='Ativo'
                        color='#FFFFFF'
                      />
                      <Button onClick={handleEspiralCriar}>Salvar</Button>
                    </div>
                  </CampoEspiral>
                  <div className='gerenciar-espirais'>
                    <h3>Gerenciar Espirais</h3>
                    <Overflow style={{marginTop: '6px'}}>
                      {espirais.length <= 0 && (<p className='warn'>Nenhuma espiral encontrada.</p>)}
                      <ListaOrdenacaoBlack
                        items={espirais}
                        gap='.4rem'
                        descricao={(item) => item.nome}
                        ordem={(item) => item.ordem}
                        onOrdemAlterada={handleOrdemAlterada}
                        onAlterar={handleEspiralEditar}
                        onDeletar={handleEspiralDeletar}
                      />
                    </Overflow>
                  </div>
                </Espirais>
              )}

              <ListagemClas>
                <h2>Gerenciar Clãs</h2>
                <TabelaGerenciarClas
                  clas={clas}
                  onSelecionar={handleClaAlterar}
                  onAlterar={handleClaAlterar}
                  onDeletar={handleClaDeletar}
                />
              </ListagemClas>
            </Box>
          </Informacoes>
        </div>
      )}
    </SApp>
  )
}

export default App;