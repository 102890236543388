import React, { useState } from 'react';
import { toast } from 'react-toastify';


import { Container } from './index.styled';


import * as acompanhamentoApi from '../../api/admin/acompanhamentoAPI';
import doIt from '../../servicos/doIt';




let mediaRecorder;
let audio;

export function MensagemAudio({ canal }) {
  const [carregando, setCarregando] = useState(false);
  const [gravando, setGravando] = useState(false);
  let chunks = [];
  
  
  
  async function initRecorder() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
				audio: true,
				video: false,
			});

			mediaRecorder = new MediaRecorder(stream);

			mediaRecorder.ondataavailable = ({ data }) => {
				chunks.push(data);
			};

			mediaRecorder.onstop = _ => {
				const blob = new Blob(chunks, { type: 'audio/mp3; code=opus' });
				const file = new File([blob], 'audio');
        audio = file;

        stream.getTracks().forEach(track => track.stop());
			};

    } catch (err) {
      toast.error('Habilite o acesso ao microfone');   
    }
	}


  
  async function gravarAudio() {
    await initRecorder();
    setGravando(true);
    setCarregando(true);
    await mediaRecorder.start();
  }



  async function pararGravacao() {
    setGravando(false);
    setCarregando(false);
    await mediaRecorder.stop();
  }



  async function enviarAudio() {
    await doIt(async _ => {
      if (!canal) {
        toast.warn('Selecione um canal');
        return;
      } else if (!audio || audio === undefined) {
        toast.warn('Nenhum áudio foi gravado');
        return;
      }
      
      setCarregando(true);
      await acompanhamentoApi.enviarMensagemAudioDiscord(canal, audio);
      toast.success('Mensagem de voz enviada com sucesso!');
      setCarregando(false);
    }, {time: 1});
  }
  

  
  return (
		<Container>
			{!gravando ? (
				<img
          className='icone_gravador'
          src='/assets/images/icones/icone_gravar.svg'
					alt='Iniciar Gravação'
          onClick={_ => gravarAudio()}
          disabled={carregando}

				/>
			) : (
				<img
          className='icone_gravador'
					src='/assets/images/icones/icone_parar.svg'
					alt='Parar Gravação'
					onClick={_ => pararGravacao()}
          disabled={carregando}
				/>
      )}

      <img className='icone_gravador' disabled={carregando} onClick={enviarAudio} src="/assets/images/icones/enviar_audio.svg" alt="" />
		</Container>
	);
}
