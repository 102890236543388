import * as React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import storage from 'local-storage';
import { API_ADDRESS } from '../../../../api/apiConfig.js';
import * as api from '../../../../api/usuario/contaAPI.js';
import doIt from '../../../../servicos/doIt.js';





import SApp, {
  Box,
  Aluno,
  CabecalhoAluno,
  Informacoes,
  Overflow
} from './index.styled.js';
import CardMeuCla from '../../../../components/cardMeuCla/index.js';
import LoadingFullscreen from '../../../../components/loading/fullscreen/index.js';
import { Menu as MenuAluno } from '../../../../components/menu/index.js';
import { menu } from '../menu.js';





function App() {
  const [nome, setNome] = React.useState('');
  const [imagem, setImagem] = React.useState('');
  const [desde, setDesde] = React.useState('');
  const [ultimo, setUltimo] = React.useState('');
  const [clas, setClas] = React.useState([])

  const [novaImagem, setNovaImagem] = React.useState();

  const [loading, setLoading] = React.useState(false);
  const [loadingTudo, setLoadingTudo] = React.useState(true);




  const navigate = useNavigate();
  const imgRef = React.useRef(null);




  async function obterTudo() {
    await doIt(async () => {
      const aluno = await api.obterInformacoesAlunoLogado();
      const clas = await api.listarClasAlunoLogado();

      setNome(aluno.info.nome);
      setImagem(aluno.info.imagem);

      setDesde(aluno.criacao);
      setUltimo(aluno.ultLogin);

      setClas(clas);

      setLoadingTudo(false);
    }, {
      onError: function () {
        setLoadingTudo(false)
      }
    });
  }




  function atualizarAvatarPerfil() {
    doIt(async () => {
      setLoading(true);

      await api.editarAvatar(novaImagem);
      toast('Imagem atualizada com sucesso.');

      setLoading(false);

      await obterTudo();
    }, {
      onError: function () {
        setLoading(false)
      }
    });
  }





  async function logado() {
    if (!storage('dmk-user')) {
      navigate('/conta/login');
      toast('Usuário não está conectado, faça o login.');
    }
  }




  React.useEffect(() => {
    logado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  React.useEffect(() => {
    obterTudo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  React.useEffect(() => {
    if (novaImagem) {
      atualizarAvatarPerfil();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novaImagem])


  


  return (
    <SApp>
      <MenuAluno
        className='menu'
        config={menu}
        tipo='flutuante'
        acesso='usuário'
      />
      <LoadingFullscreen
        loading={loading}
      />
      <LoadingFullscreen
        loading={loadingTudo}
      />
      {!loadingTudo && (
        <Box>
          <Aluno>
            <CabecalhoAluno>
              <div className='avatar'>
                <img src={imagem ? `${API_ADDRESS}/${imagem}` : '/assets/images/avatar.jpg'} alt="foto de perfil" />
                <div className='camera' onClick={_ => imgRef.current.click()} title={novaImagem?.name}>
                  <input
                    ref={imgRef}
                    type='file'
                    onChange={e => setNovaImagem(e.target.files[0])}
                    accept='image/*' />
                </div>
              </div>
              <div>
                <p>Seja bem-vindo(a)</p>
                <h1>{nome}</h1>
              </div>
            </CabecalhoAluno>
            <Informacoes>
              <Overflow>
                {clas?.map(item => (
                  <CardMeuCla
                    className='cla'
                    key={item.id.toString()}
                    item={item}
                    desde={desde}
                    ultimo={ultimo}
                  />
                ))}
                {!clas[0] && (
                  <h2 className='nenhum'>Você não possui cursos no momento.</h2>
                )}
              </Overflow>
            </Informacoes>
          </Aluno>
        </Box>
      )}
    </SApp>
  )
}

export default App;