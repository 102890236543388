import { getApi } from "./base";
const api = getApi();




export async function listarConteudos(claId, espiralId) {
	const r = await api().get(`admin/conteudo/${claId}/${espiralId}/playlist`);
	return r.data;
}



export async function alterarOrdemConteudo(claId, espiralId, conteudoId, ordem) {
	const r = await api().put(`admin/conteudo/${claId}/${espiralId}/playlist/${conteudoId}/ordem`, { novaOrdem: ordem });
	return r.status;
}



export async function deletarConteudo(claId, espiralId, conteudoId) {
	const r = await api().delete(`admin/conteudo/${claId}/${espiralId}/playlist/${conteudoId}`);
	return r.status;
}



export async function salvarNovoConteudo(claId, espiralId, parte, titulo, descricao, link, correcao, capa, tempo) {
	const form = new FormData();
	form.append('parte', parte.trim() ?? '');
	form.append('titulo', titulo);
	form.append('descricao', descricao);
	form.append('link', link);
	form.append('correcao', correcao === true);
	form.append('capa', capa);
	form.append('tempo', tempo);

	const r = await api().post(`/admin/conteudo/${claId}/${espiralId}/playlist`, form, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return r.data;
}



export async function editarConteudo(claId, espiralId, conteudoId, parte, titulo, descricao, link, correcao, capa,tempo) {
	const form = new FormData();
	form.append('parte', parte.trim() ?? '');
	form.append('titulo', titulo);
	form.append('descricao', descricao);
	form.append('link', link);
	form.append('correcao', correcao === true);
	form.append('capa', capa);
	form.append('tempo', tempo);

	const r = await api().put(`/admin/conteudo/${claId}/${espiralId}/playlist/${conteudoId}`, form, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);

	return r.status;
}



export async function consultarConteudo(claId, espiralId, conteudoId) {
	const r = await api().get(`/admin/conteudo/${claId}/${espiralId}/playlist/${conteudoId}`);
	return r.data;
}
