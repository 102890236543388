import axios from 'axios';
import localStorage from 'local-storage';
import { API_ADDRESS } from '../apiConfig';


export function getApi(basePath = '') {
  return () => axios.create({
    baseURL: `${API_ADDRESS}/${basePath}`,
    headers: {
      'x-access-token': localStorage('dmk-user')?.token ?? ''
    }
  })
}


