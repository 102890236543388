import styled, { css } from 'styled-components';

export const Item = styled.div`
  ${props => props.isDragging && css`
    border: 2px dashed rgba(0, 0, 0, 0.2);
    background: transparent;
    box-shadow: none;
    cursor: grabbing;

    p, img, svg {
      opacity: 0;
    }
  `}
`;