import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { menuEspiral as menu } from '../menu';
import './index.scss';


import { CardMembro } from '../../../../../components/cardMembro';
import CardEspiral from '../../../../../components/cardEspiral';
import { GraficoBarras } from '../../../../../components/grafico';
import { Modal } from '../../../../../components/modal';
import { Carrossel } from '../../../../../components/carrossel';
import { TituloAdmin1, TituloAdmin2 } from '../../../../../components/styled/textos/titulosAdmin';
import Input from '../../../../../components/styled/inputs/comLabel';
import PaginaAdmin from '../../../../../components/paginas/paginaAdmin';
import { SubTitulo } from './index.styled';
import BotaoRedondo from '../../../../../components/styled/botoes/redondo/index.styled';
import InputRedondo from '../../../../../components/styled/inputs/redondo/index.styled';
import Select from '../../../../../components/styled/select';


import * as acompanhamentoApi from '../../../../../api/admin/acompanhamentoAPI';
import doIt from '../../../../../servicos/doIt';
import { buscarImagem } from '../../../../../servicos/buscarImagem';




export default function Index() {
  const [cla, setCla] = useState();
  const [espirais, setEspirais] = useState();
  const [membros, setMembros] = useState();
  const [membrosFiltrados, setMembrosFiltrados] = useState([])
  const [alunosPorEspiral, setAlunosPorEspiral] = useState([]);
  const [membrosSemVinculo, setMembrosSemVinculo] = useState([]);
  const [membrosPesquisa, setMembrosPesquisa] = useState('');


  const [modalVincularEspiral, setModalVincularEspiral] = useState(false);
  const [espiralSelecionada, setEspiralSelecionada] = useState(null);
  const [qtdLeitura, setQtdLeitura] = useState(0);
  const [qtdForum, setQtdForum] = useState(0);


  const [modalVincularServidor, setModalVincularServidor] = useState(false);
  const [servidorId, setServidorId] = useState('');
  const [boasVindasId, setBoasVindasId] = useState('');
  const [personalDevId, setPersonalDevId] = useState('');
  const [espiraisId, setEspiraisId] = useState('');
  const [ajudaId, setAjudaId] = useState('');
  const [grupoEstudoId, setGrupoEstudoId] = useState('');

  const [modalVincularMembro, setModalVincularMembro] = useState(false);
  const [membroSelecionado, setMembroSelecionado] = useState(null);
  const [membroDiscordSelecionado, setMembroDiscordSelecionado] = useState(null);

  const [carregando, setCarregando] = useState(false)
  const navigate = useNavigate();
  const claId = useParams().cla;



  useEffect(() => {
    carregarCla();
    carregarEspirais();
    carregarMembros();
    carregarGrafico();

    //eslint-disable-next-line
  }, []);



  useEffect(() => {
    filtrarMembros();
    //eslint-disable-next-line
  }, [membrosPesquisa]);



  function gerarDataFormatada(ultAtualizacao) {
    if (!ultAtualizacao || !ultAtualizacao.trim()) return 'nunca';
    return new Date(ultAtualizacao).toLocaleDateString('pt-BR').substring(0, 4);
  }



  function filtrarMembros() {
    const pesquisa = membrosPesquisa.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').toLowerCase();

    if (!pesquisa) return setMembrosFiltrados(membros);

    setMembrosFiltrados(
      membros.filter(item => {
        const nome = item.nome.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').toLowerCase();
        return nome.includes(pesquisa) && item;
      })
    );
  }



  async function carregarCla() {
    await doIt(async _ => {
      const claEncontrado = await acompanhamentoApi.buscarCla(claId);
      const categorias = claEncontrado?.servidor?.categorias;
      setCla(claEncontrado);
      setServidorId(claEncontrado.servidor.id);
      setAjudaId(categorias.boasVindasId);
      setEspiraisId(categorias.espiraisId);
      setBoasVindasId(categorias.boasVindasId);
      setGrupoEstudoId(categorias.grupoEstudoId);
      setPersonalDevId(categorias.personalDevId);

      if (!claEncontrado?.servidor?.id) {
        toast.warn('O servidor não foi vinculado');
        return;
      }
      const membrosSemVinculoEncontrados = await acompanhamentoApi.buscarMembrosSemVinculo(claId);

      membrosSemVinculoEncontrados.sort((a, b) => {
        const n1 = a.nome.toLowerCase(),
          n2 = b.nome.toLowerCase();

        if (n1 < n2) return -1;
        else if (n1 > n2) return 1;
        else return 0;
      });

      setMembrosSemVinculo(membrosSemVinculoEncontrados);

      return claEncontrado;
    }, { suppressError: true });
  }



  async function carregarEspirais() {
    doIt(async _ => {
      const espiraisEncontradas = await acompanhamentoApi.listarEspirais(claId);
      setEspirais(espiraisEncontradas);
    });
  }



  async function carregarMembros() {
    doIt(async _ => {
      const membrosEncontrados = await acompanhamentoApi.listarMembrosEmCla(claId);

      membrosEncontrados.sort((a, b) => {
        const n1 = a.nome.toLowerCase(),
          n2 = b.nome.toLowerCase();

        if (n1 < n2) return -1;
        else if (n1 > n2) return 1;
        else return 0;
      });

      setMembros(membrosEncontrados);
      setMembrosFiltrados(membrosEncontrados);
    });
  }



  async function carregarGrafico() {
    doIt(async _ => {
      const alunosPorEspiralEncontrados = await acompanhamentoApi.listarQuantidadeAlunosPorEspiral(claId);
      alunosPorEspiralEncontrados.sort((a, b) => b.qtd - a.qtd);

      setAlunosPorEspiral(alunosPorEspiralEncontrados);
    });
  }



  async function carregarMembrosSemVinculo() {
    doIt(async _ => {
      const membrosSemVinculoEncontrados = await acompanhamentoApi.buscarMembrosSemVinculo(claId);

      membrosSemVinculoEncontrados.sort((a, b) => {
        const n1 = a.nome.toLowerCase(),
          n2 = b.nome.toLowerCase();

        if (n1 < n2) return -1;
        else if (n1 > n2) return 1;
        else return 0;
      });

      setMembrosSemVinculo(membrosSemVinculoEncontrados);
    });
  }



  async function sincronizarEspiral() {
    doIt(async _ => {
      setCarregando(true);
      const r = await acompanhamentoApi.sincronizarEspiral(claId, espiralSelecionada.id, qtdLeitura, qtdForum);
      await carregarEspirais();
      await carregarMembrosSemVinculo()
      setQtdLeitura(0);
      setQtdForum(0);
      setModalVincularEspiral(false);
      setCarregando(false);
      if (r !== 204) throw new Error('Não foi possível sincronizar a espiral');
      toast.success('Espiral sincronizada com sucesso!');
    },
      {
        onError: (e) => { setCarregando(false) }
      });
  }



  async function sincronizarServidor() {
    doIt(async _ => {
      setCarregando(true);
      const r = await acompanhamentoApi.sincronizarServidor(claId, servidorId, boasVindasId, personalDevId, espiraisId, ajudaId, grupoEstudoId);
      setModalVincularServidor(false);
      setCarregando(false);
      if (r !== 204) throw new Error('Não foi possível sincronizar o clã');

      carregarCla();
      carregarEspirais();
      carregarMembros();
      carregarGrafico();

      toast.success('Clã sincronizado com sucesso!');
    },
      {
        onError: (e) => { setCarregando(false) }
      });
  }



  async function sincronizarMembro() {
    doIt(async _ => {
      setCarregando(true);
      const r = await acompanhamentoApi.sincronizarMembro(claId, membroSelecionado.id, membroDiscordSelecionado);
      await carregarMembros();
      await carregarMembrosSemVinculo();

      setModalVincularMembro(false);
      setCarregando(false);
      if (r !== 204) throw new Error('Não foi possível sincronizar o aluno');
      toast.success('Membro sincronizado com sucesso!');
      setMembroDiscordSelecionado(null);
      setMembroSelecionado(null);
    },
      {
        onError: (e) => { setCarregando(false) }
      });
  }





  return (
    <PaginaAdmin
      menu={menu}
      carregando={cla === undefined}
      className='acompanhamento_espiral_admin'>
      <Modal
        ativo={modalVincularEspiral}
        setAtivo={setModalVincularEspiral}
        titulo='Vincular Espiral'
        descricao='Informe a quantidade de Leituras e Fóruns dessa espiral:'
        className='sincronizar-espiral-modal'>
        <div>
          <Input
            mode='light'
            min='0'
            placeholder='Qtd. de Leituras'
            type='number'
            value={qtdLeitura}
            onChange={e => setQtdLeitura(e.target.value)}
          />

          <Input
            mode='light'
            min='0'
            placeholder='Qtd. de Fóruns'
            type='number'
            value={qtdForum}
            onChange={e => setQtdForum(e.target.value)}
          />
        </div>

        <BotaoRedondo
          padding='5px 30px'
          background='#3A73B9'
          disabled={carregando}
          onClick={sincronizarEspiral}>
          Vincular
        </BotaoRedondo>
      </Modal>

      <Modal
        ativo={modalVincularServidor}
        setAtivo={setModalVincularServidor}
        titulo='Configurar Servidor'
        descricao="Vinculo de ID's"
        className='sincronizar-servidor-modal'>
        <div className='inputs'>
          <div>
            <h1>Servidor</h1>

            <div>
              <div>
                <h2>ID:</h2>
              </div>
              <div>
                <input
                  value={servidorId}
                  onChange={e => setServidorId(e.target.value)}
                  placeholder='ABCDEFG'
                />
              </div>
            </div>
          </div>

          <div>
            <h1>Categorias</h1>

            <div>
              <div>
                <h2>ID Boas-Vindas:</h2>
                <h2>ID Personal Dev:</h2>
                <h2>ID Espirais:</h2>
                <h2>ID Ajuda:</h2>
                <h2>ID Grupo de Estudo:</h2>
              </div>
              <div>
                <input
                  value={boasVindasId}
                  onChange={e => setBoasVindasId(e.target.value)}
                  placeholder='ABCDEFG'
                />
                <input
                  value={personalDevId}
                  onChange={e => setPersonalDevId(e.target.value)}
                  placeholder='ABCDEFG'
                />
                <input
                  value={espiraisId}
                  onChange={e => setEspiraisId(e.target.value)}
                  placeholder='ABCDEFG'
                />
                <input
                  value={ajudaId}
                  onChange={e => setAjudaId(e.target.value)}
                  placeholder='ABCDEFG'
                />
                <input
                  value={grupoEstudoId}
                  onChange={e => setGrupoEstudoId(e.target.value)}
                  placeholder='ABCDEFG'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='button'>
          <BotaoRedondo
            padding='5px 30px'
            background='#3A73B9'
            onClick={sincronizarServidor}
            disabled={carregando}>
            Salvar
          </BotaoRedondo>
        </div>
      </Modal>

      <Modal
        ativo={modalVincularMembro}
        setAtivo={setModalVincularMembro}
        titulo='Vincular Membro'
        className='sincronizar-membros-modal'>
        <Select
          mode='light'
          defaultValue='default'
          placeholder='Perfil do Membro'
          onChange={e => setMembroDiscordSelecionado(e.target.value)}>
          <option value='default' disabled hidden>
            Selecione
          </option>
          {membrosSemVinculo?.map(item => (
            <option
              value={item.membroId}
              key={item.membroId}>{`${item.nome} #${item.cod}`}</option>
          ))}
        </Select>
        <BotaoRedondo
          padding='5px 30px'
          background='#3A73B9'
          onClick={sincronizarMembro}
          disabled={carregando}
          className='botao'>
          Salvar
        </BotaoRedondo>
      </Modal>

      <main>
        <div className='cla-titulo'>
          <TituloAdmin1
            color='#3973BA'
            fontSize='34px'
            className='discord-titulo'
            onClick={_ => navigate('/admin/acompanhamento/cla')}>
            {cla?.info.nome}
          </TituloAdmin1>

          <div onClick={_ => setModalVincularServidor(true)}>
            <img src='/assets/images/icones/link.svg' alt='' />
            <span>Vincular CLÃ com Discord</span>
          </div>
        </div>

        <section className='membros'>
          <div className='titulo'>
            <TituloAdmin2 fontSize='18px'>Membros ({membros?.length})</TituloAdmin2>
            <InputRedondo
              mode='light'
              placeholder='Buscar membro'
              onChange={e => setMembrosPesquisa(e.target.value)}
            />
          </div>

          <div className='carrossel'>
            <Carrossel>
              {membrosFiltrados?.map((item, index) => (
                <CardMembro
                  titulo={item.nome}
                  id={item.membroId}
                  key={item.alunoId}
                  imagem={buscarImagem(item.imagem, '/assets/images/avatar.jpg')}
                  link={`/admin/acompanhamento/cla/${claId}/aluno/${item.alunoId}`}
                  onVincular={() => {
                    setMembroSelecionado({ id: item.alunoId, pos: index })
                    setModalVincularMembro(true)
                  }}
                />
              ))}
            </Carrossel>
          </div>
        </section>

        <section className='espirais'>
          <TituloAdmin2 color='#000' fontSize='22px' style={{ marginBottom: '25px' }}>
            Espirais
          </TituloAdmin2>

          <div>
            <Carrossel>
              {espirais?.map((item, index) => (
                <CardEspiral
                  key={item.espiralId}
                  titulo={item.nome}
                  atualizacao={`Última atualização ${gerarDataFormatada(
                    item?.ultAtualizacao
                  )}`}
                  rota='/admin/acompanhamento/cla/:cla/espiral/:espiral/'
                  parametros={[claId, item.espiralId]}
                  descricao={
                    <>
                      <SubTitulo>CANAL ID</SubTitulo>
                      <br />
                      {item.canalId !== null ? (
                        <>{item.canalId}</>
                      ) : (
                        <span
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            userSelect: 'none',
                            fontWeight: '600',
                          }}
                          onClick={_ => {
                            setEspiralSelecionada({ id: item.espiralId, pos: index })
                            setModalVincularEspiral(true)
                          }}>
                          VINCULAR
                        </span>
                      )}
                    </>
                  }
                />
              ))}
            </Carrossel>
          </div>
        </section>

        <section className='grafico'>
          <TituloAdmin2 color='#000' fontSize='22px'>
            Alunos por Espiral
          </TituloAdmin2>

          <GraficoBarras
            info={alunosPorEspiral}
            valor={item => item.qtd}
            nome={item => item.espiral}
          />
        </section>
      </main>
    </PaginaAdmin>
  )
};
