import React, { useEffect, useState } from 'react'
import './index.scss';
import ReactPlayer from 'react-player/youtube'


import TextoContainer from '../../../comps/TextoContainer';
import Botao from '../../../../../components/styled/botoes/redondo/index.styled';
import MenuTreinoTeorico from '../../../../../components/menuTreinoTeorico';
import { LoadingIcon } from '../../../../../components/loading/fullscreen/icon';





export default function Correcao({ pergunta, setTela }) {
  const [delayAntesDoVideo, setDelayAntesDoVideo] = useState(30);

  
  useEffect(() => {
    if (delayAntesDoVideo > 0) {
      setDelayAntesDoVideo(delayAntesDoVideo - 1);
    
    }
    //eslint-disable-next-line
  },[delayAntesDoVideo]);


  
  return (
    <main className='tela-correcao-leitura'>
      <MenuTreinoTeorico />
      
      <div className='container'>
        <div className='content'>
          <div className='descricao'>
            <TextoContainer 
              titulo={pergunta.info.titulo}
              descricao={pergunta.info.descricao}
              altura='150px'
              alinhamento={'left'}
            />

            <Botao onClick={() => setTela('resultado')} background='#373737' color='#B7BB0A' padding='10px 15px'> Voltar </Botao>

          </div>

          <div className='video-container'>
            {delayAntesDoVideo <= 0 && <ReactPlayer width={'100%'} height={'100%'}  playing={false} loop={false} muted={false} controls={true} url={pergunta.assets.correcao} /> }
            {delayAntesDoVideo > 0 && <LoadingIcon /> }
          </div>
        </div>
        </div>
    </main>
)
}
